import { useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { useGetModerationReportLazyQuery } from 'pages/moderationReport/api/queries/generated/GetModerationReport';
import groupBy from 'lodash/groupBy';
import { PaymentInfoEnum, PayoutType, UserContractStatus } from 'generated/graphql';
import { useFacilityGroupById } from '../useFacilityGroup';
import { activeHandler } from '../../helpers/isActiveHandler';
import { useCustomSearchParams } from '../../../pages/users/lib/useParams';

export const useGetModerationReport = () => {
  const { params } = useCustomSearchParams();
  const router = useParams();
  const id = router.id;
  const hideUsers = params.get('showAll');

  const [loadGetModerationReport, { data, loading, error }] = useGetModerationReportLazyQuery();
  useEffect(() => {
    if (id) {
      loadGetModerationReport({
        variables: { id, showAll: activeHandler(hideUsers) ?? false },
      });
    }
  }, [hideUsers, id, loadGetModerationReport]);

  const moderationReport = useMemo(() => data?.getModerationReport.report, [data?.getModerationReport.report]);
  const moderationRows = useMemo(() => data?.getModerationReport.rows, [data?.getModerationReport.rows]);
  const { facilityGroupName } = useFacilityGroupById(moderationReport?.facility?.facilityGroupId);
  const activeModerationRows = useMemo(() => {
    const ozon = facilityGroupName === 'OZON';
    const pryamikom = facilityGroupName === 'Прямиком';
    const goodGroup = ozon || pryamikom;

    return moderationRows?.filter(row => {
      const pieceworkRow = row.shifts.filter(shift => shift.payout === PayoutType.Piecework);
      const allowWorkWithoutNaimix = row.user.allowWorkWithoutNaimix;
      const contract = row.user.contracts?.find(
        (el: any) => el.facilityId === moderationReport?.facility?.id && el.status === UserContractStatus.Signed,
      );
      const payment = row.user.registrationState?.paymentInfo === PaymentInfoEnum.Confirmed;
      const namix = allowWorkWithoutNaimix ? true : contract && payment;
      const canModerateWithoutImport = goodGroup ? !moderationReport?.isImportFromFile && !!pieceworkRow.length : true;
      return row.user.isActive && !!row.shiftsCount && canModerateWithoutImport && namix;
    });
  }, [facilityGroupName, moderationRows, moderationReport?.isImportFromFile, moderationReport?.facility?.id]);
  const groupRows = useMemo(() => groupBy(moderationRows, row => row.user.id), [moderationRows]);
  return { moderationReport, moderationRows, loading, error, id, groupRows, activeModerationRows };
};
