import React, { useRef, useState } from 'react';
import ThreeDots24 from 'assets/icons/ThreeDots24';
import OutsideClick from '../../../outsideComponent';
import PushPopup from 'pages/push/components/pushPopup';
import { NotifyLetterPaginatedFragment } from 'pages/pushes/api/fragments/generated/NotifyLetterPaginated.fragment';

interface Props {
  push: NotifyLetterPaginatedFragment;
}

const More = ({ push }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const onShowPopupHandle = () => {
    setShowPopup(value => !value);
  };
  const onShowMoreHandle = () => {
    setShowMore(value => !value);
  };
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <button onClick={onShowMoreHandle} ref={ref}>
        <ThreeDots24 className={'text-smena_text-helper'} />
      </button>
      {showMore && (
        <OutsideClick
          show={showMore}
          setShow={setShowMore}
          triggerRef={ref}
          className="absolute top-full right-0 transform -translate-y-7 -translate-x-1/3 bg-smena_white flex shadow-smena rounded-lg z-10 w-max"
        >
          <button className={'Body1 px-3 py-[9.5px] text-smena_red'} onClick={onShowPopupHandle}>
            Удалить
          </button>
        </OutsideClick>
      )}
      <PushPopup showPopup={showPopup} setShowPopup={setShowPopup} pushId={push.id} />
    </>
  );
};

export default More;
