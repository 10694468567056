import React from 'react';
import { Link } from 'react-router-dom';
import { getUserFIO } from 'utils/helpers/userFullName';
import { useStore } from 'store';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useDeleteWorkerFromRequestShiftMutation } from 'pages/facilityRequests/api/mutations/generated/DeleteWorkerFromRequestShift';

const AddedUser = () => {
  const requestShift = useStore(state => state.requestShift);
  const setShowShiftSidebar = useStore(state => state.setShowShiftSidebar);
  const setUserIntersect = useStore(state => state.setUserIntersect);
  const setRequestShift = useStore(state => state.setRequestShift);
  const [deleteWorkerFromRequestShift, { loading: loadingDeleteWorkerFromRequestShift }] =
    useDeleteWorkerFromRequestShiftMutation();
  const deleteWorkerFromRequestShiftHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (requestShift) {
      deleteWorkerFromRequestShift({
        variables: {
          id: requestShift.id,
        },
        update: cache => {
          cache.modify({
            id: `ZayavkaShift:${requestShift.id}`,
            fields: {
              shift() {
                return null;
              },
              shiftId() {
                return null;
              },
            },
          });
        },
      })
        .then(() => {
          successToast('Пользователь удален из смены');
          setShowShiftSidebar(false);
          setUserIntersect(undefined);
          setRequestShift(null);
        })
        .catch(error => {
          errorToast(errorHandler(error));
        });
    }
  };
  return (
    <>
      <div className="Subtitle1 flex gap-x-1">
        <span>Исполнитель:</span>
        <Link to={`/users/${requestShift?.shift?.user?.id}`} className="text-primary">
          {getUserFIO(requestShift?.shift?.user)}
        </Link>
      </div>
      <button
        className="btn-reject_secondary self-end mt-6"
        type="button"
        onClick={deleteWorkerFromRequestShiftHandler}
        disabled={loadingDeleteWorkerFromRequestShift}
      >
        Снять со смены
      </button>
    </>
  );
};

export default AddedUser;
