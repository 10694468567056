import { usePushForm } from '../../utils/hooks/usePushForm';
import Modal from 'ui/modal';

interface Props {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const PushPopupPublic = ({ showPopup, setShowPopup }: Props) => {
  const { toPublishHandle, loading } = usePushForm();
  const showPopupHandle = () => {
    setShowPopup(value => !value);
  };
  const onSubmitHandler = () => {
    toPublishHandle();
    showPopupHandle();
  };
  return (
    <Modal
      title="Опубликовать пуш?"
      open={showPopup}
      onCancel={showPopupHandle}
      onOk={onSubmitHandler}
      okText="Опубликовать"
      okButtonProps={{ disabled: loading }}
    >
      Вы уверены, что хотите опубликовать пуш?
    </Modal>
  );
};

export default PushPopupPublic;
