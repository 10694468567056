import { useCallback } from 'react';
import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';
import { ChartType } from 'generated/graphql';
import { GetStatisticQuery } from 'pages/main/api/queries/generated/GetStatistic';

export const useResponseArray = (data?: GetStatisticQuery | null) => {
  const metricByRole = useCallback(
    (type: ChartType) => {
      return sortBy(data?.getStatistic?.find(metric => metric.type === type)?.data, el =>
        dayjs(el.createdAt).valueOf(),
      );
    },
    [data?.getStatistic],
  );
  return metricByRole(ChartType.CountForemen).map((el, index) => {
    return {
      createdAt: dayjs(el.createdAt).valueOf(),
      worker: metricByRole(ChartType.CountWorkers)[index].value,
      foreman: metricByRole(ChartType.CountForemen)[index].value,
      supervisor: metricByRole(ChartType.CountSupervisors)[index].value,
    };
  });
};
