// import { FacilitiesFragment } from 'pages/main/api/fragments/generated/Facilities.fragment';

import { CityFragment } from 'pages/city/api/fragments/generated/City.fragment';
import { FacilitiesFragment } from 'pages/main/api/fragments/generated/Facilities.fragment';

export const filterCities = (groupId?: string, facilities?: FacilitiesFragment[], cities?: CityFragment[]) => {
  if (!cities?.length) return [];
  if (!facilities?.length) return [];
  if (!groupId)
    return [
      ...new Set(
        facilities.map(facility => {
          if (facility.cityId) {
            return facility.cityId;
          }
        }),
      ),
    ]
      .filter(Boolean)
      .map(cityId => {
        return cities.find(city => city.id === cityId);
      });
  return [
    ...new Set(facilities.filter(facility => facility.facilityGroupId === groupId).map(facility => facility.cityId)),
  ]
    .filter(Boolean)
    .map(cityId => cities?.find(city => city.id === cityId));
};
