import { useMemo } from 'react';
import { getLink } from '../../../utils/helpers/get/getLink';

export const useVacanciesBreadcrumbs = () => {
  return useMemo(() => [{ path: '/vacancies', name: 'Вакансии' }], []);
};
export const useVacanciesHeaderMenu = () => {
  const vacancies = getLink('/vacancies');
  const vacanciesMap = getLink('/vacancies-map');

  return useMemo(() => {
    return [
      {
        name: 'Вакансии',
        link: vacancies,
      },
      {
        name: 'Карта вакансий',
        link: vacanciesMap,
      },
    ];
  }, [vacancies, vacanciesMap]);
};
