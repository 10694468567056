import { useCallback } from 'react';
import parsePhoneNumberFromString, { parsePhoneNumber } from 'libphonenumber-js';
import { convertPhoneToString } from 'utils/helpers/convertPhone';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useSendDownloadSmsMutation } from '../../api/mutations/generated/SendDownloadSms';

interface IUseSubmitForm {
  phone: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

export const useSubmitForm = ({ phone, setError }: IUseSubmitForm) => {
  const [sendDownloadSmsMutation, { loading }] = useSendDownloadSmsMutation();
  const onSubmit = useCallback(() => {
    if (phone.length === 0) {
      return setError('Введите номер');
    }
    if (phone.indexOf('_') !== -1) {
      return setError('Неверное количество цифр');
    }
    const phoneFromMask = parsePhoneNumberFromString(phone)?.nationalNumber;
    if (parsePhoneNumber('8' + phoneFromMask, 'RU').isValid()) {
      sendDownloadSmsMutation({
        variables: {
          phone: convertPhoneToString(phone),
        },
      })
        .then(response => {
          if (response) {
            setError('');
            return successToast('Сообщение успешно отправлено!');
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    } else {
      setError('Некорректный номер телефона');
    }
  }, [phone, sendDownloadSmsMutation, setError]);
  return { loading, onSubmit };
};
