import { useCallback, useMemo } from 'react';
import { ICustomEvent } from 'interfaces/IEvent';
import { useStore } from 'store';
import { useMeRole } from 'store/hooks/useMeRole';
import { useUserShifts } from './useUserShifts';
import useSupervisorShifts from './useSupervisorShifts';
import useTimekeepingShifts from './useTimekeepingShifts';
import { Role } from 'generated/graphql';

const useEvents = () => {
  const { userShifts } = useUserShifts();
  const { facilityId, workpostId } = useStore();
  const supervisorShifts = useSupervisorShifts();
  const supervisorTimekeepingShifts = useTimekeepingShifts(supervisorShifts);
  const timekeepingShifts = useTimekeepingShifts(userShifts);

  const filterFacility = useCallback((event: ICustomEvent) => event.resource.facility?.id === facilityId, [facilityId]);

  const isMeSupervisor = useMeRole([Role.Supervisor]);

  const filterFacilityAndPosition = useCallback(
    (event: ICustomEvent) => filterFacility(event) && event.resource.positionId === workpostId,
    [filterFacility, workpostId],
  );

  return useMemo(() => {
    const events = isMeSupervisor ? supervisorTimekeepingShifts : timekeepingShifts;

    if (facilityId) {
      if (workpostId) {
        return events?.filter(filterFacilityAndPosition);
      }
      return events?.filter(filterFacility);
    }
    return events;
  }, [
    isMeSupervisor,
    supervisorTimekeepingShifts,
    timekeepingShifts,
    facilityId,
    workpostId,
    filterFacility,
    filterFacilityAndPosition,
  ]);
};

export default useEvents;
