import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import ChevronDown from 'assets/icons/chevron-down';
import { ISpoiler } from 'interfaces/ISpoiler';

export const Spoiler = ({
  header,
  children,
  className,
  classNameHeader,
  arrowColor = 'text-smena_text',
  arrowButtonCss,
  defaultShow = true,
  isOpen,
  onToggle,
}: ISpoiler) => {
  const [show, setShow] = useState(defaultShow);

  useEffect(() => {
    if (isOpen !== undefined) {
      setShow(isOpen);
    }
  }, [isOpen]);

  const spoilerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (spoilerRef && defaultShow === true) {
      spoilerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  });

  return (
    <div className={className} ref={spoilerRef}>
      <div
        className={clsx('cursor-pointer relative', classNameHeader, show ? 'mb-5' : '')}
        onClick={() => {
          const newState = !show;
          setShow(newState);
          onToggle && onToggle(newState);
        }}
      >
        {header}
        <button
          type={'button'}
          className={clsx(
            'absolute top-1/2 right-0 transform transition-transform -translate-y-1/2',
            arrowButtonCss,
            show && 'rotate-180',
          )}
        >
          <ChevronDown className={arrowColor} />
        </button>
      </div>
      {show && children}
    </div>
  );
};
