import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ResponsibleUser from 'pages/facility/components/responsibleUsers';
import AutocompleteBlock from 'ui/autocompleteBlock';
import { IOption } from 'interfaces/IOption';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';
import { useMeRole } from 'store/hooks/useMeRole';
import { useManagersByGroupLazyQuery } from 'pages/user/api/queries/generated/ManagersByGroup';
import { useAddManagerToFacilityMutation } from 'pages/user/api/mutations/generated/AddManagerToFacility';
import { useFacility } from 'utils/api/useFacility';
import { Role } from 'generated/graphql';
import { addManagerToFacilityCache } from '../../../user/api/cache/addManagerToFacilityCache';

const ManagersAndDirectorsBlock = () => {
  const { facility, id } = useFacility();
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  const isMeDirector = useMeRole([Role.ClientDirector]);
  const clientManagers = facility?.clientManagers;
  const clientDirectors = facility?.facilityGroup?.clientDirectors;
  const [manager, setManager] = useState<IOption | null>(null);
  const [loadData, { data: dataManagers }] = useManagersByGroupLazyQuery();

  //TODO:check this code

  const managers = dataManagers?.managersByGroup;
  const managersOfFacility = useMemo(
    () =>
      clientManagers?.map(manager => {
        const { firstname, lastname, id } = manager;
        return {
          id,
          name: `${lastname} ${firstname}`,
        };
      }),
    [clientManagers],
  );

  const managerOptions = managers
    ?.map(manager => {
      const { firstname, lastname, id } = manager;
      const name = lastname + ' ' + firstname;
      return { name, id };
    })
    .filter(manager => !managersOfFacility?.find(el => el.id === manager.id));

  const [addManagerToFacility, { loading: loadingManager }] = useAddManagerToFacilityMutation({
    update: (cache, { data }) => {
      if (!id) return;
      addManagerToFacilityCache(cache, { data, facilityId: id });
    },
  });

  const addManagerToFacilityHandler = useCallback(
    (userId: string) => {
      if (!id) return;
      addManagerToFacility({
        variables: {
          facilityId: id,
          userId,
        },
      })
        .then(() => {
          successToast('Менеджер успешно добавлен');
        })
        .catch(err => errorToast(errorHandler(err)));
    },
    [id, addManagerToFacility],
  );

  useEffect(() => {
    if (facility?.facilityGroupId) {
      loadData({
        variables: {
          groupId: facility.facilityGroupId,
        },
      });
    }
  }, [facility?.facilityGroupId, loadData]);

  return (
    <div className="facilityPageBlock blockTemplate">
      <div className="grid grid-cols-8 gap-5">
        <span className="Subtitle1 text-smena_text-secondary inline-block col-span-full">Представители</span>
        <div className="col-span-full grid grid-cols-8 gap-x-5 pb-10 mb-3 border-b border-smena_gray-30">
          <div className="sm:col-span-4 col-span-8">
            <div className="grid grid-cols-[max-content_1fr] gap-x-2 items-baseline ">
              <span className="Subtitle2">Директор</span>
              <div className={'grid gap-y-1'}>
                {clientDirectors?.length ? (
                  clientDirectors.map(clientDirector => (
                    <ResponsibleUser key={clientDirector.id} user={clientDirector} canRemove={false} />
                  ))
                ) : (
                  <span className="Body2 text-smena_text-secondary">Не назначен</span>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-4 col-span-8">
            <div className="grid grid-cols-[max-content_1fr] gap-x-2 items-baseline">
              <span className="Subtitle2">Менеджер</span>
              <div className={'grid gap-y-1'}>
                {clientManagers?.length ? (
                  clientManagers.map(clientManager => (
                    <ResponsibleUser key={clientManager.id} user={clientManager} canRemove={isMeAdminOrSupervisor} />
                  ))
                ) : (
                  <span className="Body2 text-smena_text-secondary">Не назначен</span>
                )}
              </div>
            </div>
          </div>
        </div>
        {(isMeDirector || isMeAdminOrSupervisor) && (
          <div className={'sm:col-span-4 col-span-full sm:col-start-5'}>
            <AutocompleteBlock
              id="managerId"
              label="Менеджеры"
              value={manager?.name}
              onChangeHandler={value => setManager(value)}
              options={managerOptions}
              buttonHandler={() => {
                if (manager) {
                  addManagerToFacilityHandler(manager.id);
                }
              }}
              loading={loadingManager}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ManagersAndDirectorsBlock;
