import dayjs from 'dayjs';

import { useStore } from 'store';
import MainSidebar from 'ui/sidebar';
import CustomEvent from './customEvent';
import { ShiftStage } from 'generated/graphql';

const ShiftsSidebar = () => {
  const { showSidebar, setShowSidebar, selectedSchedule, hideCanceledShifts } = useStore();

  const title = `Смены
  ${selectedSchedule?.length ? dayjs(selectedSchedule[0].start).format('DD MMMM') : ''}`;
  return (
    <MainSidebar title={title} show={showSidebar} setShow={setShowSidebar}>
      {selectedSchedule
        ?.filter(shift => {
          if (hideCanceledShifts) {
            return ![ShiftStage.Refused, ShiftStage.Ineligible].includes(shift.resource.stage as ShiftStage);
          }
          return shift;
        })
        .map(shift => <CustomEvent key={shift.resource?.id} event={shift} />)}
    </MainSidebar>
  );
};

export default ShiftsSidebar;
