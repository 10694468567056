import { ShiftStage } from 'generated/graphql';
import { ShiftFactFragment } from '../../pages/moderationReport/api/fragments/generated/ShiftFact.fragment';

export const shiftStatus = (shift: ShiftFactFragment) => {
  const { moderation, stage } = shift;
  if (stage === ShiftStage.Refused) {
    return {
      text: 'Отменена',
      bg: 'bg-smena_gray-5',
      separator: 'text-smena_gray-30',
    };
  }
  if (stage === ShiftStage.Finished) {
    if (moderation && !moderation.toPayment) {
      return {
        text: 'Без оплаты',
        bg: 'bg-smena_red-extra_light',
        separator: 'text-smena_red-light',
      };
    }
    return {
      text: 'К оплате',
      bg: 'bg-smena_green-extra_light',
      separator: 'text-smena_green-light',
    };
  }
  if (moderation) {
    if (moderation.toPayment) {
      return {
        text: 'К оплате',
        bg: 'bg-smena_green-extra_light',
        separator: 'text-smena_green-light',
      };
    }
    return {
      text: 'Без оплаты',
      bg: 'bg-smena_red-extra_light',
      separator: 'text-smena_red-light',
    };
  }
  return {
    text: 'К модерации',
    bg: 'bg-smena_yellow-extra_light',
    separator: 'text-smena_yellow-light',
  };
};
