import { OchkoReferrersSorting } from 'generated/graphql';

export const twentyOneColumns = [
  {
    Header: 'Реферер',
    header_className: 'table__header',
    name: 'refer',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 350,
      width: 350,
    },
  },
  {
    Header: 'Роль',
    header_className: 'table__header',
    name: 'role',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 115,
      width: 115,
    },
  },
  {
    Header: 'Объект',
    header_className: 'table__header',
    name: 'facility',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 300,
      width: 300,
    },
  },
  {
    Header: 'Друзей',
    header_className: 'table__header',
    name: 'leads',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 115,
      width: 115,
    },
  },
  {
    Header: 'Телефон',
    header_className: 'table__header',
    name: 'referPhone',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 200,
      width: 200,
    },
  },
  {
    Header: 'Дата достижения',
    header_className: 'table__header',
    name: OchkoReferrersSorting.OchkoDate,
    sorted: true,
    className: 'table__content',
    style: {
      minWidth: 200,
    },
  },
  {
    Header: '',
    header_className: 'table__header',
    name: 'action',
    sorted: false,
    className: 'table__content pr-4 flex justify-end',
    style: {
      minWidth: 50,
    },
  },
];
export const twentyOneLeadColumns = [
  {
    Header: 'Друзья',
    header_className: 'table__header',
    name: 'friends',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 335,
      width: 335,
    },
  },
  {
    Header: 'выполнено смен',
    header_className: 'table__header',
    name: 'shifts',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 300,
      width: 300,
    },
  },
  {
    Header: 'статус',
    header_className: 'table__header',
    name: 'status',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 125,
      width: 125,
    },
  },
  {
    Header: 'Дата выполнения',
    header_className: 'table__header',
    name: 'inviteDate',
    sorted: true,
    className: 'table__content',
    style: {
      minWidth: 400,
      width: 400,
    },
  },
];

export const promotionGroupColumns = [
  {
    Header: 'Группа',
    header_className: 'table__header',
    name: 'refer',
    sorted: false,
    className: 'table__content flex gap-x-2 !border-hidden',
    style: {
      minWidth: 350,
    },
  },
  {
    Header: '',
    header_className: 'table__header',
    name: 'action',
    sorted: false,
    className: 'py-4',
    style: {
      minWidth: 50,
      width: 50,
    },
  },
];

export const promotionColumns = [
  {
    Header: 'Название',
    header_className: 'table__header border-r border-smena_gray-5',
    name: 'name',
    sorted: false,
    className: 'table__content !border-b-0',
    style: {
      minWidth: 300,
    },
  },
  {
    Header: 'Статус',
    header_className: 'table__header border-r border-smena_gray-5',
    name: 'status',
    sorted: false,
    className: 'table__content !border-b-0 border-r border-smena_gray-5',
    style: {
      minWidth: 200,
      width: 200,
    },
  },
  {
    Header: 'Дата Начала',
    header_className: 'table__header border-r border-smena_gray-5',
    name: 'dateFrom',
    sorted: false,
    className: 'table__content !border-b-0',
    style: {
      minWidth: 160,
      width: 160,
    },
  },
  {
    Header: 'Дата завершения',
    header_className: 'table__header border-r border-smena_gray-5',
    name: 'dateTo',
    sorted: false,
    className: 'table__content !border-b-0',
    style: {
      minWidth: 160,
      width: 160,
    },
  },
  {
    Header: 'Изменено в объектах',
    header_className: 'table__header',
    name: 'editedInFacilities',
    sorted: false,
    className: 'table__content !border-b-0  !border-r-0',
    style: {
      minWidth: 200,
      width: 200,
    },
  },
  {
    Header: '',
    header_className: 'table__header',
    name: 'action',
    sorted: false,
    className: '!border-b-0 pl-4 py-4',
    style: {
      width: 50,
      minWidth: 50,
    },
  },
];
export const positionsColumns = [
  {
    Header: 'Название',
    header_className: 'table__header border-r',
    name: 'name',
    sorted: false,
    className: 'table__content py-4 pl-4 Table-small',
    style: {
      minWidth: 185,
    },
  },
  {
    Header: 'Самозанятость',
    header_className: 'table__header flex justify-center',
    name: 'selfEmployed',
    sorted: false,
    className: 'table__content flex justify-center',
    style: {
      width: 160,
      minWidth: 150,
    },
  },
];
export const citiesColumns = [
  {
    Header: 'Название',
    header_className: 'table__header border-r',
    name: 'name',
    sorted: true,
    className: 'py-4 pl-4 Table-small border-r',
    style: {
      minWidth: 230,
    },
  },
  {
    Header: 'Часовой пояс',
    header_className: 'table__header',
    name: 'timezone',
    sorted: false,
    className: 'py-4 pl-4 Table-small',
    style: {
      minWidth: 230,
    },
  },
];
export const searchUserUpdateModeration = [
  {
    Header: 'Фамилия',
    header_className: 'table__header',
    name: 'lastname',
    sorted: false,
    className: 'table__content flex gap-x-2 z-10',
    style: {
      minWidth: 150,
      width: 170,
    },
  },
  {
    Header: 'Имя',
    header_className: 'table__header',
    name: 'firstname',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 130,
      width: 150,
    },
  },
  {
    Header: 'Отчество',
    header_className: 'table__header',
    name: 'patronymic',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 130,
      width: 150,
    },
  },
  {
    Header: 'Телефон',
    header_className: 'table__header',
    name: 'phone',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 150,
      width: 155,
    },
  },
  {
    Header: 'Профессия',
    header_className: 'table__header',
    name: 'profession',
    sorted: false,
    className: 'table__content h-[150px]',
    style: {
      minWidth: 240,
      width: 240,
    },
  },
  {
    Header: '',
    header_className: 'table__header',
    name: 'action',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 100,
      width: 115,
    },
  },
];
export const scheduleRequestsColumns = [
  {
    Header: 'Номер',
    header_className: 'table__header !py-2',
    name: 'number',
    sorted: false,
    className: 'py-4 pl-4 Table-small text-primary !py-2',
    style: {
      minWidth: 75,
      width: 75,
    },
  },
  {
    Header: 'Период',
    header_className: 'table__header !py-2',
    name: 'period',
    sorted: false,
    className: 'pl-4 Table-small text-primary !py-2',
    style: {
      minWidth: 160,
      width: 160,
    },
  },
  {
    Header: 'Профессии',
    header_className: 'table__header !py-2',
    name: 'positions',
    sorted: false,
    className: 'table__content border-none !py-2',
    style: {
      minWidth: 150,
      width: 150,
    },
  },
  {
    Header: 'Кол-во смен',
    header_className: 'table__header !py-2',
    name: 'shifts',
    sorted: false,
    className: 'table__content flex gap-x-2 pl-4 border-none !py-2',
    style: {
      minWidth: 150,
      width: 130,
    },
  },
  {
    Header: 'Кол-во часов',
    header_className: 'table__header !py-2',
    name: 'hours',
    sorted: false,
    className: 'table__content flex gap-x-2 border-none !py-2',
    style: {
      minWidth: 150,
      width: 130,
    },
  },
];
export const balanceColumns = [
  {
    Header: 'тип',
    header_className: 'table__header',
    name: 'type',
    sorted: false,
    className: 'py-4 pl-4 Table-small',
    style: {
      minWidth: 100,
      width: 100,
    },
  },
  {
    Header: 'Дата',
    header_className: 'table__header',
    name: 'date',
    sorted: false,
    className: 'py-4 pl-4 Table-small',
    style: {
      minWidth: 100,
      width: 100,
    },
  },
  {
    Header: 'Время',
    header_className: 'table__header',
    name: 'time',
    sorted: false,
    className: 'py-4 pl-4 Table-small',
    style: {
      minWidth: 60,
      width: 60,
    },
  },
  {
    Header: 'основание',
    header_className: 'table__header',
    name: 'reason',
    sorted: false,
    className: 'py-4 pl-4 Table-small flex items-center',
    style: {
      minWidth: 150,
      width: 360,
    },
  },
  {
    Header: 'сумма, ₽',
    header_className: 'py-4 pl-4 Table-H1 justify-end text-smena_text-secondary uppercase',
    name: 'sum',
    sorted: false,
    className: 'py-4 pl-4 Table-small flex justify-end',
    style: {
      minWidth: 130,
      width: 130,
    },
  },
  {
    Header: 'Баланс, ₽',
    header_className: 'py-4 pl-4 Table-H1 justify-end text-smena_text-secondary uppercase',
    name: 'balance',
    sorted: false,
    className: 'py-4 pl-4 Table-small flex justify-end',
    style: {
      minWidth: 130,
      width: 130,
    },
  },
];
export const facilityGroupCasingsColumns = [
  {
    Header: 'Профессия',
    header_className: 'table__header pt-3 pb-6 pr-5 mt-3',
    name: 'profession',
    sorted: true,
    className: 'table__content link',
    style: {
      minWidth: 200,
      width: 200,
    },
  },
  {
    Header: 'Объект',
    header_className: 'table__header',
    name: 'facility',
    sorted: true,
    className: 'table__content',
    style: {
      minWidth: 170,
      width: 170,
    },
  },
  {
    Header: 'Город',
    header_className: 'table__header',
    name: 'city',
    sorted: true,
    className: 'table__content',
    style: {
      minWidth: 170,
      width: 170,
    },
  },
  {
    Header: 'Тип оплаты',
    header_className: 'table__header',
    name: 'payment-type',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 129,
      width: 129,
    },
  },
  {
    Header: 'Ед. измерения',
    header_className: 'table__header',
    name: 'amount-type',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 180,
      width: 180,
    },
  },
  {
    Header: 'Ставка, ₽',
    header_className: 'table__header',
    name: 'cost',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 96,
      width: 96,
    },
  },
  {
    Header: 'СЗ',
    header_className: 'table__header pt-3 pl-2 pb-6 pr-5 mt-3 bg-smena_bb-background_base rounded-tl-lg',
    name: 'self-employer',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 64,
      width: 64,
    },
  },
  {
    Header: 'Бонус СЗ, ₽',
    header_className: 'table__header pt-3 pb-6 pr-3 mr-2 mt-3 bg-smena_bb-background_base rounded-tr-lg',
    name: 'bonus-self',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 96,
      width: 96,
    },
  },
  {
    Header: 'Проб. п.',
    header_className: 'table__header',
    name: 'probation-p',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 65,
      width: 65,
    },
  },
  {
    Header: 'Кол-во дней',
    header_className: 'table__header',
    name: 'days',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 110,
      width: 110,
    },
  },
  {
    Header: 'Ставка, ₽',
    header_className: 'table__header',
    name: 'probation-cost',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 96,
      width: 96,
    },
  },
  {
    Header: 'Мед. книжка',
    header_className: 'table__header',
    name: 'medical-book',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 100,
      width: 100,
    },
  },
  {
    Header: 'Действия',
    header_className: 'table__header',
    name: 'actions',
    sorted: false,
    className: 'table__content',
    style: {
      minWidth: 115,
      width: 115,
    },
  },
];
