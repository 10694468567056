import dayjs from 'dayjs';
import { useCreateNewPromotionMutation } from 'pages/facilityGroup/api/mutations/generated/CreateNewPromotion';
import dateHour from '../../../userSchedule/components/sidebar/newSchedule/helpers/dateHour';
import { PromotionRepeatConditionEnum, PromotionRewardTypeEnum } from 'generated/graphql';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { usePromotionStore } from '../../useBonusProgramStore';
import { useEditPromotionInFacilityGroupMutation } from 'pages/facilityGroup/api/mutations/generated/EditPromotionInFacilityGroup';
import { useFacilityGroupExtended } from '../../../../utils/api/useFacilityGroup';

export type Bonus = {
  id: string;
  name: string;
  status?: string;
  requirementShiftCount?: number;
  rewardType?: string;
  rewardSum?: number;
  badShiftCount?: number;
  dateFrom: dayjs.Dayjs | null;
  timeFrom: dayjs.Dayjs | null;
  dateTo: dayjs.Dayjs | null;
  timeTo: dayjs.Dayjs | null;
  endless: boolean;
  repeatCondition?: string;
  childPromotions?: { facility?: { name: string; id: string } | null }[] | null;
  __typename?: string;
};

type Props = { el: Bonus; setShow: React.Dispatch<React.SetStateAction<boolean>> };

const usePromotionForm = ({ el, setShow }: Props) => {
  const { id } = useFacilityGroupExtended();
  const [createNewPromotion] = useCreateNewPromotionMutation();
  const [editPromotionInFacilityGroup] = useEditPromotionInFacilityGroupMutation();
  const changeBonuses = usePromotionStore(state => state.changePromotions);
  const initialValues: Bonus = { ...el };

  const validate = (values: Bonus) => {
    const errors: { [key: string]: string } = {};
    if (!values.name) {
      errors.name = 'Обязательное поле';
    }
    if (!values.requirementShiftCount) {
      errors.requirementShiftCount = 'Обязательное поле';
    }
    if (!values.rewardType) {
      errors.rewardType = 'Обязательное поле';
    }
    if (!values.rewardSum) {
      errors.rewardSum = 'Обязательное поле';
    }
    if (!values.badShiftCount) {
      errors.badShiftCount = 'Обязательное поле';
    }
    if (!values.dateFrom) {
      errors.dateFrom = 'Обязательное поле';
    }
    if (!values.timeFrom) {
      errors.timeFrom = 'Обязательное поле';
    }
    if (!values.repeatCondition) {
      errors.repeatCondition = 'Обязательное поле';
    }
    return errors;
  };

  const onSubmit = (values: Bonus) => {
    const dateTimeStart = dateHour(values.dateFrom, values.timeFrom)?.toISOString();

    if (values.timeTo === null) {
      values.timeTo = dayjs('00:00:00');
    }
    const dateTimeEnd =
      values.dateTo && values.timeTo ? dateHour(values.dateTo, values.timeTo)?.toISOString() : undefined;

    const isExist = !!el['__typename'];
    const variables = {
      name: values.name,
      requirementShiftCount: values.requirementShiftCount!,
      rewardType: values.rewardType as PromotionRewardTypeEnum,
      rewardSum: values.rewardSum!,
      badShiftCount: values.badShiftCount!,
      dateTimeStart: dateTimeStart!,
      dateTimeEnd: dateTimeEnd,
      repeatCondition: values.repeatCondition as PromotionRepeatConditionEnum,
    };
    if (isExist) {
      editPromotionInFacilityGroup({
        variables: {
          input: { ...variables, promotionId: el.id },
        },
      })
        .then(response => {
          if (!response.data) return;
          const res = response.data.editPromotionInFacilityGroup;
          successToast('Акция изменена');
          changeBonuses(el.id, {
            ...res,
            dateTo: res.dateTimeEnd ? dayjs(res.dateTimeEnd) : null,
            timeTo: res.dateTimeEnd ? dayjs(res.dateTimeEnd) : null,
            dateFrom: res.dateTimeStart ? dayjs(res.dateTimeStart) : null,
            timeFrom: res.dateTimeStart ? dayjs(res.dateTimeStart) : null,
            endless: false,
          });
        })
        .catch(err => {
          errorToast(errorHandler(err));
        })
        .finally(() => {
          setShow(false);
        });
    } else {
      createNewPromotion({
        variables: {
          input: { ...variables, facilityGroupId: id! },
        },
      })
        .then(response => {
          if (!response.data) return;
          const res = response.data.createNewPromotion;
          changeBonuses(el.id, {
            ...res,
            dateTo: res.dateTimeEnd ? dayjs(res.dateTimeEnd) : null,
            timeTo: res.dateTimeEnd ? dayjs(res.dateTimeEnd) : null,
            dateFrom: res.dateTimeStart ? dayjs(res.dateTimeStart) : null,
            timeFrom: res.dateTimeStart ? dayjs(res.dateTimeStart) : null,
            endless: false,
          });
          successToast('Акция создана');
        })
        .catch(err => {
          errorToast(errorHandler(err));
        })
        .finally(() => {
          setShow(false);
        });
    }
  };
  return { initialValues, validate, onSubmit };
};

export default usePromotionForm;
