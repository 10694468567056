import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import MainSidebar from 'ui/sidebar';
import SimpleSelect from 'ui/select';
import { auchanLunchDurationTypes, lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { timeDifference } from 'utils/helpers/timeDifference';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import DatePickerInput from 'ui/pickers/DatePickerInput';

import TimePickerInput from 'ui/pickers/TimePickerInput';
import { periodOptions, RU_DATE } from 'utils/helpers/constVariables';
import uniqueArray from 'utils/helpers/uniqueArray';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { Form, Formik } from 'formik';
import { useCreateRequestScheduleForm } from './hooks';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';
import SimpleInput from 'ui/input';

interface ICreateNewSchedule {
  showCreateGraphicSidebar: boolean;
  setShowCreateGraphicSidebar: (value: boolean) => void;
}

const CreateRequestSchedule = ({ showCreateGraphicSidebar, setShowCreateGraphicSidebar }: ICreateNewSchedule) => {
  const { request } = useCurrentRequest();
  const { scheduleTypes } = useGetScheduleTypes();

  const { initialValues, validate, onSubmit, loading } = useCreateRequestScheduleForm(setShowCreateGraphicSidebar);

  const requestPositions = useMemo(
    () =>
      uniqueArray({
        field: 'id',
        array: request?.plans.map(plan => ({
          id: plan.position.id,
          name: plan.position.name,
        })),
      }),
    [request?.plans],
  );

  const { isAuchan } = useFacilityGroupById(request?.facility?.facilityGroup?.id);

  return (
    <MainSidebar title="Добавить график" show={showCreateGraphicSidebar} setShow={setShowCreateGraphicSidebar}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, errors, touched, setFieldValue, handleChange }) => {
          return (
            <Form className="mb-5 flex flex-col gap-y-4">
              <div className="grid grid-cols-2 gap-x-6">
                <SimpleSelect
                  divClassName="col-span-1"
                  label="Должность"
                  onChange={value => {
                    setFieldValue('positionId', value);
                  }}
                  value={values.positionId}
                  validation
                  options={requestPositions}
                  placeholder="Не выбрано"
                  error={touched.positionId && errors.positionId ? errors.positionId : ''}
                />
                <SimpleInput
                  label={'Кол-во исполнителей'}
                  divClassName={'col-span-1'}
                  name={'countSchedules'}
                  value={values.countSchedules}
                  type={'number'}
                  onChange={handleChange}
                  placeholder="Введите кол-во исолнителей"
                  error={touched.countSchedules && errors.countSchedules ? errors.countSchedules : ''}
                />
              </div>
              <div className="grid grid-cols-3 gap-x-6">
                <SimpleSelect
                  divClassName="col-span-1"
                  label="График"
                  onChange={value => {
                    setFieldValue('scheduleType', value);
                  }}
                  value={values.scheduleType}
                  validation
                  placeholder="Выберите график"
                  options={scheduleTypes}
                  error={touched.scheduleType && errors.scheduleType ? errors.scheduleType : ''}
                />
                <TimePickerInput
                  label="Начало смены"
                  className="col-span-1"
                  popupClassName="fixed"
                  value={values.shiftStartTime}
                  onChange={timeValue => {
                    setFieldValue('shiftStartTime', timeValue);
                    if (isAuchan) {
                      setFieldValue(
                        'lunchDuration',
                        lunchDurationHandle(timeDifference(timeValue, values.shiftEndTime)),
                      );
                    }
                  }}
                  error={touched.shiftStartTime && errors.shiftStartTime ? errors.shiftStartTime : ''}
                />
                <TimePickerInput
                  label="Конец смены"
                  className="col-span-1"
                  popupClassName="fixed"
                  value={values.shiftEndTime}
                  onChange={timeValue => {
                    setFieldValue('shiftEndTime', timeValue);
                    if (isAuchan) {
                      setFieldValue(
                        'lunchDuration',
                        lunchDurationHandle(timeDifference(values.shiftStartTime, timeValue)),
                      );
                    }
                  }}
                  error={touched.shiftEndTime && errors.shiftEndTime ? errors.shiftEndTime : ''}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-6">
                <DatePickerInput
                  label="Дата начала графика"
                  divClassName="col-span-1"
                  placement={'bottomRight'}
                  popupClassName="fixed"
                  value={values.shiftStartDate}
                  onChange={selectedDate => setFieldValue('shiftStartDate', selectedDate)}
                  maxDate={dayjs(request?.dateTo).add(1, 'day')}
                  placeholder={dayjs().startOf('month').format(RU_DATE)}
                  error={touched.shiftStartDate && errors.shiftStartDate ? errors.shiftStartDate : ''}
                />
                <DatePickerInput
                  label="Дата конца графика"
                  divClassName="col-span-1"
                  popupClassName="fixed"
                  placement={'bottomRight'}
                  value={values.shiftEndDate}
                  onChange={selectedDate => setFieldValue('shiftEndDate', selectedDate)}
                  minDate={dayjs(request?.dateFrom).subtract(1, 'day')}
                  maxDate={dayjs(request?.dateTo)}
                  placeholder={dayjs().startOf('month').format(RU_DATE)}
                  error={touched.shiftEndDate && errors.shiftEndDate ? errors.shiftEndDate : ''}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-6">
                <SimpleSelect
                  divClassName="col-span-1"
                  label="Обед"
                  onChange={value => {
                    if (!isAuchan) {
                      setFieldValue('lunchDuration', Number(value));
                    }
                  }}
                  value={values.lunchDuration}
                  sort={false}
                  options={isAuchan ? auchanLunchDurationTypes : lunchDurationTypes}
                  disabled={isAuchan}
                />
                <SimpleSelect
                  divClassName="col-span-1"
                  label="Время суток"
                  onChange={value => {
                    setFieldValue('timesOfDay', value);
                  }}
                  value={values.timesOfDay}
                  allOption={'Все периоды'}
                  placeholder={'Не выбрано'}
                  options={periodOptions}
                />
              </div>
              <div className={'flex justify-end'}>
                <button className="btn-primary" type="submit" disabled={loading}>
                  Добавить график
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </MainSidebar>
  );
};

export default CreateRequestSchedule;
