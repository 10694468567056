import { useDeleteStoryAndFilesMutation } from '../../story/api/mutations/generated/DeleteStoryAndFiles';
import { errorToast, successToast } from '../../../utils/helpers/notify';
import { errorHandler } from '../../../utils/helpers/errorHandler';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'ui/modal';

interface Props {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
}

const StoryPopup = ({ showPopup, setShowPopup, id }: Props) => {
  const onShowPopupHandle = () => {
    setShowPopup(value => !value);
  };
  const [deleteStoryAndFiles] = useDeleteStoryAndFilesMutation();
  const location = useLocation();
  const navigate = useNavigate();

  const removeStory = () => {
    if (!id) return;
    deleteStoryAndFiles({
      variables: {
        id,
      },
      update: cache => {
        cache.evict({
          id: `Story:${id}`,
        });
      },
    })
      .then(() => {
        if (location.pathname.includes(id)) {
          navigate('/stories');
        }
        successToast('Черновик удален');
      })
      .catch(err => errorToast(errorHandler(err)))
      .finally(onShowPopupHandle);
  };
  return (
    <Modal type="delete" title="Удалить черновик?" open={showPopup} onCancel={onShowPopupHandle} onOk={removeStory}>
      Вы уверены, что хотите удалить черновик истории?
    </Modal>
  );
};

export default StoryPopup;
