import { StatisticPeriod } from 'generated/graphql';

export const intervalChart = (time: StatisticPeriod) => {
  switch (time) {
    case StatisticPeriod.Day:
    case StatisticPeriod.AllTime:
      return 1;
    default:
      return 0;
  }
};
