import { NotifyLetterStatus } from 'generated/graphql';

const minInHour = 60;
const minInDay = minInHour * 24;
const minInWeek = minInDay * 7;
const minInMonth = minInDay * 30;

export const pushStatuses = [
  {
    id: NotifyLetterStatus.New,
    name: 'Черновик',
  },
  {
    id: NotifyLetterStatus.Pending,
    name: 'Ожидает отправки',
  },
  {
    id: NotifyLetterStatus.Published,
    name: 'Отправлено',
  },
  {
    id: NotifyLetterStatus.Removed,
    name: 'Снято с публикации',
  },
];

export const pushRepeats = [
  {
    id: null,
    name: 'Однократно',
  },
  {
    id: minInDay,
    name: 'Ежедневно',
  },
  {
    id: minInDay * 2,
    name: 'Раз в 2 дня',
  },
  {
    id: minInDay * 3,
    name: 'Раз в 3 дня',
  },
  {
    id: minInDay * 4,
    name: 'Раз в 4 дня',
  },
  {
    id: minInDay * 5,
    name: 'Раз в 5 дней',
  },
  {
    id: minInDay * 6,
    name: 'Раз в 6 дней',
  },
  {
    id: minInWeek,
    name: 'Раз в неделю',
  },
  {
    id: minInWeek * 2,
    name: 'Раз в 2 недели',
  },
  {
    id: minInMonth,
    name: 'Раз в месяц',
  },
];
