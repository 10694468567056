import { ApolloCache } from '@apollo/client';
import { PositionFragment, PositionFragmentDoc } from '../fragments/generated/Position.fragment';

interface Props {
  cache: ApolloCache<any>;
  data?: PositionFragment;
  update?: boolean;
}

export const createPositionCache = ({ cache, data }: Props) => {
  cache.modify({
    fields: {
      positions(existingPositions = []) {
        const newPosition = cache.writeFragment({
          data,
          fragment: PositionFragmentDoc,
        });
        return [...existingPositions, newPosition];
      },
    },
  });
};
