import React, { useCallback, useEffect, useMemo } from 'react';
import SimpleSelect from 'ui/select';
import { useStore } from 'store';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { useMeRole } from 'store/hooks/useMeRole';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import isNull from 'lodash/isNull';
import { Role } from 'generated/graphql';

const TrendSelects = () => {
  const trendsFacilityId = useStore(state => state.trendsFacilityId);
  const trendsGroupId = useStore(state => state.trendsGroupId);
  const setTrendsFacilityId = useStore(state => state.setTrendsFacilityId);
  const setTrendsGroupId = useStore(state => state.setTrendsGroupId);
  const localStorageGroup = localStorage.getItem('trendsFacilityGroupId');
  const localStorageFacility = localStorage.getItem('trendsFacilityId');
  const isMeAdmin = useMeRole([Role.Admin]);
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeManager = useMeRole([Role.ClientManager]);
  const { facilities } = useSelectFacilities();
  const { facilitiesGroups } = useSelectFacilityGroups();
  const filteredFacilities = useMemo(() => filterFacilities(trendsGroupId, facilities), [facilities, trendsGroupId]);

  const handleFacilityChange = useCallback(
    (value?: string) => {
      if (typeof value === 'string') {
        setTrendsFacilityId(value);
        localStorage.setItem('trendsFacilityId', value);
      } else {
        setTrendsFacilityId(null);
        localStorage.removeItem('trendsFacilityId');
      }
    },
    [setTrendsFacilityId],
  );

  const handleGroupChange = useCallback(
    (value?: string) => {
      if (typeof value === 'string') {
        setTrendsGroupId(value);
        localStorage.setItem('trendsFacilityGroupId', value);
      } else {
        setTrendsGroupId(null);
        localStorage.removeItem('trendsFacilityGroupId');
      }
      setTrendsFacilityId(null);
      localStorage.removeItem('trendsFacilityId');
    },
    [setTrendsFacilityId, setTrendsGroupId],
  );

  useEffect(() => {
    return () => {
      setTrendsGroupId(undefined);
      setTrendsFacilityId(undefined);
    };
  }, [setTrendsFacilityId, setTrendsGroupId]);

  useEffect(() => {
    if (!isNull(localStorageGroup)) {
      setTrendsGroupId(localStorageGroup);
    } else if (facilitiesGroups.length && !isMeAdmin && !trendsGroupId) {
      setTrendsGroupId(facilitiesGroups[0].id);
    }
  }, [facilitiesGroups, isMeAdmin, localStorageGroup, setTrendsGroupId, trendsGroupId]);

  useEffect(() => {
    if (!isNull(localStorageFacility)) {
      setTrendsFacilityId(localStorageFacility);
    } else if (trendsGroupId && filteredFacilities.length && (isMeManager || isMeSupervisor)) {
      setTrendsFacilityId(filteredFacilities[0].id);
    }
  }, [filteredFacilities, isMeManager, isMeSupervisor, localStorageFacility, setTrendsFacilityId, trendsGroupId]);

  return (
    <div className="flex gap-x-5">
      <SimpleSelect
        divClassName="chart-filter"
        placeholder="Выберите группу"
        popupClassName="max-w-[300px]"
        onChange={handleGroupChange}
        value={trendsGroupId}
        options={facilitiesGroups}
        allOption={!isMeAdmin ? undefined : 'Все группы'}
      />
      <SimpleSelect
        divClassName="chart-filter"
        placeholder="Выберите объект"
        onChange={handleFacilityChange}
        value={trendsFacilityId}
        options={filteredFacilities}
        allOption={isMeSupervisor || isMeManager ? undefined : 'Все объекты'}
      />
    </div>
  );
};

export default TrendSelects;
