import { Maybe } from 'graphql/jsutils/Maybe';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Requisite, SelfEmployedStatus, User } from 'generated/graphql';
import { useStore } from 'store';
import SelfEmployer from 'assets/icons/selfEmployer';
import CustomName from './customName';
import PaymentsRow from './row';
import { userFullName } from 'utils/helpers/userFullName';
import { RU_DATE } from 'utils/helpers/constVariables';

interface UserType {
  user?: Maybe<
    { __typename?: 'User' } & Pick<User, 'id' | 'firstname' | 'lastname' | 'patronymic'> & {
        requisite?: Maybe<{ __typename?: 'Requisite' } & Pick<Requisite, 'selfEmployed' | 'selfEmployedDate'>>;
      }
  >;
}

const Payment = ({ user }: UserType) => {
  const { manualAccrual } = useStore();
  const [userSum, setUserSum] = useState(0);

  return (
    <div className="payment">
      <div className="grid items-center">
        <span></span>
        <div className="p-2 flex">
          <span className="Subtitle1">{userFullName(user)}</span>
          <span className="ml-4" title={dayjs(user?.requisite?.selfEmployedDate).format(RU_DATE)}>
            {user?.requisite?.selfEmployed === SelfEmployedStatus.Accepted ? <SelfEmployer /> : null}
          </span>
        </div>
      </div>
      {/* {dataUser?.user.workposts?.map(workpost => {
        return (
          <PaymentsRow
            item={item}
            key={item.id}
            userId={user?.id}
            setUserSum={setUserSum}
          />
        );
      })} */}
      {manualAccrual?.items
        ?.filter(item => item.userId === user?.id && !item?.customName)
        .map(item => {
          return <PaymentsRow item={item} key={item.id} userId={user?.id} setUserSum={setUserSum} />;
        })}

      <div
        className="grid grid-cols-7 mt-4 bg-smena_bb-background_light rounded-md"
        style={{
          gridTemplateColumns: '2fr repeat(6, 1fr)',
        }}
      >
        <div className="col-start-2 col-end-5 py-2">
          {manualAccrual?.items
            ?.filter(item => item.userId === user?.id && item.customName)
            .map(item => {
              return <CustomName item={item} key={item.id} userId={user?.id} setUserSum={setUserSum} />;
            })}
        </div>
        <div className="col-start-7 p-2 font-roboto">{userSum.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default Payment;
