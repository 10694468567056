import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useMeRole } from 'store/hooks/useMeRole';
import { useMe } from 'utils/api/useMe';
import { useParams } from 'react-router-dom';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { Role } from 'generated/graphql';
import { useFacilityById } from 'utils/api/useFacility';

export const useRequestDate = () => {
  const { request } = useCurrentRequest();
  if (dayjs(request?.dateFrom).get('month') !== dayjs(request?.dateTo).get('month')) {
    return `${dayjs(request?.dateFrom).format('MMMM YYYY')} - ${dayjs(request?.dateTo).format('MMMM YYYY')}`;
  }
  return `${dayjs(request?.dateFrom).format('MMMM YYYY')}`;
};

export const useBreadcrumbs = () => {
  const router = useParams();
  const requestIdURL = router.requestId;
  const facilityURL = router.facilityId;
  const { facility } = useFacilityById(facilityURL);

  return useMemo(() => {
    if (facilityURL) {
      return [
        { path: '/facilities', name: 'Объекты' },
        { path: `/facilities/${facilityURL}`, name: facility?.name || '' },
        {
          path: `/facilities/${facilityURL}/requests/${requestIdURL}`,
          name: 'Заявка',
        },
      ];
    }
    return [
      { path: '/requests', name: 'Заявки' },
      { path: `/request/${requestIdURL}`, name: 'Заявка' },
    ];
  }, [requestIdURL, requestIdURL, facility?.name]);
};

export const useRequestBreadcrumbs = () => {
  const router = useParams();
  const requestId = router.requestId ?? '';
  const facilityIdURL = router.facilityId ?? '';
  return useMemo(() => {
    if (facilityIdURL) {
      return [
        { path: '/facilities', name: 'Объекты' },
        { path: '/facilities/' + facilityIdURL, name: 'Страница объекта' },
        requestId
          ? {
              path: '/facilities/' + facilityIdURL + '/edit' + requestId,
              name: 'Заявка',
            }
          : {
              path: '/facilities/' + facilityIdURL + '/create',
              name: 'Новая заявка',
            },
      ];
    }
    return [
      {
        path: '/requests',
        name: 'Заявки',
      },
      requestId
        ? {
            path: '/requests/' + requestId + '/edit',
            name: ' Редактирование заявки',
          }
        : {
            path: '/requests/create',
            name: 'Новая заявка',
          },
    ];
  }, [requestId, facilityIdURL]);
};

export const useCanEditRequest = () => {
  const { request } = useCurrentRequest();
  const me = useMe();
  const isMeSupervisor = useMeRole([Role.Supervisor]);

  return useMemo(() => {
    if (isMeSupervisor) {
      const requestHasNotClientManagers = !request?.facility?.clientManagers?.length;
      const requestHasNotClientDirectors = !request?.facility?.facilityGroup?.clientDirectors?.length;
      const requestHasNotClients = requestHasNotClientManagers && requestHasNotClientDirectors;
      const isMeRequestAuthor = request?.authorId === me?.id;

      return isMeRequestAuthor && requestHasNotClients;
    }
    return true;
  }, [
    isMeSupervisor,
    me?.id,
    request?.authorId,
    request?.facility?.clientManagers?.length,
    request?.facility?.facilityGroup?.clientDirectors?.length,
  ]);
};
