import { chain, last, sumBy } from 'lodash';
import dayjs from 'dayjs';
import { ChartType } from 'generated/graphql';
import { GetStatisticQuery } from 'pages/main/api/queries/generated/GetStatistic';

export const getChartLengthHandler = ({ data, chartType }: { data?: GetStatisticQuery; chartType: ChartType }) => {
  if (data) {
    const getMetric = chain(data.getStatistic).find({ type: chartType }).value();
    const filterMetric = getMetric?.data.filter(el => dayjs(el.createdAt).isToday());
    return sumBy(filterMetric, 'value');
  }
  return 0;
};

export const getAllUsersChartLengthHandler = ({
  chartTypes,
  data,
}: {
  chartTypes: ChartType[];
  data?: GetStatisticQuery;
}) => {
  return chain(data?.getStatistic)
    .filter(el => chartTypes.includes(el.type))
    .map(el => last(el.data)?.value)
    .sum()
    .value();
};
