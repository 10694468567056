import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const userAgent = navigator.userAgent;
let app;

if (userAgent.match(/chrome|chromium|crios/i)) {
  app = initializeApp(firebaseConfig);
} else if (userAgent.match(/firefox|fxios/i)) {
  app = initializeApp(firebaseConfig);
} else if (userAgent.match(/safari/i)) {
  app = null;
} else if (userAgent.match(/opr\//i)) {
  app = initializeApp(firebaseConfig);
} else if (userAgent.match(/edg/i)) {
  app = initializeApp(firebaseConfig);
}

const messaging = app ? getMessaging(app) : null;

export const fetchToken = (setToken: (token: string) => void) => {
  if (messaging) {
    return getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    }).then(currentToken => {
      if (currentToken) {
        setToken(currentToken);
      }
    });
  }
};

export const onMessageListener = () =>
  new Promise(resolve => {
    if (messaging) {
      onMessage(messaging, payload => {
        resolve(payload);
      });
    }
  });
