import React from 'react';
import { TIcon } from 'interfaces/TIcon';

const MoonIcon = ({ className }: TIcon) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M14.7212 9.38808C14.3175 10.7062 13.5083 11.8634 12.4087 12.695C11.4434 13.4215 10.2947 13.8646 9.09152 13.9746C7.88834 14.0845 6.67833 13.8569 5.5973 13.3174C4.51627 12.7779 3.60702 11.9477 2.97159 10.9201C2.33616 9.89251 1.99971 8.70814 2 7.49995C1.99566 6.08974 2.4541 4.71704 3.305 3.59245C4.13659 2.49295 5.29377 1.68373 6.61188 1.27995C6.69875 1.2532 6.79127 1.25064 6.87949 1.27254C6.96771 1.29445 7.04829 1.33998 7.11257 1.40426C7.17684 1.46853 7.22238 1.54912 7.24428 1.63734C7.26619 1.72556 7.26362 1.81808 7.23688 1.90495C6.94865 2.85835 6.92448 3.87207 7.16695 4.83812C7.40942 5.80416 7.90943 6.68633 8.61372 7.39061C9.318 8.09489 10.2002 8.59491 11.1662 8.83738C12.1323 9.07984 13.146 9.05568 14.0994 8.76745C14.1862 8.7407 14.2788 8.73814 14.367 8.76004C14.4552 8.78195 14.5358 8.82748 14.6001 8.89176C14.6643 8.95603 14.7099 9.03662 14.7318 9.12484C14.7537 9.21306 14.7511 9.30558 14.7244 9.39245L14.7212 9.38808Z" />
    </svg>
  );
};

export default MoonIcon;
