import { UserPersonalInfoStatus } from 'generated/graphql';

export const userPersonalStatusesList = () => {
  return [
    { id: UserPersonalInfoStatus.PersonalDataVerified, name: 'Документы приняты' },
    { id: UserPersonalInfoStatus.PersonalDataNotVerified, name: 'Документы незаполненны' },
    { id: UserPersonalInfoStatus.PersonalDataNotSent, name: 'Документы отклонены' },
    { id: UserPersonalInfoStatus.PersonalDataSent, name: 'Документы отправлены' },
  ];
};
