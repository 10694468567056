import dayjs from 'dayjs';
import { ISO_DATE } from '../../helpers/constVariables';
import { chain } from 'lodash';
import { useParams, useSearchParams } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo } from 'react';
import { useGetRequestByFacilityLazyQuery } from 'pages/facilityRequests/api/queries/generated/GetRequestByFacility';
import { useStore } from 'store';
import { positionName } from '../../helpers/filters/filtersName';
import { usePositions } from '../usePositions';
import { ZayavkaByFacilityDisplay } from 'generated/graphql';
import { useCurrentRequest } from '../useCurrentRequest';

export const useGetRequestByFacilityId = () => {
  const { request } = useCurrentRequest();
  const router = useParams();
  const facilityId = router.facilityId || request?.facilityId;
  const { positions } = usePositions();
  const { setFacilityScheduleIds, setFacilityScheduleHeaderCheckbox, activeRequests } = useStore();
  const [params] = useSearchParams();
  const dateParam = params.get('date');
  const date = useMemo(() => (dateParam ? dayjs(dateParam) : dayjs().startOf('month')), [dateParam]);
  const [loadData, { data, loading, error }] = useGetRequestByFacilityLazyQuery();
  useEffect(() => {
    if (!facilityId) return;
    loadData({
      variables: {
        facilityId,
        month: dayjs(date).format(ISO_DATE),
        show: activeRequests ? ZayavkaByFacilityDisplay.Active : ZayavkaByFacilityDisplay.Finished,
      },
      onCompleted: data => {
        const sortedRequests = sortBy(data.getZayavkyByFacility, el => el.number);
        setFacilityScheduleIds(sortedRequests.map(request => request.id));
        setFacilityScheduleHeaderCheckbox(!!data.getZayavkyByFacility.length);
      },
    });
  }, [activeRequests, date, facilityId, loadData, setFacilityScheduleHeaderCheckbox, setFacilityScheduleIds]);
  const requests = useMemo(() => data?.getZayavkyByFacility || [], [data?.getZayavkyByFacility]);
  const sortedRequests = useMemo(() => sortBy(requests, el => el.number), [requests]);
  const requestSchedules = useMemo(
    () =>
      chain(sortedRequests)
        .map(request => request.zayavkaSchedule?.map(el => ({ ...el, number: request.number })))
        .flatten()
        .compact()
        .sortBy((el: { positionId: string }) => positionName(positions, el.positionId))
        .value(),
    [positions, sortedRequests],
  );
  return { sortedRequests, requestSchedules, loading, error, date };
};
