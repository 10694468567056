import { ReferralProgramStatusEnum } from 'generated/graphql';

export const getReferralProgramStatusColor = (status?: ReferralProgramStatusEnum): string => {
  if (!status) return '';

  const statusColors: Record<ReferralProgramStatusEnum, string> = {
    [ReferralProgramStatusEnum.Active]: 'bg-smena_green-extra_light text-smena_green-dark',
    [ReferralProgramStatusEnum.Archive]: 'text-smena_text-secondary border-[1px] border-smena_text-secondary',
    [ReferralProgramStatusEnum.Draft]: 'text-smena_text-secondary border-[1px] border-smena_text-secondary',
    [ReferralProgramStatusEnum.Planned]: 'bg-smena_yellow-extra_light text-smena_orange-dark',
  };

  return statusColors[status] || 'black';
};
