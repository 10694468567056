import MainLayout from 'components/layout/main';
import { useVacanciesBreadcrumbs, useVacanciesHeaderMenu } from '../vacancies/hooks';
import Sidebar from './components/sidebar';
import VacancyPopup from './components/VacancyPopup';
import YandexMap from './components/map';

const VacanciesMapPage = () => {
  const breadCrumbs = useVacanciesBreadcrumbs();
  const headerMenu = useVacanciesHeaderMenu();
  return (
    <MainLayout title="Карта вакансии" breadCrumbs={breadCrumbs} headerMenu={headerMenu}>
      <div className={'rounded-lg relative h-[calc(100vh-212px)] shadow-smena flex'}>
        <Sidebar />
        <VacancyPopup />
        <YandexMap />
      </div>
    </MainLayout>
  );
};

export default VacanciesMapPage;
