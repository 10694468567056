import clsx from 'clsx';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';
import { useMeRole } from 'store/hooks/useMeRole';
import ThreeDots from 'assets/icons/ThreeDots';
import OutsideClick from 'components/outsideComponent';
import { RequestScheduleFragment } from 'pages/facilityRequests/api/fragments/generated/RequestSchedule.fragment';
import { useDeleteWorkerFromRequestScheduleMutation } from 'pages/facilityRequests/api/mutations/generated/DeleteWorkerFromRequestSchedule';
import { Role } from 'generated/graphql';
import Modal from 'ui/modal';

interface IRequestProfession {
  requestSchedule?: RequestScheduleFragment | null;
}

const RequestProfession: React.FC<IRequestProfession> = React.memo(({ requestSchedule }) => {
  const setRequestSchedule = useStore(state => state.setRequestSchedule);
  const setShowGraphicSidebar = useStore(state => state.setShowGraphicSidebar);
  const setShowFreeScheduleSidebar = useStore(state => state.setShowFreeScheduleSidebar);
  const [editGraphic, setEditGraphic] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const doesScheduleHaveFreeShifts = useMemo(
    () => requestSchedule?.zayavkaShift.some(el => el.isFree),
    [requestSchedule?.zayavkaShift],
  );
  const doesScheduleHaveNormalShifts = useMemo(
    () => requestSchedule?.zayavkaShift.some(el => !el.isFree),
    [requestSchedule?.zayavkaShift],
  );

  const popupHandler = useCallback(() => {
    setShowPopup(value => !value);
  }, []);

  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);

  const [deleteWorkerFromRequestSchedule] = useDeleteWorkerFromRequestScheduleMutation();

  const deleteRequestScheduleHandler = () => {
    if (requestSchedule) {
      deleteWorkerFromRequestSchedule({
        variables: {
          id: requestSchedule.id,
        },
      })
        .then(res => {
          if (res.data?.deleteWorkerFromZayavkaSchedule) {
            successToast('График освобожден');
            popupHandler();
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    }
  };

  const btnRef = useRef<HTMLButtonElement>(null);
  const parent = btnRef.current?.parentElement?.parentElement;
  const top = Number(btnRef.current?.offsetTop) + Number(parent?.offsetTop) - Number(parent?.parentElement?.scrollTop);
  const style = editGraphic
    ? {
        top: top + 'px',
        left: Number(btnRef.current?.offsetLeft) - 160 + 'px',
      }
    : {};

  return (
    <>
      <ScrollSyncPane>
        <div className={clsx('requestScheduleProfessionsRows border-b group relative')}>
          <div className="flex items-center justify-between">
            <span className="Caption-small">{requestSchedule?.position?.name}</span>
            {isMeAdminOrSupervisor && (
              <button
                onClick={() => {
                  setEditGraphic(value => !value);
                }}
                ref={btnRef}
              >
                <ThreeDots className="text-smena_text-disabled group-hover:text-smena_text" />
              </button>
            )}
          </div>
        </div>
      </ScrollSyncPane>
      <OutsideClick
        show={editGraphic}
        setShow={setEditGraphic}
        triggerRef={btnRef}
        className={clsx(
          'absolute top-0 transform translate-x-full translate-y-5 bg-smena_white flex flex-col shadow-smena rounded-lg z-10 w-max h-max',
          editGraphic ? 'opacity-100' : 'opacity-0 invisible pointer-events-none',
        )}
        style={style}
      >
        {doesScheduleHaveNormalShifts ? (
          <button
            className="px-3 py-2 Body1 text-left hover:bg-smena_bb-background_base"
            onClick={() => {
              setShowGraphicSidebar(true);
              setEditGraphic(false);
              setRequestSchedule(requestSchedule);
            }}
          >
            Назначить на график
          </button>
        ) : null}
        {doesScheduleHaveFreeShifts ? (
          <button
            className="px-3 py-2 Body1 text-left hover:bg-smena_bb-background_base"
            onClick={() => {
              setShowFreeScheduleSidebar(true);
              setShowGraphicSidebar(true);
              setEditGraphic(false);
              setRequestSchedule(requestSchedule);
            }}
          >
            Подтвердить исполнителя
          </button>
        ) : null}
        <button
          className="px-3 py-2 Body1 text-left text-smena_red hover:bg-smena_bb-background_base"
          onClick={popupHandler}
        >
          Освободить график
        </button>
      </OutsideClick>
      <Modal
        type="delete"
        title="Освободить график"
        open={showPopup}
        onCancel={popupHandler}
        onOk={deleteRequestScheduleHandler}
        okText="Снять"
      >
        Вы уверены, что хотите снять исполнителя(-лей) со всего графика?
      </Modal>
    </>
  );
});

export default RequestProfession;
