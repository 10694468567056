import { TIcon } from 'interfaces/TIcon';

const Down = ({ className }: TIcon) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path d="M0.538169 0.456177L8.26824 0.456177C8.56178 0.456177 8.75748 0.651875 8.75748 0.945422C8.75748 1.04327 8.75748 1.14112 8.65963 1.23897L4.84352 5.83787C4.64783 6.03357 4.35428 6.03357 4.15858 5.93572L4.06073 5.83787L0.146773 1.23897C-0.0489245 1.04327 -0.0489245 0.749724 0.146773 0.554026C0.244622 0.456177 0.44032 0.456177 0.538169 0.456177Z" />
    </svg>
  );
};

export default Down;
