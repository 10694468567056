const LoopIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2C5.63401 2 2.5 5.13401 2.5 9C2.5 12.866 5.63401 16 9.5 16C13.366 16 16.5 12.866 16.5 9C16.5 5.13401 13.366 2 9.5 2ZM0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9C18.5 11.125 17.7636 13.0779 16.532 14.6175L20.2073 18.2928C20.5978 18.6834 20.5978 19.3165 20.2073 19.7071C19.8168 20.0976 19.1836 20.0976 18.7931 19.7071L15.1178 16.0318C13.5781 17.2635 11.6251 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9ZM9.5 4.46436C10.0523 4.46436 10.5 4.91207 10.5 5.46436V7.99989H13.0355C13.5878 7.99989 14.0355 8.44761 14.0355 8.99989C14.0355 9.55217 13.5878 9.99989 13.0355 9.99989H10.5V12.5354C10.5 13.0877 10.0523 13.5354 9.5 13.5354C8.94772 13.5354 8.5 13.0877 8.5 12.5354V9.99989H5.96447C5.41218 9.99989 4.96447 9.55217 4.96447 8.99989C4.96447 8.44761 5.41218 7.99989 5.96447 7.99989H8.5V5.46436C8.5 4.91207 8.94771 4.46436 9.5 4.46436Z"
        fill="white"
      />
    </svg>
  );
};

export default LoopIcon;
