const CitiesIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.75C8.86193 2.75 8.75 2.86193 8.75 3V14V16.25V17.75V20C8.75 20.1381 8.86193 20.25 9 20.25H19C19.1381 20.25 19.25 20.1381 19.25 20V3C19.25 2.86193 19.1381 2.75 19 2.75H9ZM7.25 3V12.2677C7.16835 12.256 7.08488 12.25 7 12.25H5C4.0335 12.25 3.25 13.0335 3.25 14V20C3.25 20.0849 3.25604 20.1683 3.26772 20.25H2C1.58579 20.25 1.25 20.5858 1.25 21C1.25 21.4142 1.58579 21.75 2 21.75H5H7H9H19H22C22.4142 21.75 22.75 21.4142 22.75 21C22.75 20.5858 22.4142 20.25 22 20.25H20.7323C20.744 20.1683 20.75 20.0849 20.75 20V3C20.75 2.0335 19.9665 1.25 19 1.25H9C8.0335 1.25 7.25 2.0335 7.25 3ZM7 13.75C7.13807 13.75 7.25 13.8619 7.25 14V16.25H6C5.58579 16.25 5.25 16.5858 5.25 17C5.25 17.4142 5.58579 17.75 6 17.75H7.25V20C7.25 20.1381 7.13807 20.25 7 20.25H5C4.86193 20.25 4.75 20.1381 4.75 20V14C4.75 13.8619 4.86193 13.75 5 13.75H7ZM13 5H11V7H13V5ZM17 5H15V7H17V5ZM13 8.5H11V10.5H13V8.5ZM17 8.5H15V10.5H17V8.5ZM17 12H15V14H17V12ZM17 15.5H15V17.5H17V15.5Z"
      />
    </svg>
  );
};

export default CitiesIcon;
