import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Plus from 'assets/icons/plus';
import { positionName } from 'utils/helpers/filters/filtersName';
import SimpleSelect from 'ui/select';
import { useMeRole } from 'store/hooks/useMeRole';
import Search from 'ui/search';
import { useCustomSearchParams } from 'pages/users/lib/useParams';
import { useFilteredCities } from 'pages/publishStory/components/showSettings/hooks/useFilteredCities';
import TableFilter from 'components/table/filters';
import Filter from 'components/table/filters/filter';
import { useParams } from 'react-router';
import { usePositions } from 'utils/api/usePositions';
import { useFilteredFacilities } from '../../publishStory/components/showSettings/hooks/useFilteredFacilities';
import { Role } from 'generated/graphql';
import { useFacilityById } from '../../../utils/api/useFacility';
import { useCityById } from '../../../utils/api/useCity';

const FacilityGroupCasingsFilter = () => {
  const router = useParams();
  const id = router.id;
  const { handleParams, params, setParams, useParamsCount, resetFilter } = useCustomSearchParams();

  const facilityIdParam = params.get('facilityId');
  const cityParam = params.get('cityId');
  const positionIdParam = params.get('positionId');

  const [positionId, setPositionId] = useState<string | null>(positionIdParam);
  const [facilityId, setFacilityId] = useState<string | null>(facilityIdParam);
  const [cityId, setCityId] = useState<string | null>(cityParam);
  const [showFilter, setShowFilter] = useState(false);

  const { city } = useCityById(cityParam);
  const { positions } = usePositions();

  const { facility } = useFacilityById(facilityIdParam);
  const filteredFacilities = useFilteredFacilities({ cities: cityId ? [cityId] : [], facilityGroups: id ? [id] : [] });
  const filteredCities = useFilteredCities({
    facilityGroupIds: [],
    facilityIds: facilityId ? [facilityId] : [],
  });

  const paramsCount = useParamsCount();
  const btnRef = useRef<HTMLButtonElement>(null);
  const isMeAdmin = useMeRole([Role.Admin]);

  return (
    <div className="filter-bar flex xs:flex-row flex-col gap-y-2 justify-between shadow-smena">
      <div className="flex flex-col gap-y-2">
        <div className={'flex relative gap-x-2'}>
          <button
            type="button"
            ref={btnRef}
            className="btn-stroke flex gap-x-1 items-center"
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <Search />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              label="Профессия"
              onChange={value => setPositionId(value)}
              value={positionId}
              allOption={'Все профессии'}
              placeholder={'Не выбрано'}
              options={positions}
            />
            <SimpleSelect
              label="Объект"
              onChange={value => setFacilityId(value)}
              allOption={'Все объекты'}
              placeholder={'Не выбрано'}
              value={facilityId}
              options={filteredFacilities}
            />
            <SimpleSelect
              label="Город"
              onChange={value => setCityId(value)}
              value={cityId}
              allOption={'Все города'}
              placeholder={'Не выбрано'}
              options={filteredCities}
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('facilityId', facilityId);
                  handleParams('cityId', cityId);
                  handleParams('positionId', positionId);
                  params.delete('page');
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setFacilityId(null);
                  setCityId(null);
                  setPositionId(null);
                  setParams();
                  setShowFilter(false);
                }}
              >
                Сбросить
              </button>
            </div>
          </TableFilter>
        </div>

        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!facilityIdParam}
              content={facility?.name}
              clickHandler={() => {
                setFacilityId(null);
                resetFilter('facilityId');
              }}
            />

            <Filter
              show={!!cityParam}
              content={'Город ' + city?.name}
              clickHandler={() => {
                setCityId(null);
                resetFilter('cityId');
              }}
            />

            <Filter
              show={!!positionIdParam}
              content={positionName(positions, positionId)}
              clickHandler={() => {
                setPositionId(null);
                resetFilter('positionId');
              }}
            />
          </div>
        )}
      </div>
      {isMeAdmin && (
        <div className="flex self-start">
          <Link to={`create`}>
            <button type="button" className="btn-primary_big">
              <span className="flex items-center">
                <span className="inline-flex mr-2">
                  <Plus className="text-smena_white" />
                </span>
                Новая профессия
              </span>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default FacilityGroupCasingsFilter;
