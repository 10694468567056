import React from 'react';
import SimpleInput from 'ui/input';
import { FormikHandlers, FormikState } from 'formik';
import { PushFormProps, usePushForm } from 'pages/push/utils/hooks/usePushForm';
import TextArea from 'ui/textArea';
import { usePush } from 'utils/api/usePush';
import clsx from 'clsx';

export interface MainInformationProps {
  values: PushFormProps;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikState<PushFormProps>['errors'];
}

const PushNotification = ({ values, handleChange, errors }: MainInformationProps) => {
  const { isPushIsNotNew } = usePush();
  const { onSave } = usePushForm();
  return (
    <div className={'grid gap-y-4'}>
      <span className="Subtitle1 text-smena_text-secondary">Пуш-уведомление</span>
      <div className={clsx('grid gap-x-6', isPushIsNotNew && 'gap-y-4')}>
        <SimpleInput
          onBlur={() => {
            onSave(values);
          }}
          type={'text'}
          label={'Заголовок'}
          placeholder={'Текст заголовка'}
          onChange={handleChange}
          name={'title'}
          showText={isPushIsNotNew}
          value={values.title}
          error={errors.title ? errors.title : ''}
        />
        <TextArea
          onBlur={() => {
            onSave(values);
          }}
          label={'Текст'}
          style={{
            height: '65px',
            resize: 'none',
          }}
          showText={isPushIsNotNew}
          placeholder={'Введите текст'}
          onChange={handleChange}
          name={'body'}
          value={values.body}
          error={errors.body ? errors.body : ''}
        />
      </div>
    </div>
  );
};

export default PushNotification;
