import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import { useMemo, useState } from 'react';
import { FacilityPaginatedFragment } from 'pages/facilities/api/fragments/generated/FacilityPaginated.fragment';
import { cityName } from 'utils/helpers/filters/filtersName';
import { useCities } from 'utils/api/useCities';
import { FacilityFieldsSorting } from 'generated/graphql';
import { Link } from 'react-router-dom';

export const useFacilitiesClientTable = (data: FacilityPaginatedFragment[]) => {
  const { cities } = useCities();
  const columns: ColumnDef<FacilityPaginatedFragment>[] = useMemo(() => {
    return [
      {
        id: 'facility',
        header: 'Объект',
        size: 210,
        minSize: 180,
        cell: ({ row: { original } }) => (
          <Link to={`/facilities/${original.id}`} className="link">
            {original.name}
          </Link>
        ),
      },
      {
        id: 'city',
        header: 'Город',
        size: 200,
        minSize: 170,
        cell: ({ row: { original } }) =>
          original?.address?.city ? original.address.city : cityName(cities, original?.cityId),
      },
      {
        id: 'address',
        header: 'Адрес',
        size: 250,
        minSize: 250,
        cell: ({ row: { original } }) => original?.address?.address,
      },
      {
        id: FacilityFieldsSorting.Metro,
        header: 'Метро',
        size: 250,
        minSize: 250,
        cell: ({ row: { original } }) => original?.metroStations?.map(el => el.name).join(', '),
      },
      {
        id: 'status',
        header: 'Статус',
        size: 87,
        minSize: 87,
        cell: ({ row: { original } }) =>
          original?.is_active ? <Check className="text-smena_green-dark" /> : <Across className="text-smena_red" />,
      },
    ];
  }, [cities]);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    meta: {
      facility: true,
      metro: true,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};
