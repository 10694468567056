import { useMemo } from 'react';
import { useGetLinkedNaimixObjectsQuery } from '../../../pages/facilityGroupFacilities/api/query/generated/GetLinkedNaimixObjects';

export const useGetLinkedNaimixObjects = () => {
  const { data, loading, error } = useGetLinkedNaimixObjectsQuery();

  const namixObjects = useMemo(
    () => data?.getLinkedNaimixObjects.map(el => ({ id: el.naimixObjectId, name: el.naimixObjectName })),
    [data?.getLinkedNaimixObjects],
  );

  return { namixObjects, loading, error };
};
