import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router';

import { setTokens } from 'app/config/auth';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useStore } from 'store';
import { errorToast } from 'utils/helpers/notify';
import { saveLoginData } from '../lib/utils';
import SimpleInput from 'ui/input';
import EyeHide from 'assets/icons/EyeHide';
import EyeShow from 'assets/icons/EyeShow';
import { useState } from 'react';
import { useLoginMutation } from '../api/mutations/generated/Login';
import { userFullName } from 'utils/helpers/userFullName';
import { REDIRECT_PAGE } from 'utils/helpers/constVariables';
import { useMeShortlyLazyQuery } from 'pages/main/api/queries/generated/MeShortly';

const AdminLogin = () => {
  const [loginMutation, { loading }] = useLoginMutation();
  const [passwordType, setPasswordType] = useState(true);
  const [load] = useMeShortlyLazyQuery();
  const { phoneNumber } = useStore();
  const navigate = useNavigate();
  const pageFrom = localStorage.getItem(REDIRECT_PAGE);

  return (
    <Formik
      initialValues={{ phoneNumber, password: '' }}
      onSubmit={values => {
        loginMutation({
          variables: {
            data: {
              phone: values.phoneNumber,
              password: values.password,
            },
          },
        })
          .then(response => {
            const data = response.data?.login;
            if (data?.user) {
              setTokens({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
              });
              load().then(meRes => {
                if (meRes.data) {
                  const user = meRes.data.meShortly;
                  localStorage.setItem('me', JSON.stringify(user));
                  saveLoginData({
                    name: userFullName(user),
                    phone: values.phoneNumber,
                    password: values.password,
                    role: user.role,
                  });
                }
                if (pageFrom) {
                  navigate(pageFrom);
                  localStorage.removeItem(REDIRECT_PAGE);
                } else {
                  navigate('/');
                }
              });
            }
          })
          .catch(e => {
            errorToast(errorHandler(e));
          });
      }}
    >
      {({ values, handleChange }) => {
        return (
          <Form className="bg-white shadow w-full rounded-lg p-5 flex flex-col gap-y-3">
            <div className="flex gap-x-2">
              <SimpleInput
                divClassName="w-full"
                label="Пароль"
                placeholder="Введите пароль"
                value={values.password}
                onChange={handleChange}
                type={passwordType ? 'password' : 'text'}
                name="password"
                autoComplete="password"
              />
              <button
                className="flex mt-8"
                type="button"
                onClick={() => {
                  setPasswordType(value => !value);
                }}
              >
                {passwordType ? <EyeHide /> : <EyeShow />}
              </button>
            </div>
            <button type="submit" disabled={values.password.length === 0 || loading} className="btn-primary_big w-full">
              {loading ? 'Загрузка' : 'Войти'}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdminLogin;
