import { useMemo } from 'react';
import { useVacancy } from 'utils/api/useVacancy';
import { useParams } from 'react-router-dom';
import { useFacilityGroup } from 'utils/api/useFacilityGroup';
import { useFacilityById } from 'utils/api/useFacility';

export const useVacancyTitle = () => {
  const { vacancy } = useVacancy();
  return useMemo(() => {
    if (vacancy?.id) {
      return `Редактировать вакансию ${vacancy?.position?.name}`;
    }
    return 'Новая вакансия';
  }, [vacancy?.id, vacancy?.position?.name]);
};

export const useVacancyBreadcrumbs = () => {
  const param = useParams();
  const { vacancyId, vacancy } = useVacancy();
  const { facilityGroup } = useFacilityGroup();
  const facilityId = param.facilityId;
  const { facility } = useFacilityById(facilityId);
  const facilityGroupId = param.id;
  const vacancyBreadcrumb = useMemo(
    () =>
      vacancy?.position?.name
        ? { path: '/vacancies/' + vacancyId, name: vacancy.position.name }
        : { path: '/vacancies/create', name: 'Новая вакансия' },
    [vacancy?.position?.name, vacancyId],
  );
  return useMemo(() => {
    if (facilityGroupId) {
      return [
        { path: '/groups', name: 'Группы' },
        { path: '/groups/' + facilityGroupId, name: `${facilityGroup?.name}` },
        vacancyBreadcrumb,
      ];
    }
    if (facilityId) {
      return [
        { path: '/facilities', name: 'Объекты' },
        { path: '/facilities/' + facilityId, name: `${facility?.name}` },
        vacancyBreadcrumb,
      ];
    }
    return [{ path: '/vacancies', name: 'Вакансии' }, vacancyBreadcrumb];
  }, [facility?.name, facilityGroup?.name, facilityGroupId, facilityId, vacancyBreadcrumb]);
};
