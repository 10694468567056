import clsx from 'clsx';
import ChevronDown from 'assets/icons/chevron-down';
import { ISpoiler } from 'interfaces/ISpoiler';

interface IScheduleSpoiler extends ISpoiler {
  show: boolean;
  setShow: (show: boolean) => void;
  setShowActive: (show: boolean) => void;
}

const ScheduleSpoiler = ({ className, header, children, show, setShow, setShowActive }: IScheduleSpoiler) => {
  return (
    <div className={className}>
      <div className={clsx('relative', header ? 'pr-10' : '')}>
        {header}
        <button
          className={clsx(
            'absolute top-0 right-0 transform transition-transform translate-y-1/2',
            show && 'rotate-180'
          )}
          onClick={() => {
            setShow(!show);
            if (show) {
              setShowActive(true);
            }
          }}
        >
          <ChevronDown className="text-smena_text" />
        </button>
      </div>
      {show && children}
    </div>
  );
};
export default ScheduleSpoiler;
