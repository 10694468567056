import { usePositionsQuery } from 'pages/positions/api/queries/generated/Positions';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

export const usePositions = () => {
  const { data, loading, error } = usePositionsQuery();

  const positions = useMemo(() => sortBy(data?.positions, el => el.name), [data?.positions]);

  return { positions, loading, error };
};
