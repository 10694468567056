import { FacilityGroupsFragment } from 'pages/main/api/fragments/generated/FacilityGroups.fragment';

type TGetFacilityGroup = {
  facilityGroupId?: string | null;
  facilitiesGroups: FacilityGroupsFragment[];
};
export const getFacilityGroup = ({ facilityGroupId, facilitiesGroups }: TGetFacilityGroup) => {
  const res = facilitiesGroups.find(facilityGroup => facilityGroup.id === facilityGroupId);
  if (!res) return null;
  return res;
};
