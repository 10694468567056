import { LeadFragment } from 'pages/lead/api/fragments/generated/Lead.fragment';

export function stockCompleteFn(lead: LeadFragment) {
  if (!lead?.user?.goodShiftCount || !lead?.referralProgram?.numberOfGoodShifts) return 0;
  if (lead.referralProgram?.numberOfGoodShifts >= lead.user?.goodShiftCount) {
    return lead.user?.goodShiftCount;
  } else {
    return lead.referralProgram?.numberOfGoodShifts;
  }
}
