import clsx from 'clsx';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { getShiftBgColorFuture } from 'pages/facilityReport/lib/helpers/getShiftBgColorFuture';
import { getShiftBgColorPast } from 'pages/facilityReport/lib/helpers/getShiftBgColorPast';
import { useStore } from 'store';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { isFutureOrToday } from 'pages/facilityReport/lib/helpers/isFutureOrToday';

interface Props {
  shift: any;
  supervisor: any;
  dayIndex: number;
  rowIndex: number;
  groupIndex: number;
  isDisabled?: boolean;
}

const ShiftCell: React.FC<Props> = ({ shift, supervisor, dayIndex, rowIndex, groupIndex, isDisabled }) => {
  const { setShift, setShowEditShiftSidebar, setShowCreateRequestShiftSidebar, setShowSidebar } = useStore();
  const {
    setUser,
    setSelectedDay,
    setSelectedRow,
    setSelectedGroup,
    selectedDay,
    selectedRow,
    selectedGroup,
    isCalendar,
    setShowEditModerationShiftSidebar,
  } = useFacilityReportStore();

  const [params] = useSearchParams();
  const dateParam = params.get('date');
  const currentDay = dayjs().date();

  let cellClass = 'pt-2 pb-2 min-w-[70px] h-[36px] text-center border-r-[1px] font-medium cursor-pointer';

  if (shift) {
    const bg = isFutureOrToday(dayIndex, dateParam) ? getShiftBgColorFuture(shift) : getShiftBgColorPast(shift);
    cellClass = clsx(cellClass, bg);
  }

  const isSelected = selectedDay === dayIndex && selectedRow === rowIndex && groupIndex === selectedGroup;

  return (
    <div
      className={clsx(cellClass, {
        ['border-l-[2px] border-r-[2px] border-primary']:
          currentDay === dayIndex &&
          dayjs().month() === dayjs(dateParam).month() &&
          dayjs().year() === dayjs(dateParam).year(),
        ['border-[1px] border-primary']: !isCalendar && isSelected,
        ['border-b-[2px] border-b-smena_purple']: shift?.isProbation,
        ['cursor-not-allowed opacity-50']: isDisabled,
      })}
      onClick={() => {
        if (!isCalendar && !isDisabled) {
          if (shift) {
            setShift(shift);
            setUser({ user: supervisor.user, workPost: supervisor.workPost });
            if (isFutureOrToday(dayIndex, dateParam)) {
              setShowEditShiftSidebar(true);
            } else {
              setShowEditModerationShiftSidebar(true);
            }
          } else {
            setUser({ user: supervisor.user, workPost: supervisor.workPost });
            if (isFutureOrToday(dayIndex, dateParam)) {
              setShowCreateRequestShiftSidebar(true);
            } else {
              setShowSidebar(true);
            }
          }

          setSelectedDay(dayIndex);
          setSelectedRow(rowIndex);
          setSelectedGroup(groupIndex);
        }
      }}
    >
      {shift
        ? shift.duration > shift.lunchDuration
          ? (() => {
              const totalMinutes = shift.duration - shift.lunchDuration;
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
              return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            })()
          : '00:00'
        : null}
    </div>
  );
};

export default ShiftCell;
