export const filterFacilities = (
  groupId?: string | null,
  facilities?: { id: string; name: string; facilityGroupId: string; cityId: string }[],
  cityId?: string | null,
) => {
  if (!facilities) return [];
  if (!groupId && !cityId) return facilities;
  return facilities.filter(facility => {
    const isGroup = groupId ? facility.facilityGroupId === groupId : true;
    const isCity = cityId ? facility.cityId === cityId : true;
    return isGroup && isCity;
  });
};
