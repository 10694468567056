import React from 'react';

const ErrorRobotIcon = () => {
  return (
    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="12.5" y="20.4297" width="39" height="34" rx="1.5" fill="#E6EEFF" stroke="#397DFF" />
      <path d="M32 17.4473V11.4473" stroke="#397DFF" />
      <circle cx="32" cy="9.44727" r="2.5" fill="#E6EEFF" stroke="#397DFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6785 37.3543C26.8737 37.5496 27.1903 37.5496 27.3856 37.3543C27.5808 37.159 27.5808 36.8425 27.3856 36.6472L24.206 33.4676L27.3895 30.2841C27.5848 30.0888 27.5848 29.7722 27.3895 29.577C27.1943 29.3817 26.8777 29.3817 26.6824 29.577L23.4989 32.7605L20.3145 29.5761C20.1192 29.3809 19.8026 29.3809 19.6074 29.5761C19.4121 29.7714 19.4121 30.088 19.6074 30.2832L22.7918 33.4676L19.6114 36.648C19.4161 36.8433 19.4161 37.1599 19.6114 37.3551C19.8066 37.5504 20.1232 37.5504 20.3185 37.3551L23.4989 34.1747L26.6785 37.3543Z"
        fill="#397DFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.6785 37.3543C43.8737 37.5496 44.1903 37.5496 44.3856 37.3543C44.5808 37.159 44.5808 36.8425 44.3856 36.6472L41.206 33.4676L44.3895 30.2841C44.5848 30.0888 44.5848 29.7722 44.3895 29.577C44.1943 29.3817 43.8777 29.3817 43.6824 29.577L40.4989 32.7605L37.3145 29.5761C37.1192 29.3809 36.8026 29.3809 36.6074 29.5761C36.4121 29.7714 36.4121 30.088 36.6074 30.2832L39.7918 33.4676L36.6114 36.648C36.4161 36.8433 36.4161 37.1599 36.6114 37.3551C36.8066 37.5504 37.1232 37.5504 37.3185 37.3551L40.4989 34.1747L43.6785 37.3543Z"
        fill="#397DFF"
      />
      <path
        d="M23.5 18.9297C23.5 18.1013 24.1716 17.4297 25 17.4297H39C39.8284 17.4297 40.5 18.1013 40.5 18.9297V20.4297H23.5V18.9297Z"
        fill="#E6EEFF"
        stroke="#397DFF"
      />
      <path
        d="M53 29.4297C53.8284 29.4297 54.5 30.1013 54.5 30.9297L54.5 38.9297C54.5 39.7581 53.8284 40.4297 53 40.4297L51.5 40.4297L51.5 29.4297L53 29.4297Z"
        fill="#E6EEFF"
        stroke="#397DFF"
      />
      <path
        d="M11 29.4297C10.1716 29.4297 9.5 30.1013 9.5 30.9297L9.5 38.9297C9.5 39.7581 10.1716 40.4297 11 40.4297L12.5 40.4297L12.5 29.4297L11 29.4297Z"
        fill="#E6EEFF"
        stroke="#397DFF"
      />
      <path d="M30 43.9297V47.9297" stroke="#397DFF" strokeLinecap="round" />
      <path d="M28 43.9297V47.9297" stroke="#397DFF" strokeLinecap="round" />
      <path d="M26 43.9297V47.9297" stroke="#397DFF" strokeLinecap="round" />
      <path d="M32 43.9297V47.9297" stroke="#397DFF" strokeLinecap="round" />
      <path d="M34 43.9297V47.9297" stroke="#397DFF" strokeLinecap="round" />
      <path d="M36 43.9297V47.9297" stroke="#397DFF" strokeLinecap="round" />
      <path d="M38 43.9297V47.9297" stroke="#397DFF" strokeLinecap="round" />
      <g clipPath="url(#clip0_2924_261373)">
        <path
          d="M57.5041 4.88114C57.413 4.81417 57.3018 4.7801 57.1887 4.78449C57.0757 4.78888 56.9675 4.83148 56.8818 4.90532L52.2402 8.89849L50.9099 5.85341C50.8708 5.7639 50.8064 5.6878 50.7246 5.63448C50.6428 5.58117 50.5471 5.55298 50.4495 5.5534C50.3518 5.55382 50.2564 5.58282 50.1751 5.63683C50.0937 5.69085 50.0299 5.7675 49.9917 5.85733L45.4053 16.5912C45.3608 16.695 45.3529 16.8109 45.3827 16.9199C45.4126 17.0289 45.4785 17.1245 45.5696 17.1913C45.6608 17.258 45.7719 17.2919 45.8848 17.2874C45.9977 17.2829 46.1058 17.2403 46.1914 17.1666L50.8338 13.173L52.1642 16.2181C52.2032 16.3076 52.2677 16.3837 52.3495 16.437C52.4313 16.4903 52.5269 16.5185 52.6246 16.5181C52.7222 16.5177 52.8176 16.4887 52.899 16.4347C52.9803 16.3807 53.0441 16.304 53.0824 16.2142L57.6688 5.48034C57.7132 5.37658 57.7211 5.26087 57.6914 5.15201C57.6617 5.04315 57.596 4.94754 57.5051 4.88074L57.5041 4.88114Z"
          fill="#E6EEFF"
        />
        <path
          d="M57.5041 4.88114C57.413 4.81417 57.3018 4.7801 57.1887 4.78449C57.0757 4.78888 56.9675 4.83148 56.8818 4.90532L52.2402 8.89849L50.9099 5.85341C50.8708 5.7639 50.8064 5.6878 50.7246 5.63448C50.6428 5.58117 50.5471 5.55298 50.4495 5.5534C50.3518 5.55382 50.2564 5.58282 50.1751 5.63683C50.0937 5.69085 50.0299 5.7675 49.9917 5.85733L45.4053 16.5912C45.3608 16.695 45.3529 16.8109 45.3827 16.9199C45.4126 17.0289 45.4785 17.1245 45.5696 17.1913C45.6608 17.258 45.7719 17.2919 45.8848 17.2874C45.9977 17.2829 46.1058 17.2403 46.1914 17.1666L50.8338 13.173L52.1642 16.2181C52.2032 16.3076 52.2677 16.3837 52.3495 16.437C52.4313 16.4903 52.5269 16.5185 52.6246 16.5181C52.7222 16.5177 52.8176 16.4887 52.899 16.4347C52.9803 16.3807 53.0441 16.304 53.0824 16.2142L57.6688 5.48034C57.7132 5.37658 57.7211 5.26087 57.6914 5.15201C57.6617 5.04315 57.596 4.94754 57.5051 4.88074L57.5041 4.88114ZM52.6173 14.7575L51.4792 12.1523C51.4479 12.0806 51.4002 12.0172 51.3399 11.9673C51.2796 11.9174 51.2085 11.8823 51.1322 11.8649C51.0559 11.8475 50.9766 11.8482 50.9007 11.867C50.8247 11.8859 50.7543 11.9223 50.6949 11.9733L47.1712 15.0056L50.4571 7.3149L51.5949 9.91921C51.6262 9.99092 51.6739 10.0543 51.7341 10.1042C51.7944 10.1541 51.8655 10.1892 51.9418 10.2066C52.0181 10.224 52.0974 10.2233 52.1734 10.2044C52.2493 10.1856 52.3198 10.1492 52.3791 10.0982L55.9029 7.06591L52.6173 14.7575Z"
          fill="#397DFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2924_261373">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-0.40034 -0.916366 -0.916366 0.40034 62.0703 15.1621)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ErrorRobotIcon;
