import { useMemo } from 'react';
import { getLink } from 'utils/helpers/get/getLink';

export const useUsersHeaderMenu = () => {
  const users = getLink('/users');
  const firedUsers = getLink('/users/fired-users');

  return useMemo(() => {
    return [
      {
        name: 'Пользователи',
        link: users,
      },
      {
        name: 'Более не сотрудничают',
        link: firedUsers,
      },
    ];
  }, [firedUsers, users]);
};
