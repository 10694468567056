import { ZayavkaChangesStatus, ZayavkaStage } from 'generated/graphql';
import { useMemo } from 'react';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';

export const useChangesByStatus = ({ status }: { status: ZayavkaChangesStatus[] }) => {
  const { request } = useCurrentRequest();
  return useMemo(() => {
    return Boolean(request?.zayavkaChanges?.filter(change => status.includes(change.stage))?.length);
  }, [status, request?.zayavkaChanges]);
};

export const useRequestStatus = ({ status }: { status: ZayavkaStage[] }) => {
  const { request } = useCurrentRequest();

  return useMemo(() => {
    if (!request) return false;
    return status.includes(request.status);
  }, [status, request]);
};
