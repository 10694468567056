import { UnitType } from 'generated/graphql';

export const units = [
  {
    id: UnitType.Piece,
    name: 'Заказы, шт.',
  },
  {
    id: UnitType.Point,
    name: 'Процент выработки, %',
  },
];
export const hourlyUnit = [
  {
    id: UnitType.Hour,
    name: 'Часы, ч.',
  },
];
export const allUnits = [...units, ...hourlyUnit];
