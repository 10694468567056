import React, { useCallback, useEffect, useState } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { ChartType, StatisticPeriod } from 'generated/graphql';

import { useStore } from 'store';
import SimpleSelect from 'ui/select';
import { ITooltipStatus } from 'interfaces/ITooltipStatus';
import { getTimePeriodCharts } from 'utils/helpers/get/getTimeePeriodCharts';
import { chartTimes } from 'utils/helpers/lists/chartTimes';
import { tickFormatterHandlerFn } from 'utils/helpers/charts/tickFormatter';
import { labelFormatterHandler } from 'utils/helpers/charts/getLabelFormaterCharts';
import { chartClick, chartHover } from 'utils/helpers/charts/chartHandlers';
import { lastTimeLength } from 'utils/helpers/charts/lastTimeLength';
import { intervalChart } from 'utils/helpers/charts/intervalChart';
import Skeleton from 'antd/es/skeleton';
import { useGetStatisticResponse, useIsSendRequest } from '../hooks';
import { useGetStatisticLazyQuery } from 'pages/main/api/queries/generated/GetStatistic';

export const WorkerOnShiftChart = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const [time, setTime] = useState(StatisticPeriod.Day);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });
  const isSendRequest = useIsSendRequest();

  const [loadData, { data, loading }] = useGetStatisticLazyQuery();

  const handleTimeChange = useCallback((value: string) => {
    setTime(value as StatisticPeriod);
  }, []);

  const res = useGetStatisticResponse(data);
  const tickFormatterHandler = useCallback(
    (value: any) => {
      if (value && value !== 'auto') {
        return tickFormatterHandlerFn(value, time);
      }
      return '0';
    },
    [time],
  );

  useEffect(() => {
    if (!isSendRequest) return;
    loadData({
      variables: {
        input: {
          facilityId: trendsFacilityId,
          facilityGroupId: trendsGroupId,
          types: [ChartType.WorkingShifts],
          period: time,
        },
      },
    });
  }, [isSendRequest, loadData, time, trendsFacilityId, trendsGroupId]);

  return (
    <div className={'row-span-1 grid gap-y-[5px]'}>
      <div className="grid grid-cols-[auto,_auto] justify-between items-center">
        <span className="Subtitle2 text-smena_text-secondary">Исполнители на смене</span>
        <SimpleSelect
          divClassName="chart-filter"
          label=""
          onChange={handleTimeChange}
          value={time}
          sort={false}
          options={chartTimes}
        />
      </div>
      <div className="flex gap-x-1 items-end">
        {loading ? (
          <Skeleton.Button active />
        ) : (
          <span className="ChartNumber text-smena_text">{lastTimeLength(time, res)}</span>
        )}
        <span className="Caption text-smena_text-secondary">за {getTimePeriodCharts(time)}</span>
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="h-[240px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={res}
              margin={{ right: 27, bottom: 5 }}
              onClick={state => {
                if (state !== null) {
                  if (tooltipStatus.barId === state.activeTooltipIndex) {
                    setTooltipStatus(chartHover(state));
                  } else {
                    setTooltipStatus(chartClick(state));
                  }
                }
              }}
            >
              <XAxis
                dataKey={res ? 'createdAt' : undefined}
                interval={intervalChart(time)}
                tickFormatter={tickFormatterHandler}
                height={30}
                angle={-25}
                tickMargin={10}
              />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                trigger={tooltipStatus.status}
                cursor={false}
                labelFormatter={(label: string) => {
                  return labelFormatterHandler({ label, time });
                }}
                formatter={(value: string) => {
                  return [value, 'Исполнители на смене'];
                }}
              />
              <Area type="monotone" dataKey="value" stroke="#2CD7B8" fill="#2CD7B840" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
