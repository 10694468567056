export const lunchDurationTypes = [
  {
    id: 0,
    name: 'Без обеда',
  },
  {
    id: 15,
    name: '15 минут',
  },
  {
    id: 30,
    name: '30 минут',
  },
  {
    id: 45,
    name: '45 минут',
  },
  {
    id: 60,
    name: '1 час',
  },
  {
    id: 90,
    name: '1.5 часа',
  },
];

export const auchanLunchDurationTypes = [
  {
    id: 0,
    name: 'Без обеда',
  },
  {
    id: 30,
    name: '30 минут',
  },
  {
    id: 60,
    name: '1 час',
  },
  {
    id: 90,
    name: '1.5 часа',
  },
];

export const getLunchDuration = (value?: number | null) => {
  const res = lunchDurationTypes.find(type => type.id === Number(value));
  if (!res) return '';
  return res.name;
};
