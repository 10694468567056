import { useSearchParams } from 'react-router-dom';
import { convertSearch } from 'utils/helpers/searchString';
import { SortingWay, UserContractSorting, UserContractStatus, UserContractType } from 'generated/graphql';
import { useMemo } from 'react';
import { useSearchUserContractsQuery } from './queries/generated/SearchUserContracts';

export const useUserContractsPaginated = () => {
  const [params] = useSearchParams();
  const facilityId = params.get('facilityId');
  const type = params.get('type');
  const status = params.get('status');
  const createdAt = params.get('createdAt');
  const facilityGroupId = params.get('facilityGroupId');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const {
    data: dataUserContracts,
    loading: loadingUsers,
    error,
  } = useSearchUserContractsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        searchQuery: convertSearch(searchQuery),
        sortBy: sortBy as UserContractSorting,
        sortWay: sortWay as SortingWay,
        facilityGroupId,
        facilityId,
        type: type as UserContractType,
        status: status as UserContractStatus,
        createdAt,
        size,
      },
    },
  });

  const userContractsPaginated = dataUserContracts?.searchUserContracts;
  const contracts = useMemo(() => userContractsPaginated?.contracts || [], [userContractsPaginated?.contracts]);
  return { contracts, userContractsPaginated, loadingUsers, error, page };
};
