import { useStore } from 'store';
import { useCallback, useState } from 'react';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { writeUserShiftsCache } from './writeCache';
import { useRefuseShiftMutation } from 'pages/moderationReport/api/mutations/generated/RefuseShift';
import { useCurrentUser } from 'utils/api/useUser';
import Modal from 'ui/modal';

const EditShiftButtons = () => {
  const { setShift, shift } = useStore();
  const { user } = useCurrentUser();
  const [showModal, setShowModal] = useState(false);

  const [params] = useSearchParams();
  const dateFrom = params.get('dateFrom')
    ? dayjs(params.get('dateFrom')).startOf('day').valueOf().toString()
    : dayjs().startOf('month').valueOf().toString();
  const dateTo = params.get('dateTo')
    ? dayjs(params.get('dateTo')).endOf('day').valueOf().toString()
    : dayjs().endOf('month').valueOf().toString();

  const [refuseShift, { loading }] = useRefuseShiftMutation();

  const showRemovePopup = useCallback(() => {
    setShowModal(true);
  }, []);

  const exitHandler = useCallback(() => {
    setShift(undefined);
  }, [setShift]);

  const removeShift = useCallback(() => {
    if (shift) {
      refuseShift({
        variables: {
          id: shift?.id,
        },
        update: (store, { data }) => {
          if (user?.id) {
            writeUserShiftsCache({
              store,
              data: data?.refuseShift,
              variables: { dateFrom, dateTo, userId: user.id },
            });
          }
        },
      })
        .then(() => successToast('Смена отменена'))
        .catch(err => errorToast(errorHandler(err)))
        .finally(() => {
          setShift(undefined);
          setShowModal(false);
        });
    }
  }, [user?.id, dateFrom, dateTo, refuseShift, setShift, shift]);
  return (
    <div className="flex flex-col items-start mt-3">
      <button className="btn-reject_secondary mb-2" onClick={showRemovePopup}>
        Отменить
      </button>
      <button className="btn-stroke_big" onClick={exitHandler}>
        Выход
      </button>

      <Modal
        type="delete"
        title="Отменить смену?"
        open={showModal}
        okButtonProps={{ disabled: loading }}
        onCancel={() => setShowModal(false)}
        onOk={removeShift}
        okText="Отменить"
      >
        Эта смена подтверждена и у нее есть исполнитель. Все участники получат уведомления об отмене.
      </Modal>
    </div>
  );
};

export default EditShiftButtons;
