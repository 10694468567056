import clsx from 'clsx';
import { TIcon } from 'interfaces/TIcon';

const QuestionMarkIconBig = ({ className }: TIcon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path d="M9.75 9C9.75 8.34102 10.0001 7.89541 10.3446 7.60313C10.7054 7.29702 11.2131 7.125 11.75 7.125C12.2869 7.125 12.7946 7.29702 13.1554 7.60313C13.4999 7.89541 13.75 8.34102 13.75 9C13.75 9.48412 13.6337 9.77553 13.4853 9.99804C13.3276 10.2347 13.1207 10.4205 12.8205 10.6901L12.7483 10.755C12.4304 11.0411 12.037 11.4069 11.7379 11.9352C11.4315 12.4765 11.25 13.1383 11.25 14C11.25 14.4142 11.5858 14.75 12 14.75C12.4142 14.75 12.75 14.4142 12.75 14C12.75 13.3617 12.881 12.961 13.0433 12.6741C13.213 12.3743 13.4446 12.1464 13.7517 11.87C13.7798 11.8447 13.8089 11.8187 13.839 11.7919C14.1143 11.5462 14.4642 11.234 14.7334 10.8301C15.0538 10.3495 15.25 9.76588 15.25 9C15.25 7.90898 14.8126 7.04209 14.1259 6.45937C13.4554 5.89048 12.5881 5.625 11.75 5.625C10.9119 5.625 10.0446 5.89048 9.37414 6.45937C8.68736 7.04209 8.25 7.90898 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75C9.41421 9.75 9.75 9.41421 9.75 9Z" />
      <path d="M12.5649 18.5035C12.8436 18.197 12.821 17.7227 12.5145 17.4441C12.208 17.1654 11.7337 17.188 11.4551 17.4945L11.4451 17.5055C11.1664 17.812 11.189 18.2863 11.4955 18.5649C11.802 18.8436 12.2763 18.821 12.5549 18.5145L12.5649 18.5035Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06279 1.25 1.25 6.06279 1.25 12C1.25 17.9372 6.06279 22.75 12 22.75C17.9372 22.75 22.75 17.9372 22.75 12C22.75 6.06279 17.9372 1.25 12 1.25ZM2.75 12C2.75 6.89121 6.89121 2.75 12 2.75C17.1088 2.75 21.25 6.89121 21.25 12C21.25 17.1088 17.1088 21.25 12 21.25C6.89121 21.25 2.75 17.1088 2.75 12Z"
      />
    </svg>
  );
};

export default QuestionMarkIconBig;
