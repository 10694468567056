import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import NewSchedule from 'assets/icons/newSchedule';
import GarbageArrowLess from 'assets/icons/GarbageArrowLess';
import Garbage from 'assets/icons/GarbageArrow';
import Key from 'assets/icons/key';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { updateUserPaginationRowCache } from 'pages/users/api/updateCache';
import { useFakeCodeMutation } from 'pages/users/api/mutations/generated/FakeCode';
import { useChangeUserStatusMutation } from 'pages/users/api/mutations/generated/ChangeUserStatus';
import UnassignedWorker from 'assets/icons/UnassignWorker';
import { useMeRole } from 'store/hooks/useMeRole';
import StopCooperationPopup from 'pages/users/components/ourUsers/StopCooperationPopup';
import uniqueArray from 'utils/helpers/uniqueArray';
import { Role, WorkPostStatus } from 'generated/graphql';
import StopCooperationSidebar from 'pages/users/components/ourUsers/StopCooperationSidebar';
import { clsx } from 'clsx';
import { userRole } from 'pages/main/lib/helpers/userRole';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';
import Tooltip from 'antd/es/tooltip';

type Props = {
  original: UsersPaginatedFragment;
};
const Actions = ({ original }: Props) => {
  const [showStopCooperationSidebar, setShowStopCooperationSidebar] = useState(false);
  const [showStopCooperationPopup, setShowStopCooperationPopup] = useState(false);
  const isMeAdmin = useMeRole([Role.Admin]);
  const isUserAdmin = userRole([Role.Admin], original);
  const isUserWorker = userRole([Role.Worker], original);
  const isUserForeman = userRole([Role.Foreman], original);
  const isUserWorkerOrForeman = isUserWorker || isUserForeman;
  const workerFacilities = useMemo(() => {
    return uniqueArray({
      array: original?.workposts?.filter(el => el.status === WorkPostStatus.Active),
      field: 'facilityId',
    }).map(el => el.facility);
  }, [original?.workposts]);
  const [fakeCodeMutation, { loading }] = useFakeCodeMutation();
  const fakeCode = useCallback(
    (user: UsersPaginatedFragment) => {
      fakeCodeMutation({
        variables: {
          phone: user.phone,
        },
      })
        .then(res => successToast(`Код доступа ${res.data?.fakeCode}`))
        .catch(e => errorHandler(e));
    },
    [fakeCodeMutation],
  );
  const [mutateUserStatus, { loading: mutateUserStatusLoading }] = useChangeUserStatusMutation();
  const toggleUserStatus = useCallback(
    (user: UsersPaginatedFragment) => {
      mutateUserStatus({
        variables: {
          input: {
            userId: user.id,
            isActive: !user.isActive,
          },
        },
        update: (cache, { data }) => {
          updateUserPaginationRowCache({ cache, data, user });
        },
      })
        .then(() => successToast('Статус активности изменен!'))
        .catch(err => errorToast(errorHandler(err)));
    },
    [mutateUserStatus],
  );
  const onShowStopCooperationSidebarHandler = () => {
    setShowStopCooperationSidebar(value => !value);
  };
  return (
    <>
      <div className="flex gap-x-3">
        {isUserWorkerOrForeman ? (
          <Tooltip title={'Перейти в график'}>
            <Link to={`/users/${original.id}/schedule`}>
              <NewSchedule className="text-smena_text" />
            </Link>
          </Tooltip>
        ) : (
          <div className="cursor-not-allowed">
            <NewSchedule className="text-smena_text-disabled" />
          </div>
        )}
        <Tooltip title={'Изменить статус активности'}>
          <button disabled={mutateUserStatusLoading} onClick={() => toggleUserStatus(original)}>
            {original.isActive ? <GarbageArrowLess /> : <Garbage />}
          </button>
        </Tooltip>
        <Tooltip title={'Открепить пользователя'}>
          <button
            type={'button'}
            onClick={onShowStopCooperationSidebarHandler}
            className={clsx(!isUserWorkerOrForeman ? 'cursor-not-allowed text-smena_text-disabled' : '')}
            disabled={!isUserWorkerOrForeman}
          >
            <UnassignedWorker />
          </button>
        </Tooltip>
        {isMeAdmin ? (
          isUserAdmin ? (
            <button disabled className="cursor-not-allowed">
              <Key className="text-smena_text-disabled" />
            </button>
          ) : (
            <Tooltip title={'Получить SMS-код'}>
              <button disabled={loading} onClick={() => fakeCode(original)}>
                <Key className="text-smena_text" />
              </button>
            </Tooltip>
          )
        ) : (
          <button disabled className="cursor-not-allowed">
            <Key className="text-smena_text-disabled" />
          </button>
        )}
      </div>
      <StopCooperationPopup
        user={original}
        facilities={workerFacilities}
        showStopCooperationPopup={showStopCooperationPopup}
        setShowStopCooperationPopup={setShowStopCooperationPopup}
      />
      <StopCooperationSidebar
        user={original}
        facilities={workerFacilities}
        showStopCooperationSidebar={showStopCooperationSidebar}
        setShowStopCooperationPopup={setShowStopCooperationPopup}
        setShowStopCooperationSidebar={setShowStopCooperationSidebar}
      />
    </>
  );
};

export default Actions;
