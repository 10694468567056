import { TIcon } from 'interfaces/TIcon';

const ThreeDots = ({ className }: TIcon) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 1.66602C7.55789 1.66602 7.13388 1.84165 6.82126 2.15427C6.50864 2.46689 6.33301 2.8909 6.33301 3.33302C6.33301 3.77513 6.50864 4.19914 6.82126 4.51176C7.13388 4.82439 7.55789 5.00002 8.00001 5.00002C8.44212 5.00002 8.86613 4.82439 9.17876 4.51176C9.49138 4.19914 9.66701 3.77513 9.66701 3.33302C9.66701 2.8909 9.49138 2.46689 9.17876 2.15427C8.86613 1.84165 8.44212 1.66602 8.00001 1.66602ZM8.00001 6.33302C7.55789 6.33302 7.13388 6.50865 6.82126 6.82127C6.50864 7.13389 6.33301 7.5579 6.33301 8.00002C6.33301 8.44213 6.50864 8.86614 6.82126 9.17876C7.13389 9.49139 7.55789 9.66702 8.00001 9.66702C8.44212 9.66702 8.86613 9.49139 9.17876 9.17876C9.49138 8.86614 9.66701 8.44213 9.66701 8.00002C9.66701 7.5579 9.49138 7.13389 9.17876 6.82127C8.86613 6.50865 8.44212 6.33302 8.00001 6.33302ZM8.61877 11.1085C8.4091 11.0287 8.18548 10.9921 7.96129 11.0008C7.53067 11.0174 7.12324 11.2003 6.82448 11.5108C6.52572 11.8214 6.35883 12.2356 6.35883 12.6665C6.35883 13.0975 6.52572 13.5117 6.82448 13.8222C7.12325 14.1328 7.53068 14.3156 7.96129 14.3323C8.18547 14.341 8.40909 14.3043 8.61877 14.2245C8.82846 14.1447 9.01986 14.0234 9.18153 13.8679C9.34321 13.7123 9.47183 13.5258 9.55969 13.3193C9.64754 13.1129 9.69283 12.8909 9.69283 12.6665C9.69283 12.4422 9.64754 12.2201 9.55969 12.0137C9.47183 11.8073 9.34321 11.6207 9.18153 11.4652C9.01986 11.3096 8.82845 11.1883 8.61877 11.1085Z"
      />
    </svg>
  );
};

export default ThreeDots;
