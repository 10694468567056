import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import MainSidebar from 'ui/sidebar';
import dayjs from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import { errorToast, successToast } from 'utils/helpers/notify';
import { positionName } from 'utils/helpers/filters/filtersName';
import { lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { errorHandler } from 'utils/helpers/errorHandler';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import SimpleSelect from 'ui/select';
import { useEditShiftMutation } from 'pages/moderationReport/api/mutations/generated/EditShift';
import { usePositions } from 'utils/api/usePositions';
import { shiftTimeDifference } from '../../../helpers';

const EditShiftSidebar = () => {
  const { showEditShiftSidebar, setShowEditShiftSidebar, shift, setShift, setLunchDuration, lunchDuration } =
    useStore();
  const { positions } = usePositions();
  const [timeStart, setTimeStart] = useState<DayjsOrNull>(null);
  const [timeEnd, setTimeEnd] = useState<DayjsOrNull>(null);

  const [editShiftMutation] = useEditShiftMutation();

  const onChangeTimeStart = useCallback((timeValue: DayjsOrNull) => {
    if (timeValue) {
      setTimeStart(dayjs(timeValue).set('milliseconds', 0));
      // if (facilityGroupName === 'Ашан') {
      //   setLunchDuration(lunchDurationHandle(timeDifference(timeValue, timeEnd)));
      // }
    }
  }, []);

  const onChangeTimeEnd = useCallback((timeValue: DayjsOrNull) => {
    if (timeValue) {
      setTimeEnd(timeValue.set('milliseconds', 0));
      // setEndScheduleTime(timeValue);
      // if (facilityGroupName === 'Ашан') {
      //   setLunchDuration(lunchDurationHandle(timeDifference(timeStart, timeValue)));
      // }
    }
  }, []);

  const exitHandler = useCallback(() => {
    setShowEditShiftSidebar(false);
    setShift(undefined);
  }, [setShift, setShowEditShiftSidebar]);

  const buttonClickHandler = useCallback(() => {
    if (shiftTimeDifference(timeStart, timeEnd)) {
      return errorToast('Смена не может быть меньше 1 часа');
    }
    if (shift) {
      editShiftMutation({
        variables: {
          input: {
            id: shift.id,
            lunchDuration,
            dateFrom: timeStart ? String(timeStart.valueOf()) : '',
            shiftEndDate: timeEnd ? String(timeEnd.valueOf()) : '',
          },
        },
      })
        .then(() => successToast('Смена успешно изменена'))
        .catch(error => {
          errorToast(errorHandler(error));
        });
      // .finally(() => {
      //   setShowEditShiftSidebar(false);
      // });
    }
  }, [editShiftMutation, lunchDuration, shift, timeEnd, timeStart]);

  useEffect(() => {
    if (shift) {
      setTimeEnd(dayjs(Number(shift.dateEnd)));
      setTimeStart(dayjs(Number(shift.dateFrom)));
      setLunchDuration(Number(shift.lunchDuration));
    }
  }, [setLunchDuration, shift]);
  return (
    <MainSidebar title="Редактировать смену" show={showEditShiftSidebar} setShow={setShowEditShiftSidebar}>
      <div className="flex flex-col gap-y-10">
        <div className="flex flex-col gap-y-1">
          <div className="H4">{positionName(positions, shift?.positionId)}</div>
          <div className="flex gap-x-2">
            <div>
              {dayjs(shift?.schedule?.dateFrom).format(RU_DATE)} – {dayjs(shift?.schedule?.dateTo).format(RU_DATE)}
            </div>
            <span>·</span>
            <span>{dayjs(Number(shift?.dateEnd)).format(RU_DATE)}</span>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-x-5 gap-y-4">
          <TimePickerInput
            label="Начало смены"
            className="col-span-2"
            popupClassName="fixed"
            value={timeStart}
            onChange={onChangeTimeStart}
          />
          <TimePickerInput
            label="Конец смены"
            className="col-span-2"
            popupClassName="fixed"
            value={timeEnd}
            onChange={onChangeTimeEnd}
          />

          <SimpleSelect
            divClassName="col-span-2"
            label="Обед"
            sort={false}
            onChange={value => {
              // if (facilityGroupName !== 'Ашан') {
              setLunchDuration(Number(value));
              // }
            }}
            value={lunchDuration}
            options={lunchDurationTypes}
            // options={facilityGroupName === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes}
            // disabled={facilityGroupName === 'Ашан'}
          />

          <div className="col-span-full flex justify-end gap-x-5">
            <button onClick={exitHandler} type="button" className="btn-stroke_reject_small">
              <span>Отменить</span>
            </button>
            <button onClick={buttonClickHandler} type="button" className="btn-primary_small">
              <span>Сохранить</span>
            </button>
          </div>
        </div>
      </div>
    </MainSidebar>
  );
};

export default EditShiftSidebar;
