export const options = (array?: any[] | null) => {
  const collator = new Intl.Collator();

  const mappedOptions = array?.map(manager => {
    const { firstname, lastname, id } = manager;
    return {
      name: lastname + ' ' + firstname,
      id,
    };
  });

  return mappedOptions?.sort((a, b) => {
    return collator.compare(a.name, b.name);
  });
};
