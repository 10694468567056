import { ShiftStage } from 'generated/graphql';
import { RequestShiftFragment } from 'pages/facilityRequests/api/fragments/generated/RequestShift.fragment';
import { useMemo } from 'react';
import { FacilityRequestShiftFragment } from 'pages/facilityRequests/api/fragments/generated/FacilityRequestShift.fragment';

interface Shifts {
  shifts: (RequestShiftFragment | FacilityRequestShiftFragment | undefined | null)[] | null | undefined;
}

export const useEmptyShifts = ({ shifts }: Shifts) => {
  return useMemo(() => shifts?.filter(shift => !shift?.shiftId && !shift?.isFree), [shifts]);
};

export const useFreeShifts = ({ shifts }: Shifts) => {
  return useMemo(() => shifts?.filter(shift => shift?.isFree), [shifts]);
};

export const useDesiredShifts = ({ shifts }: { shifts?: FacilityRequestShiftFragment[] }) => {
  return useMemo(() => shifts?.filter(shift => shift?.requestShiftSuggestions.length), [shifts]);
};

export const useAcceptedShifts = ({ shifts }: Shifts) => {
  return useMemo(
    () => shifts?.filter(shift => shift?.shiftId && shift?.shift?.stage === ShiftStage.Accepted),
    [shifts],
  );
};

export const useNotAcceptedByWorkerShifts = ({ shifts }: Shifts) => {
  return useMemo(
    () => shifts?.filter(shift => shift?.shift?.stage === ShiftStage.New && shift.authorId !== shift.shift?.userId),
    [shifts],
  );
};

export const useRefusedShift = ({ shifts }: Shifts) => {
  return useMemo(() => shifts?.filter(shift => shift?.shift?.stage === ShiftStage.Refused), [shifts]);
};

export const useProbationShifts = ({ shifts }: Shifts) => {
  return useMemo(
    () =>
      shifts?.filter(
        shift =>
          shift?.shift?.isProbation && ![ShiftStage.Ineligible, ShiftStage.Refused].includes(shift?.shift?.stage),
      ),
    [shifts],
  );
};
