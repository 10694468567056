import clsx from 'clsx';
import dayjs from 'dayjs';
import { LikeIcon } from 'assets/icons/LikeIcon';
import { positionName } from 'utils/helpers/filters/filtersName';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { usePositions } from 'utils/api/usePositions';

const ReviewElement = ({ review }: { review?: any }) => {
  const { positions } = usePositions();
  return (
    <div className={'pb-6 border-b border-smena_gray-5'}>
      <h3 className="Caption text-smena_text-secondary">{dayjs(review?.createdAt).format('DD.MM.YYYY HH:mm')}</h3>
      <div className="flex justify-between">
        <div className="flex gap-x-1 Subtitle1">
          <span>{`${review?.author.firstname} ${review?.author.lastname}`}</span>
          <span className="text-smena_text-secondary">({`${getRoleRu(review?.author.role)}`})</span>
        </div>
        <div
          className={clsx(
            'flex gap-x-2 px-2 py-1 rounded-lg Body2 items-center',
            review?.isGood
              ? 'text-smena_green-dark bg-smena_green-extra_light'
              : 'text-smena_red bg-smena_red-extra_light',
          )}
        >
          {review?.isGood ? (
            <>
              <span>
                <LikeIcon />
              </span>
              <span>Рекомендует</span>
            </>
          ) : (
            <>
              <span className="transform rotate-180">
                <LikeIcon />
              </span>
              <span>Не рекомендует</span>
            </>
          )}
        </div>
      </div>
      <div className="flex gap-x-1 Body1 text-smena_text-secondary">
        <span>{review?.facility?.facilityGroup?.name}</span>
        <span>·</span>
        <span>{review?.facility?.name}</span>
        <span>·</span>
        <span>{positionName(positions, review?.positionId)}</span>
      </div>
      <div className="Body1">{review?.text}</div>
    </div>
  );
};

export default ReviewElement;
