import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Plus from 'assets/icons/plus';
import { getActive } from 'utils/helpers/get/getActive';
import Filter from 'components/table/filters/filter';
import { activeOptions } from 'utils/helpers/constVariables';
import SimpleSelect from 'ui/select';
import { useMeRole } from 'store/hooks/useMeRole';
import TableFilter from 'components/table/filters';
import { useCustomSearchParams } from '../../../users/lib/useParams';
import Search from 'ui/search';
import { useFilteredFacilityGroups } from '../../../publishStory/components/showSettings/hooks/useFilteredFacilityGroups';
import { useFilteredCities } from '../../../publishStory/components/showSettings/hooks/useFilteredCities';
import { Table } from '@tanstack/react-table';
import { FacilityPaginatedFragment } from 'pages/facilities/api/fragments/generated/FacilityPaginated.fragment';
import ColumnSettings from 'components/table/columnSettings';
import { Role } from 'generated/graphql';
import { useCityById } from 'utils/api/useCity';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';

interface Props {
  table: Table<FacilityPaginatedFragment>;
}

const FacilitiesFilter = ({ table }: Props) => {
  const { handleParams, params, setParams, useParamsCount, resetFilter } = useCustomSearchParams();

  const facilityGroupIdParam = params.get('groupId');
  const cityParam = params.get('cityId');
  const activeParam = params.get('active');

  const [isActive, setIsActive] = useState<string | null>(activeParam);
  const [groupId, setGroupId] = useState<string | null>(facilityGroupIdParam);
  const [cityId, setCityId] = useState<string | null>(cityParam);
  const [showFilter, setShowFilter] = useState(false);

  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);
  const { city } = useCityById(cityParam);
  const filteredFacilityGroups = useFilteredFacilityGroups({ cities: cityId ? [cityId] : [] });
  const filteredCities = useFilteredCities({
    facilityGroupIds: groupId ? [groupId] : [],
    facilityIds: [],
  });

  const paramsCount = useParamsCount();
  const btnRef = useRef<HTMLButtonElement>(null);
  const isMeAdmin = useMeRole([Role.Admin]);

  return (
    <div className="filter-bar flex xs:flex-row flex-col gap-y-2 justify-between">
      <div className="flex flex-col gap-y-2">
        <div className={'flex relative gap-x-2'}>
          <Search />
          <button
            type="button"
            ref={btnRef}
            className="btn-stroke flex gap-x-1 items-center"
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <ColumnSettings<FacilityPaginatedFragment> table={table} />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              label="Город"
              onChange={value => setCityId(value)}
              value={cityId}
              allOption={'Все города'}
              placeholder={'Не выбрано'}
              options={filteredCities}
            />

            <SimpleSelect
              label="Группа"
              popupClassName={'max-w-[300px]'}
              onChange={value => setGroupId(value)}
              allOption={'Все группы'}
              placeholder={'Не выбрано'}
              value={groupId}
              options={filteredFacilityGroups}
            />

            <SimpleSelect
              label="Статус активности"
              onChange={value => {
                setIsActive(value);
              }}
              value={isActive}
              allOption="Все"
              placeholder="Не выбрано"
              options={activeOptions}
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('groupId', groupId);
                  handleParams('cityId', cityId);
                  handleParams('active', isActive);
                  params.delete('page');
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setGroupId(null);
                  setCityId(null);
                  setIsActive(null);
                  setParams();
                  setShowFilter(false);
                }}
              >
                Сбросить
              </button>
            </div>
          </TableFilter>
        </div>

        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!facilityGroupIdParam}
              content={'Группа ' + facilityGroupName}
              clickHandler={() => {
                setGroupId(null);
                resetFilter('groupId');
              }}
            />

            <Filter
              show={!!cityParam}
              content={'Город ' + city?.name}
              clickHandler={() => {
                setCityId(null);
                resetFilter('cityId');
              }}
            />

            <Filter
              show={!!activeParam}
              content={getActive(activeParam)}
              clickHandler={() => {
                setIsActive(null);
                resetFilter('active');
              }}
            />
          </div>
        )}
      </div>
      {isMeAdmin && (
        <Link to="/facilities/create">
          <button type="button" className="btn-primary_big flex items-center gap-x-2">
            <Plus className="text-smena_white" />
            Новый объект
          </button>
        </Link>
      )}
    </div>
  );
};

export default FacilitiesFilter;
