import { useCallback, useMemo } from 'react';
import { ShiftStage } from 'generated/graphql';
import { ICustomEvent } from 'interfaces/IEvent';
import { useStore } from 'store';

const useProbationShifts = (events?: ICustomEvent[]) => {
  const { facilityId, workpostId } = useStore();
  const filterMarks = useCallback((shift: ICustomEvent) => shift.resource.stage === ShiftStage.Wasted, []);
  const filterFacility = useCallback((event: ICustomEvent) => event.resource.facility?.id === facilityId, [facilityId]);

  const filterFacilityAndPosition = useCallback(
    (event: ICustomEvent) => filterFacility(event) && event.resource.positionId === workpostId,
    [filterFacility, workpostId],
  );

  return useMemo(() => {
    if (facilityId) {
      if (workpostId) {
        return events?.filter(filterMarks).filter(filterFacilityAndPosition);
      }
      return events?.filter(filterMarks).filter(filterFacility);
    }
    return events?.filter(filterMarks);
  }, [events, facilityId, workpostId, filterMarks, filterFacility, filterFacilityAndPosition]);
};

export default useProbationShifts;
