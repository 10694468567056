import { useChangesByStatus, useRequestStatus } from '../buttons/hooks';
import { ZayavkaChangesStatus, ZayavkaStage } from '../../../../generated/graphql';
import { useMemo } from 'react';
import { useCurrentRequest } from '../../../../utils/api/useCurrentRequest';

export const useShowHelperButtons = () => {
  const { request } = useCurrentRequest();
  const canceledStatus = useRequestStatus({ status: [ZayavkaStage.Canceled] });
  const blankStatus = useRequestStatus({ status: [ZayavkaStage.Blank] });
  const draftStatus = useRequestStatus({ status: [ZayavkaStage.Draft] });
  const refusedStatus = useRequestStatus({ status: [ZayavkaStage.Refused] });
  const workStatus = useRequestStatus({ status: [ZayavkaStage.Working] });
  const draftStatusChanges = useChangesByStatus({
    status: [ZayavkaChangesStatus.Draft, ZayavkaChangesStatus.Ineligible],
  });
  const workingChanges = useChangesByStatus({
    status: [ZayavkaChangesStatus.Refused, ZayavkaChangesStatus.Ineligible, ZayavkaChangesStatus.New],
  });
  const emptyChanges = !request?.zayavkaChanges?.length;
  return useMemo(() => {
    return (
      blankStatus ||
      (draftStatus && draftStatusChanges) ||
      refusedStatus ||
      (workStatus && (workingChanges || emptyChanges)) ||
      canceledStatus
    );
  }, [
    blankStatus,
    canceledStatus,
    draftStatus,
    draftStatusChanges,
    emptyChanges,
    refusedStatus,
    workStatus,
    workingChanges,
  ]);
};
