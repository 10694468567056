import { ApolloCache } from '@apollo/client';
import { FacilityGroupBalanceOperationFragment } from 'pages/balance/api/fragments/generated/FacilityGroupBalanceOperation.fragment';
import {
  GetFacilityGroupOperationListDocument,
  GetFacilityGroupOperationListQuery,
} from 'pages/balance/api/queries/generated/GetFacilityGroupOperationList';

interface Props {
  facilityGroupId: string;
  store: ApolloCache<any>;
  data?: FacilityGroupBalanceOperationFragment;
  id?: string;
  remove?: boolean;
  edit?: boolean;
}
export const writeBalanceListCache = ({ facilityGroupId, store, data, id, remove, edit }: Props) => {
  const variables = {
    facilityGroupId,
  };
  const cacheList = store.readQuery<GetFacilityGroupOperationListQuery>({
    query: GetFacilityGroupOperationListDocument,
    variables,
  });
  store.writeQuery<GetFacilityGroupOperationListQuery>({
    query: GetFacilityGroupOperationListDocument,
    variables,
    data: {
      __typename: 'Query',
      getFacilityGroupOperationList: remove
        ? [...cacheList!.getFacilityGroupOperationList.filter(el => el.id !== id)]
        : edit
        ? [
            ...cacheList!.getFacilityGroupOperationList.map(el => {
              if (el.id === id) {
                return data!;
              }
              return el;
            }),
          ]
        : [...cacheList!.getFacilityGroupOperationList, data!],
    },
    id: remove || edit ? undefined : id,
  });
};
