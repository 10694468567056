import { BalanceOperationType, ReferralProgramStatusEnum } from 'generated/graphql';

export const RU_DATE = 'DD.MM.YYYY';
export const TIME_DATE = 'HH:mm';
export const ISO_DATE = 'YYYY-MM-DD';
export const latitudeReg = /(^-?[0-9]{2}[.]\b\d{3,15}\b)/;
export const longitudeReg = /(^-?[0-9]{2,3}[.]\b\d{3,15}\b)/;
export const reqCoordinates = /^(-?\d+(\.\d+)?).(\d+(\.\d+)?)$/;
export const erpReg = /^[\d, ]+$/;
export const regFacilityName = /^[а-яА-ЯёЁa-zA-Z0-9 ,]+$/;
export const regPassword = /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/;
export const regTimeAtVacancyPage = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/;
export const emailReg = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const regFIO = /^[А-яёЁa-zA-Z -]+$/;
export const isTest = window.location.origin.includes('localhost') || window.location.origin.includes('test');
export const isProd = !isTest;
export const REDIRECT_PAGE = 'redirectPage';
export const tgLink = 'https://api.telegram.org/bot';
export const chatId = '@dashboard_smena';

export const activeOptions = [
  { id: 'true', name: 'Активный' },
  { id: 'false', name: 'Не активный' },
];

export const periodOptions = [
  { id: 'DAY', name: 'День' },
  { id: 'NIGHT', name: 'Ночь' },
];

export const leadStatusOptions = [
  { id: 'ACCEPTED', name: 'Работает' },
  { id: 'PENDING', name: 'Ожидание' },
  { id: 'REJECTED', name: 'Отказ' },
];

export const referralProgramStatusOptions = [
  { id: ReferralProgramStatusEnum.Active, name: 'Активно' },
  { id: ReferralProgramStatusEnum.Planned, name: 'Запланировано' },
  { id: ReferralProgramStatusEnum.Archive, name: 'Архив' },
  { id: ReferralProgramStatusEnum.Draft, name: 'Черновик' },
];

export const balanceOperationTypeOptions = [
  { id: BalanceOperationType.Addition, name: 'Пополнение' },
  { id: BalanceOperationType.Subtraction, name: 'Списание' },
];
