import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useChangeShiftModerationMutation } from 'pages/facilityRequests/api/mutations/generated/ChangeShiftModeration';
import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { successToast, errorToast } from 'utils/helpers/notify';

interface Props {
  paymentArrestedSum?: number;
  paymentArrestedCause?: string;
  paymentArrestedComment?: string;
  cancelPaymentArrested: boolean;
}

export const useHoldForm = () => {
  const [changeShift] = useChangeShiftModerationMutation({ refetchQueries: [GetSupervisorWorkSheetDocument] });
  const { shift } = useStore() as any;

  const initialValues: Props = {
    paymentArrestedSum: shift?.moderation?.paymentArrestedSum,
    paymentArrestedCause: shift?.moderation?.paymentArrestedCause,
    paymentArrestedComment: shift?.moderation?.paymentArrestedComment,
    cancelPaymentArrested: shift?.moderation?.cancelPaymentArrested,
  };

  const validate = (values: Props) => {
    const errors: any = {};

    if (values.paymentArrestedSum || values.paymentArrestedCause || values.paymentArrestedComment) {
      if (!values.paymentArrestedSum) {
        errors.paymentArrestedSum = 'Обязательное поле';
      }
      if (!values.paymentArrestedCause) {
        errors.paymentArrestedCause = 'Обязательное поле';
      }
      if (!values.paymentArrestedComment) {
        errors.paymentArrestedComment = 'Обязательное поле';
      }
    }

    return errors;
  };

  const onSubmit = (values: Props) => {
    changeShift({
      variables: {
        moderation: {
          shiftId: shift.id,
          hourRate: shift.moderation?.hourRate || 0,
          value: shift.moderation?.value || 0,
          paymentArrestedSum: Number(values.paymentArrestedSum) || undefined,
          paymentArrestedCause: values.paymentArrestedCause,
          paymentArrestedComment: values.paymentArrestedComment,
          cancelPaymentArrested: values.cancelPaymentArrested,
        },
      },
    })
      .then(res => {
        if (res.data?.changeShiftModeration) {
          successToast('Все изменения сохранены');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };
  return { initialValues, onSubmit, validate };
};
