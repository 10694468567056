import clsx from 'clsx';
import { ErrorNotification } from '../errorNotification';
import * as React from 'react';
import { HelperText } from '../helperText';

interface FormInput {
  divClassName?: string;
  style?: React.CSSProperties;
  label?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  value: string | number | undefined;
  name: string;
  placeholder?: string;
  className?: string;
  error?: string;
  helperText?: string;
  validation?: boolean;
  showTextRole?: string[];
  showText?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

const TextArea = ({
  divClassName,
  onChange,
  onBlur,
  name,
  placeholder,
  className,
  value,
  label,
  error,
  style,
  showText,
  disabled,
  helperText,
  maxLength,
}: FormInput) => {
  return (
    <>
      {showText ? (
        <div className={clsx('flex flex-col gap-y-0.5', divClassName)}>
          {Boolean(label) && <div className="Subtitle2">{label}</div>}
          <span className={'Body2 text-smena_text-secondary'}>{value ? value : 'Отсутствует'}</span>
        </div>
      ) : (
        <div className={clsx('flex flex-col gap-y-0.5', divClassName)}>
          {Boolean(label) && <span className="Subtitle2">{label}</span>}
          <textarea
            style={style}
            onBlur={onBlur}
            maxLength={maxLength}
            className={clsx(error ? 'textarea-error' : 'textarea-primary', className)}
            placeholder={placeholder}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
          {error && <ErrorNotification text={error} />}
          {helperText && !error && <HelperText text={helperText} />}
        </div>
      )}
    </>
  );
};
export default TextArea;
