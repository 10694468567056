import { useMapStore } from 'pages/facility/lib/hooks/useMapStore';
import React, { useMemo } from 'react';
import { Map, Placemark, withYMaps, YMaps } from 'react-yandex-maps';

export default React.memo(() => {
  const { latitude, longitude } = useMapStore();
  const PositionedMap = React.memo(() => {
    const cords = useMemo(() => (latitude && longitude ? [+latitude, +longitude] : []), []);
    return (
      <div className="w-full h-full overflow-hidden rounded-md">
        <Map
          state={{
            center: cords,
            zoom: 10,
          }}
          width="100%"
          height="100%"
        >
          <Placemark
            geometry={cords}
            options={{
              preset: 'islands#dotIcon',
            }}
          />
        </Map>
      </div>
    );
  });

  const ConnectedMap = React.useMemo(() => {
    return withYMaps(PositionedMap, true, ['geocode']);
  }, [PositionedMap]);
  return (
    <YMaps query={{ apikey: process.env.REACT_APP_YA_MAP_API }}>
      <ConnectedMap />
    </YMaps>
  );
});
