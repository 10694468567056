import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import { ChartType, StatisticPeriod } from 'generated/graphql';
import { ITooltipStatus } from 'interfaces/ITooltipStatus';
import { useIsSendRequest } from '../hooks';
import { useGetStatisticLazyQuery } from 'pages/main/api/queries/generated/GetStatistic';

import SimpleSelect from 'ui/select';
import { chartTimes } from 'utils/helpers/lists/chartTimes';
import Skeleton from 'antd/es/skeleton';
import { tickFormatterHandlerFn } from 'utils/helpers/charts/tickFormatter';
import { intervalChart } from 'utils/helpers/charts/intervalChart';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { labelFormatterHandler } from 'utils/helpers/charts/getLabelFormaterCharts';
import { useStatisticArray } from './utils/hook';
import { getStatisticRu } from './utils/helpers';
import { chartClick, chartHover } from 'utils/helpers/charts/chartHandlers';

const FinishedAndGoodShifts = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const [time, setTime] = useState(StatisticPeriod.Day);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });
  const isSendRequest = useIsSendRequest();
  const [loadData, { data, loading }] = useGetStatisticLazyQuery();

  const res = useStatisticArray(data);

  const handleTimeChange = useCallback((value: string) => {
    setTime(value as StatisticPeriod);
  }, []);

  const tickFormatterHandler = useCallback(
    (value: any) => {
      if (value && value !== 'auto') {
        return tickFormatterHandlerFn(value, time);
      }
      return '0';
    },
    [time]
  );

  const interval = useMemo(() => intervalChart(time), [time]);

  useEffect(() => {
    if (!isSendRequest) return;
    loadData({
      variables: {
        input: {
          facilityId: trendsFacilityId,
          facilityGroupId: trendsGroupId,
          period: time,
          types: [ChartType.FinishedShifts, ChartType.GoodShifts],
        },
      },
    });
  }, [isSendRequest, loadData, time, trendsFacilityId, trendsGroupId]);
  return (
    <div className={'row-span-1 grid gap-y-[5px]'}>
      <div className="grid grid-cols-[auto,_auto] justify-between items-center">
        <span className="Subtitle2 text-smena_text-secondary">Соотношение завершенных и хороших смен</span>
        <SimpleSelect
          divClassName="chart-filter"
          label=""
          onChange={handleTimeChange}
          value={time}
          sort={false}
          options={chartTimes}
        />
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="h-[240px]">
          <div className={'flex gap-x-4'}>
            <div className={'flex gap-x-2 items-center'}>
              <span className={'w-[10px] h-[10px] bg-primary rounded-full'}></span>
              <span className={'Caption text-smena_text-secondary'}>Завершенные смены</span>
            </div>
            <div className={'flex gap-x-2 items-center'}>
              <span className={'w-[10px] h-[10px] bg-smena_teal rounded-full'}></span>
              <span className={'Caption text-smena_text-secondary'}>Хорошие смены</span>
            </div>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={res}
              onClick={state => {
                if (state !== null) {
                  if (tooltipStatus.barId === state.activeTooltipIndex) {
                    setTooltipStatus(chartHover(state));
                  } else {
                    setTooltipStatus(chartClick(state));
                  }
                }
              }}
            >
              <CartesianGrid strokeDasharray="5" />
              <XAxis
                dataKey={res ? 'createdAt' : undefined}
                interval={interval}
                tickFormatter={tickFormatterHandler}
                height={30}
                angle={-25}
                tickMargin={10}
              />
              <YAxis />
              <Tooltip
                cursor={false}
                trigger={tooltipStatus.status}
                labelFormatter={(label: string) => labelFormatterHandler({ label, time })}
                formatter={(value: string, name: string | ChartType) => [value, getStatisticRu(name)]}
                separator={':'}
              />
              <Line
                type="monotone"
                dataKey={ChartType.FinishedShifts}
                stroke="#397DFF"
                activeDot={{ r: 5 }}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey={ChartType.GoodShifts}
                stroke="#2CD7B8"
                activeDot={{ r: 5 }}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default FinishedAndGoodShifts;
