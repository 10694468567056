import React, { useCallback, useState } from 'react';
import Up from 'assets/icons/sort/up';
import Down from 'assets/icons/sort/down';
import { useSearchParams } from 'react-router-dom';
import { FacilityGroupCasingsSorting, SortingWay } from 'generated/graphql';
import clsx from 'clsx';

interface Props {
  item: {
    name?: JSX.Element | string;
    id: string;
    sort: boolean;
  };
  defaultSortBy?: string;
}

const HeaderItem = ({ item, defaultSortBy = FacilityGroupCasingsSorting.Position }: Props) => {
  const [clicked, setClicked] = useState('');
  const [params, setParams] = useSearchParams();
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const desc = sortWay === SortingWay.Desc;
  const asc = sortWay === SortingWay.Asc;

  const color = useCallback(
    (icon: string, column: { id: string }) => {
      const thisColumn = sortBy === column.id;
      if (!thisColumn) {
        return 'text-smena_text-disabled';
      }
      if (desc) {
        if (icon === 'up') {
          return 'text-smena_text-disabled';
        }
        return 'text-smena_text-helper';
      }
      if (asc) {
        if (icon === 'up') {
          return 'text-smena_text-helper';
        }
        return 'text-smena_text-disabled';
      }
      return 'text-smena_text-disabled';
    },
    [asc, desc, sortBy]
  );
  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    if (item.sort) {
      setClicked(item.id);
      params.set('sortBy', (e.target as Element).id);
      setParams(params);
      if (clicked !== (e.target as Element).id) {
        params.set('sortWay', 'desc');
        setParams(params);
      } else {
        if (sortWay === null) {
          params.set('sortWay', SortingWay.Desc);
          setParams(params);
        }
        if (desc) {
          params.set('sortWay', SortingWay.Asc);
          setParams(params);
        }
        if (asc) {
          params.set('sortBy', defaultSortBy);
          params.delete('sortWay');
          setParams(params);
        }
      }
    }
  };
  return (
    <div
      className={clsx(item.sort ? 'cursor-pointer' : '', 'flex gap-x-2 items-center')}
      id={item.id}
      onClick={onClickHandler}
    >
      {typeof item.name === 'string' ? <span className="pointer-events-none">{item.name}</span> : item.name}
      {item.sort && (
        <span className="pointer-events-none flex flex-col gap-y-1">
          <Up className={color('up', item)} />
          <Down className={color('down', item)} />
        </span>
      )}
    </div>
  );
};

export default HeaderItem;
