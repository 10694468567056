import React from 'react';
import { Placemark, YMapsApi } from 'react-yandex-maps';
import { getImagePath } from 'utils/helpers/get/getImagePath';
import { morph } from 'utils/helpers/morph';
import { FacilityVacancies } from '../../store';
import { createPinTemplateFactory } from '../../utils/createPinTemplateFactory';

type Props = {
  pin: FacilityVacancies;
  ymaps: YMapsApi | null;
};

const Pin: React.FC<Props> = ({ pin, ymaps }) => {
  const img = getImagePath(pin.facilityGroup?.logo?.path);
  const cords = [Number(pin.latitude), Number(pin.longitude)];
  if (!ymaps) return null;
  const template = createPinTemplateFactory(ymaps)({
    img,
    vacanciesLength: pin.vacancies?.length || 0,
    name: pin.name,
    isHover: pin.isHover,
    isActive: pin.isActive,
    pin,
  });

  return (
    <Placemark
      geometry={cords}
      options={{
        iconLayout: template,
        zIndex: pin.isActive || pin.isHover ? 700 : 650,
      }}
      properties={{
        img,
        pin,
        vacanciesLength: pin.vacancies?.length || 0,
        vacanciesMorph: morph(pin.vacancies?.length || 0, ['вакансия', 'вакансии', 'вакансий']),
        name: pin.name,
        isActive: pin.isActive,
      }}
    />
  );
};
export default Pin;
