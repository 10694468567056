import Tooltip from 'antd/lib/tooltip';
import QuestionMarkIconSmall from 'assets/icons/QuestionMarkIconSmall';
import clsx from 'clsx';
import dayjs from 'dayjs';

interface HistoryInterface {
  rate: number;
  startDatetime: any;
}

interface ItemInterface {
  currentItem: HistoryInterface;
  nextItem: HistoryInterface | null;
  isLastItem: boolean;
}

interface Props {
  history?: HistoryInterface[] | null;
  disabled?: boolean;
}

const Item = ({ currentItem, nextItem, isLastItem }: ItemInterface) => {
  const { rate: currentRate, startDatetime: currentStartDatetime } = currentItem;
  const nextRate = nextItem?.rate;

  return (
    <div>
      <span className="Body2 text-smena_text-helper">{dayjs(currentStartDatetime).format('DD.MM.YYYY - ')}</span>
      {!isLastItem && <span>{nextRate} ₽</span>}
      {isLastItem ? null : <span className="text-smena_text-helper"> → </span>}
      <span>{currentRate} ₽</span>
    </div>
  );
};

const HistoryRateChanges = (props: Props) => {
  const { history, disabled = false } = props;
  
  return (
    <Tooltip
      title={
        history?.length && !disabled ? (
          <div className="flex flex-col">
            {history?.map((item, index) => (
              <Item
                key={index}
                currentItem={item}
                nextItem={history.length - 1 !== index ? history[index + 1] : null}
                isLastItem={history.length - 1 === index}
              />
            ))}
          </div>
        ) : (
          ''
        )
      }
    >
      <span
        className={clsx('Subtitle2 text-primary flex items-center self-start gap-2', {
          ['text-smena_text-disabled cursor-default']: disabled,
          ['cursor-pointer']: !disabled,
        })}
      >
        <QuestionMarkIconSmall className={clsx('text-primary', { ['text-smena_text-disabled']: disabled })} />
        История изменений ставки
      </span>
    </Tooltip>
  );
};

export default HistoryRateChanges;
