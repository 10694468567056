import { IOption } from 'interfaces/IOption';
import { useFacilityGroupExtended } from 'utils/api/useFacilityGroup';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useFacilityGroupUploadImageMutation } from '../api/mutations/generated/FacilityGroupUploadImage';
import { useUpdateFacilityGroupMutation } from '../api/queries/generated/UpdateFacilityGroup';
import { useCreateFacilityGroupMutation } from '../api/queries/generated/CreateFacilityGroup';
import { useStore } from 'store';

export type useFacilityGroupFormType = {
  groupName: string;
  imageURL?: string;
  director: IOption | null;
  legalEntity: string;
  imgSrc: string;
};

export const useFacilityGroupForm = (setImgSrc: React.Dispatch<React.SetStateAction<string>>) => {
  const navigate = useNavigate();
  const { facilityGroup, id } = useFacilityGroupExtended();
  const { files, setFiles } = useStore();
  const [uploadImage] = useFacilityGroupUploadImageMutation();
  const [updateGroup, { loading: updateLoading }] = useUpdateFacilityGroupMutation();
  const [createGroup, { loading: createLoading }] = useCreateFacilityGroupMutation();
  const initialValues: useFacilityGroupFormType = {
    groupName: facilityGroup?.name || '',
    imageURL: facilityGroup?.logo?.path,
    director: null as unknown as IOption | null,
    legalEntity: '',
    imgSrc: '',
  };

  const loading = updateLoading || createLoading;

  const validate = (values: useFacilityGroupFormType) => {
    const errors: any = {};
    if (!values.groupName.trim()) {
      errors.groupName = 'Вы не указали название группы';
    }
    return errors;
  };
  const updateGroupFn = useCallback(
    (name: string) => {
      if (!id) return;
      updateGroup({
        variables: {
          input: {
            id,
            name,
          },
        },
      })
        .then(response => {
          if (response.data?.updateFacilityGroup) {
            successToast('Группа изменена');
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    },
    [id, updateGroup],
  );
  const onSubmit = (values: useFacilityGroupFormType) => {
    const name = values.groupName.trim();
    if (id) {
      if (files.length === 1) {
        uploadImage({
          variables: {
            id,
            image: files[0],
          },
        })
          .then(() => {
            successToast('Фото загружено');
            updateGroupFn(name);
          })
          .catch(e => {
            errorToast(errorHandler(e));
            setFiles([]);
            setImgSrc('');
          });
      } else {
        updateGroupFn(name);
      }
    } else {
      createGroup({
        variables: {
          name,
        },
      })
        .then(response => {
          successToast('Группа создана');
          const newGroup = response.data?.createFacilityGroup;
          if (newGroup) {
            if (files.length === 1) {
              uploadImage({
                variables: {
                  id: newGroup.id,
                  image: files[0],
                },
              })
                .then(() => {
                  successToast('Фото загружено');
                })
                .catch(e => errorToast(errorHandler(e)));
            }
            navigate(`/groups/${newGroup.id}`);
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    }
  };
  return { initialValues, validate, onSubmit, loading };
};
