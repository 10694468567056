import { DayOrNullArray } from 'interfaces/CustomTypes';
import dayjs from 'dayjs';
import { PeriodType } from 'generated/graphql';

export const disabledDatesFn = ({
  calendarValue,
  current,
  period,
}: {
  calendarValue: DayOrNullArray;
  current: dayjs.Dayjs;
  period?: string;
}) => {
  const startOf = (date: dayjs.Dayjs) => {
    return date.startOf('day').valueOf();
  };
  if (!calendarValue) {
    if (period === PeriodType.Week) {
      return current > dayjs().endOf('day').subtract(6, 'days');
    }
    if (period === PeriodType.TwoWeek) {
      return current > dayjs().endOf('day').subtract(13, 'days');
    }
    if (period === PeriodType.Month) {
      if (current.startOf('day') <= dayjs().subtract(1, 'month').startOf('month')) {
        return current.get('date') !== 1;
      }
      return true;
    }
  }

  const firstDate = calendarValue ? calendarValue[0] : null;
  const lastDate = calendarValue ? calendarValue[1] : null;

  if (period === PeriodType.Week) {
    if (firstDate?.isValid()) {
      return startOf(current) !== startOf(firstDate.add(1, 'week').subtract(1, 'd'));
    }
    if (lastDate?.isValid()) {
      return startOf(current) !== startOf(lastDate.subtract(1, 'week').add(1, 'd'));
    }
    return current && current < dayjs().endOf('day');
  }
  if (period === PeriodType.TwoWeek) {
    if (firstDate?.isValid()) {
      return startOf(current) !== startOf(firstDate.add(2, 'week').subtract(1, 'd'));
    }
    if (lastDate?.isValid()) {
      return startOf(current) !== startOf(lastDate.subtract(2, 'week').add(1, 'd'));
    }
    return false;
  }
  if (period === PeriodType.Month) {
    if (firstDate?.isValid()) {
      return startOf(current) !== startOf(firstDate.add(1, 'month').subtract(1, 'd'));
    }
    if (lastDate?.isValid()) {
      return startOf(current) !== startOf(lastDate.subtract(1, 'month'));
    }
    return false;
  }
  return false;
};
