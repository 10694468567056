export const objectDurationTypes = [
  {
    id: 15,
    name: '15 минут',
  },
  {
    id: 30,
    name: '30 минут',
  },
  {
    id: 45,
    name: '45 час',
  },
  {
    id: 60,
    name: '1 час',
  },
];
