import { useMemo } from 'react';
import { userFullName } from '../../utils/helpers/userFullName';
import { useLead } from '../../utils/api/useLead';
import dayjs from 'dayjs';
import { errorToast, successToast } from '../../utils/helpers/notify';
import { errorHandler } from '../../utils/helpers/errorHandler';
import { useUpdateLeadMutation } from './api/mutations/generated/UpdateLead';
import { updateLeadCache } from './api/updateCahe';

export const useLeadTitle = () => {
  const { lead } = useLead();
  return useMemo(() => {
    if (lead) {
      return `Лид ${userFullName(lead.user)}`;
    }
    return '';
  }, [lead]);
};

export const useLeadForm = () => {
  const { lead, id } = useLead();
  const [updateLeadMutation, { loading }] = useUpdateLeadMutation({
    update: (cache, { data }) => {
      if (!id) return;
      updateLeadCache({ cache, data, leadId: id });
    },
  });
  const initialValues = {
    date: lead?.date ? dayjs(lead?.date) : dayjs(),
    id: id || '',
    isProcessed: lead?.isProcessed,
    status: lead?.status,
  };
  const onSubmit = (values: typeof initialValues) => {
    if (!id) return;
    updateLeadMutation({
      variables: {
        input: { ...values },
      },
    })
      .then(response => {
        if (response.data?.updateLead) {
          successToast('Вы успешно внесли изменения');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };
  return { initialValues, onSubmit, loading };
};
