import React from 'react';
import { useRemoveStatusFreeRequestShiftMutation } from '../../../api/mutations/generated/RemoveStatusFreeRequestShift';
import { useStore } from 'store';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from '../../../../../utils/helpers/errorHandler';

const FreeShiftWithoutUsers = () => {
  const setShowShiftSidebar = useStore(state => state.setShowShiftSidebar);
  const requestShift = useStore(state => state.requestShift);
  const setRequestShift = useStore(state => state.setRequestShift);
  const [removeStatusFreeRequestShift] = useRemoveStatusFreeRequestShiftMutation();
  const defaultHandler = () => {
    if (!requestShift) return;
    removeStatusFreeRequestShift({
      variables: {
        input: {
          requestShiftId: requestShift.id,
        },
      },
      update: cache => {
        cache.modify({
          id: `ZayavkaShift:${requestShift.id}`,
          fields: {
            isFree() {
              return false;
            },
          },
        });
      },
    })
      .then(() => {
        successToast('Применен статус по умолчанию');
        setShowShiftSidebar(false);
        setRequestShift(undefined);
      })
      .catch(error => {
        errorToast(errorHandler(error));
      });
  };
  return (
    <>
      <p className={'Subtitle1'}>
        Исполнитель: <span className={'text-smena_text-helper'}>отсутствует</span>
      </p>
      <button className={'btn-stroke self-end'} type={'button'} onClick={defaultHandler}>
        Сделать смену по умолчанию
      </button>
    </>
  );
};

export default FreeShiftWithoutUsers;
