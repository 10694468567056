import Skeleton from 'antd/es/skeleton';
import dayjs from 'dayjs';
import { userFullName } from 'utils/helpers/userFullName';
import { RU_DATE } from 'utils/helpers/constVariables';
import { ManualUserAccrualFragment } from '../api/fragments/generated/ManualUserAccural.fragment';
import { useUser } from 'utils/api/useUser';

interface Props {
  user: { __typename?: 'ManualUserAccrual' } & ManualUserAccrualFragment;
}

const ManualUserAccrualUser = ({ user }: Props) => {
  const { user: currentUser, loading } = useUser(user.userId);

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="grid md:gap-3" style={{ gridTemplateColumns: '300px 150px 200px' }}>
          <div className="p-2 text-left text-sm font-medium">{userFullName(currentUser)}</div>
          <div className="p-2 text-left text-sm font-medium uppercase tracking-wider">{user.summ}</div>
          <div className="p-2 text-left text-sm font-medium uppercase tracking-wider">
            {dayjs(user.createdAt).format(RU_DATE)}
          </div>
        </div>
      )}
    </>
  );
};

export default ManualUserAccrualUser;
