import React, { useEffect, useRef, useState } from 'react';
import { AddressSuggestions, DaDataAddress, DaDataAddressBounds, DaDataSuggestion } from 'react-dadata';
import { dotify } from '../utils';
import Highlighter from 'react-highlight-words';
import { ErrorNotification } from 'ui/errorNotification';
import clsx from 'clsx';
import Skeleton from 'antd/es/skeleton';

interface Props {
  label?: string;
  value?: DaDataSuggestion<DaDataAddress>;
  textValue?: string;
  onType?: (value: string) => void;
  onChange?: (value: DaDataSuggestion<DaDataAddress> | undefined) => void;
  filterFrom?: DaDataAddressBounds;
  filterTo?: DaDataAddressBounds;
  placeholder?: string;
  customInput?: React.ElementType<any> | undefined;
  error?: string;
  inputClassname?: string;
  style?: React.CSSProperties;
  filterLocations?: { [p: string]: any }[];
  loading?: boolean;
  asterisk?: boolean;
}

const DadataAddress = ({
  label,
  value,
  onChange,
  filterFrom,
  filterTo,
  placeholder,
  customInput,
  inputClassname,
  error,
  textValue,
  style,
  filterLocations,
  loading,
  onType,
  asterisk = false,
}: Props) => {
  const [searchText, setSearchText] = useState('');
  const suggestionsRef = useRef<AddressSuggestions>(null);
  useEffect(() => {
    if (suggestionsRef.current) {
      if (value) {
        suggestionsRef.current.setInputValue(dotify(value.value));
      } else if (typeof textValue === 'string') {
        suggestionsRef.current.setInputValue(textValue);
      }
    }
  }, [textValue, value]);
  return (
    <>
      {loading ? (
        <div className={clsx('flex flex-col gap-y-1 justify-center')}>
          {Boolean(label) && <label className="label-primary">{label}</label>}
          <Skeleton.Input active />
        </div>
      ) : (
        <div className={'flex flex-col gap-y-0.5'}>
          {Boolean(label) && (
            <label className="label-primary">
              {label} {asterisk ? <span className={clsx(error ? 'text-smena_red' : '')}>*</span> : null}
            </label>
          )}
          <AddressSuggestions
            token={`${process.env.REACT_APP_DADATA_API_KEY}}`}
            ref={suggestionsRef}
            onChange={onChange}
            value={value}
            filterFromBound={filterFrom}
            filterToBound={filterTo}
            customInput={customInput}
            filterLocations={filterLocations}
            inputProps={{
              className: clsx(error ? 'input-error' : inputClassname || 'input-primary', 'px-3'),
              value: textValue,
              placeholder,
              onChange: event => {
                if (onType) {
                  onType(event.currentTarget.value);
                }
                setSearchText(event.currentTarget.value);
              },
              style,
            }}
            renderOption={suggestion => {
              return (
                <Highlighter
                  highlightClassName="react-dadata--highlighted"
                  searchWords={searchText.split(' ')}
                  autoEscape
                  textToHighlight={dotify(suggestion.value)}
                />
              );
            }}
          />
          {error && <ErrorNotification text={error} />}
        </div>
      )}
    </>
  );
};

export default DadataAddress;
