import React, { useCallback, useMemo } from 'react';
import { chain } from 'lodash';
import uniqueArray from 'utils/helpers/uniqueArray';
import { useGetRequestByFacilityId } from '../../utils/api/useGetRequestByFacilityId';
import { useStore } from 'store';
import { getUserFIO } from 'utils/helpers/userFullName';

export const useUsersAtScheduleRequest = () => {
  const { sortedRequests } = useGetRequestByFacilityId();

  const shiftsWithUsers = useMemo(
    () => chain(sortedRequests).map('zayavkaSchedule').flatten().map('zayavkaShift').flatten().filter('shift').value(),
    [sortedRequests],
  );

  const usersFromShifts = useMemo(
    () => shiftsWithUsers?.map(requestShift => requestShift?.shift?.user).filter(Boolean),
    [shiftsWithUsers],
  );

  const uniqueUsers = useMemo(() => uniqueArray({ array: usersFromShifts, field: 'id' }), [usersFromShifts]);

  const usersOptions = useMemo(
    () =>
      uniqueUsers.map(user => ({
        id: user?.id,
        name: getUserFIO(user),
      })),
    [uniqueUsers],
  );

  const positions = useFilterRequestWithSchedule().map(el => el.position);

  const uniqPositionsFromRequest = useMemo(() => uniqueArray({ array: positions, field: 'id' }), [positions]);

  return { usersOptions, positionOptions: uniqPositionsFromRequest };
};

export const useActiveRequestSchedule = () => {
  const { facilityScheduleIds, setFacilityScheduleHeaderCheckbox, setFacilityScheduleIds } = useStore();
  const { requestSchedules, sortedRequests } = useGetRequestByFacilityId();
  const activeRequestSchedule = useMemo(() => {
    return requestSchedules
      ?.filter(request => facilityScheduleIds.includes(request.zayavkaId))
      .map(scheduleRequest => scheduleRequest.zayavkaId);
  }, [requestSchedules, facilityScheduleIds]);

  const checkboxHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setFacilityScheduleHeaderCheckbox(checked);
      if (sortedRequests) {
        if (checked) {
          setFacilityScheduleIds(sortedRequests.map(request => request.id));
        } else setFacilityScheduleIds([]);
      }
    },
    [setFacilityScheduleHeaderCheckbox, setFacilityScheduleIds, sortedRequests],
  );

  const requestWithSchedule = useMemo(() => {
    return requestSchedules
      ?.filter(request => activeRequestSchedule?.includes(request.zayavkaId))
      .filter(request => !!request.zayavkaShift?.length);
  }, [activeRequestSchedule, requestSchedules]);

  return { checkboxHandler, requestWithSchedule };
};

export const useFilterRequestWithSchedule = () => {
  const positionId = useStore(state => state.positionId);
  const workerId = useStore(state => state.workerId);
  const { requestWithSchedule } = useActiveRequestSchedule();
  return useMemo(
    () =>
      requestWithSchedule?.filter(request => {
        const userScheduleRequest = request?.zayavkaShift?.find(el => el.shift?.userId === workerId);
        const worker = workerId ? request?.id === userScheduleRequest?.zayavkaScheduleId : true;
        const position = positionId ? request?.positionId === positionId : true;
        return worker && position;
      }),
    [positionId, requestWithSchedule, workerId],
  );
};
