import { useUserShiftsQuery } from '../../../pages/userSchedule/api/queries/generated/UserShifts';
import { useCurrentUser } from '../useUser';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

export const useUsersShift = () => {
  const { userId } = useCurrentUser();
  const [params] = useSearchParams();
  const dateFromParam = params.get('dateFrom');
  const dateToParam = params.get('dateTo');
  const dateFrom = dateFromParam ? dayjs(dateFromParam) : dayjs().startOf('month');
  const dateTo = dateToParam ? dayjs(dateToParam).endOf('day') : dayjs().endOf('month').endOf('day');
  const { data, loading, error } = useUserShiftsQuery({
    variables: {
      input: {
        userId,
        dateFrom,
        dateTo,
      },
    },
  });

  const shifts = data?.userShifts || [];
  return { shifts, loading, error, dateTo, dateFrom };
};
