import React, { ReactNode, useRef } from 'react';
import OutsideClick from 'components/outsideComponent';
import ThreeDots from 'assets/icons/ThreeDots';

interface Props {
  children: ReactNode;
  onShowDropdownHandler: React.MouseEventHandler<HTMLButtonElement>;
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  icon?: ReactNode;
  style?: React.CSSProperties;
}

const MoreDropdown: React.FC<Props> = ({
  children,
  onShowDropdownHandler,
  showDropdown,
  setShowDropdown,
  icon = <ThreeDots className="text-smena_text-disabled group-hover:text-smena_text" />,
  style,
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <button onClick={onShowDropdownHandler} ref={btnRef} type={'button'}>
        {icon}
      </button>
      {showDropdown && (
        <OutsideClick
          show={showDropdown}
          setShow={setShowDropdown}
          triggerRef={btnRef}
          className="absolute top-full right-6 transform translate-x-full translate-y-1 bg-smena_white flex shadow-smena rounded-lg z-10 w-max"
          style={style}
        >
          {children}
        </OutsideClick>
      )}
    </>
  );
};

export default MoreDropdown;
