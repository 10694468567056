import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useChangeShiftModerationMutation } from 'pages/facilityRequests/api/mutations/generated/ChangeShiftModeration';
import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';

interface Props {
  paymentSalarySum?: number;
  paymentSalaryCause?: string;
  paymentSalaryComment?: string;
  cancelPaymentSalary: boolean;
}

export const useSurchargeForm = () => {
  const [changeShift] = useChangeShiftModerationMutation({ refetchQueries: [GetSupervisorWorkSheetDocument] });
  const { shift } = useStore() as any;

  const initialValues: Props = {
    paymentSalarySum: shift?.moderation?.paymentSalarySum,
    paymentSalaryCause: shift?.moderation?.paymentSalaryCause,
    paymentSalaryComment: shift?.moderation?.paymentSalaryComment,
    cancelPaymentSalary: shift?.moderation?.cancelPaymentSalary,
  };

  const validate = (values: Props) => {
    const errors: any = {};

    if (values.paymentSalarySum || values.paymentSalaryCause || values.paymentSalaryComment) {
      if (!values.paymentSalarySum) {
        errors.paymentSalarySum = 'Обязательное поле';
      }
      if (!values.paymentSalaryCause) {
        errors.paymentSalaryCause = 'Обязательное поле';
      }
      if (!values.paymentSalaryComment) {
        errors.paymentSalaryComment = 'Обязательное поле';
      }
    }

    return errors;
  };

  const onSubmit = (values: Props) => {
    changeShift({
      variables: {
        moderation: {
          shiftId: shift.id,
          hourRate: shift.moderation?.hourRate || 0,
          value: shift.moderation?.value || 0,
          paymentSalarySum: Number(values.paymentSalarySum) || undefined,
          paymentSalaryCause: values.paymentSalaryCause,
          paymentSalaryComment: values.paymentSalaryComment,
          cancelPaymentSalary: values.cancelPaymentSalary,
        },
      },
    })
      .then(res => {
        if (res.data?.changeShiftModeration) {
          successToast('Все изменения сохранены');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };
  return { initialValues, onSubmit, validate };
};
