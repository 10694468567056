import { TIcon } from 'interfaces/TIcon';

const RequestsIcon = ({ className }: TIcon) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52344 4.09302C5.52344 2.93708 6.46052 2 7.61646 2H8.08158C9.23752 2 10.1746 2.93708 10.1746 4.09302V4.32812H13.6626V4.09302C13.6626 2.93708 14.5997 2 15.7556 2H16.2207C17.3767 2 18.3138 2.93708 18.3138 4.09302V4.32812H18.5465C19.9593 4.32812 21.1047 5.47344 21.1047 6.88626V14.3957C21.9677 15.2765 22.4999 16.4828 22.4999 17.8134C22.4999 20.5106 20.3133 22.6971 17.6161 22.6971C16.1712 22.6971 14.8729 22.0696 13.9787 21.0723H5.05814C3.64532 21.0723 2.5 19.927 2.5 18.5142V6.88626C2.5 5.47344 3.64532 4.32812 5.05814 4.32812H5.52344V4.09302ZM19.7093 10.6072V13.3997C19.0749 13.0983 18.3652 12.9297 17.6161 12.9297C14.9189 12.9297 12.7324 15.1162 12.7324 17.8134C12.7324 18.4733 12.8633 19.1027 13.1006 19.677H5.05814C4.41595 19.677 3.89535 19.1564 3.89535 18.5142V10.6072H19.7093ZM18.3138 5.72347V6.4186C18.3138 7.0608 17.7932 7.5814 17.151 7.5814H14.8254C14.1832 7.5814 13.6626 7.0608 13.6626 6.4186V5.72347H10.1746V6.4186C10.1746 7.0608 9.654 7.5814 9.01181 7.5814H6.68623C6.04404 7.5814 5.52344 7.0608 5.52344 6.4186V5.72347H5.05814C4.41595 5.72347 3.89535 6.24407 3.89535 6.88626V9.21185H19.7093V6.88626C19.7093 6.24407 19.1887 5.72347 18.5465 5.72347H18.3138ZM7.61646 3.39535C7.23115 3.39535 6.91879 3.70771 6.91879 4.09302V6.18605H8.77925V4.09302C8.77925 3.70771 8.46689 3.39535 8.08158 3.39535H7.61646ZM15.0579 4.09302C15.0579 3.70771 15.3703 3.39535 15.7556 3.39535H16.2207C16.6061 3.39535 16.9184 3.70771 16.9184 4.09302V6.18605H15.0579V4.09302ZM17.6161 14.325C15.6896 14.325 14.1278 15.8868 14.1278 17.8134C14.1278 19.74 15.6896 21.3018 17.6161 21.3018C19.5427 21.3018 21.1045 19.74 21.1045 17.8134C21.1045 15.8868 19.5427 14.325 17.6161 14.325ZM18.3138 15.3695C18.3138 14.9842 18.0014 14.6719 17.6161 14.6719C17.2308 14.6719 16.9185 14.9842 16.9185 15.3695V17.9277C16.9185 18.313 17.2308 18.6254 17.6161 18.6254H19.9417C20.327 18.6254 20.6394 18.313 20.6394 17.9277C20.6394 17.5424 20.327 17.23 19.9417 17.23H18.3138V15.3695Z"
    />
  </svg>
);

export default RequestsIcon;
