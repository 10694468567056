import Tooltip from 'antd/lib/tooltip';
import { UserContractStatus } from 'generated/graphql';
import { getContractTypeRu } from 'pages/userContacts/utils/getContractTypeRu';
import dayjs from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';

export const showContracts = (contracts?: any[] | null) => {
  if (!contracts?.length) {
    return <span className={'text-smena_text-secondary bg-smena_gray-5 px-2 py-0.5 rounded-full'}>Отсутствует</span>;
  }
  if (contracts.filter(el => el.status === UserContractStatus.Signed).length === contracts.length) {
    return <span className={'text-smena_green bg-smena_green-extra_light px-2 py-0.5 rounded-full'}>Подписан</span>;
  }
  const countOfUnsigned = contracts.filter(el => el.status === UserContractStatus.Unsigned);
  return (
    <div className={'flex flex-col gap-y-1'}>
      <span className={'text-smena_red bg-smena_red-extra_light px-2 py-0.5 rounded-full'}>
        {countOfUnsigned.length} не {countOfUnsigned.length === 1 ? 'подписан' : 'подписано'}
      </span>
      <Tooltip
        placement="bottom"
        title={() => (
          <ul className={'flex flex-col pl-3 gap-y-1'}>
            {countOfUnsigned.map(el => (
              <li key={el.id} className={'list-disc Table-small'}>
                {getContractTypeRu(el.type)} от {dayjs(el.createdAt).format(RU_DATE)}
              </li>
            ))}
          </ul>
        )}
        className={'Table-small text-primary cursor-pointer'}
      >
        Подробнее
      </Tooltip>
    </div>
  );
};
