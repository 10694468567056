import { useEffect, useMemo } from 'react';
import { sortObj } from '../../helpers/sortObject';
import { useSelectFacilityGroupsLazyQuery } from 'pages/main/api/queries/generated/SelectFacilityGroups';

export const useSelectFacilityGroups = () => {
  const [getSelectFacilityGroups, { data, loading, error }] = useSelectFacilityGroupsLazyQuery();

  useEffect(() => {
    getSelectFacilityGroups();
  }, [getSelectFacilityGroups]);

  const facilitiesGroups = useMemo(() => sortObj(data?.facilityGroups), [data?.facilityGroups]);
  return { facilitiesGroups, loading, error };
};
