import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { YMaps } from 'react-yandex-maps';

import { useStore } from 'store';
import { parsedData } from 'utils/helpers/mapData';

import { FilterSystem } from 'components/map/select';
import { OurMap } from 'components/map';
import { useUsersOnMapByFacilityQuery } from './user/api/queries/generated/UsersOnMapByFacility';
import SpinnerLoad from '../ui/loader';

const MapPage = () => {
  const { system, setSystem, profession } = useStore();

  const { data, loading } = useUsersOnMapByFacilityQuery();

  let users = data?.usersOnMapByFacility;

  if (profession) {
    users = data?.usersOnMapByFacility
      .filter(facility => !!facility.positions?.filter(position => position.name === profession).length)
      .flat();
  }

  const points = useMemo(() => parsedData(system, users), [users, system]);

  useEffect(() => setSystem(''), [setSystem]);

  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <title>Карта вакансий и соискателей</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>

      <FilterSystem />
      {loading && (
        <div
          className={
            'absolute w-full h-full top-0 left-0 flex justify-center items-center z-50 bg-smena_gray-60 bg-opacity-50'
          }
        >
          <SpinnerLoad />
        </div>
      )}

      <YMaps>
        <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
          <OurMap loading={loading} points={points} />
        </div>
      </YMaps>
    </div>
  );
};

export default MapPage;
