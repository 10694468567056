import Skeleton from 'antd/es/skeleton/Skeleton';
import clsx from 'clsx';

export const SkeletonLayout = (className?: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx(className)}>
      <Skeleton />
    </div>
  );
};
