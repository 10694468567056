import { useCallback, useState } from 'react';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { useStore } from 'store';

import { auchanLunchDurationTypes, lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import { timeDifference } from 'utils/helpers/timeDifference';
import { TIME_DATE } from 'utils/helpers/constVariables';

import { DayjsOrNull } from 'interfaces/CustomTypes';
import SimpleSelect from 'ui/select';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import { useFacilityQuery } from 'pages/facility/api/queries/generated/Facility';
import { useMe } from 'utils/api/useMe';
import { useCurrentUser } from 'utils/api/useUser';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { shiftTimeDifference } from '../../../helpers';
import { errorToast } from 'utils/helpers/notify';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';

const NewCustomSchedule = () => {
  const [timeStart, setTimeStart] = useState<DayjsOrNull>(dayjs());
  const [timeEnd, setTimeEnd] = useState<DayjsOrNull>(dayjs().add(4, 'h'));

  const setCreateScheduleMenu = useStore(state => state.setCreateScheduleMenu);
  const setScheduleEvent = useStore(state => state.setScheduleEvent);
  const lunchDuration = useStore(state => state.lunchDuration);
  const setLunchDuration = useStore(state => state.setLunchDuration);
  const facilityId = useStore(state => state.facilityId);
  const setFacilityIdError = useStore(state => state.setFacilityIdError);
  const workpostId = useStore(state => state.workpostId);
  const setWorkpostIdError = useStore(state => state.setWorkpostIdError);
  const setStartScheduleTime = useStore(state => state.setStartScheduleTime);
  const setEndScheduleTime = useStore(state => state.setEndScheduleTime);

  const { user } = useCurrentUser();
  const { facilities } = useSelectFacilities();
  const me = useMe();

  const { data } = useFacilityQuery({
    variables: {
      id: facilityId,
    },
  });
  const { isAuchan } = useFacilityGroupById(data?.facility.facilityGroupId);

  const onChangeTimeStart = useCallback(
    (timeValue: DayjsOrNull) => {
      setTimeStart(timeValue);
      setStartScheduleTime(timeValue);
      if (isAuchan) {
        setLunchDuration(lunchDurationHandle(timeDifference(timeValue, timeEnd)));
      }
    },
    [isAuchan, setLunchDuration, setStartScheduleTime, timeEnd],
  );

  const onChangeTimeEnd = useCallback(
    (timeValue: DayjsOrNull) => {
      setTimeEnd(timeValue);
      setEndScheduleTime(timeValue);
      if (isAuchan) {
        setLunchDuration(lunchDurationHandle(timeDifference(timeStart, timeValue)));
      }
    },
    [setEndScheduleTime, isAuchan, setLunchDuration, timeStart],
  );

  const buttonClickHandler = () => {
    if (facilityId) {
      setFacilityIdError('');
    } else {
      setFacilityIdError('Обязательное поле');
    }
    if (workpostId) {
      setWorkpostIdError('');
    } else {
      setWorkpostIdError('Обязательное поле');
    }
    if (shiftTimeDifference(timeStart, timeEnd)) {
      return errorToast('Смена не может быть меньше 1 часа');
    }

    if (facilityId && workpostId && user?.id && me?.id) {
      setScheduleEvent({
        title: `${dayjs(Number(timeStart)).format(TIME_DATE)} - ${dayjs(Number(timeEnd)).format(TIME_DATE)}`,
        start: dayjs(timeStart).toDate(),
        end: dayjs(timeEnd).toDate(),
        resource: {
          id: nanoid(),
          userId: user.id,
          authorId: me.id,
          dateFrom: timeStart,
          dateEnd: timeEnd,
          stage: 'CUSTOM',
          positionId: workpostId,
          lunchDuration,
          facility: {
            id: facilityId,
            name: facilities?.find(fac => fac.id === facilityId)?.name,
            groupName: '',
          },
          isProbation: false,
        },
      });
      toast.info('Нажмите на дни в которые хотите добавить смены', {
        theme: 'colored',
        toastId: 'clickYourDays',
      });
      setCreateScheduleMenu(false);
    }
  };

  return (
    <div className="grid grid-cols-4 gap-x-5 gap-y-4">
      <TimePickerInput
        label="Начало смены"
        className="col-span-2"
        popupClassName="fixed"
        value={timeStart}
        onChange={onChangeTimeStart}
      />
      <TimePickerInput
        label="Конец смены"
        className="col-span-2"
        popupClassName="fixed"
        value={timeEnd}
        onChange={onChangeTimeEnd}
      />
      <SimpleSelect
        divClassName="col-span-2"
        label="Обед"
        sort={false}
        onChange={value => {
          setLunchDuration(Number(value));
        }}
        value={lunchDuration}
        options={isAuchan ? auchanLunchDurationTypes : lunchDurationTypes}
      />

      <div className="col-span-full flex flex-col items-end">
        <button onClick={buttonClickHandler} type="button" className="btn-primary_small">
          Перейти к календарю
        </button>
      </div>
    </div>
  );
};

export default NewCustomSchedule;
