import { InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache({
  typePolicies: {
    Casing: {
      keyFields: ['facilityId', 'positionId'],
    },
    ModerationReportTableRow: {
      keyFields: ['position', ['id'], 'user', ['id'], 'reportId'],
    },
    WorkPost: {
      keyFields: ['facilityId', 'positionId', 'userId'],
    },
    getModerationReport: {
      keyFields: ['report', ['id']],
    },
    PersonalInfoOutput: {
      keyFields: ['userId'],
    },
    OchkoReferrerOutput: {
      keyFields: (args, context) => {
        const id = `${args.__typename}:${args.id}`;
        const leads: readonly any[] | undefined = context.readField('leads');
        const firstLead = leads?.length ? leads[0] : '';
        const readLeadId = context.readField('id', firstLead);
        const firstLeadId = readLeadId?.toString() || '';
        return `${args.__typename}:${JSON.stringify({ id: id, FirstLeadId: firstLeadId })}`;
      },
    },
  },
});

export default cache;
