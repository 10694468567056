import { useMemo } from 'react';
import { useMeRole } from 'store/hooks/useMeRole';
import useSuperFacilities from 'utils/hooks/userCalendar/useSuperFacilities';
import { useUsersShift } from 'utils/api/useUserShifts';
import { Role, ShiftStage } from 'generated/graphql';

export const useUserShifts = () => {
  const { shifts } = useUsersShift();
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const supervisorFacilities = useSuperFacilities();
  const userShifts = useMemo(
    () =>
      shifts.filter(shift => {
        const isSupervisorCanSeeShift = !!supervisorFacilities?.filter(el => el.facility?.id === shift.facility?.id)
          .length;
        if (!isMeSupervisor) return shift;
        if (isSupervisorCanSeeShift) return shift;
        return false;
      }),
    [isMeSupervisor, supervisorFacilities, shifts],
  );
  const notProbationUserShift = useMemo(() => userShifts.filter(shift => !shift.isProbation), [userShifts]);
  const shiftsWithoutCanceled = useMemo(
    () =>
      userShifts.filter(shift => {
        const { stage, isProbation } = shift;
        return ![ShiftStage.Refused, ShiftStage.Ineligible].includes(stage) && !isProbation;
      }),
    [userShifts],
  );
  return { userShifts, notProbationUserShift, shiftsWithoutCanceled };
};
