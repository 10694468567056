import { ApolloCache, Reference, StoreObject } from '@apollo/client';

interface Props {
  facilityId: string;
  userId: string;
}

interface Props2 {
  facilityGroupId: string;
  userId: string;
}

//manager done
export const updateFacilityClientManagersCache = (cache: ApolloCache<any>, { facilityId, userId }: Props) => {
  cache.modify({
    id: `Facility:${facilityId}`,
    fields: {
      clientManagers(prev = [], { readField }) {
        return prev.filter((el?: Reference | StoreObject) => readField('id', el) !== userId);
      },
    },
  });
  cache.modify({
    id: `RatingUser:${userId}`,
    fields: {
      facilitiesManager(prev = [], { readField }) {
        return prev.filter((el?: Reference | StoreObject) => readField('id', el) !== facilityId);
      },
    },
  });
};
//director done
export const updateFacilityGroupClientDirectorsCache = (
  cache: ApolloCache<any>,
  { facilityGroupId, userId }: Props2,
) => {
  cache.modify({
    id: `FacilityGroup:${facilityGroupId}`,
    fields: {
      clientDirectors(prev = [], { readField }) {
        return prev.filter((el?: Reference | StoreObject) => readField('id', el) !== userId);
      },
    },
  });
  cache.modify({
    id: `RatingUser:${userId}`,
    fields: {
      groupsDirector(prev = [], { readField }) {
        return prev.filter((el?: Reference | StoreObject) => readField('id', el) !== facilityGroupId);
      },
    },
  });
};
