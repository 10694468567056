import ShiftsSidebar from './sidebar';
import ShiftsCalendar from './main';
import CalendarHeader from '../../../components/calendar/calendarHeader';

export const Calendar = () => {
  return (
    <>
      <CalendarHeader />
      <div className="shifts">
        <ShiftsCalendar />
        <ShiftsSidebar />
      </div>
    </>
  );
};
