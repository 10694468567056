import { FacilitiesFragment } from 'pages/main/api/fragments/generated/Facilities.fragment';

type TGetFacility = { facilityId?: string | null; facilities: FacilitiesFragment[] };
type TGetFacilityByGroupId = {
  facilityGroupId: string;
  facilities: FacilitiesFragment[];
};
export const getFacility = ({ facilityId, facilities }: TGetFacility) => {
  const res = facilities.find(facility => facility.id === facilityId);
  if (!res) return null;
  return res;
};
export const getFacilityByGroupId = ({ facilityGroupId, facilities }: TGetFacilityByGroupId) => {
  return facilities.filter(facility => facility.facilityGroupId === facilityGroupId);
};
