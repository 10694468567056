import React, { useEffect, useRef, useState } from 'react';
import SimpleSelect from 'ui/select';
import TableFilter from 'components/table/filters';
import { useCustomSearchParams } from '../../users/lib/useParams';
import { useFilteredFacilityGroups } from '../../publishStory/components/showSettings/hooks/useFilteredFacilityGroups';
import { useFilteredFacilities } from '../../publishStory/components/showSettings/hooks/useFilteredFacilities';
import { usePositions } from 'utils/api/usePositions';
import clsx from 'clsx';
import FilterIcon from 'assets/icons/FilterIcon';
import DadataAddress from 'components/dadata/address';
import { dotify } from 'components/dadata/utils';
import Magnifier from 'assets/icons/magnifier';
import { useSearchFacilitiesVacanciesQuery } from '../api/query/generated/SearchFacilitiesVacancies';
import { useVacanciesMap } from '../store';
import { positionName } from 'utils/helpers/filters/filtersName';
import { getActive } from 'utils/helpers/get/getActive';
import AcrossSmall from 'assets/icons/AcrossSmall';
import Filter from 'components/table/filters/filter';
import { useFacilityById } from '../../../utils/api/useFacility';
import { useFacilityGroupById } from '../../../utils/api/useFacilityGroup';

const Filters = () => {
  const { params, setParams, handleParams, useParamsCount, resetFilter } = useCustomSearchParams();
  const setFacilityVacancies = useVacanciesMap(state => state.setFacilityVacancies);
  const setSearchPinLocation = useVacanciesMap(state => state.setSearchPinLocation);
  const setCenter = useVacanciesMap(state => state.setCenter);
  const setZoom = useVacanciesMap(state => state.setZoom);
  const setLoading = useVacanciesMap(state => state.setLoading);
  const [lat, setLat] = useState<null | number>(null);
  const [lon, setLon] = useState<null | number>(null);
  const [showFilter, setShowFilter] = useState(false);
  const facilityGroupIdParam = params.get('groupId');
  const facilityParam = params.get('facilityId');
  const positionParam = params.get('positionId');
  const activeParam = params.get('isActive');
  const { facility } = useFacilityById(facilityParam);
  const { loading: dataLoading } = useSearchFacilitiesVacanciesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      if (res.searchFacilitiesVacancies) {
        setFacilityVacancies(res.searchFacilitiesVacancies.map(el => ({ ...el, isHover: false, isActive: false })));
      }
    },
    variables: {
      input: {
        longitude: lon,
        latitude: lat,
        facilityId: facilityParam,
        positionId: positionParam,
        facilityGroupId: facilityGroupIdParam,
      },
    },
  });
  const btnRef = useRef<HTMLButtonElement>(null);
  const [search, setSearch] = useState('');

  const [groupId, setGroupId] = useState(facilityGroupIdParam);
  const [facilityId, setFacilityId] = useState(facilityParam);
  const [positionId, setPositionId] = useState(positionParam);
  const [isActive, setIsActive] = useState(activeParam);

  const { positions } = usePositions();
  const paramsCount = useParamsCount();

  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);
  const filteredFacilityGroups = useFilteredFacilityGroups({ cities: [] });
  const filteredFacilities = useFilteredFacilities({
    facilityGroups: groupId ? [groupId] : [],
    cities: [],
  });

  useEffect(() => {
    setLoading(dataLoading);
  }, [dataLoading, setLoading]);
  return (
    <div className="flex flex-col gap-2 px-6 py-3">
      <div className={clsx('flex relative gap-x-2')}>
        <div className="flex items-center gap-x-[2px] flex-[1_0] relative">
          <DadataAddress
            textValue={search}
            onChange={res => {
              if (res) {
                setSearch(dotify(res.value));
                setSearchPinLocation({ lat: Number(res.data.geo_lat), lon: Number(res.data.geo_lon) });
                setLat(Number(res.data.geo_lat));
                setLon(Number(res.data.geo_lon));
                setCenter([Number(res.data.geo_lat), Number(res.data.geo_lon)]);
                setZoom(15);
              }
            }}
            style={{
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              paddingRight: '22px',
            }}
            placeholder="Поиск по адресу"
          />
          {search && (
            <span
              onClick={() => {
                setSearch('');
                setSearchPinLocation(undefined);
                setLat(null);
                setLon(null);
              }}
              className={'absolute top-1/2 right-10 cursor-pointer transform -translate-y-1/2 z-10 bg-smena_white'}
            >
              <AcrossSmall />
            </span>
          )}
          <button className={'bg-primary rounded-tr-md rounded-br-md p-1 h-[32px]'}>
            <Magnifier className={'text-smena_white'} />
          </button>
        </div>
        <button
          className={'bg-white rounded-md p-1 h-[32px] outline outline-1 outline-primary/30'}
          ref={btnRef}
          onClick={() => {
            setShowFilter(value => !value);
          }}
        >
          <FilterIcon />
        </button>
        <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
          <div className="flex flex-col gap-y-3">
            <SimpleSelect
              divClassName=""
              label="Группа"
              popupClassName={'max-w-[300px]'}
              onChange={value => {
                setGroupId(value);
                setFacilityId('');
              }}
              placeholder={'Не выбрано'}
              allOption={'Все группы'}
              value={groupId}
              options={filteredFacilityGroups}
            />

            <SimpleSelect
              divClassName=""
              label="Объект"
              onChange={value => {
                setFacilityId(value);
              }}
              value={facilityId}
              placeholder="Не выбрано"
              allOption={'Все'}
              options={filteredFacilities}
            />

            <SimpleSelect
              label="Профессия"
              onChange={value => setPositionId(value)}
              value={positionId}
              allOption={'Все профессии'}
              placeholder={'Не выбрано'}
              options={positions}
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('groupId', groupId);
                  handleParams('facilityId', facilityId);
                  handleParams('positionId', positionId);
                  handleParams('isActive', isActive);
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setGroupId(null);
                  setFacilityId(null);
                  setPositionId(null);
                  setIsActive(null);
                  setParams();
                  setShowFilter(false);
                }}
              >
                Сбросить
              </button>
            </div>
          </div>
        </TableFilter>
      </div>
      {!!paramsCount && (
        <div className="flex flex-wrap gap-2">
          <Filter
            show={!!facilityGroupIdParam}
            content={'Группа ' + facilityGroupName}
            clickHandler={() => {
              setGroupId(null);
              resetFilter('groupId');
            }}
          />
          <Filter
            show={!!facilityParam}
            content={facility?.name}
            clickHandler={() => {
              setFacilityId(null);
              resetFilter('facilityId');
            }}
          />
          <Filter
            show={!!positionParam}
            content={positionName(positions, positionId)}
            clickHandler={() => {
              setPositionId(null);
              resetFilter('positionId');
            }}
          />
          <Filter
            show={!!activeParam}
            content={getActive(activeParam)}
            clickHandler={() => {
              setIsActive('');
              resetFilter('isActive');
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Filters;
