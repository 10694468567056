import React from 'react';
import Magnifier from '../../assets/icons/magnifier';
import { useCustomSearchParams } from '../../pages/users/lib/useParams';

const Search = () => {
  const { search, handleChangeSearch, handleSearch } = useCustomSearchParams();
  return (
    <form className="flex w-[250px] gap-x-[2px]" onSubmit={handleSearch}>
      <input
        className="input-primary"
        style={{
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
        }}
        onChange={handleChangeSearch}
        value={search}
        type="text"
        name="search"
        id="search"
        placeholder="Поиск"
      />
      <button className={'bg-primary rounded-tr-md rounded-br-md p-1 h-[32px]'}>
        <Magnifier className={'text-smena_white'} />
      </button>
    </form>
  );
};

export default Search;
