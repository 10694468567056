import { useDeleteNotifyLetterMutation } from '../../api/mutations/generated/DeleteNotifyLetter';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'ui/modal';

interface Props {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  pushId?: string;
}

const PushPopup = ({ showPopup, setShowPopup, pushId }: Props) => {
  const onShowPopupHandle = () => {
    setShowPopup(value => !value);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const [deleteNotifyLetter] = useDeleteNotifyLetterMutation();

  const removePush = () => {
    if (!pushId) return;
    deleteNotifyLetter({
      variables: {
        id: pushId,
      },
      update: cache => {
        cache.evict({ id: `NotifyLetter:${pushId}` });
      },
    })
      .then(() => {
        if (location.pathname.includes(pushId)) {
          navigate('/pushes');
        }
        successToast('Черновик удален');
      })
      .catch(err => errorToast(errorHandler(err)))
      .finally(onShowPopupHandle);
  };

  return (
    <Modal type="delete" title="Удалить черновик" open={showPopup} onCancel={onShowPopupHandle} onOk={removePush}>
      Вы уверены, что хотите удалить черновик пуш-уведомления?
    </Modal>
  );
};

export default PushPopup;
