import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useGetCityLazyQuery } from '../../../pages/city/api/queries/generated/getCity';

export const useCity = () => {
  const router = useParams();
  const id = router.id;

  const [loadData, { data: dataCity, loading, error }] = useGetCityLazyQuery();

  useEffect(() => {
    if (id) {
      loadData({
        variables: { id },
      });
    }
  }, [loadData, id]);

  const city = dataCity?.getCity;
  return { city, loading, error, id };
};

export const useCityById = (id?: string | null) => {
  const [loadData, { data: dataCity, loading, error }] = useGetCityLazyQuery();

  useEffect(() => {
    if (id) {
      loadData({
        variables: { id },
      });
    }
  }, [loadData, id]);

  const city = dataCity?.getCity;
  return { city, loading, error };
};
