import Selects from './components/selects';
import MoreFiltersPopup from './components/moreFiltersPopup';
import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import ToggleStatistics from './components/toggleStatistics';
import Legend from '../popups/components/legend';
import CustomShiftsController from './components/customShiftsController';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';

interface Props {
  filterData?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['filterData'];
}

const FacilityReportFilter: React.FC<Props> = ({ filterData }) => {
  const { isCalendar } = useFacilityReportStore();

  return (
    <div className="px-6 py-5 flex justify-between items-center border-b-[1px]">
      <div className="flex items-center gap-6">
        <Selects filterData={filterData} />
        <MoreFiltersPopup />
        {isCalendar && <CustomShiftsController />}
      </div>

      <div className="flex gap-6">
        <ToggleStatistics />
        <Legend />
      </div>
    </div>
  );
};

export default FacilityReportFilter;
