interface Props {
  style: React.CSSProperties;
}
export const mapColumns = (columns?: Props[]) =>
  columns
    ?.map(column => {
      const {
        style: { width, minWidth },
      } = column;
      if (!width) {
        return `minmax(${minWidth}px, 1fr)`;
      }
      return `minmax(${minWidth}px, ${width}px)`;
    })
    .join(' ');
