import { PaginatedRequestFragment } from 'pages/facilityRequests/api/fragments/generated/PaginatedRequest.fragment';
import { RequestProgressFragment } from 'pages/facilityUpdateRequest/api/fragmetns/generated/RequestProgress.fragment';
import { LeadFragment } from 'pages/lead/api/fragments/generated/Lead.fragment';

export const progressPercent = (total: number, current: number) => (current / total) * 100;
export const progressPercentObj = (request?: RequestProgressFragment | PaginatedRequestFragment) => {
  if (request) {
    const { shiftsScheduleCount, shiftsPlanCount, hoursPlanCount, hoursScheduleCount } = request;
    return {
      shiftsProgress: progressPercent(shiftsPlanCount, shiftsScheduleCount),
      hoursProgress: progressPercent(hoursPlanCount, hoursScheduleCount),
    };
  }
  return {
    shiftsProgress: 0,
    hoursProgress: 0,
  };
};

export const progressPercentLead = (lead?: LeadFragment) => {
  if (lead) {
    const { referralProgram, user } = lead;
    const goodShiftCount = user?.goodShiftCount;
    const numberOfGoodShifts = referralProgram?.numberOfGoodShifts;

    if (numberOfGoodShifts && goodShiftCount) return progressPercent(numberOfGoodShifts, goodShiftCount);
  }

  return 0;
};

export const percentLeadColor = (percent: number) =>
  percent > 100 ? '#3DDD7D' : percent === 100 ? '#3DDD7D' : '#397DFF';

export const percentColor = (percent: number) => (percent > 100 ? '#FF555F' : percent === 100 ? '#3DDD7D' : '#397DFF');
