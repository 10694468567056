interface Props {
  x?: number;
  currentSelfTax?: number;
  withNpd: boolean;
}

const Tax = (props: Props) => {
  const { currentSelfTax, x, withNpd } = props;

  if (withNpd) {
    return (
      <span className="pl-1">
        <span className="Subtitle2 text-smena_text-secondary">С налогом: </span>
        {x ? Math.round((x / Number(currentSelfTax)) * 100) / 100 : 0} ₽
      </span>
    );
  } else {
    return (
      <span className="pl-1">
        <span className="Subtitle2 text-smena_text-secondary">Без налога</span>
      </span>
    );
  }
};

export default Tax;
