import React from 'react';
import { TIcon } from '../../interfaces/TIcon';

const EyeOpen = ({ className }: TIcon) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M15.6892 7.69562C15.6661 7.645 15.1186 6.42937 13.9092 5.22C12.2911 3.60437 10.2517 2.75 8.00171 2.75C5.75171 2.75 3.71234 3.60437 2.09609 5.22C0.88671 6.42937 0.33921 7.645 0.31421 7.69562C0.271868 7.79162 0.25 7.89539 0.25 8.00031C0.25 8.10523 0.271868 8.209 0.31421 8.305C0.337335 8.35625 0.884835 9.57125 2.09484 10.7806C3.71234 12.3963 5.75171 13.25 8.00171 13.25C10.2517 13.25 12.2911 12.3963 13.9067 10.7806C15.1167 9.57125 15.6642 8.35625 15.6873 8.305C15.73 8.20913 15.7522 8.10543 15.7525 8.00051C15.7528 7.89559 15.7313 7.79175 15.6892 7.69562ZM12.8105 9.75813C11.4686 11.0794 9.85109 11.75 8.00171 11.75C6.15234 11.75 4.53484 11.0794 3.19484 9.7575C2.66755 9.23577 2.21399 8.64447 1.84671 8C2.21409 7.35579 2.66765 6.76471 3.19484 6.24312C4.53546 4.92062 6.15234 4.25 8.00171 4.25C9.85109 4.25 11.468 4.92062 12.8086 6.24312C13.3358 6.76467 13.7894 7.35575 14.1567 8C13.7894 8.64443 13.3358 9.23572 12.8086 9.7575L12.8105 9.75813ZM8.00171 5.25C7.45781 5.25 6.92613 5.41128 6.47389 5.71346C6.02166 6.01563 5.66918 6.44512 5.46104 6.94762C5.2529 7.45012 5.19844 8.00305 5.30455 8.5365C5.41066 9.06995 5.67257 9.55995 6.05717 9.94454C6.44176 10.3291 6.93176 10.5911 7.46521 10.6972C7.99866 10.8033 8.55159 10.7488 9.05409 10.5407C9.55659 10.3325 9.98608 9.98005 10.2883 9.52782C10.5904 9.07558 10.7517 8.5439 10.7517 8C10.7509 7.27091 10.4609 6.57192 9.94534 6.05637C9.42979 5.54082 8.7308 5.25083 8.00171 5.25ZM8.00171 9.25C7.75448 9.25 7.51281 9.17669 7.30725 9.03934C7.10169 8.90199 6.94147 8.70676 6.84686 8.47835C6.75225 8.24995 6.7275 7.99861 6.77573 7.75614C6.82396 7.51366 6.94301 7.29093 7.11783 7.11612C7.29264 6.9413 7.51537 6.82225 7.75785 6.77402C8.00032 6.72579 8.25166 6.75054 8.48006 6.84515C8.70847 6.93976 8.9037 7.09998 9.04105 7.30554C9.1784 7.5111 9.25171 7.75277 9.25171 8C9.25171 8.33152 9.12001 8.64946 8.88559 8.88388C8.65117 9.1183 8.33323 9.25 8.00171 9.25Z" />
    </svg>
  );
};

export default EyeOpen;
