import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Clusterer, Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import { FilterSystem } from 'components/map/select';
import { useStore } from 'store';
import { parsedData } from 'utils/helpers/mapData';
import { useUsersOnMapByFacility2Query } from './user/api/queries/generated/UsersOnMapByFacility2';
import SpinnerLoad from '../ui/loader';

const MapTwoPage = () => {
  const { system, setSystem, cluster, profession } = useStore();

  const { data, loading } = useUsersOnMapByFacility2Query();

  const users = useMemo(() => {
    if (profession) {
      return data?.usersOnMapByFacility2
        .filter(facility => !!facility.positions?.filter(position => position.name === profession).length)
        .flat();
    }
    return data?.usersOnMapByFacility2;
  }, [data?.usersOnMapByFacility2, profession]);

  const points = useMemo(() => parsedData(system, users), [users, system]);

  useEffect(() => setSystem(''), [setSystem]);
  return (
    <div>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <title>Карта вакансий и соискателей</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>

      <FilterSystem />

      {loading && (
        <div
          className={
            'absolute w-full h-full top-0 left-0 flex justify-center items-center z-50 bg-smena_gray-60 bg-opacity-50'
          }
        >
          <SpinnerLoad />
        </div>
      )}
      <YMaps>
        <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
          <Map
            defaultState={{ center: [55.75, 37.57], zoom: 5 }}
            width="100%"
            height="100%"
            modules={['clusterer.addon.balloon', 'geoObject.addon.balloon', 'layout.ImageWithContent']}
          >
            <ZoomControl />
            {cluster ? (
              <Clusterer
                options={{
                  preset: 'islands#dotIcon',
                  clusterDisableClickZoom: true,
                  clusterOpenBalloonOnClick: true,
                }}
              >
                {!loading &&
                  points?.map(point => {
                    return (
                      <Placemark
                        key={point.geometry.coordinates.join('')}
                        geometry={point.geometry.coordinates}
                        properties={point.properties}
                        options={point.options}
                      />
                    );
                  })}
              </Clusterer>
            ) : (
              !loading &&
              points?.map(point => {
                return (
                  <Placemark
                    key={point.geometry.coordinates.join('')}
                    geometry={point.geometry.coordinates}
                    properties={point.properties}
                    options={point.options}
                  />
                );
              })
            )}
          </Map>
        </div>
      </YMaps>
    </div>
  );
};

export default MapTwoPage;
