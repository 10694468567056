import { useStore } from 'store';
import MainSidebar from 'ui/sidebar';
import dayjs from 'dayjs';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { Link } from 'react-router-dom';
import { getUserFIO } from 'utils/helpers/userFullName';
import ViewScheduleFormElement from 'pages/request/components/editRequest/sidebar/viewElement';
import { getLunchDuration } from 'utils/helpers/lists/lunchDurationTypes';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import { ShiftStage } from 'generated/graphql';
import EditShiftButtons from './editShiftButtons';

const EditShiftSidebar = () => {
  const { showEditShiftSidebar, setShowEditShiftSidebar, shift } = useStore();
  const { user } = useFacilityReportStore();

  return (
    <MainSidebar title={'Смена'} show={showEditShiftSidebar} setShow={setShowEditShiftSidebar}>
      <div className="flex flex-col gap-y-6">
        <div className="Subtitle1 flex gap-x-1">
          <span>График смены:</span>
          <span className="text-smena_text-secondary">
            {dayjs(shift?.dateFrom).format(RU_DATE)} – {dayjs(shift?.dateEnd).format(RU_DATE)}
          </span>
        </div>

        <div className="flex flex-col gap-y-1">
          <span className="H4">{user?.workPost?.position.name}</span>
          <div className="flex gap-x-2 Subtitle2 text-smena_text-secondary">
            <ViewScheduleFormElement data={dayjs(shift?.dateFrom).format(RU_DATE)} newData={false} />
            <span>·</span>
            <ViewScheduleFormElement
              data={`${dayjs(shift?.dateFrom).format(TIME_DATE)} – ${dayjs(shift?.dateEnd).format(TIME_DATE)}`}
            />
            <span>·</span>
            <ViewScheduleFormElement data={getLunchDuration(shift?.lunchDuration)} />
          </div>
        </div>

        <div className="flex items-center gap-x-1">
          <span className="Subtitle1">Исполнитель:</span>
          {user?.user ? (
            <Link className="text-primary" to={`/users/${user.user?.id}`}>
              {user?.user.firstname && user?.user.lastname
                ? getUserFIO(user.user)
                : convertPhoneNumberToNormal(user?.user.phone)}
            </Link>
          ) : (
            <span className={'text-smena_text-secondary'}>отсутствует</span>
          )}
        </div>

        {shift?.stage === ShiftStage.Refused && <div className="text-smena_red">Смена отменена</div>}
        {shift?.stage === ShiftStage.Ineligible && <div className="text-smena_red">Смена отклонена</div>}

        <EditShiftButtons />
      </div>
    </MainSidebar>
  );
};

export default EditShiftSidebar;
