import { ApolloCache } from '@apollo/client';
import { AddForemanToFacilityMutation } from '../../../user/api/mutations/generated/AddForemanToFacility';
import { FacilitiesFragmentDoc } from '../../../main/api/fragments/generated/Facilities.fragment';

interface Props {
  facilityId: string;
  data?: AddForemanToFacilityMutation | null;
}

export const addForemanCache = (cache: ApolloCache<any>, { data, facilityId }: Props) => {
  const facilityFragment = cache.readFragment({
    id: `Facility:${facilityId}`,
    fragment: FacilitiesFragmentDoc,
    fragmentName: 'Facilities',
  });
  cache.modify({
    id: `RatingUser:${data?.addForemanToFacility.id}`,
    fields: {
      Facility_FOREMAN(prev = []) {
        if (!prev) return [facilityFragment];
        return [...prev, facilityFragment];
      },
    },
  });
  cache.modify({
    id: `Facility:${facilityId}`,
    fields: {
      foremen(prev = []) {
        if (!prev) return [data?.addForemanToFacility];
        return [...prev, data?.addForemanToFacility];
      },
    },
  });
};
