import { NotificationFragment } from 'graphql/fragments/generated/Notification.fragment';
import { create } from 'zustand';
import { uniqBy } from 'lodash';

interface Props {
  notifications: NotificationFragment[];
  setNotifications: (notifications: NotificationFragment[]) => void;
  setNotificationReadOpposite: (notificationId: string) => void;
  newNotifications: NotificationFragment[];
  readNotifications: NotificationFragment[];
  setAllReadStatus: () => void;
  newCount: number;
  setNewCount: (newCount: number) => void;
  readCount: number;
  setReadCount: (readCount: number) => void;
  isRead: boolean;
  setIsRead: (isRead: boolean) => void;
}

export const useNotificationsStore = create<Props>(set => ({
  notifications: [],
  setNotifications: notifications =>
    set(state => {
      return {
        notifications: uniqBy(
          state.notifications.concat(notifications).sort((a, b) => b.createdAt.localeCompare(a.createdAt)),
          el => el.id,
        ),
      };
    }),
  setNotificationReadOpposite: notificationId =>
    set(state => {
      return {
        notifications: state.notifications.map(el => {
          if (el.id === notificationId) {
            return { ...el, isRead: !el.isRead };
          }
          return el;
        }),
      };
    }),
  setAllReadStatus: () => {
    set(state => {
      return {
        notifications: (state.notifications = state.notifications.map(el => {
          if (!el.isRead) {
            return { ...el, isRead: true };
          }
          return el;
        })),
        readCount: state.readCount + state.newCount,
        newCount: 0,
      };
    });
  },
  newNotifications: [],
  readNotifications: [],
  newCount: 0,
  setNewCount: newCount => set({ newCount }),
  readCount: 0,
  setReadCount: readCount => set({ readCount }),
  isRead: false,
  setIsRead: isRead => set({ isRead }),
}));

export const selectNewNotifications = (state: Props) => {
  return state.notifications.filter(notification => notification.isRead);
};
export const selectOldNotifications = (state: Props) => {
  return state.notifications.filter(notification => !notification.isRead);
};
