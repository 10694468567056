import { UserDocument, UserQueryVariables } from 'pages/user/api/queries/generated/User';

interface IUserQueryConfig {
  query: typeof UserDocument;
  variables: UserQueryVariables;
}

export const userQueryConfigFn = (id?: string): IUserQueryConfig => ({
  query: UserDocument,
  variables: {
    userId: id || '',
  },
});
