import { create } from 'zustand';
import { FacilityVacancyFragment } from '../api/fragments/generated/FacilityVacancy.fragment';
import { MapVacancyFragment } from '../api/fragments/generated/MapVacancy.fragment';

type Status = {
  isHover: boolean;
  isActive: boolean;
};
export type FacilityVacancies = Status & FacilityVacancyFragment;
export interface ISearchPinLocation {
  lat: number;
  lon: number;
}

interface MapState {
  facilityVacancies: FacilityVacancies[];
  setFacilityVacancies: (facilityVacancies: FacilityVacancies[]) => void;
  changeFacilityVacancyStatus: (id: string) => void;
  searchPinLocation?: ISearchPinLocation;
  setSearchPinLocation: (location?: ISearchPinLocation) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  center: number[];
  setCenter: (center: number[]) => void;
  setActiveFacilityVacancy: (id: string) => void;
  activeVacancy?: MapVacancyFragment;
  setActiveVacancy: (activeVacancy?: MapVacancyFragment) => void;
}

export const useVacanciesMap = create<MapState>(set => ({
  facilityVacancies: [],
  setFacilityVacancies: facilityVacancies => set({ facilityVacancies }),
  changeFacilityVacancyStatus: id =>
    set(state => {
      return {
        facilityVacancies: state.facilityVacancies.map(el => {
          if (el.id === id) {
            return { ...el, isHover: !el.isHover };
          }
          return el;
        }),
      };
    }),
  searchPinLocation: undefined,
  setSearchPinLocation: location => set({ searchPinLocation: location }),
  zoom: 10,
  setZoom: zoom => set({ zoom }),
  loading: false,
  setLoading: loading => set({ loading }),
  setActiveFacilityVacancy: id =>
    set(state => {
      return {
        facilityVacancies: state.facilityVacancies.map(el => {
          if (el.id === id) {
            return { ...el, isHover: false, isActive: !el.isActive };
          }
          return { ...el, isActive: false };
        }),
      };
    }),
  center: [59.94044552268284, 30.314362834341292],
  setCenter: center => set({ center }),
  activeVacancy: undefined,
  setActiveVacancy: activeVacancy => set({ activeVacancy }),
}));
