import React from 'react';
import SimpleSelect from 'ui/select';
import { payoutTypes } from 'utils/helpers/lists/payoutTypes';
import { hourlyUnit, units } from 'utils/helpers/lists/units';
import SimpleInput from 'ui/input';
import SimpleCheckbox from 'ui/checkbox';
import clsx from 'clsx';
import { FormikHandlers, FormikState } from 'formik';
import { payoutFrequencyList } from 'utils/helpers/lists/PayoutFrequencyList';

interface Props {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  errors: FormikState<any>['errors'];
  touched: FormikState<any>['touched'];
  setFieldValue: (name: string, value: any) => void;
}

const Payments = ({ values, handleChange, setFieldValue, errors, touched }: Props) => {
  return (
    <>
      <span className="Subtitle1 text-smena_text-secondary">Оплата</span>
      <div className="flex gap-4 flex-wrap">
        <SimpleSelect
          divClassName="flex-[1_0]"
          label="Тип оплаты"
          onChange={value => {
            setFieldValue('payout', value);
            if (value === 'PIECEWORK') {
              setFieldValue('unit', 'PIECE');
            } else {
              setFieldValue('unit', 'HOUR');
            }
          }}
          value={values.payout}
          options={payoutTypes}
          error={touched.payout && errors.payout ? errors.payout : ''}
        />
        <SimpleSelect
          divClassName="flex-[1_0]"
          label="Ед. измерения"
          onChange={value => {
            setFieldValue('unit', value);
          }}
          value={values.unit}
          options={values.payout === 'HOURLY' ? hourlyUnit : units}
          validation
          error={touched.unit && errors.unit ? errors.unit : ''}
        />

        <SimpleInput
          divClassName="flex-[1_0]"
          label="Ставка, ₽"
          onChange={handleChange}
          float
          value={values.hourRate}
          type="text"
          name="hourRate"
          error={touched.hourRate && errors.hourRate ? errors.hourRate : ''}
        />
      </div>
      <div className={'flex gap-4'}>
        <SimpleSelect
          divClassName="flex-[1_0]"
          label="Частота выплат"
          onChange={value => {
            setFieldValue('payoutFrequency', value);
          }}
          sort={false}
          value={values.payoutFrequency}
          options={payoutFrequencyList}
        />
        <div className={'flex-[1_0]'}></div>
        <div className={'flex-[1_0]'}></div>
      </div>
      <SimpleCheckbox
        divClassName="flex items-center"
        label="Публиковать стоимость смены"
        required={false}
        onChange={e => {
          handleChange(e);
          if (e.target.checked) {
            setFieldValue('isRangeCost', false);
          }
        }}
        checked={values.isPublishCostOfShift}
        name="isPublishCostOfShift"
      />
      {values.isPublishCostOfShift && (
        <div className={'flex gap-x-4 items-center'}>
          <input
            type="radio"
            className={'radio radio_primary'}
            name={'isRangeCost'}
            checked={!values.isRangeCost}
            onChange={() => {
              setFieldValue('isRangeCost', false);
            }}
          />
          <SimpleInput
            divClassName="flex-[1_0]"
            label="Средняя, ₽"
            placeholder="0"
            regText
            disabled={values.isRangeCost}
            onChange={handleChange}
            value={values.averageCost}
            type="text"
            name="averageCost"
            error={touched.averageCost && errors.averageCost ? errors.averageCost : ''}
          />
          <span className={'h-8 w-px bg-smena_gray-30'}></span>
          <input
            type="radio"
            className={clsx('radio radio_primary')}
            name={'isRangeCost'}
            checked={values.isRangeCost}
            onChange={() => {
              setFieldValue('isRangeCost', true);
            }}
          />
          <SimpleInput
            divClassName="flex-[1_0]"
            label="Диапазон от, ₽"
            placeholder="0"
            regText
            disabled={!values.isRangeCost}
            onChange={handleChange}
            value={values.shiftCostFrom}
            type="text"
            name="shiftCostFrom"
            error={touched.shiftCostFrom && errors.shiftCostFrom ? errors.shiftCostFrom : ''}
          />
          <SimpleInput
            divClassName="flex-[1_0]"
            label="Диапазон до, ₽"
            placeholder="0"
            regText
            disabled={!values.isRangeCost}
            onChange={handleChange}
            value={values.shiftCostUpTo}
            type="text"
            name="shiftCostUpTo"
            error={touched.shiftCostUpTo && errors.shiftCostUpTo ? errors.shiftCostUpTo : ''}
          />
        </div>
      )}
    </>
  );
};

export default Payments;
