import { useEffect, useState } from 'react';
import OutsideClick from 'components/outsideComponent';
import { useVacanciesMap } from '../store';
import clsx from 'clsx';
import Across from 'assets/icons/Across';
import { useCost } from '../utils/hook';
import { durationText, lunchDurationText } from '../utils/helper';
import { getPayoutFrequencyRu } from '../utils/getPayoutFrequencyRu';
import { CasingDocumentTypes } from '../../vacancy/util/const';
import { useCountries } from 'utils/api/useCountries';
import { getPeriodBlock } from '../utils/getPeriod';
import CoffeeIcon from 'assets/icons/coffeeIcon';
import { getSexRu } from 'utils/helpers/get/getSexRu';
import { morph } from 'utils/helpers/morph';

const VacancyPopup = () => {
  const activeVacancy = useVacanciesMap(state => state.activeVacancy);
  const setActiveVacancy = useVacanciesMap(state => state.setActiveVacancy);
  const [show, setShow] = useState(false);
  const cost = useCost(activeVacancy);
  const duration = durationText(activeVacancy);
  const lunchDuration = lunchDurationText(activeVacancy?.lunchDuration);
  const { countries } = useCountries();
  useEffect(() => {
    if (activeVacancy) {
      setShow(true);
    }
  }, [activeVacancy]);
  return (
    <OutsideClick
      setShow={() => {
        setActiveVacancy(undefined);
        setShow(false);
      }}
      className={clsx(
        'absolute top-4 left-[416px] w-[400px] max-w-[400px] h-full max-h-[95%] overflow-auto bg-smena_white rounded-lg px-6 py-3 shadow-smena z-10 transition-all',
        show ? 'opacity-100' : 'opacity-0 pointer-events-none',
      )}
      show={show}
    >
      <>
        <button
          className="absolute top-[15px] right-[15px]"
          onClick={() => {
            setShow(false);
            setTimeout(() => {
              setActiveVacancy(undefined);
            }, 200);
          }}
        >
          <Across className="text-smena_text" />
        </button>
        <div className={'flex flex-col gap-y-2'}>
          <div>
            <div className={'Subtitle1'}>{activeVacancy?.position?.name}</div>
            <div className={'Subtitle2 text-primary'}>{cost}</div>
          </div>
          <div>
            <div className={'Subtitle2'}>Продолжительность</div>
            <span className={'Body2'}>{`${activeVacancy?.timeStart} - ${activeVacancy?.timeEnd} ${duration}`}</span>
            <div className={'flex gap-2 Body2 flex-wrap'}>
              <div className={'flex gap-x-2 Caption-small pl-1 pr-2 py-0.5 rounded-lg border border-smena_gray-30'}>
                {getPeriodBlock(activeVacancy?.period)}
              </div>
              <div className={'flex gap-x-2 Caption-small pl-1 pr-2 py-0.5 rounded-lg border border-smena_gray-30'}>
                <CoffeeIcon />
                <span>{lunchDuration}</span>
              </div>
            </div>
          </div>

          {activeVacancy?.payoutFrequency && (
            <>
              <div>
                <div className={'Subtitle2'}>Частота выплат</div>
                <div className={'Body2 text-smena_text-secondary'}>
                  {getPayoutFrequencyRu(activeVacancy?.payoutFrequency)}
                </div>
              </div>
            </>
          )}
          <hr className={'bg-smena_gray-30'} />
          {activeVacancy?.description && (
            <div>
              <div className={'Subtitle2'}>Описание</div>
              <div
                className={'Body2 text-smena_text-secondary'}
                dangerouslySetInnerHTML={{ __html: activeVacancy.description }}
              />
            </div>
          )}
          {activeVacancy?.requirements && (
            <div>
              <div className={'Subtitle2'}>Требования</div>
              <div className={'Body2 text-smena_text-secondary'}>{activeVacancy.requirements}</div>
            </div>
          )}
          {activeVacancy?.responsibilities && (
            <div>
              <div className={'Subtitle2'}>Обязанности</div>
              <div className={'Body2 text-smena_text-secondary'}>{activeVacancy.responsibilities}</div>
            </div>
          )}
          {activeVacancy?.section && (
            <div>
              <div className={'Subtitle2'}>Отдел или секция</div>
              <div className={'Body2 text-smena_text-secondary'}>{activeVacancy.section}</div>
            </div>
          )}
          {activeVacancy?.workwear && (
            <div>
              <div className={'Subtitle2'}>Спец. одежда</div>
              <div className={'Body2 text-smena_text-secondary'}>
                {activeVacancy.workwear ? 'Выдается' : 'Не выдается'}
              </div>
            </div>
          )}
          <div className={'flex gap-x-4'}>
            {activeVacancy?.hasEducation ? (
              <div className={'flex-[1_0]'}>
                <div className={'Subtitle2'}>Обучение</div>
                <div className={'Body2 text-smena_text-secondary'}>{activeVacancy.hasEducation ? 'Есть' : 'Нет'}</div>
              </div>
            ) : null}
            {activeVacancy?.probationPeriod ? (
              <div className={'flex-[1_0]'}>
                <div className={'Subtitle2'}>Проб. период</div>
                <div className={'Body2 text-smena_text-secondary'}>
                  {`${activeVacancy?.probationPeriod} ${morph(activeVacancy?.probationPeriod, ['день', 'дня', 'дней'])}`}
                </div>
              </div>
            ) : null}
          </div>
          {activeVacancy?.requiredDocuments.length ? (
            <div>
              <div className={'Subtitle2'}>Необходимые документы</div>
              <ol className={'px-4'}>
                {activeVacancy?.requiredDocuments.map(el => (
                  <li key={el} className={'list-disc Body2 text-smena_text-secondary'}>
                    {CasingDocumentTypes.find(type => type.value === el)?.label}
                  </li>
                ))}
              </ol>
            </div>
          ) : null}
          {activeVacancy?.documentsNotes && (
            <div>
              <div className={'Subtitle2'}>Примечания к документам</div>
              <div className={'Body2 text-smena_text-secondary'}>{activeVacancy.documentsNotes}</div>
            </div>
          )}
          {activeVacancy?.description ||
          activeVacancy?.requirements ||
          activeVacancy?.responsibilities ||
          activeVacancy?.section ||
          activeVacancy?.workwear ||
          activeVacancy?.hasEducation ||
          activeVacancy?.probationPeriod ||
          activeVacancy?.documentsNotes ||
          Boolean(activeVacancy?.requiredDocuments.length) ? (
            <hr className={'bg-smena_gray-30'} />
          ) : null}
          <div className={'flex gap-x-4'}>
            {activeVacancy?.maxAge || activeVacancy?.minAge ? (
              <div className={'flex-[1_0]'}>
                <div className={'Subtitle2'}>Возраст</div>
                <div className={'Body2 text-smena_text-secondary'}>
                  {`${activeVacancy?.minAge ? `от ${activeVacancy?.minAge}` : ''} 
                  ${activeVacancy?.maxAge ? `до ${activeVacancy?.maxAge}` : ''}`}
                </div>
              </div>
            ) : null}
            {activeVacancy?.sex && (
              <div className={'flex-[1_0]'}>
                <div className={'Subtitle2'}>Пол</div>
                <div className={'Body2 text-smena_text-secondary'}>{getSexRu(activeVacancy.sex)}</div>
              </div>
            )}
          </div>
          {activeVacancy?.citizenshipsIds?.length ? (
            <div>
              <div className={'Subtitle2'}>Гражданство</div>
              <ol className={'px-4'}>
                {activeVacancy?.citizenshipsIds.map(el => (
                  <li key={el} className={'list-disc Body2 text-smena_text-secondary'}>
                    {countries.find(type => type.id === el)?.name}
                  </li>
                ))}
              </ol>
            </div>
          ) : null}
          {(Boolean(activeVacancy?.maxAge) ||
            Boolean(activeVacancy?.minAge) ||
            activeVacancy?.sex ||
            activeVacancy?.documentsNotes ||
            Boolean(activeVacancy?.citizenshipsIds?.length)) && <hr className={'bg-smena_gray-30'} />}
          {activeVacancy?.transport && (
            <div>
              <div className={'Subtitle2'}>Транспорт</div>
              <div className={'Body2 text-smena_text-secondary'}>{activeVacancy.transport}</div>
            </div>
          )}
          {activeVacancy?.notes && (
            <div>
              <div className={'Subtitle2'}>Примечания</div>
              <div className={'Body2 text-smena_text-secondary'}>{activeVacancy.notes}</div>
            </div>
          )}
        </div>
      </>
    </OutsideClick>
  );
};

export default VacancyPopup;
