import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useCurrentUser } from 'utils/api/useUser';
import { Country } from 'generated/graphql';
import { CountryFragmentDoc } from 'graphql/fragments/generated/Country.fragment';
import { useUpdateUserNameAndCitizenshipMutation } from '../api/mutations/generated/UpdateUserNameAndCitizenship';

export type UpdateUserFormProps = {
  firstname: string;
  lastname: string;
  patronymic: string;
  citizenshipId: string;
};

export const useUpdateUserForm = () => {
  const { user } = useCurrentUser();
  const [updateUser, { loading }] = useUpdateUserNameAndCitizenshipMutation();
  const initialValues = {
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    patronymic: user?.patronymic || '',
    citizenshipId: user?.citizenship?.id || '',
  };

  const validate = (values: UpdateUserFormProps) => {
    const errors: any = {};

    if (!values.firstname) {
      errors.firstname = 'Обязательное поле';
    }
    if (!values.lastname) {
      errors.lastname = 'Обязательное поле';
    }
    if (!values.citizenshipId) {
      errors.citizenshipId = 'Обязательное поле';
    }
    return errors;
  };
  const onSubmit = (values: UpdateUserFormProps) => {
    if (!user) return;
    updateUser({
      variables: {
        input: { ...values, patronymic: values.patronymic || null, userId: user.id },
      },
      update: (cache, { data }) => {
        const user = data?.updateUserNameAndCitizenship;
        const citizenship = cache.readFragment<Country>({
          id: `Country:${values.citizenshipId}`,
          fragment: CountryFragmentDoc,
          fragmentName: 'Country',
        });

        if (!user) return;
        cache.modify({
          id: `RatingUser:${user.id}`,
          fields: {
            firstname() {
              return user.firstname;
            },
            lastname() {
              return user.lastname;
            },
            patronymic() {
              return user.patronymic ? user.patronymic : null;
            },
            citizenship() {
              return citizenship;
            },
          },
        });
      },
    })
      .then(() => {
        successToast('Данные сохранены');
      })
      .catch(err => {
        errorToast(errorHandler(err));
      });
  };

  return { initialValues, validate, onSubmit, loading };
};
