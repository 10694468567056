import { Link, useParams } from 'react-router-dom';
import Across from 'assets/icons/Across';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, infoToast, successToast } from 'utils/helpers/notify';
import { updateFacilityClientManagersCache, updateFacilityGroupClientDirectorsCache } from './writeCache';
import { useRemoveManagerFromFacilityMutation } from 'pages/user/api/mutations/generated/RemoveManagerFromFacility';
import { useRemoveDirectorFromFacilityGroupMutation } from 'pages/user/api/mutations/generated/RemoveDirectorFromFacilityGroup';
import { useRemoveSupervisorFromFacilityMutation } from 'pages/user/api/mutations/generated/RemoveSupervisorFromFacility';
import { useRemoveForemanFromFacilityMutation } from 'pages/user/api/mutations/generated/RemoveForemanFromFacility';
import { removeForemanCache } from '../../api/cache/removeForemanCache';
import { removeSupervisorCache } from '../../api/cache/removeSupervisorCache';
import { Role } from 'generated/graphql';
import { Person } from 'interfaces/TPerson';

interface Props {
  user: Person;
  canRemove?: boolean;
}

const ResponsibleUser = ({ user, canRemove = true }: Props) => {
  const router = useParams();
  const facilityId = router.facilityId;
  const groupId = router.id;

  const { lastname, firstname, id: userId, role } = user;

  const [removeSupervisor, { loading: loadingRemoveSupervisor }] = useRemoveSupervisorFromFacilityMutation();
  const [removeForeman, { loading: loadingRemoveForeman }] = useRemoveForemanFromFacilityMutation();
  const [removeManagerFromFacility, { loading: loadingRemoveManager }] = useRemoveManagerFromFacilityMutation();
  const [removeDirectorFromFacilityGroup, { loading: loadingRemoveDirector }] =
    useRemoveDirectorFromFacilityGroupMutation({
      update: cache => {
        if (!groupId) return;
        updateFacilityGroupClientDirectorsCache(cache, { facilityGroupId: groupId, userId });
      },
    });

  const removeSupervisorHandler = () => {
    if (!facilityId) return;
    removeSupervisor({
      variables: {
        facilityId,
        userId,
      },
      update: cache => {
        removeSupervisorCache(cache, { userId, facilityId });
      },
    })
      .then(() => successToast('Супервайзер удален'))
      .catch(e => errorToast(errorHandler(e)));
  };

  const removeForemanHandler = () => {
    if (!facilityId) return;
    removeForeman({
      variables: {
        facilityId,
        userId,
      },
      update: cache => {
        removeForemanCache(cache, { userId, facilityId });
      },
    })
      .then(response => {
        if (response.data?.removeForemanFromFacility) {
          infoToast('Бригадир удален');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  const removeManagerFromFacilityHandler = () => {
    if (!facilityId) return;
    removeManagerFromFacility({
      variables: {
        facilityId,
        userId,
      },
      update: cache => {
        updateFacilityClientManagersCache(cache, { facilityId, userId });
      },
    })
      .then(() => successToast('Менеджер успешно удален'))
      .catch(err => errorToast(errorHandler(err)));
  };

  const removeDirectorFromFacilityGroupHandler = () => {
    if (!groupId) return;
    removeDirectorFromFacilityGroup({
      variables: {
        groupId,
        userId,
      },
      update: cache => {
        updateFacilityGroupClientDirectorsCache(cache, { facilityGroupId: groupId, userId });
      },
    })
      .then(() => successToast('Директор успешно удален'))
      .catch(err => errorToast(errorHandler(err)));
  };

  const disabled = loadingRemoveSupervisor || loadingRemoveForeman || loadingRemoveManager || loadingRemoveDirector;

  return (
    <div className="grid grid-cols-[1fr_60px] items-center h-6">
      <Link to={`/users/${userId}`} className="col-auto Body2 text-primary truncate" title={lastname + ' ' + firstname}>
        {lastname + ' ' + firstname}
      </Link>
      {canRemove && (
        <button
          className="auto-cols-max flex justify-center"
          type="button"
          disabled={disabled}
          onClick={() => {
            if (role === Role.Supervisor) {
              removeSupervisorHandler();
            }
            if (role === Role.Foreman) {
              removeForemanHandler();
            }
            if (role === Role.ClientManager) {
              removeManagerFromFacilityHandler();
            }
            if (role === Role.ClientDirector) {
              removeDirectorFromFacilityGroupHandler();
            }
          }}
        >
          <Across className="text-smena_red" />
        </button>
      )}
    </div>
  );
};
export default ResponsibleUser;
