import { useState } from 'react';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';

import MainLayout from 'components/layout/main';
import SimpleSelect from 'ui/select';
import RangePickerInput from 'ui/pickers/RangePickerInput';
import { PeriodType } from 'generated/graphql';
import { periodShift } from 'utils/helpers/lists/periodType';
import { getPeriodAmount, getPeriodRu } from 'utils/helpers/get/getPeriodRu';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { useCreateModerationReport } from './useCreateModerationReport';
import { DayOrNullArray } from 'interfaces/CustomTypes';
import { disabledDatesFn } from './helper';

const CreateReport = () => {
  const { facilities } = useSelectFacilities();
  const { facilitiesGroups } = useSelectFacilityGroups();
  const { loading, validate, onSubmit, initialValues } = useCreateModerationReport();
  const [calendarValue, setCalendarValue] = useState<DayOrNullArray>(null);

  const onOpenChange = (open: boolean) => {
    if (open) {
      setCalendarValue(null);
    }
  };

  return (
    <MainLayout
      title="Новый период"
      breadCrumbs={[
        { path: '/moderation', name: 'Модерация смен' },
        { path: '/moderation/create', name: 'Период' },
      ]}
    >
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, setFieldValue, touched, errors }) => {
          const filteredFacilities = filterFacilities(values.groupId, facilities);
          const disabledDate = (current: dayjs.Dayjs) => {
            if (values.date && values.date.length) {
              return true;
            }
            return disabledDatesFn({
              calendarValue,
              current,
              period: values.period as PeriodType,
            });
          };
          const onCalendarChange = (val: DayOrNullArray) => {
            setCalendarValue(val);
            if (!val?.length || values.period === PeriodType.Custom) return;
            const { amount, type } = getPeriodAmount(values.period);
            if (val[0]) {
              const dateEnd =
                type === 'month' ? dayjs(val[0]).add(amount, type).subtract(1, 'day') : dayjs(val[0]).add(amount, type);
              setFieldValue('date', [val[0], dateEnd]);
            } else if (val[1]) {
              const dateStart =
                type === 'month' ? dayjs(val[1]).subtract(amount, type) : dayjs(val[1]).subtract(amount, type);
              const dateEnd = type === 'month' ? dayjs(val[1]).subtract(1, 'day') : val[1];
              setFieldValue('date', [dateStart, dateEnd]);
            }
          };
          return (
            <Form className="bg-smena_white w-full flex gap-x-6 p-5 rounded-lg shadow-smena">
              <SimpleSelect
                divClassName="w-[220px]"
                label="Группа"
                popupClassName={'max-w-[300px]'}
                onChange={value => {
                  setFieldValue('groupId', value);
                }}
                value={values.groupId}
                placeholder="Все группы"
                options={facilitiesGroups}
              />
              <SimpleSelect
                divClassName="w-[220px]"
                label="Объект"
                onChange={value => {
                  setFieldValue('facilityId', value);
                }}
                value={values.facilityId}
                placeholder="Все объекты"
                options={filteredFacilities}
                error={touched.facilityId && errors.facilityId ? errors.facilityId : ''}
              />

              <SimpleSelect
                divClassName="w-[150px]"
                label="Тип периода"
                onChange={value => {
                  setFieldValue('period', value);
                  setFieldValue('date', null);
                }}
                value={values.period}
                placeholder="Все периоды"
                options={periodShift.map(el => ({ id: el, name: getPeriodRu(el) }))}
                sort={false}
                error={touched.period && errors.period ? errors.period : undefined}
              />
              <RangePickerInput
                divClassName={'w-[220px]'}
                label="Период"
                value={values.date}
                onChange={value => {
                  setFieldValue('date', value);
                }}
                onOpenChange={onOpenChange}
                onCalendarChange={onCalendarChange}
                disabledDate={disabledDate}
                error={touched.date && errors.date ? errors.date : undefined}
              />
              <div className="flex items-end">
                <button className="btn-secondary_small" type="submit" disabled={loading}>
                  Создать
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default CreateReport;
