import { ICustomEvent } from 'interfaces/IEvent';

const CustomEvent = (event: { event: ICustomEvent }) => {
  const {
    event: { title },
  } = event;
  return <div className="text-center Tag text-smena_gray-80 cursor-pointer">{title}</div>;
};

export default CustomEvent;
