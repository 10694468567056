import Days from './components/Days';
import Performers from './components/Performers/Performers';
import Statistics from './components/Statistics';

import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
}

const FacilityTable: React.FC<Props> = ({ data }) => {
  const { statisticsVisible } = useFacilityReportStore();

  return (
    <div className="overflow-auto" style={{ maxHeight: 'calc(100vh - 358px)' }}>
      <div className="grid grid-cols-[460px_1fr_485px] relative">
        <div className="sticky left-0 z-50 bg-white">
          <Performers data={data} />
        </div>
        <div>
          <Days data={data} />
        </div>
        {statisticsVisible && (
          <div className="sticky right-0 z-50 bg-white">
            <Statistics data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FacilityTable;
