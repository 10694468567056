import { DayjsOrNull } from 'interfaces/CustomTypes';
import { useCreateManyReferralProgramsMutation } from 'pages/referralProgramCreate/api/generated/CreateManyReferralPrograms';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useNavigate } from 'react-router';

export interface InitialValues {
  name: string;
  description?: string;
  numberOfGoodShifts: number | string;
  rewardForTheReferer: number | string;
  startDate?: DayjsOrNull;
  startTime?: DayjsOrNull;
  endDate?: DayjsOrNull;
  endTime?: DayjsOrNull;
  indefinitely?: boolean;
  facilityGroupIds: string[];
  facilityIds: string[];
}

const useReferralProgramCreatePage = () => {
  const navigate = useNavigate();

  const [createManyReferralPrograms, { loading }] = useCreateManyReferralProgramsMutation();

  const initialValues: InitialValues = {
    name: '',
    description: '',
    numberOfGoodShifts: '',
    rewardForTheReferer: '',
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    indefinitely: false,
    facilityGroupIds: [],
    facilityIds: [],
  };

  const formatDateTime = (date?: DayjsOrNull, time?: DayjsOrNull): string => {
    if (date && time) {
      return dayjs(date)
        .set('hour', time.hour())
        .set('minute', time.minute())
        .set('second', 0)
        .set('millisecond', 0)
        .toISOString();
    }
    return '';
  };

  const validate = (values: InitialValues) => {
    const errors: any = {};
    if (!values.name.trim()) errors.name = 'Обязательное поле';
    if (!values.numberOfGoodShifts) errors.numberOfGoodShifts = 'Обязательное поле';
    if (!values.startDate) errors.startDate = 'Обязательное поле';
    if (!values.startTime) errors.startTime = 'Обязательное поле';
    if (!values.rewardForTheReferer) errors.rewardForTheReferer = 'Обязательное поле';
    if (!values.facilityGroupIds.length && !values.facilityIds.length) errors.facilityGroupIds = 'Обязательное поле';
    return errors;
  };

  const onSubmit = useCallback(
    async ({
      name,
      description,
      numberOfGoodShifts,
      rewardForTheReferer,
      startDate,
      startTime,
      endDate,
      endTime,
      indefinitely,
      facilityGroupIds,
      facilityIds,
    }: InitialValues) => {
      const startDatetime = formatDateTime(startDate, startTime);
      const endDatetime = formatDateTime(endDate, endTime);
      try {
        await createManyReferralPrograms({
          variables: {
            input: {
              name: name.trim(),
              description: description?.trim() || null,
              numberOfGoodShifts: Number(numberOfGoodShifts),
              rewardForTheReferer: Number(rewardForTheReferer),
              startDatetime,
              endDatetime: endDatetime ? (indefinitely ? null : endDatetime) : null,
              facilityGroupIds: facilityGroupIds.length ? facilityGroupIds : undefined,
              facilityIds: facilityIds.length ? facilityIds : undefined,
            },
          },
        });
        navigate('/offers/referrals');
        successToast('Реферальные программы созданы');
      } catch (error: any) {
        errorToast(errorHandler(error));
      }
    },
    [createManyReferralPrograms, navigate],
  );

  return {
    initialValues,
    onSubmit,
    validate,
    loading,
  };
};

export default useReferralProgramCreatePage;
