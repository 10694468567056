import React from 'react';
import clsx from 'clsx';
import { TIcon } from '../../interfaces/TIcon';

const CheckCircleFill12 = ({ className }: TIcon) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0002 10.8002C8.65116 10.8002 10.8002 8.65116 10.8002 6.0002C10.8002 3.34923 8.65116 1.2002 6.0002 1.2002C3.34923 1.2002 1.2002 3.34923 1.2002 6.0002C1.2002 8.65116 3.34923 10.8002 6.0002 10.8002ZM8.22446 5.22446C8.45877 4.99014 8.45877 4.61025 8.22446 4.37593C7.99015 4.14162 7.61025 4.14162 7.37593 4.37593L5.4002 6.35167L4.62446 5.57593C4.39015 5.34162 4.01025 5.34162 3.77593 5.57593C3.54162 5.81025 3.54162 6.19014 3.77593 6.42446L4.97593 7.62446C5.21025 7.85877 5.59015 7.85877 5.82446 7.62446L8.22446 5.22446Z"
      />
    </svg>
  );
};

export default CheckCircleFill12;
