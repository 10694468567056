import dayjs from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';
import { Link } from 'react-router-dom';
import { useGetRequestByFacilityId } from 'utils/api/useGetRequestByFacilityId';
import { useMemo } from 'react';
import { useStore } from 'store';

interface IActiveScheduleCheckbox {
  id: string;
}

const ActiveScheduleCheckbox = ({ id }: IActiveScheduleCheckbox) => {
  const { sortedRequests } = useGetRequestByFacilityId();
  const { editFacilityScheduleIds } = useStore();
  const request = useMemo(() => sortedRequests?.find(request => request.id === id), [id, sortedRequests]);
  return (
    <div className="flex items-center gap-x-1 bg-smena_bb-background p-2 Table-small rounded-lg text-primary">
      <input
        type="checkbox"
        className="checkbox-primary"
        defaultChecked
        onClick={() => {
          editFacilityScheduleIds(id);
        }}
      />
      <Link target="_blank" to={`/requests/${request?.id}`}>
        {request?.number}
      </Link>
      <span>·</span>
      <div className="flex w-max">
        {dayjs(request?.dateFrom).format(RU_DATE)} - {dayjs(request?.dateTo).format(RU_DATE)}
      </div>
    </div>
  );
};

export default ActiveScheduleCheckbox;
