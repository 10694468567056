const UnknownIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.22222 2.77783H17.7778C18.3671 2.77783 18.9324 3.01196 19.3491 3.42871C19.7659 3.84545 20 4.41068 20 5.00005V15.0001C20 15.5894 19.7659 16.1547 19.3491 16.5714C18.9324 16.9881 18.3671 17.2223 17.7778 17.2223H2.22222C1.63285 17.2223 1.06762 16.9881 0.650874 16.5714C0.234126 16.1547 0 15.5894 0 15.0001V5.00005C0 4.41068 0.234126 3.84545 0.650874 3.42871C1.06762 3.01196 1.63285 2.77783 2.22222 2.77783Z"
        fill="#D4D5DB"
      />
      <path
        d="M9.01442 6.89645C9.35375 6.69702 9.75272 6.62412 10.1407 6.69066C10.5286 6.7572 10.8805 6.95889 11.1339 7.26001C11.3874 7.56112 11.5261 7.94222 11.5256 8.33582L11.5256 8.33707C11.5256 8.72801 11.2213 9.13558 10.6466 9.51869C10.3847 9.69334 10.1168 9.82788 9.91106 9.9193C9.8094 9.96449 9.72584 9.99786 9.66965 10.0193C9.64162 10.0299 9.62061 10.0376 9.60779 10.0421L9.59449 10.0468C9.15843 10.1927 8.92287 10.6643 9.06832 11.1006C9.21386 11.5372 9.68579 11.7732 10.1224 11.6276L10.1235 11.6273L10.1247 11.6269L10.128 11.6258L10.1372 11.6226L10.1661 11.6125C10.1899 11.604 10.2226 11.5921 10.263 11.5767C10.3435 11.5461 10.4552 11.5013 10.588 11.4423C10.851 11.3254 11.2081 11.1475 11.5711 10.9054C12.2463 10.4553 13.1918 9.61312 13.1922 8.33783C13.1933 7.5508 12.9158 6.78877 12.409 6.18667C11.902 5.58444 11.1983 5.18107 10.4224 5.04799C9.64655 4.9149 8.84862 5.06071 8.16995 5.45957C7.49128 5.85843 6.97567 6.4846 6.71444 7.2272C6.56171 7.66135 6.78986 8.13712 7.22402 8.28984C7.65817 8.44257 8.13394 8.21443 8.28667 7.78027C8.41728 7.40897 8.67508 7.09588 9.01442 6.89645Z"
        fill="#393B55"
      />
      <path
        d="M9.92555 13.3371C9.46532 13.3371 9.09222 13.7102 9.09222 14.1704C9.09222 14.6306 9.46532 15.0037 9.92555 15.0037H9.93389C10.3941 15.0037 10.7672 14.6306 10.7672 14.1704C10.7672 13.7102 10.3941 13.3371 9.93389 13.3371H9.92555Z"
        fill="#393B55"
      />
    </svg>
  );
};

export default UnknownIcon;
