import { clsx } from 'clsx';
import React from 'react';
import { PromotionStatusEnum } from '../../../../generated/graphql';

type Props = {
  status?: string;
};

const statusName = (status?: string) => {
  if (!status) return '';
  const statusRu: { [key: string]: string } = {
    Blank: 'Черновик',
    [PromotionStatusEnum.WaitingToBeSent]: 'Ожидает публикации',
    [PromotionStatusEnum.Published]: 'Опубликовано',
    [PromotionStatusEnum.Deleted]: 'Удален',
  };
  return statusRu[status];
};

const statusClassName = (status?: string) => {
  switch (status) {
    case 'Blank':
    case PromotionStatusEnum.Deleted:
      return 'bg-smena_white border border-smena_text-secondary text-smena_text-secondary';
    case PromotionStatusEnum.Published:
      return 'bg-smena_green-extra_light text-smena_green-dark';
    case PromotionStatusEnum.WaitingToBeSent:
      return 'bg-smena_yellow-extra_light text-smena_orange-dark';
    default:
      return '';
  }
};

const Status = React.memo(({ status }: Props) => {
  const className = statusClassName(status);
  const name = statusName(status);
  return <span className={clsx(className, 'rounded-full inline-flex px-2 py-[2px] Table-small')}>{name}</span>;
});

export default Status;
