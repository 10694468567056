import React from 'react';

export const errorStateHandler = (
  field: string,
  state: any,
  set: React.Dispatch<React.SetStateAction<{ [p: string]: string | undefined }>>,
  errorCounter: React.MutableRefObject<number>,
) => {
  if (!state) {
    set(value => ({ ...value, [field]: 'Обязательное поле' }));
    errorCounter.current += 1;
  } else {
    if (errorCounter.current === 0) {
      errorCounter.current = 0;
    } else {
      errorCounter.current -= 1;
    }
    set(value => ({ ...value, [field]: undefined }));
  }
};

export const getFinalBonus = (shift: any) => {
  let bonusForThisShift = 0;

  shift?.promotionResults.map((promotion: any) => {
    if (promotion.progress >= promotion.promotion.requirementShiftCount) {
      let bonusForThisPromotion: number;

      if (promotion.promotion.rewardType === 'SURCHARGE_TO_THE_RATE') {
        bonusForThisPromotion = Math.round(promotion.promotion.rewardSum * (shift.durationPlan / 60));
      } else {
        bonusForThisPromotion = promotion.promotion.rewardSum;
      }

      if ((shift.moderation && shift.moderation.toPaymentBonus) || (!shift.moderation && shift.stage === 'FINISHED')) {
        bonusForThisShift += bonusForThisPromotion;
      }
    }
  });

  return bonusForThisShift;
};
