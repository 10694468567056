import { PeriodType } from 'generated/graphql';
import { ManipulateType } from 'dayjs';

type TPeriod = { period: PeriodType; amount: number; periodRu: string; type: ManipulateType };

export const periodList: TPeriod[] = [
  {
    period: PeriodType.Month,
    amount: 1,
    type: 'month',
    periodRu: 'Месяц',
  },
  {
    period: PeriodType.TwoWeek,
    amount: 13,
    type: 'days',
    periodRu: 'Две недели',
  },
  {
    period: PeriodType.Week,
    amount: 6,
    type: 'days',
    periodRu: 'Неделя',
  },
  {
    period: PeriodType.Custom,
    amount: 0,
    type: 'day',
    periodRu: 'Свободный выбор даты',
  },
];

export const getPeriodRu = (period?: PeriodType) => {
  const res = periodList.find(item => period === item.period)?.periodRu;
  if (!res) return '';
  return res;
};

export const getPeriodAmount = (period?: string): { amount: number; type: ManipulateType } => {
  const res = periodList.find(item => period === item.period);
  if (!res) return { type: 'day', amount: 0 };
  return res;
};
