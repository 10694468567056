const Home = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 17H13.92C13.6022 16.9985 13.2979 16.8748 13.0732 16.6558C12.8485 16.4368 12.7216 16.1403 12.72 15.8307V13.2581H11.28V15.8307C11.2784 16.1403 11.1515 16.4368 10.9268 16.6558C10.7021 16.8748 10.3978 16.9985 10.08 17H7.2C6.88223 16.9985 6.57792 16.8748 6.35321 16.6558C6.12851 16.4368 6.00158 16.1403 6 15.8307V10.4225C6.0004 10.2593 6.03583 10.098 6.10403 9.94896C6.17224 9.79993 6.27169 9.66646 6.396 9.55714L11.19 5.30658C11.4112 5.10937 11.7002 5 12 5C12.2998 5 12.5888 5.10937 12.81 5.30658L17.61 9.55714C17.7324 9.66765 17.8301 9.80149 17.8972 9.95034C17.9643 10.0992 17.9993 10.2599 18 10.4225V15.8307C17.9984 16.1403 17.8715 16.4368 17.6468 16.6558C17.4221 16.8748 17.1178 16.9985 16.8 17ZM14.16 15.5968H16.56V10.5277L12 6.48762L7.44 10.5277V15.5968H9.84V13.0242C9.84158 12.7146 9.96851 12.418 10.1932 12.1991C10.4179 11.9801 10.7222 11.8564 11.04 11.8549H12.96C13.2778 11.8564 13.5821 11.9801 13.8068 12.1991C14.0315 12.418 14.1584 12.7146 14.16 13.0242V15.5968Z"
        fill="#737484"
      />
    </svg>
  );
};

export default Home;
