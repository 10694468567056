import { useMemo, useRef, useState } from 'react';

import Filter from 'components/table/filters/filter';
import clsx from 'clsx';
import SimpleSelect from 'ui/select';
import { UserContractStatus, UserContractType } from 'generated/graphql';
import TableFilter from 'components/table/filters';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import Search from 'ui/search';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import ColumnSettings from 'components/table/columnSettings';
import { Table } from '@tanstack/react-table';
import { useFacilityById } from 'utils/api/useFacility';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';
import { contractStatuses, contractTypes } from '../utils/consts';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import { useCustomSearchParams } from 'pages/users/lib/useParams';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import dayjs from 'dayjs';
import { ISO_DATE } from 'utils/helpers/constVariables';
import { getContractStatusRu, getContractTypeRu } from '../utils/getContractTypeRu';
import { ContractFragment } from '../api/fragments/generated/contract.fragment';

interface Props {
  table: Table<ContractFragment>;
}

const UserContractsFilters = ({ table }: Props) => {
  const { handleParams, params, setParams, useParamsCount, resetFilter } = useCustomSearchParams();
  const typeParam = params.get('type');
  const statusParam = params.get('status');
  const createdAtParam = params.get('createdAt');
  const facilityIdParam = params.get('facilityId');
  const facilityGroupIdParam = params.get('facilityGroupId');
  const [type, setType] = useState(typeParam);
  const [facilityId, setFacilityId] = useState(facilityIdParam);
  const [facilityGroupId, setFacilityGroupId] = useState(facilityGroupIdParam);
  const [status, setStatus] = useState(statusParam);
  const [createdAt, setCreatedAt] = useState<DayjsOrNull>(createdAtParam ? dayjs(createdAtParam) : null);
  const [showFilter, setShowFilter] = useState(false);
  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);
  const paramsCount = useParamsCount();

  const { facility } = useFacilityById(facilityIdParam);
  const { facilities } = useSelectFacilities();
  const { facilitiesGroups } = useSelectFacilityGroups();
  const filteredFacilities = useMemo(
    () => filterFacilities(facilityGroupId, facilities),
    [facilityGroupId, facilities],
  );
  const btnRef = useRef<HTMLButtonElement>(null);
  const documentStatus = useMemo(() => contractStatuses.map(el => ({ id: el.name, name: el.nameRu })), []);
  const documentTypes = useMemo(() => contractTypes.map(el => ({ id: el.name, name: el.nameRu })), []);

  return (
    <div className="filter-bar flex flex-col gap-y-2">
      <div className={clsx('flex relative gap-x-2')}>
        <Search />
        <div className={'flex gap-x-0.5'}>
          <button
            type="button"
            className={clsx('btn-stroke_small flex gap-x-1 items-center')}
            ref={btnRef}
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
        </div>
        <ColumnSettings<ContractFragment> table={table} />
        <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
          <SimpleSelect
            label="Тип документа"
            onChange={value => {
              setType(value);
            }}
            value={type}
            placeholder="Не выбрано"
            allOption={'Все'}
            options={documentTypes}
            sort={false}
          />
          <SimpleSelect
            label="Группа"
            onChange={value => {
              setFacilityGroupId(value);
            }}
            value={facilityGroupId}
            placeholder="Не выбрано"
            allOption={'Все'}
            options={facilitiesGroups}
          />
          <SimpleSelect
            label="Объект"
            onChange={value => {
              setFacilityId(value);
            }}
            value={facilityId}
            placeholder="Не выбрано"
            allOption={'Все'}
            options={filteredFacilities}
          />
          <SimpleSelect
            label="Статус"
            sort={false}
            onChange={value => {
              setStatus(value);
            }}
            value={status}
            placeholder="Не выбрано"
            allOption={'Все'}
            options={documentStatus}
          />
          <DatePickerInput
            label="Дата создания"
            onChange={value => {
              setCreatedAt(value);
            }}
            value={createdAt}
          />
          <div className="flex gap-x-5">
            <button
              className="btn-primary"
              onClick={() => {
                handleParams('facilityGroupId', facilityGroupId);
                handleParams('facilityId', facilityId);
                handleParams('type', type);
                handleParams('status', status);
                handleParams('createdAt', createdAt ? dayjs(createdAt).format(ISO_DATE) : null);
                params.delete('page');
                setParams(params);
                setShowFilter(false);
              }}
            >
              Применить
            </button>
            <button
              className="btn-stroke xs:w-auto w-full"
              onClick={() => {
                setType(null);
                setFacilityGroupId(null);
                setFacilityId(null);
                setStatus(null);
                setCreatedAt(null);
                setParams();
                setShowFilter(false);
              }}
            >
              <span>Сбросить</span>
            </button>
          </div>
        </TableFilter>
      </div>

      {!!paramsCount && (
        <div className="flex gap-2">
          <Filter
            show={!!facilityGroupIdParam}
            content={facilityGroupName}
            clickHandler={() => {
              setFacilityGroupId(null);
              resetFilter('facilityGroupId');
            }}
          />
          <Filter
            show={!!facilityIdParam}
            content={facility?.name}
            clickHandler={() => {
              setFacilityId(null);
              resetFilter('facilityId');
            }}
          />
          <Filter
            show={!!typeParam}
            content={getContractTypeRu(typeParam as UserContractType)}
            clickHandler={() => {
              setType(null);
              resetFilter('type');
            }}
          />
          <Filter
            show={!!status}
            content={getContractStatusRu(status as UserContractStatus)?.nameRu}
            clickHandler={() => {
              setStatus(null);
              resetFilter('status');
            }}
          />
          <Filter
            show={!!createdAtParam}
            content={dayjs(createdAtParam).format('DD.MM.YYYY')}
            clickHandler={() => {
              setCreatedAt(null);
              resetFilter('createdAt');
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UserContractsFilters;
