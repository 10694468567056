import dayjs, { Dayjs } from 'dayjs';
import DatePickerIcon from 'assets/icons/DatePickerIcon';
import { ErrorNotification } from '../errorNotification';
import { RU_DATE } from 'utils/helpers/constVariables';
import { DayOrNullArray } from 'interfaces/CustomTypes';
import DatePicker, { DatePickerProps } from 'antd/es/date-picker';
import clsx from 'clsx';
import React from 'react';

const { RangePicker } = DatePicker;

interface Props {
  divClassName?: string;
  popupClassName?: string;
  defaultPickerValue?: DayOrNullArray;
  allowEmpty?: [boolean, boolean];
  value: DayOrNullArray;
  onChange: (date: DayOrNullArray, dateString: [string, string]) => void;
  label?: string;
  format?: string;
  separator?: React.ReactNode;
  presets?: { label: string; value: [Dayjs, Dayjs] }[];
  disabled?: [boolean, boolean];
  disabledDate?: (current: dayjs.Dayjs) => boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  size?: 'large' | 'middle' | 'small';
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  placeholder?: [string, string];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onCalendarChange?: (date: DayOrNullArray, dateString: [string, string]) => void;
  error?: string;
  placement?: DatePickerProps['placement'];
}

const RangePickerInput = React.memo(
  ({
    divClassName,
    popupClassName,
    label,
    value,
    allowEmpty,
    format = RU_DATE,
    onChange,
    size = 'middle',
    separator = <span>→</span>,
    disabled,
    disabledDate,
    presets,
    error,
    placeholder = ['Начало', 'Конец'],
    picker,
    open,
    onCalendarChange,
    onOpenChange,
    placement,
  }: Props) => {
    return (
      <div className={clsx(divClassName, 'flex flex-col gap-y-0.5')}>
        {!!label && <label className="label-primary">{label}</label>}
        <RangePicker
          value={value}
          onChange={onChange}
          separator={separator}
          size={size}
          format={format}
          suffixIcon={<DatePickerIcon className="opacity-100" />}
          status={error ? 'error' : ''}
          allowEmpty={allowEmpty}
          panelRender={panelNode => {
            return <div className={'coockPanel'}>{panelNode}</div>;
          }}
          onCalendarChange={onCalendarChange}
          onOpenChange={onOpenChange}
          disabled={disabled}
          presets={presets}
          className="custom__picker"
          disabledDate={disabledDate}
          placeholder={placeholder}
          popupClassName={popupClassName}
          picker={picker}
          open={open}
          placement={placement}
        />
        {Boolean(error) && <ErrorNotification text={error} />}
      </div>
    );
  },
);

export default RangePickerInput;
