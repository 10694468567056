import Check from 'assets/icons/check';
import Plus from 'assets/icons/plus';
import clsx from 'clsx';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useState } from 'react';

interface Props {
  groupIndex: number;
  userIndex: number;
  dayIndex: number;
}

const CustomShiftCell: React.FC<Props> = ({ dayIndex, groupIndex, userIndex }) => {
  const [isActive, setIsActive] = useState(false);
  const { setCustomShifts } = useFacilityReportStore();

  const handleClick = () => {
    setIsActive(prevState => !prevState);
    setCustomShifts({ dayIndex, groupIndex, userIndex });
  };

  return (
    <div
      className={clsx(
        'pt-2 pb-2 min-w-[70px] h-[36px] text-center border-r-[1px] cursor-pointer flex justify-center items-center',
        { ['bg-primary']: isActive },
        { ['bg-smena_bb-background']: !isActive },
      )}
      onClick={handleClick}
    >
      {isActive ? (
        <Check className="w-[16px] h-[16px] text-white" />
      ) : (
        <Plus className="w-[16px] h-[16px] text-primary" />
      )}
    </div>
  );
};

export default CustomShiftCell;
