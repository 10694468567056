import { Role } from 'generated/graphql';

export const getRoleRu = (role?: string | null) => {
  const roleList = [
    {
      role: Role.Newbie,
      roleRu: 'Ньюби',
    },
    {
      role: Role.Worker,
      roleRu: 'Работник',
    },
    {
      role: Role.Foreman,
      roleRu: 'Бригадир',
    },
    {
      role: Role.ClientManager,
      roleRu: 'Менеджер',
    },
    {
      role: Role.ClientDirector,
      roleRu: 'Директор',
    },
    {
      role: Role.Supervisor,
      roleRu: 'Супервайзер',
    },
    {
      role: Role.Admin,
      roleRu: 'Администратор',
    },
  ];

  const response = roleList.find(item => role === item.role)?.roleRu;
  if (response) return response;
  return '';
};
