import { useMemo } from 'react';
import SimpleSelect from 'ui/select';
import { useMeRole } from 'store/hooks/useMeRole';
import { useStore } from 'store';
import { WorkpostFragment } from 'pages/user/api/fragments/generated/Workpost.fragment';
import { Role } from 'generated/graphql';

interface Props {
  selectFacilityHandler: (value: string) => void;
  supervisorWorkposts?: WorkpostFragment[];
  workpostsFacilities: WorkpostFragment[];
}

const FacilitySelect = ({ selectFacilityHandler, supervisorWorkposts, workpostsFacilities }: Props) => {
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const { facilityId, facilityIdError } = useStore();

  const options = useMemo(() => {
    if (isMeSupervisor) {
      return supervisorWorkposts;
    }
    return workpostsFacilities;
  }, [isMeSupervisor, supervisorWorkposts, workpostsFacilities]);
  return (
    <SimpleSelect
      divClassName="w-[180px]"
      label="Объект"
      onChange={selectFacilityHandler}
      value={facilityId}
      allOption="Все объекты"
      placeholder="Выберите объект"
      options={
        options?.length === 1
          ? [{ id: options[0].facilityId, name: options[0].facility?.name }]
          : options?.map(el => ({ id: el.facilityId, name: el.facility?.name }))
      }
      error={facilityIdError}
    />
  );
};

export default FacilitySelect;
