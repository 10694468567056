const BelarusIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9532_6016)">
        <path
          d="M3.33301 17.2223H17.7775C18.3668 17.2223 18.9321 16.9881 19.3488 16.5714C19.7655 16.1547 19.9997 15.5894 19.9997 15.0001V12.7778H3.33301V17.2223Z"
          fill="#4AA657"
        />
        <path
          d="M17.7775 2.77783H3.33301V12.7778H19.9997V5.00005C19.9997 4.41068 19.7655 3.84545 19.3488 3.42871C18.9321 3.01196 18.3668 2.77783 17.7775 2.77783Z"
          fill="#C8313E"
        />
        <path
          d="M2.22222 2.77783C1.63285 2.77783 1.06762 3.01196 0.650874 3.42871C0.234126 3.84545 0 4.41068 0 5.00005L0 15.0001C0 15.5894 0.234126 16.1547 0.650874 16.5714C1.06762 16.9882 1.63285 17.2223 2.22222 17.2223H3.33333V2.77783H2.22222Z"
          fill="white"
        />
        <path
          d="M0.425608 16.2156L0.77283 15.5995L1.12894 16.2334L0.82783 16.7173C0.922559 16.7946 1.02366 16.8637 1.13005 16.9239L1.57005 16.1256L0.781163 14.8612L0.160608 15.8201C0.262274 16.0756 0.411163 16.3045 0.594497 16.5034L0.425608 16.2156ZM3.32283 16.1651L2.49116 14.9306L1.65672 16.1367L2.37116 17.2223H2.73061L3.32283 16.1651ZM2.51561 15.5989L2.87172 16.2328L2.51561 16.8056L2.16839 16.2156L2.51561 15.5989ZM3.33339 10.1851V9.68839L3.1595 9.93061L3.33339 10.1851ZM1.5695 3.90339L1.07283 3.10672C0.637907 3.37109 0.307326 3.7772 0.136719 4.25672L0.850608 5.20839L1.5695 3.90339ZM0.425608 3.99339L0.77283 3.37728L1.12894 4.01117L0.77283 4.58339L0.425608 3.99339ZM2.56505 5.29505L3.32283 3.94228L2.53839 2.77783H2.44339L1.65727 3.9145L2.56505 5.29505ZM2.87116 4.01061L2.51561 4.58339L2.16839 3.99339L2.51561 3.37728L2.87116 4.01061Z"
          fill="#C8313E"
        />
        <path
          d="M2.75167 8.33328L3.23778 7.52606L3.33333 7.56828V7.22217L2.55222 6.00661L1.61444 7.30883L0.781111 5.97217L0 7.17939V7.5405L0.477222 8.33328L0.815556 7.83828L0.572778 7.49995L0.755 7.25661L1.28444 7.94217L0.503333 9.22661L0 8.53384V9.39661L0.0322222 9.35384L0.561667 10.0394L0 10.9638V11.6177L0.503333 10.9244L1.28444 12.2088L0.845555 12.7777L0.755 12.8949L0.667222 12.7777L0.572778 12.6516L0.816111 12.3133L0.477778 11.8183L0 12.6116V12.9311L0.798889 14.1494L1.57667 12.7777L1.60611 12.7255L1.63833 12.7777L2.535 14.2272L3.33333 12.8122V12.5949L3.26389 12.6261L2.77778 11.8183L2.43889 12.3133L2.68222 12.6516L2.58778 12.7777L2.5 12.8949L2.40944 12.7777L1.97056 12.2094L2.75167 10.9249L3.33333 11.7255V11.0911L2.69056 10.0344L3.22 9.34883L3.33333 9.49995V8.3905L2.72556 9.22772L1.94444 7.94272L2.47389 7.25717L2.65611 7.49995L2.41278 7.83828L2.75167 8.33328ZM2.38056 9.46161V10.5327L1.60556 11.7566L0.875556 10.5277V9.46772L1.60556 8.23828L2.38056 9.46161Z"
          fill="#C8313E"
        />
        <path
          d="M1.12854 9.34626L1.46076 9.95237L1.12854 10.5585L1.37187 10.6918L1.61909 10.2413L1.86632 10.6918L2.10965 10.5585L1.77743 9.95237L2.10965 9.34626L1.86632 9.21292L1.61909 9.66348L1.37187 9.21292L1.12854 9.34626ZM0.0146484 14.549L0.361871 15.139L0.717982 14.5663L0.361871 13.9324L0.0146484 14.549ZM0.856871 14.549L1.20409 15.139L1.55965 14.5663L1.20409 13.9324L0.856871 14.549ZM1.68132 14.549L2.02854 15.139L2.38465 14.5663L2.02854 13.9324L1.68132 14.549ZM2.56576 14.549L2.91298 15.139L3.26854 14.5663L2.91298 13.9324L2.56576 14.549ZM0.0146484 5.62514L0.361871 6.2157L0.717982 5.64237L0.361871 5.00903L0.0146484 5.62514ZM0.856871 5.62514L1.20409 6.2157L1.55965 5.64237L1.20409 5.00903L0.856871 5.62514ZM1.68132 5.62514L2.02854 6.2157L2.38465 5.64237L2.02854 5.00903L1.68132 5.62514ZM2.56576 5.62514L2.91298 6.2157L3.26854 5.64237L2.91298 5.00903L2.56576 5.62514Z"
          fill="#C8313E"
        />
      </g>
      <defs>
        <clipPath id="clip0_9532_6016">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BelarusIcon;
