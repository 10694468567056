import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';

import ChevronDown from 'assets/icons/chevron-down';
import React, { useMemo, useState } from 'react';
import { MenuItemProps } from '../list';

const MobileSubMenu = React.memo(({ item }: { item: MenuItemProps }) => {
  const [subMenuActive, setSubMenuActive] = useState(false);

  const location = useLocation();

  const isSubMenuElActive = useMemo(() => {
    return item.menus?.map(el => el.url).includes(location.pathname);
  }, [item.menus, location.pathname]);

  const activeStyle = isSubMenuElActive ? 'nav-link_active' : 'nav-link';

  return (
    <ul key={item.id} className={clsx('flex flex-col gap-y-1', item.className)}>
      <li
        className={clsx('group cursor-pointer text-smena_text rounded-lg', activeStyle)}
        onClick={() => {
          setSubMenuActive(!subMenuActive);
        }}
      >
        <div className="flex justify-between items-center w-full">
          <div className="flex relative gap-x-4">
            {item.icon}
            <span>{item.name}</span>
          </div>
          <span className={clsx('transition-transform transform', subMenuActive ? 'rotate-180' : '')}>
            <ChevronDown className={clsx('flex group-hover:text-smena_white')} />
          </span>
        </div>
      </li>

      <ul className={clsx(subMenuActive ? 'show-subMenu' : 'subMenu')}>
        {item.menus?.map(el => (
          <li key={el.name} className="submenu-item relative">
            <NavLink
              to={el.url}
              className={({ isActive }) => {
                return clsx(isActive ? 'nav-link_active' : 'nav-link', 'ml-[10px] rounded-lg');
              }}
            >
              <span className={clsx('pointer-events-none ml-[15px]')}>{el.name}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </ul>
  );
});

export default MobileSubMenu;
