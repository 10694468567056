import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import Skeleton from 'antd/es/skeleton';

import { useStore } from 'store';
import Breadcrumbs from 'ui/breadcrumbs';
import Menu from './menu';
import CustomLink from './menu/customLink';
import { IMainLayout } from 'interfaces/IMainLayout';
import MobileMenuElements from './menu/elements/MobileMenuElements';
import NotificationBlock from 'components/notification';
import Profile from '../notification/profile';
import isNil from 'lodash/isNil';
import Error from '../error';
import BurgerButton from './BurgerButton';
import AutoSave from './autoSave';
import BlockScreen from './blockScreen';

const MainLayout = React.memo(
  ({
    children,
    title,
    headerMenu,
    bg = 'bg-smena_bb-background_base',
    breadCrumbs,
    loading,
    error,
    autoSave,
    className,
  }: IMainLayout) => {
    const [settingsError, setSettingsError] = useState(false);
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    const { showSidebarMenu } = useStore();

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        </Helmet>
        <div className="bg-gray flex flex-col min-h-screen">
          {settingsError ? (
            <div className="flex justify-center items-center w-full h-full">
              <BlockScreen />
            </div>
          ) : (
            <>
              <Menu />
              <div className={clsx(showSidebarMenu ? 'close_menu' : 'open_menu', 'relative flex-grow flex flex-col', bg)}>
                <div
                  className={clsx(
                    'bg-smena_white',
                    bg !== 'bg-smena_white' && headerMenu === undefined ? 'border-b border-smena_gray-30' : '',
                  )}
                >
                  <div className={clsx('flex flex-col md:gap-0 gap-y-3', headerMenu ? 'px-6 pt-6 pb-4' : 'p-6')}>
                    <div className="flex gap-x-5 items-center justify-between">
                      <div className="flex gap-x-6">
                        <BurgerButton showBurgerMenu={showBurgerMenu} setShowBurgerMenu={setShowBurgerMenu} />
                        <Breadcrumbs links={breadCrumbs} />
                      </div>
                      <div className="flex gap-x-5 items-center">
                        <NotificationBlock />
                        <Profile setSettingsError={setSettingsError} />
                      </div>
                    </div>

                    <div className="flex items-center gap-x-6">
                      <span className="H2">{loading ? <Skeleton.Input active /> : title ? title : 'Ошибка'}</span>
                      {autoSave ? <AutoSave status={autoSave} /> : null}
                    </div>
                  </div>
                </div>

                {headerMenu && (
                  <div className="bg-smena_white px-5 flex border-b border-smena_gray-30">
                    {headerMenu.map(el => (
                      <CustomLink key={el.link} to={el.disabled ? '/' : el.link} aria-disabled={el.disabled}>
                        <button disabled={el.disabled} className="Subtitle2 menu__link h-full">
                          {el.name}
                        </button>
                      </CustomLink>
                    ))}
                  </div>
                )}

                <div
                  className={clsx(
                    'w-full h-full z-30 flex flex-col p-5 md:px-0 gap-y-1 bg-smena_white ease-in-out duration-300 md:hidden',
                    showBurgerMenu ? 'relative top-0 left-0' : 'absolute top-[0px] -left-[9999px]',
                  )}
                >
                  <MobileMenuElements />
                </div>
                <div className={clsx('px-6 my-6 flex-grow flex flex-col', showBurgerMenu ? 'hidden' : '', className)}>
                  {!isNil(loading) && loading ? (
                    <Skeleton active className="col-span-full" />
                  ) : error ? (
                    <Error />
                  ) : (
                    children
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  },
);

export default MainLayout;
