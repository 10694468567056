import { useCallback, useMemo } from 'react';
import { useFacility } from 'utils/api/useFacility';
import { useSetEverythingIsPossibleBonusMutation } from '../../api/mutations/generated/SetEverythingIsPossibleBonus';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';

export const useFacilityAllIsPossible = () => {
  const { facility, id } = useFacility();
  const [setEverythingIsPossibleBonusHandler] = useSetEverythingIsPossibleBonusMutation({});
  const {
    everythingIsPossibleBonus: bonus,
    possibleBonusViewSum: viewSum,
    possibleBonusPaymentSum: paymentSum,
    possibleBonusShiftCount: shiftCount,
  } = facility || {};

  const initialValues = useMemo(
    () => ({
      everythingIsPossibleBonus: bonus || false,
      possibleBonusViewSum: viewSum || '',
      possibleBonusPaymentSum: paymentSum || '',
      possibleBonusShiftCount: shiftCount || '',
    }),
    [bonus, paymentSum, shiftCount, viewSum]
  );

  const validate = (values: typeof initialValues) => {
    const error: any = {};
    if (!values.possibleBonusShiftCount) {
      error.possibleBonusShiftCount = 'Обязательное поле';
    }
    if (!values.possibleBonusPaymentSum) {
      error.possibleBonusPaymentSum = 'Обязательное поле';
    }
    if (!values.possibleBonusViewSum) {
      error.possibleBonusViewSum = 'Обязательное поле';
    }
    return error;
  };

  const promotionSwitchOffHandler = useCallback(() => {
    if (!id) return;
    setEverythingIsPossibleBonusHandler({
      variables: {
        input: {
          facilityId: id,
          everythingIsPossibleBonus: false,
        },
      },
    })
      .then(() => successToast('Акция выключена'))
      .catch(error => errorToast(errorHandler(error)));
  }, [id, setEverythingIsPossibleBonusHandler]);

  const onSubmit = (values: typeof initialValues) => {
    if (!id) return;
    setEverythingIsPossibleBonusHandler({
      variables: {
        input: {
          facilityId: id,
          everythingIsPossibleBonus: values.everythingIsPossibleBonus,
          possibleBonusPaymentSum: Number(values.possibleBonusPaymentSum),
          possibleBonusShiftCount: Number(values.possibleBonusShiftCount),
          possibleBonusViewSum: Number(values.possibleBonusViewSum),
        },
      },
    })
      .then(() => successToast('Акция включена'))
      .catch(error => errorToast(errorHandler(error)));
  };

  return { initialValues, validate, onSubmit, promotionSwitchOffHandler };
};
