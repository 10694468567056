import { StatisticPeriod } from 'generated/graphql';

export const getTimePeriodCharts = (period: StatisticPeriod) => {
  const periodsRu = [
    { name: 'последний час', value: StatisticPeriod.Day },
    { name: 'последний день', value: StatisticPeriod.Week },
    { name: 'последнюю неделю', value: StatisticPeriod.Month },
    { name: 'последний месяц', value: StatisticPeriod.HalfYear },
    { name: 'последние полгода', value: StatisticPeriod.Year },
    { name: 'последний год', value: StatisticPeriod.AllTime },
  ];
  return periodsRu.find(periodRu => periodRu.value === period)?.name;
};
