const HomeIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 20.25V3.75H20.25V20.25H3.75ZM3.6 2.25C3.24196 2.25 2.89858 2.39223 2.64541 2.64541C2.39223 2.89858 2.25 3.24196 2.25 3.6V20.4C2.25 20.758 2.39223 21.1014 2.64541 21.3546C2.89858 21.6078 3.24196 21.75 3.6 21.75H20.4C20.758 21.75 21.1014 21.6078 21.3546 21.3546C21.6078 21.1014 21.75 20.758 21.75 20.4V3.6C21.75 3.24196 21.6078 2.89858 21.3546 2.64541C21.1014 2.39223 20.758 2.25 20.4 2.25H3.6ZM16.75 8C16.75 7.58579 16.4142 7.25 16 7.25C15.5858 7.25 15.25 7.58579 15.25 8V16C15.25 16.4142 15.5858 16.75 16 16.75C16.4142 16.75 16.75 16.4142 16.75 16V8ZM12.75 11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V16C11.25 16.4142 11.5858 16.75 12 16.75C12.4142 16.75 12.75 16.4142 12.75 16V11ZM8.75 13C8.75 12.5858 8.41421 12.25 8 12.25C7.58579 12.25 7.25 12.5858 7.25 13V16C7.25 16.4142 7.58579 16.75 8 16.75C8.41421 16.75 8.75 16.4142 8.75 16V13Z"
      />
    </svg>
  );
};

export default HomeIcon;
