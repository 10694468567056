const UsersIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0444 3.67606C13.7945 4.53538 14.2498 5.66036 14.2498 6.89157C14.2498 7.74849 14.0292 8.55395 13.6422 9.25421C13.4783 9.56149 13.2829 9.84941 13.0602 10.1136C13.4675 10.2809 13.9137 10.3731 14.3821 10.3731C16.3049 10.3731 17.8637 8.81438 17.8637 6.89157C17.8637 4.96875 16.3049 3.41 14.3821 3.41C13.9076 3.41 13.456 3.50461 13.0444 3.67606ZM11.9112 11.114C11.1507 11.5929 10.2502 11.87 9.28502 11.87C6.55949 11.87 4.35 9.66055 4.35 6.93501C4.35 4.20948 6.55949 2 9.28502 2C10.2201 2 11.0945 2.26009 11.8398 2.71188C12.5808 2.26031 13.4518 2 14.3821 2C17.0836 2 19.2737 4.19003 19.2737 6.89157C19.2737 9.59311 17.0836 11.7831 14.3821 11.7831C13.4815 11.7831 12.6366 11.5392 11.9112 11.114ZM10.9875 10.0224C10.4828 10.3013 9.90247 10.46 9.28502 10.46C7.33821 10.46 5.76001 8.88182 5.76001 6.93501C5.76001 4.98821 7.33821 3.41 9.28502 3.41C11.2318 3.41 12.81 4.98821 12.81 6.93501C12.81 7.53508 12.6601 8.10012 12.3956 8.59472C12.1577 9.01806 11.8337 9.38681 11.4468 9.67743L10.9875 10.0224ZM3.41 19.3832C3.41 16.2727 5.93442 13.7498 9.05002 13.7498C12.1656 13.7498 14.69 16.2727 14.69 19.3832C14.69 19.4036 14.6899 19.424 14.6897 19.4444C14.6869 19.5843 14.6789 19.7229 14.6659 19.8599H3.42987C3.41672 19.7028 3.41 19.5438 3.41 19.3832ZM15.0921 21.2699H3.00987C2.56327 21.2699 2.15887 20.95 2.08544 20.4823C2.02915 20.1237 2 19.7566 2 19.3832C2 15.4925 5.15711 12.3398 9.05002 12.3398C9.92546 12.3398 10.7637 12.4993 11.5372 12.7907C12.3071 12.4992 13.1414 12.3398 14.0114 12.3398C17.871 12.3398 20.9999 15.4687 20.9999 19.3283C20.9999 19.6981 20.9711 20.0617 20.9155 20.4168C20.8422 20.8849 20.4416 21.2699 19.995 21.2699H15.8693H15.0921ZM16.0882 19.7948H19.5707C19.5834 19.6411 19.5899 19.4855 19.5899 19.3283C19.5899 16.2474 17.0923 13.7498 14.0114 13.7498C13.7944 13.7498 13.5804 13.7622 13.37 13.7863C15.0301 15.0635 16.1009 17.0703 16.1009 19.3283C16.1009 19.3744 16.1005 19.4204 16.0996 19.4662C16.0983 19.5763 16.0945 19.6859 16.0882 19.7948Z"
      />
    </svg>
  );
};

export default UsersIcon;
