import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AcrossSmall from 'assets/icons/AcrossSmall';
import { morph } from 'utils/helpers/morph';
import StopCooperationPopup from '../StopCooperationPopup';
import StopCooperationSidebar from '../StopCooperationSidebar';
import { useMeRole } from 'store/hooks/useMeRole';
import { ClientUserFragment } from '../../../api/fragments/generated/ClientUser.fragment';
import dayjs from 'dayjs';
import { useStore } from 'store';
import clsx from 'clsx';
import { Role } from 'generated/graphql';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';

interface Props {
  showAll: boolean;
  setShowAll: React.Dispatch<React.SetStateAction<boolean>>;
  canUnassign: boolean;
  userFacilities: {
    id: string;
    name: string;
  }[];
  facility: {
    id: string;
    name: string;
    facilityGroupId: string;
  };
  index: number;
  user?: ClientUserFragment | UsersPaginatedFragment | null;
  isCollaborate: boolean;
}

const FacilityBubble = ({
  facility,
  index,
  user,
  userFacilities,
  canUnassign,
  isCollaborate,
  showAll,
  setShowAll,
}: Props) => {
  const { setStopCooperationComment, setStopCooperationDate } = useStore();
  const isMeAdmin = useMeRole([Role.Admin]);
  const showAllHandle = () => {
    setShowAll(value => !value);
  };
  const [showStopCooperationSidebar, setShowStopCooperationSidebar] = useState(false);
  const [showStopCooperationPopup, setShowStopCooperationPopup] = useState(false);
  const onShowStopCooperationSidebarHandler = () => {
    setStopCooperationDate(dayjs());
    setStopCooperationComment('');
    setShowStopCooperationSidebar(value => !value);
  };
  return (
    <>
      <div
        key={facility.id}
        className={clsx(
          isCollaborate ? 'smena-bubble' : 'smena-bubble_disabled',
          '[&:has(button:hover)]:bg-smena_red-extra_light max-w-fit',
        )}
      >
        {isMeAdmin ? (
          <Link to={`/facilities/${facility.id}`} title={facility?.name}>
            {facility?.name}
          </Link>
        ) : (
          <span>{facility?.name}</span>
        )}
        {canUnassign && isCollaborate && (
          <button className={'hover:text-smena_red'} onClick={onShowStopCooperationSidebarHandler}>
            <AcrossSmall />
          </button>
        )}
      </div>
      {index === 1 && userFacilities.length > 2
        ? !showAll && (
            <button className={'Table-small text-left text-primary'} type={'button'} onClick={showAllHandle}>
              {`+${userFacilities.length - 2} ${morph(userFacilities.length - 2, ['объект', 'объекта', 'объектов'])}`}
            </button>
          )
        : showAll &&
          userFacilities.length - 1 === index && (
            <button className={'Table-small text-left text-primary'} type={'button'} onClick={showAllHandle}>
              Свернуть
            </button>
          )}
      <StopCooperationPopup
        user={user}
        facility={facility}
        showStopCooperationPopup={showStopCooperationPopup}
        setShowStopCooperationPopup={setShowStopCooperationPopup}
      />
      <StopCooperationSidebar
        user={user}
        facility={facility}
        showStopCooperationSidebar={showStopCooperationSidebar}
        setShowStopCooperationPopup={setShowStopCooperationPopup}
        setShowStopCooperationSidebar={setShowStopCooperationSidebar}
      />
    </>
  );
};

export default FacilityBubble;
