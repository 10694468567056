import { useMemo } from 'react';
import { usePush } from '../../../../utils/api/usePush';

export const usePushBreadcrumbs = () => {
  const { push, id } = usePush();
  return useMemo(
    () => [
      { path: '/pushes', name: 'Пуш-уведомления' },
      { path: `/pushes/${id}`, name: `${push?.name}` },
    ],
    [id, push?.name]
  );
};
