import { useMemo } from 'react';
import { useStore } from 'store';
import { goodStages } from 'utils/helpers/lists/goodStages';
import { useMeRole } from 'store/hooks/useMeRole';
import { UserShiftsFragment } from 'pages/user/api/fragments/generated/UserShifts.fragment';
import { useUserShifts } from '../../../pages/userTracking/lib/hooks/useUserShifts';
import useSupervisorShifts from 'pages/userTracking/lib/hooks/useSupervisorShifts';
import { Role } from 'generated/graphql';

const filterNotProbation = (shift: UserShiftsFragment) => !shift.isProbation;

const useAcceptedShifts = () => {
  const { userShifts } = useUserShifts();
  const { facilityId, workpostId } = useStore();
  const supervisorShifts = useSupervisorShifts();
  const superShiftsNotProbation = supervisorShifts?.filter(filterNotProbation);
  const isMeSupervisor = useMeRole([Role.Supervisor]);

  return useMemo(() => {
    const shifts = (isMeSupervisor ? superShiftsNotProbation : userShifts) || [];
    return shifts.filter(shift => {
      const isStageFits = goodStages.includes(shift.stage);
      const isPositionFits = workpostId ? shift.positionId === workpostId : true;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId === facilityId : true;

      return isStageFits && isPositionFits && isFacilityFits;
    });
  }, [facilityId, isMeSupervisor, superShiftsNotProbation, userShifts, workpostId]);
};

export default useAcceptedShifts;
