import React, { useEffect, useMemo, useState } from 'react';
import SimpleSelect from 'ui/select';
import { getUserFIO } from 'utils/helpers/userFullName';
import { useGetUsersWhoRespondedToTheRequestShiftLazyQuery } from '../../../api/queries/generated/GetUsersWhoRespondedToTheRequestShift';
import { useStore } from 'store';
import { undefStr } from 'interfaces/CustomTypes';
import { useAcceptRequestShiftsSuggestionsMutation } from '../../../api/mutations/generated/AcceptRequestShiftsSuggestions';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useDeclineRequestShiftsSuggestionsMutation } from '../../../api/mutations/generated/DeclineRequestShiftsSuggestions';

const FreeShiftForUser = () => {
  const [selectedUser, setSelectedUser] = useState<undefStr>(undefined);
  const [loadQuery, { data: getUsersWhoResponded }] = useGetUsersWhoRespondedToTheRequestShiftLazyQuery();
  const setShowShiftSidebar = useStore(state => state.setShowShiftSidebar);
  const requestShift = useStore(state => state.requestShift);
  const freeShiftUsers = useStore(state => state.freeShiftUsers);
  const changeFreeShiftUser = useStore(state => state.changeFreeShiftUser);
  const setFreeShiftUsers = useStore(state => state.setFreeShiftUsers);
  const [declineRequestShiftsSuggestions] = useDeclineRequestShiftsSuggestionsMutation();
  const [acceptRequestShiftsSuggestions] = useAcceptRequestShiftsSuggestionsMutation();
  const userOptions = useMemo(() => {
    return getUsersWhoResponded?.getUsersWhoRespondedToTheRequestShift.requestShiftSuggestions.map(el => ({
      id: el.id,
      name: getUserFIO(el.user),
    }));
  }, [getUsersWhoResponded?.getUsersWhoRespondedToTheRequestShift.requestShiftSuggestions]);
  useEffect(() => {
    if (!requestShift) return;
    loadQuery({
      variables: {
        input: {
          requestShiftId: requestShift.id,
        },
      },
    });
  }, [loadQuery, requestShift]);

  useEffect(() => {
    if (userOptions && userOptions.length === 1) {
      setSelectedUser(userOptions[0].id);
      setFreeShiftUsers(userOptions[0].id);
    }
    return () => {
      setFreeShiftUsers([]);
    };
  }, [setFreeShiftUsers, userOptions]);
  const approveUser = () => {
    if (!requestShift || !freeShiftUsers.length) return;
    acceptRequestShiftsSuggestions({
      variables: {
        input: {
          requestScheduleId: requestShift?.zayavkaScheduleId,
          acceptedSuggestionIds: freeShiftUsers,
        },
      },
    })
      .then(() => {
        successToast('Пользователь подтвержден');
      })
      .catch(err => {
        errorToast(errorHandler(err));
      })
      .finally(() => {
        setShowShiftSidebar(false);
      });
  };
  const declineShift = () => {
    if (!requestShift || !freeShiftUsers.length) return;
    declineRequestShiftsSuggestions({
      variables: {
        input: {
          requestScheduleId: requestShift?.zayavkaScheduleId,
          declinedSuggestionIds: freeShiftUsers,
        },
      },
    })
      .then(() => {
        successToast('Пользователь отклонен');
      })
      .catch(err => {
        errorToast(errorHandler(err));
      })
      .finally(() => {
        setShowShiftSidebar(false);
      });
  };
  return (
    <>
      <SimpleSelect
        label={'Исполнитель'}
        placeholder={'Выберите исполнителя'}
        value={selectedUser}
        options={userOptions}
        onChange={value => {
          setSelectedUser(value);
          changeFreeShiftUser(value);
        }}
      />
      <button className={'btn-primary self-end'} onClick={approveUser} disabled={!selectedUser}>
        Подтвердить исполнителя
      </button>
      <button className={'btn-reject self-end'} onClick={declineShift} disabled={!selectedUser}>
        Отклонить
      </button>
    </>
  );
};

export default FreeShiftForUser;
