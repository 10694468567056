import useMenuElement from 'utils/hooks/useMenuElements';

import clsx from 'clsx';
import SubMenu from '../submenu';
import MenuElement from '../element';
import { bottomElements } from '../list';
import ExternalLink from 'components/layout/menu/externalLink';
import { useStore } from 'store';
import React from 'react';

interface Props {
  scrollTop: number;
}

const MenuElements = React.memo(({ scrollTop }: Props) => {
  const menuElements = useMenuElement();
  const { showSidebarMenu } = useStore();

  return (
    <div className={'flex flex-col justify-between h-full'}>
      <div
        className={clsx(
          showSidebarMenu ? 'items-normal' : 'items-center',
          'flex flex-col px-5 md:px-0 gap-y-2 bg-smena_white',
        )}
      >
        {menuElements.map(item => {
          if (item.menus?.length) {
            return <SubMenu key={item.id} item={item} scrollTop={scrollTop} />;
          }
          return <MenuElement key={item.id} item={item} />;
        })}
      </div>
      <div
        className={clsx(
          'flex flex-col px-5 md:px-0 gap-y-1 bg-smena_white',
          showSidebarMenu ? 'items-normal' : 'items-center',
        )}
      >
        {bottomElements.map(el => (
          <ExternalLink item={el} key={el.id} target={'_blank'} />
        ))}
      </div>
    </div>
  );
});

export default MenuElements;
