import dayjs from 'dayjs';

import clsx from 'clsx';
import { getDistance } from 'utils/helpers/get/getDistance';
import { durationHoursAndMinutes } from 'utils/helpers/durationHoursAndMinutes';
import { showTime } from 'utils/helpers/showTime';
import { ICustomEvent } from 'interfaces/IEvent';
import { shiftsClassName } from 'utils/helpers/shiftsClassName';
import { TIME_DATE } from 'utils/helpers/constVariables';
import { usePositions } from 'utils/api/usePositions';
import { useMarks } from '../../../../moderationReport/lib/hooks';
import { MarkType } from '../../../../../generated/graphql';

const CustomEvent = ({ event }: { event: ICustomEvent }) => {
  const { positions } = usePositions();
  const {
    resource: { id, facility, marks, dateFrom, dateEnd, positionId, lunchDuration },
  } = event;

  const { marksType: checkins } = useMarks({ marks, type: MarkType.Checkin });
  const { marksType: checkouts } = useMarks({ marks, type: MarkType.Checkout });
  return (
    <div className="flex flex-col mb-10 p-4 border rounded-lg shadow-smena bg-smena_white gap-y-2">
      <div className="flex items-center Caption-numbers text-smena_text gap-x-1">
        <span>{facility?.name};</span>
        <span>{positions?.find(position => position.id === positionId)?.name}</span>
      </div>
      <div className="flex flex-col">
        <div className="flex gap-x-[5px]">
          <div
            className={clsx(
              'flex justify-center items-center rounded-md w-[80px] h-[17px]',
              shiftsClassName(event.resource).className,
            )}
          >
            {marks && marks.length ? (
              <span className="Tag text-smena_gray-80">
                {checkins?.length ? `${dayjs(checkins[0].time).format(TIME_DATE)} – ` : null}
                {checkouts?.length ? dayjs(checkouts[checkouts.length - 1].time).format(TIME_DATE) : <span>—:—</span>}
              </span>
            ) : (
              <span className="Tag text-smena_gray-80">—:—</span>
            )}
          </div>
          <span className="Tag text-smena_text-secondary">по факту</span>
        </div>
        <div className="flex">
          <div className="flex items-center Tag text-smena_text-secondary gap-x-[5px]">
            <span className="flex justify-center items-center w-[80px]">
              {dayjs(dateFrom).format(TIME_DATE) + ' – '}
              {dateEnd ? dayjs(dateEnd).format(TIME_DATE) : '—:—'}
            </span>
            <span>по графику</span>
          </div>
        </div>
      </div>

      {marks && !!marks.length && (
        <div>
          {marks.map((mark: any) => {
            return (
              <div key={mark.time} className="grid gap-x-[10px] grid-cols-[86px_144px]">
                <div className="col-span-1 grid grid-cols-[55px_31px]">
                  <span className="Caption text-smena_text">
                    {mark.type === MarkType.Checkout ? 'Чекаут: ' : 'Чекин:'}
                  </span>
                  <span className={clsx('Caption-numbers', mark.statusMessage ? 'text-smena_red' : 'text-smena_text')}>
                    {dayjs(mark.time).format(TIME_DATE)}
                  </span>
                </div>
                <div className="col-span-1 grid gap-x-[5px] grid-cols-[77px_max-content]">
                  <span className="Caption text-smena_text">Расстояние:</span>
                  <span className={clsx('Caption-numbers', mark.statusMessage ? 'text-smena_red' : 'text-smena_text')}>
                    {getDistance(mark.distance)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="items-center mt-2 Tag text-smena_text-helper gap-x-1">
        <span>Обед: </span>
        <span>{lunchDuration ? showTime(durationHoursAndMinutes(lunchDuration)) : 'нет'}</span>
      </div>
      <div className="flex items-center Tag text-smena_text-helper gap-x-1">
        <span>id смены:</span>
        <span>{id}</span>
      </div>
      <div className="flex items-center Tag text-smena_text-helper gap-x-1">
        <span>Координаты объекта: </span>
        <span>{`${facility?.latitude}; ${facility?.longitude}`}</span>
      </div>
    </div>
  );
};

export default CustomEvent;
