import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { DaDataAddress, DaDataSuggestion } from 'react-dadata';
import { useCity } from '../../utils/api/useCity';
import { useCreateCityMutation } from './api/mutations/generated/CreateCity';
import { useUpdateCityMutation } from './api/mutations/generated/UpdateCity';
import { useCityMap } from './store';
import { createCityCache } from './api/updateCache';
import { useRemoveCityMutation } from './api/mutations/generated/RemoveCity';

export type useCityPageProps = {
  name?: string;
  value?: DaDataSuggestion<DaDataAddress>;
  showCoordinates: boolean;
};

export const useCityPage = () => {
  const navigate = useNavigate();
  const { id, city, loading: loadingPosition } = useCity();
  const lat = useCityMap(state => state.lat);
  const lon = useCityMap(state => state.lon);
  const setLat = useCityMap(state => state.setLat);
  const setLon = useCityMap(state => state.setLon);

  const title = city?.name ? `Город ${city.name}` : 'Новый город';

  const [createCity, { loading: createCityLoading }] = useCreateCityMutation({
    update: (cache, { data }) => {
      createCityCache({ cache, data: data?.createCity });
    },
  });
  const [updateCity, { loading: updateCityLoading }] = useUpdateCityMutation();
  const [removeCity, { loading: removeCityLoading }] = useRemoveCityMutation();

  const loading = useMemo(() => createCityLoading || updateCityLoading, [createCityLoading, updateCityLoading]);

  const breadcrumbs = useMemo(
    () => [
      { path: '/cities', name: 'Справочник городов' },
      id ? { path: '/cities' + id, name: `Город ${city?.name}` } : { path: '/cities/create', name: 'Новый город' },
    ],
    [city?.name, id],
  );

  const initialValues: useCityPageProps = {
    name: city?.name,
    value: undefined,
    showCoordinates: false,
  };

  const validate = (values: useCityPageProps) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Обязательное поле';
    }
    if (!lat || !lon) {
      errors.showCoordinates = 'Координаты - обязательны';
    }

    return errors;
  };

  const onDelete = () => {
    if (!id) return;
    removeCity({
      variables: { id: id },
      update: cache => {
        cache.evict({ id: `City:${id}` });
      },
    })
      .then(() => {
        successToast('Город удален');
        navigate('/cities');
      })
      .catch(err => errorToast(errorHandler(err)));
  };

  const onSubmit = useCallback(
    (values: useCityPageProps) => {
      const variablesPosition = {
        name: values.name!.trim(),
        latitude: String(lat),
        longitude: String(lon),
      };
      if (id) {
        updateCity({
          variables: {
            input: {
              id: id,
              ...variablesPosition,
            },
          },
        })
          .then(response => {
            if (response.data?.updateCity) {
              successToast('Город обновлен');
            }
          })
          .catch(e => errorToast(errorHandler(e)));
      } else {
        createCity({
          variables: {
            input: variablesPosition,
          },
        })
          .then(response => {
            const cityRes = response.data?.createCity;
            if (cityRes) {
              successToast('Город создан');
              navigate(`/cities/${cityRes.id}`);
            }
          })
          .catch(e => {
            errorToast(errorHandler(e));
          });
      }
    },
    [lat, lon, id, updateCity, createCity, navigate],
  );
  useEffect(() => {
    if (city?.latitude) {
      setLat(Number(city?.latitude));
    }
    if (city?.longitude) {
      setLon(Number(city?.longitude));
    }
  }, [city?.latitude, city?.longitude, setLat, setLon]);
  return {
    title,
    initialValues,
    validate,
    onSubmit,
    loading,
    breadcrumbs,
    id,
    loadingPosition,
    onDelete,
    removeCityLoading,
  };
};
