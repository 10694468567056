import React, { useCallback, useState } from 'react';
import Up from 'assets/icons/sort/up';
import Down from 'assets/icons/sort/down';
import { IColumns } from 'interfaces/IColumns';
import { useSearchParams } from 'react-router-dom';
import { SortingWay } from 'generated/graphql';
import clsx from 'clsx';

interface Props {
  column: IColumns;
  defaultSortBy?: string;
}

const Column = ({ column, defaultSortBy = 'updatedAt' }: Props) => {
  const [clicked, setClicked] = useState('');
  const [params, setParams] = useSearchParams();
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const desc = sortWay === SortingWay.Desc;
  const asc = sortWay === SortingWay.Asc;

  const color = useCallback(
    (icon: string, column: { name: string }) => {
      const thisColumn = sortBy === column.name;
      if (!thisColumn) {
        return 'text-smena_text-disabled';
      }
      if (desc) {
        if (icon === 'up') {
          return 'text-smena_text-disabled';
        }
        return 'text-smena_text-helper';
      }
      if (asc) {
        if (icon === 'up') {
          return 'text-smena_text-helper';
        }
        return 'text-smena_text-disabled';
      }
      return 'text-smena_text-disabled';
    },
    [asc, desc, sortBy],
  );
  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    if (column.sorted) {
      setClicked(column.name);
      params.set('sortBy', (e.target as Element).id);
      setParams(params);
      if (clicked !== (e.target as Element).id) {
        params.set('sortWay', 'desc');
        setParams(params);
      } else {
        if (sortWay === null) {
          params.set('sortWay', SortingWay.Desc);
          setParams(params);
        }
        if (desc) {
          params.set('sortWay', SortingWay.Asc);
          setParams(params);
        }
        if (asc) {
          params.set('sortBy', defaultSortBy);
          params.delete('sortWay');
          setParams(params);
        }
      }
    }
  };
  return (
    <div
      className={clsx(column.header_className, column.sorted ? 'cursor-pointer' : '', 'flex gap-x-2')}
      id={column.name}
      onClick={onClickHandler}
    >
      {typeof column.Header === 'string' ? <span className="pointer-events-none">{column.Header}</span> : column.Header}
      {column.sorted && (
        <span className="pointer-events-none flex flex-col gap-y-1">
          <Up className={color('up', column)} />
          <Down className={color('down', column)} />
        </span>
      )}
    </div>
  );
};

export default Column;
