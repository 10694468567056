import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import image from 'assets/images/notFound.png';

const NotFound = () => {
  const router = useParams();
  const navigate = useNavigate();

  const userId = router.userId;

  const onClick = useCallback(() => {
    navigate(`/users/${userId}`);
  }, [userId, navigate]);

  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="flex flex-col w-[450px] h-[300px]">
        <div className="flex justify-center mb-16">
          <img src={image} alt="" className="object-contain" />
        </div>
        <div className="flex flex-col gap-y-4 items-center">
          <span className="Subtitle1 text-smena_text-secondary">График пока не назначен</span>
          <span className="Body2 text-smena_text-secondary text-center">
            Чтобы у исполнителя появился график, необходимо во вкладке информация присвоить ему группу, объект и
            должность
          </span>
          <button className="btn-secondary_small" onClick={onClick}>
            Редактировать информацию
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
