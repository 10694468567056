import { Formik, Form } from 'formik';
import { useUpdateShiftPayment } from './hooks';
import { useStore } from 'store';
import { getFinalBonus } from 'pages/moderationReport/components/sidebar/dailyShifts/helper';
import clsx from 'clsx';
import { RU_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';
import Loader from 'ui/loader';

const UpdateShiftPayment = () => {
  const { shift } = useStore() as any;
  const { initialValues, onSubmit, validate, loading } = useUpdateShiftPayment();

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit} enableReinitialize>
      {({ values, setFieldValue }) => (
        <Form className={'flex flex-col'}>
          <p className={'Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30'}>Оплата</p>

          <div className="Subtitle2 px-4 py-2">
            К оплате:{' '}
            <span className="Body2 text-smena_text-secondary pr-4">{shift?.cost + getFinalBonus(shift)} ₽</span>
          </div>

          <div className="grid grid-cols-2 gap-y-1 p-4 border-y border-smena_gray-30">
            <label className={clsx('rounded-l-lg radio-block', { 'radio-block_active': values.toPayment })}>
              <input
                type="radio"
                name="toPayment"
                className="radio radio_approve"
                checked={values.toPayment}
                onChange={() => setFieldValue('toPayment', true)}
                disabled={loading}
              />
              К оплате
            </label>
            <label className={clsx('radio-block rounded-r-lg', { 'radio-block_active': !values.toPayment })}>
              <input
                type="radio"
                name="toPayment"
                className="radio radio_reject"
                checked={!values.toPayment}
                onChange={() => setFieldValue('toPayment', false)}
                disabled={loading}
              />
              Без оплаты
            </label>
          </div>

          <div className="flex justify-between items-center px-4 py-4">
            <div className="Caption text-smena_text-secondary flex gap-x-1">
              <span>Посл. изменения:</span>
              <span>{dayjs(shift?.updatedAt).format(RU_DATE)}</span>
            </div>
            <button type="submit" className="btn-primary_small" disabled={loading}>
              {loading ? (
                <div className="flex items-center gap-x-2 text-smena_gray-30">
                  <Loader />
                  <span>Сохранение</span>
                </div>
              ) : (
                'Сохранить'
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateShiftPayment;
