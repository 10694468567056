import clsx from 'clsx';
import {
  PaginationModerationReportFragment
} from 'pages/moderationReports/api/fragments/generated/PaginationModerationReport.fragment';
import React from 'react';

const Checkbox = ({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean; original?: PaginationModerationReportFragment } & React.HTMLProps<HTMLInputElement>) => {
  const ref = React.useRef<HTMLInputElement>(null!);

  const notModeratedMoreThanO = Number(rest.original?.noModerated) > 0;
  const workersCountEqualO = Number(rest.original?.workersCount) === 0;
  const shiftCountEqualO = Number(rest.original?.shiftCount) === 0;

  const disableCheckbox = notModeratedMoreThanO || workersCountEqualO || shiftCountEqualO;

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      disabled={disableCheckbox}
      className={clsx(className, 'checkbox-primary cursor-pointer')}
      {...rest}
    />
  );
};

export default Checkbox;
