import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

export const useCustomSearchParams = () => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useSearchParams();
  const searchParam = params.get('search');

  const removeParam = useCallback(
    (param: string) => {
      if (params.has(param)) {
        params.delete(param);
      }
    },
    [params],
  );

  const resetPageParam = useCallback(() => {
    params.set('page', '1');
    setParams(params);
  }, [params]);

  const addParams = useCallback(
    (paramName: string, param: string) => {
      params.set(paramName, param);
    },
    [params],
  );

  const handleParams = useCallback(
    (paramName: string, param?: string | null) => {
      if (param) {
        params.set(paramName, param);
      } else {
        removeParam(paramName);
      }
    },
    [addParams, removeParam],
  );

  const useParamsCount = useCallback(
    (paramsArr?: string[]) => {
      let paramsCount = 0;
      if (paramsArr) {
        paramsArr.forEach(el => {
          if (params.has(el)) {
            paramsCount++;
          }
        });
      } else {
        params.forEach((_, key) => {
          if (!['page', 'sortWay', 'sortBy', 'search', 'size'].includes(key)) {
            paramsCount++;
          }
        });
      }
      return paramsCount;
    },
    [params],
  );

  const resetFilter = useCallback(
    (paramName: string) => {
      params.delete(paramName);
      params.delete('page');
      setParams(params);
    },
    [params],
  );

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };
  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (search !== '') {
      params.set('search', search);
      setParams(params);
    } else {
      params.delete('search');
      setParams(params);
    }
    resetPageParam();
  };

  useEffect(() => {
    if (searchParam) setSearch(searchParam);
  }, [searchParam]);

  return {
    handleParams,
    addParams,
    params,
    setParams,
    useParamsCount,
    handleChangeSearch,
    resetFilter,
    search,
    handleSearch,
  };
};
