import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { setTokens } from 'app/config/auth';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { filterLogins, getLogins, IFastLogin } from '../lib/utils';
import { useLoginMutation } from '../api/mutations/generated/Login';
import { useLoginByCodeMutation } from '../api/mutations/generated/LoginByCode';
import { errorToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import Across from 'assets/icons/Across';
import { REDIRECT_PAGE } from 'utils/helpers/constVariables';
import { useMeShortlyLazyQuery } from 'pages/main/api/queries/generated/MeShortly';

interface Props {
  entry: IFastLogin;
  setLogins: React.Dispatch<React.SetStateAction<IFastLogin[]>>;
}

export function FastLoginItem({ entry, setLogins }: Props) {
  const [loginByPassMutation] = useLoginMutation();
  const [loginByCodeMutation] = useLoginByCodeMutation();
  const [load] = useMeShortlyLazyQuery();
  const navigate = useNavigate();
  const pageFrom = localStorage.getItem(REDIRECT_PAGE);
  const removeItem = useCallback(() => {
    const newLogins = getLogins().filter(login => login.phone !== entry.phone);
    filterLogins(newLogins);
    setLogins(newLogins);
  }, [entry.phone, setLogins]);

  const onItemClick = useCallback(async () => {
    const { phone, code, password } = entry;
    if (password) {
      try {
        const res = await loginByPassMutation({ variables: { data: { password, phone } } });
        const data = res.data?.login;
        if (data) {
          localStorage.setItem('me', JSON.stringify(data.user));
          setTokens({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          });
          if (pageFrom) {
            navigate(pageFrom);
            localStorage.removeItem(REDIRECT_PAGE);
          } else {
            navigate('/');
          }
        }
      } catch (error: any) {
        errorToast(errorHandler(error));
      }
    }
    if (code) {
      try {
        const res = await loginByCodeMutation({ variables: { input: { code, phone } } });
        const data = res.data?.loginByCode;
        if (data) {
          setTokens({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          });
          load().then(meRes => {
            if (meRes.data) {
              const user = meRes.data.meShortly;
              localStorage.setItem('me', JSON.stringify(user));
            }
            if (pageFrom) {
              navigate(pageFrom);
              localStorage.removeItem(REDIRECT_PAGE);
            } else {
              navigate('/');
            }
          });
        }
      } catch (error: any) {
        errorToast(errorHandler(error));
      }
    }
  }, [entry, loginByPassMutation, pageFrom, navigate, loginByCodeMutation, load]);

  return (
    <div className={'hover:bg-gray-100 -mx-5 px-5  flex justify-between'}>
      <div onClick={onItemClick} className={'cursor-pointer'}>
        <div>{convertPhoneNumberToNormal(entry.phone)}</div>
        <div>{entry.name}</div>
        <div>{getRoleRu(entry.role)}</div>
      </div>
      <button onClick={removeItem}>
        <Across />
      </button>
    </div>
  );
}
