import React from 'react';

import MainLayout from 'components/layout/main';
import ScheduleCalendar from 'pages/userSchedule/components';
import { useUserHeaderMenu } from 'utils/hooks/headerMenu/useUserHeaderMenu';
import useUserBreadcrumbs from 'utils/hooks/breadcrumbs/useUserBreadcrumbs';
import { useMeRole } from 'store/hooks/useMeRole';
import NotFound from './components/notFound';
import { useCurrentUser } from 'utils/api/useUser';
import { useUserTitle } from '../user/lib/hooks';
import { Role } from 'generated/graphql';

const SchedulePage = () => {
  const { user, loading } = useCurrentUser();
  const title = useUserTitle();
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);
  const headerMenu = useUserHeaderMenu();
  const breadCrumbs = useUserBreadcrumbs();

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs} loading={loading}>
      {!user?.workposts?.length ? isMeAdminOrSupervisor && <NotFound /> : <ScheduleCalendar />}
    </MainLayout>
  );
};

export default SchedulePage;
