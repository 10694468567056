import {
  GetRequestDocument,
  GetRequestQueryVariables,
} from 'pages/facilityUpdateRequest/api/queries/generated/GetRequest';

interface IZayavkaConfig {
  query: typeof GetRequestDocument;
  variables: GetRequestQueryVariables;
}

export const zayavkaConfigFn = (id: string): IZayavkaConfig => ({
  query: GetRequestDocument,
  variables: {
    id,
  },
});
