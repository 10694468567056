import React, { useMemo } from 'react';

import { percentColor, progressPercent } from 'utils/helpers/progressPercentHelper';
import ProgressComponent from 'ui/progress';
import { RequestPlanFragment } from 'pages/facilityRequests/api/fragments/generated/RequestPlan.fragment';
import SunIcon from 'assets/icons/SunIcon';
import { ShiftTime } from 'generated/graphql';
import MoonIcon from 'assets/icons/MoonIcon';
import Tooltip from 'antd/lib/tooltip';
import { useCurrentRequest } from '../../../utils/api/useCurrentRequest';

interface Props {
  plan: RequestPlanFragment;
}

const RequestPlan: React.FC<Props> = React.memo(({ plan }) => {
  const { request } = useCurrentRequest();
  const planShiftsCount = useMemo(
    () =>
      request?.zayavkaSchedule
        ?.filter(
          shift => shift?.positionId === plan.position.id && !shift.isDeleted && shift.timesOfDay === plan.timesOfDay,
        )
        .map(el => el.zayavkaShift)
        .flat(),
    [plan.position.id, plan.timesOfDay, request?.zayavkaSchedule],
  );
  const planShiftsDuration = planShiftsCount?.map(shift => shift?.duration);
  const totalShiftsHours = planShiftsDuration?.length
    ? Math.floor(planShiftsDuration.reduce((prev, curr) => prev + curr) / 60)
    : 0;

  const percentShifts = progressPercent(plan.shiftCount * plan.countWorkers, Number(planShiftsCount?.length));
  const percentHours = progressPercent(plan.hours * plan.countWorkers, Number(totalShiftsHours));
  const strokeColorShifts = percentColor(percentShifts);
  const strokeColorShiftsHours = percentColor(percentHours);

  return (
    <div className="flex flex-col gap-y-2 Body2" key={plan.id}>
      <div className={'flex gap-x-1 items-center'}>
        <p className="Subtitle2">{`${plan.position.name} (${plan.countWorkers})`}</p>
        {plan.timesOfDay === ShiftTime.Day ? (
          <Tooltip title={'Дневная смена'} placement={'top'}>
            <div>
              <SunIcon className={'text-smena_text-helper'} />
            </div>
          </Tooltip>
        ) : plan.timesOfDay === ShiftTime.Night ? (
          <Tooltip title={'Ночная смена'} placement={'top'}>
            <div>
              <MoonIcon className={'text-smena_text-helper'} />
            </div>
          </Tooltip>
        ) : null}
      </div>
      <div className="flex flex-col gap-x-1 gap-y-0.5">
        <p>
          Кол-во смен:
          <span>{` ${planShiftsCount?.length}/${plan.shiftCount * plan.countWorkers}`}</span>
        </p>
        <ProgressComponent percent={percentShifts} strokeColor={strokeColorShifts} />
      </div>
      <div className="flex flex-col gap-x-1 gap-y-0.5">
        <p>
          Кол-во часов:
          <span>{` ${totalShiftsHours}/${plan.hours * plan.countWorkers}`}</span>
        </p>
        <ProgressComponent percent={percentHours} strokeColor={strokeColorShiftsHours} />
      </div>
    </div>
  );
});

export default RequestPlan;
