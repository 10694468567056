import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import SelfEmployer from 'assets/icons/selfEmployer';
import { useStore } from 'store';
import SimpleSelect from 'ui/select';
import { errorToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import RangePickerInput from 'ui/pickers/RangePickerInput';
import dayjs, { Dayjs } from 'dayjs';
import { DayOrNullArray } from 'interfaces/CustomTypes';
import Skeleton from 'antd/es/skeleton';
import { ISO_DATE } from 'utils/helpers/constVariables';
import { useCreateManualAccrualByPeriodMutation } from 'pages/payments/api/mutations/generated/CreateManualAccuralByPeriod';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';

const PaymentsFilter = () => {
  const [facility, setFacility] = useState<string | undefined>();
  const [value, setValue] = useState<DayOrNullArray>(null);
  const { manualAccrual, setManualAccrual } = useStore();
  const { facilities, loading: facilitiesLoading } = useSelectFacilities();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get('facilityId');
  const dateFromParam = searchParams.get('dateFrom');
  const dateToParam = searchParams.get('dateTo');

  const [createAccrualByPeriod, { loading }] = useCreateManualAccrualByPeriodMutation();

  const presets: { label: string; value: [Dayjs, Dayjs] }[] = useMemo(() => {
    const startOfMonth = dayjs().startOf('month');
    const startOfMonthPlusTwoWeeks = dayjs().startOf('month').add(2, 'w');
    const lastDayOfPreviousMonth = startOfMonth.subtract(1, 'd');
    const previousMonth = lastDayOfPreviousMonth.startOf('month');
    const previousMonthPlusTwoWeeks = previousMonth.add(2, 'w');

    return [
      {
        label: 'Предыдущий период',
        value: [
          dayjs().date() > 15 ? previousMonthPlusTwoWeeks : previousMonth,
          dayjs().date() > 15 ? lastDayOfPreviousMonth : previousMonthPlusTwoWeeks,
        ],
      },
      {
        label: 'Текущий период',
        value: [
          dayjs().date() > 15 ? startOfMonth : previousMonthPlusTwoWeeks,
          dayjs().date() > 15 ? startOfMonthPlusTwoWeeks : lastDayOfPreviousMonth,
        ],
      },
    ];
  }, []);

  const clickHandler = useCallback(() => {
    if (!facility) {
      return errorToast('Выберите объект');
    }
    if (!value) {
      return errorToast('Выберите период');
    }
    if (value[0] && value[1]) {
      createAccrualByPeriod({
        variables: {
          dateFrom: String(value[0]?.valueOf()),
          dateTo: String(value[1]?.valueOf()),
          facilityId: facility,
        },
      })
        .then(e => {
          if (e.data?.createManualAccuralByPeriod) {
            setManualAccrual(e.data.createManualAccuralByPeriod);
            searchParams.set('facilityId', facility);
            searchParams.set('dateFrom', String(dayjs(value[0]).format(ISO_DATE)));
            searchParams.set('dateTo', String(dayjs(value[1]).format(ISO_DATE)));
            setSearchParams(searchParams);
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    }
  }, [createAccrualByPeriod, facility, searchParams, setManualAccrual, setSearchParams, value]);

  const selectFacilityHandler = useCallback((value: string) => {
    setFacility(value);
  }, []);

  useEffect(() => {
    if (searchParam) {
      setFacility(searchParam);
    }
    if (searchParams.has('dateFrom') && searchParams.has('dateTo')) {
      setValue([dayjs(dateFromParam), dayjs(dateToParam)]);
    }
  }, [dateFromParam, dateToParam, searchParam, searchParams]);

  const disabledDate = (current: dayjs.Dayjs) => {
    return !(
      current.date() === dayjs(current).startOf('month').date() ||
      current.date() === dayjs(current).startOf('month').add(2, 'week').date() ||
      current.date() === dayjs(current).endOf('month').date()
    );
  };

  return (
    <div className="flex justify-between rounded-t-lg bg-smena_white border-b border-smena_gray-30 p-3">
      <div className="flex flex-col">
        {!manualAccrual && <h3 className="mb-10 leading-6 text-smena_gray-90">Создать выплаты для объекта:</h3>}

        {manualAccrual && <h3 className="mb-2 leading-6 text-smena_gray-90">Выплаты для объекта:</h3>}
        <div className="flex">
          <div>
            {facilitiesLoading ? (
              <Skeleton.Button active style={{ width: '40px', height: '40px' }} />
            ) : (
              <SimpleSelect
                divClassName="w-[220px]"
                label="Объект"
                onChange={selectFacilityHandler}
                value={facility}
                placeholder="Выберите объект"
                options={facilities}
              />
            )}
          </div>
          <div className="ml-4 flex">
            <RangePickerInput
              label="Период"
              value={value && [value[0], value[1]]}
              onChange={newValue => {
                setValue(newValue);
              }}
              disabledDate={disabledDate}
              presets={presets}
            />

            <div className="self-end">
              <button className="btn-primary_big ml-5" onClick={clickHandler} disabled={loading}>
                открыть
              </button>
            </div>
          </div>
        </div>
      </div>
      {manualAccrual && (
        <div className="grid gap-2 grid-cols-[40px_auto]">
          <span className="opacity-50">250</span>
          <span className="text-sm text-smena_black pb-1 flex">Не редактируемое поле</span>
          <span>250</span>
          <span className="text-sm text-smena_black pb-1 flex">Редактируемое поле</span>
          <span>
            <SelfEmployer />
          </span>
          <span className="text-sm text-smena_black pb-1 flex">Cамозанятый</span>
          <input type="checkbox" checked disabled className="checkbox-primary" />
          <span className="text-sm text-smena_black pb-1 flex">Исполнителю совершена выплата</span>
        </div>
      )}
    </div>
  );
};

export default PaymentsFilter;
