import { StatisticPeriod } from 'generated/graphql';

export const chartTimes = [
  { name: 'День', id: StatisticPeriod.Day },
  { name: 'Неделя', id: StatisticPeriod.Week },
  { name: 'Месяц', id: StatisticPeriod.Month },
  { name: 'Полгода', id: StatisticPeriod.HalfYear },
  { name: 'Год', id: StatisticPeriod.Year },
  { name: 'Все время', id: StatisticPeriod.AllTime },
];
