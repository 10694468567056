import clsx from 'clsx';
import { Table } from '@tanstack/react-table';
import React from 'react';
import Skeleton from 'antd/es/skeleton';

interface SkeletonLayoutInterface<T> {
  table?: Table<T>;
  headerMenu?: boolean;
}

const NewSkeletonLayout = <T,>({ table, headerMenu }: SkeletonLayoutInterface<T>) => {
  const rows = [...Array(6).keys()];
  return (
    <div className={clsx(headerMenu ? 'max-h-[495px]' : 'max-h-[550px]')}>
      <table
        {...{
          style: {
            width: table?.getCenterTotalSize(),
          },
        }}
      >
        <thead>
          {table?.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className={'table__header border-y first:border-l-0 border-l border-smena_gray-30'}>
                  <Skeleton.Button active size={'small'} block />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table?.getHeaderGroups().map(headerGroup => {
            return rows.map(row => (
              <tr key={row} className={'table__row'}>
                {headerGroup.headers.map(header => (
                  <td key={header.id} className={'table__content'}>
                    <Skeleton.Button active size={'small'} block />
                  </td>
                ))}
              </tr>
            ));
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NewSkeletonLayout;
