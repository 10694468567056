import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { useFacilityCasingsQuery } from '../../../pages/facilityCasings/api/queries/generated/FacilityCasings';
import { positionName } from '../../helpers/filters/filtersName';
import { usePositions } from '../usePositions';

export const useFacilityCasings = () => {
  const { positions } = usePositions();
  const router = useParams();
  const id = router.facilityId ?? '';
  const { data, loading, error } = useFacilityCasingsQuery({
    variables: {
      facilityId: id,
    },
  });

  const facilityCasings = useMemo(
    () => sortBy(data?.facilityCasings, el => positionName(positions, el.positionId)),
    [data?.facilityCasings, positions]
  );

  return { facilityCasings, loading, error };
};
