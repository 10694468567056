import { Role } from 'generated/graphql';

export const clientUsersRole = [
  { value: Role.Worker, name: 'Работник' },
  { value: Role.Foreman, name: 'Бригадир' },
];

export const rolesForStoryAndPush = [{ value: Role.Newbie, name: 'Ньюби' }, ...clientUsersRole];

export const superWithoutNewbie = [
  ...clientUsersRole,
  { value: Role.Supervisor, name: 'Супервайзер' },
  { value: Role.ClientManager, name: 'Менеджер' },
  { value: Role.ClientDirector, name: 'Директор' },
];

export const roleArray = [...superWithoutNewbie, { value: Role.Admin, name: 'Администратор' }];

export const allRoleArray = [{ value: Role.Newbie, name: 'Ньюби' }, ...roleArray];
