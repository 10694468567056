import React, { useCallback, useState } from 'react';
import clsx from 'clsx';

import { useStore } from 'store';

import { getStatus } from 'utils/helpers/get/getStatus';
import { durationHoursAndMinutes } from 'utils/helpers/durationHoursAndMinutes';
import { showTime } from 'utils/helpers/showTime';

import CoffeeIcon from 'assets/icons/coffeeIcon';
import { ICustomEvent } from 'interfaces/IEvent';
import { scheduleClassName } from 'utils/helpers/scheduleClassName';
import { isTest, RU_DATE } from 'utils/helpers/constVariables';
import { positionName } from 'utils/helpers/filters/filtersName';
import { ShiftStage } from 'generated/graphql';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import MoreDropdown from 'ui/moreDropdown';
import { usePositions } from 'utils/api/usePositions';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { getScheduleTypeName } from 'utils/api/useGetScheduleTypes/getScheduleTypeName';
import Tooltip from 'antd/lib/tooltip';

const CustomEvent = (event: { event: ICustomEvent }) => {
  const [shiftEditMenu, setShiftEditMenu] = useState(false);
  const { scheduleTypes } = useGetScheduleTypes();
  const { facilityId, workpostId, setShift, setSchedule, setShowSidebar } = useStore();

  const { positions } = usePositions();

  const {
    resource: {
      stage,
      facility,
      comment,
      lunchDuration,
      id,
      positionId,
      // scheduleId: shiftScheduleId,
      schedule,
      request,
      dateEnd,
      dateFrom,
    },
    title,
  } = event.event;

  const { className } = scheduleClassName(
    {
      facilityId,
      workpostId: workpostId,
      resource: event.event.resource,
    },
    // shift?.id,
    // shiftScheduleId
  );

  const onClickShift = useCallback(() => setShiftEditMenu(value => !value), []);

  // const editShiftHandler = useCallback(() => {
  //   setShift({ id, stage, dateEnd, dateFrom, lunchDuration, positionId, schedule, facility, action: 'edit' });
  //   onClickShift();
  //   setShowSidebar(false);
  //   setShowEditShiftSidebar(true);
  // }, [
  //   dateEnd,
  //   dateFrom,
  //   facility,
  //   id,
  //   lunchDuration,
  //   onClickShift,
  //   positionId,
  //   schedule,
  //   setShift,
  //   setShowEditShiftSidebar,
  //   setShowSidebar,
  //   stage,
  // ]);

  // const editScheduleHandler = useCallback(() => {
  //   if (schedule) {
  //     setSchedule({
  //       id: schedule.id,
  //       dateFrom: schedule.dateFrom,
  //       dateTo: schedule.dateTo,
  //       action: 'edit',
  //     });
  //     onClickShift();
  //     setShowSidebar(false);
  //     setShowEditScheduleSidebar(true);
  //   }
  // }, [schedule, setSchedule, onClickShift, setShowSidebar, setShowEditScheduleSidebar]);

  const removeShiftHandler = useCallback(() => {
    setSchedule(undefined);
    setShift({ id, action: 'remove', lunchDuration: null });
    onClickShift();
    setShowSidebar(false);
  }, [id, onClickShift, setSchedule, setShift, setShowSidebar]);

  const removeScheduleHandler = useCallback(() => {
    if (schedule) {
      setShift(undefined);
      setSchedule({ id: schedule.id, action: 'remove' });
      onClickShift();
      setShowSidebar(false);
    }
  }, [schedule, setShift, setSchedule, onClickShift, setShowSidebar]);

  console.log(event, schedule);

  return (
    <div className="flex flex-col gap-y-1 mb-4 p-4 border rounded-lg shadow-smena bg-smena_white">
      <div className="flex justify-between relative">
        <div className="flex gap-x-1 Caption-numbers text-smena_text">
          <span>{facility?.name};</span>
          <span>{positionName(positions, positionId)}</span>
        </div>
        {[ShiftStage.Accepted, ShiftStage.New].includes(stage as ShiftStage) && (
          <MoreDropdown
            onShowDropdownHandler={onClickShift}
            showDropdown={shiftEditMenu}
            setShowDropdown={setShiftEditMenu}
            style={{
              right: '0',
              transform: 'translate(0, 5px)',
            }}
          >
            <div className={'flex flex-col'}>
              {/*<button className="px-3 py-2 Body1 text-left" onClick={editShiftHandler}>*/}
              {/*  Редактировать смену*/}
              {/*</button>*/}
              {/*<button className="px-3 py-2 Body1 text-left" onClick={editScheduleHandler}>*/}
              {/*  Редактировать график*/}
              {/*</button>*/}
              <button className="px-3 py-2 Body1 text-left text-smena_red" onClick={removeShiftHandler}>
                Отменить смену
              </button>
              <button className="px-3 py-2 Body1 text-left text-smena_red" onClick={removeScheduleHandler}>
                Отменить график
              </button>
            </div>
          </MoreDropdown>
        )}
      </div>
      <div className="flex gap-x-1 Tag text-smena_text-secondary">
        <div className="flex gap-x-1">
          <span>{dayjs(dateFrom).format(RU_DATE)}</span>
          <span>–</span>
          <span>{dayjs(dateEnd).format(RU_DATE)}</span>
        </div>
        <span>·</span>
        <span>
          {schedule?.isCustom
            ? 'Кастомный график'
            : `График ${getScheduleTypeName(scheduleTypes, schedule?.scheduleTypeId)}`}
        </span>
        {request?.id ? (
          <>
            <span>·</span>
            <Link to={`/requests/${request?.id}`} className="text-primary">
              Заявка №{request?.number}
            </Link>
          </>
        ) : null}
      </div>
      <span className="Tag text-smena_text-secondary">{getStatus(stage)}</span>
      {isTest && <span className="Tag text-smena_text-secondary">{stage}</span>}
      {comment && (
        <div className="flex gap-x-1 Tag">
          <span className="text-smena_text">Комментарий:</span>
          <span className="text-smena_text-secondary">{comment}</span>
        </div>
      )}
      <div className="flex gap-x-2">
        <span className={clsx('Tag text-smena_gray-80 px-6 rounded h-[17px]', className)}>{title}</span>
        <Tooltip title="Время обеда">
          <span className="Tag text-smena_gray-80 bg-smena_bb-background px-2 flex justify-center items-center relative rounded h-[17px]">
            <CoffeeIcon />
            <span className="pl-1 Tag text-smena_text-secondary">
              {lunchDuration ? showTime(durationHoursAndMinutes(lunchDuration)) : 'Нет обеда'}
            </span>
          </span>
        </Tooltip>
      </div>
      <div className="Tag text-smena_text-helper mt-3 flex gap-x-1">
        <span>id смены:</span>
        <span>{id}</span>
      </div>
    </div>
  );
};

export default CustomEvent;
