import clsx from 'clsx';
import { TIcon } from 'interfaces/TIcon';

const QuestionMarkIconSmall = ({ className }: TIcon) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C5.87827 0 3.84344 0.842854 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842854 10.1217 0 8 0ZM12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426ZM7.43998 5.80083C7.64364 5.68107 7.88312 5.6373 8.11597 5.67725C8.34883 5.71721 8.56002 5.83832 8.7121 6.01912C8.86419 6.19992 8.94735 6.42872 8.94684 6.66498V6.66712C8.94684 6.80271 8.83181 7.04179 8.39209 7.33497C8.2027 7.46125 8.0062 7.56024 7.8531 7.6283C7.77797 7.66171 7.71675 7.68613 7.67666 7.70141C7.65669 7.70902 7.64222 7.71427 7.63416 7.71713L7.6272 7.71958C7.10538 7.89557 6.82384 8.46066 6.99821 8.98349C7.17293 9.5074 7.7393 9.79048 8.26321 9.61575L7.95113 8.67996C8.26322 9.61575 8.26442 9.61535 8.26442 9.61535L8.26581 9.61488L8.26917 9.61375L8.27808 9.6107L8.30445 9.60147C8.32568 9.59391 8.35418 9.58352 8.3889 9.57029C8.45818 9.54389 8.55322 9.50579 8.66559 9.45583C8.88749 9.35718 9.19098 9.20611 9.50159 8.99901C10.0615 8.62566 10.9458 7.86542 10.9468 6.66925L10.9468 6.66712H9.94684L10.9468 6.66925C10.9484 5.96048 10.6989 5.27406 10.2426 4.73166C9.78635 4.18927 9.15279 3.82594 8.45423 3.70606C7.75566 3.58619 7.03721 3.71752 6.42623 4.07678C5.81525 4.43604 5.3512 5.00002 5.11634 5.66875C4.93333 6.18983 5.2074 6.76061 5.72848 6.94362C6.24956 7.12663 6.82034 6.85256 7.00335 6.33148C7.08163 6.10857 7.23632 5.92058 7.43998 5.80083ZM7.99984 10.3341C7.44756 10.3341 6.99984 10.7818 6.99984 11.3341C6.99984 11.8864 7.44756 12.3341 7.99984 12.3341H8.00684C8.55913 12.3341 9.00684 11.8864 9.00684 11.3341C9.00684 10.7818 8.55913 10.3341 8.00684 10.3341H7.99984Z"
        className={clsx('fill-current', className)}
      />
    </svg>
  );
};

export default QuestionMarkIconSmall;
