import { PushFormProps } from '../../utils/hooks/usePushForm';
import { useToRemoveNotifyLetterMutation } from '../../api/mutations/generated/ToRemoveNotifyLetter';
import { usePush } from 'utils/api/usePush';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import Modal from 'ui/modal';

interface Props {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  values: PushFormProps;
}

const PushPopupWithdraw = ({ showPopup, setShowPopup }: Props) => {
  const { id } = usePush();
  const [toRemovePush] = useToRemoveNotifyLetterMutation();
  const showPopupHandle = () => {
    setShowPopup(value => !value);
  };

  const onClick = () => {
    if (!id) return;
    toRemovePush({
      variables: {
        id,
      },
    })
      .then(() => successToast('Пуш снят с публикации'))
      .catch(error => errorToast(errorHandler(error)))
      .finally(showPopupHandle);
  };
  return (
    <Modal
      title="Снять с публикации?"
      open={showPopup}
      onCancel={showPopupHandle}
      onOk={onClick}
      okText="Снять с публикации"
    >
      Вы уверены, что хотите снять пуш с публикации?
    </Modal>
  );
};

export default PushPopupWithdraw;
