import React from 'react';
import { Switch } from 'ui/switch';
import SimpleInput from 'ui/input';
import { useFacility } from 'utils/api/useFacility';
import { Form, Formik } from 'formik';
import { useFacilityAllIsPossible } from './hooks';

const FacilityAllIsPossible = () => {
  const { id, facility } = useFacility();
  const { initialValues, validate, onSubmit, promotionSwitchOffHandler } = useFacilityAllIsPossible();
  return (
    <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, setFieldValue }) => {
        return (
          <Form className="facilityPageBlock blockTemplate">
            <div className="grid grid-cols-8 gap-5 gap">
              <div className="flex gap-x-5 items-center col-span-full">
                <span className="Subtitle1 text-smena_text-secondary">Акция “Все возможно”</span>
                <Switch
                  name="everythingIsPossibleBonus"
                  checked={values.everythingIsPossibleBonus}
                  onChange={({ target: { checked } }) => {
                    setFieldValue('everythingIsPossibleBonus', checked);
                    if (!checked && facility?.everythingIsPossibleBonus) {
                      promotionSwitchOffHandler();
                    }
                  }}
                  disabled={!id}
                />
              </div>
              {values.everythingIsPossibleBonus && (
                <>
                  <div className="col-span-full block Body2">
                    <span className={'inline-flex'}>
                      Бонус исполнителям, которые отработали заданное количество смен за месяц. <br /> Акция повторяется
                      каждый месяц. Исполнители отслеживают прогресс в приложении.
                    </span>
                  </div>
                  <div className="col-span-full block Body2 text-smena_text-secondary">
                    <span className={'inline-flex'}>Укажите условия акции для этого объекта</span>
                  </div>
                  <SimpleInput
                    divClassName="md:col-span-2 sm:col-span-4 col-span-full"
                    label={'Количество смен'}
                    placeholder={'20'}
                    onChange={handleChange}
                    value={values.possibleBonusShiftCount}
                    name={'possibleBonusShiftCount'}
                    type={'text'}
                    regText
                    error={
                      touched.possibleBonusShiftCount && errors.possibleBonusShiftCount
                        ? errors.possibleBonusShiftCount
                        : ''
                    }
                  />
                  <SimpleInput
                    divClassName="md:col-span-2 sm:col-span-4 col-span-full"
                    placeholder="1000"
                    label="К выплате, ₽"
                    onChange={handleChange}
                    value={values.possibleBonusPaymentSum}
                    type="text"
                    name="possibleBonusPaymentSum"
                    float
                    error={
                      touched.possibleBonusPaymentSum && errors.possibleBonusPaymentSum
                        ? errors.possibleBonusPaymentSum
                        : ''
                    }
                  />
                  <SimpleInput
                    divClassName="md:col-span-2 sm:col-span-4 col-span-full"
                    placeholder="1000"
                    label="Сумма для публикации, ₽"
                    onChange={handleChange}
                    value={values.possibleBonusViewSum}
                    type="text"
                    name="possibleBonusViewSum"
                    float
                    error={
                      touched.possibleBonusViewSum && errors.possibleBonusViewSum ? errors.possibleBonusViewSum : ''
                    }
                  />
                  <div className="col-span-2 pt-6">
                    <button className="btn-primary_small" type="submit">
                      Сохранить
                    </button>
                  </div>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FacilityAllIsPossible;
