import clsx from 'clsx';
import { TIcon } from 'interfaces/TIcon';

const PaperAirPlane = ({ className }: TIcon) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={clsx('fill-current', className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1224_251652)">
        <path d="M11.4875 0.460509C11.4243 0.397647 11.3444 0.354124 11.2572 0.335057C11.17 0.31599 11.0792 0.322172 10.9954 0.352875L0.685253 4.09666C0.596337 4.13034 0.519784 4.19023 0.465764 4.26839C0.411744 4.34654 0.382812 4.43926 0.382812 4.53422C0.382812 4.62918 0.411744 4.72189 0.465764 4.80005C0.519784 4.8782 0.596337 4.93809 0.685253 4.97177L4.71091 6.57692L7.68212 3.60061L8.34291 4.26045L5.35764 7.24144L6.96978 11.2613C7.00451 11.3484 7.06462 11.4231 7.14233 11.4756C7.22003 11.5282 7.31176 11.5562 7.40562 11.5562C7.50032 11.5542 7.59222 11.5237 7.66919 11.4685C7.74615 11.4134 7.80458 11.3363 7.83677 11.2473L11.5859 0.951881C11.6179 0.869085 11.6256 0.778932 11.6081 0.691933C11.5907 0.604933 11.5489 0.524673 11.4875 0.460509Z" />
      </g>
      <defs>
        <clipPath id="clip0_1224_251652">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaperAirPlane;
