import { Sex } from 'generated/graphql';

export const genders = [
  {
    id: Sex.Female,
    name: 'Женский',
  },
  {
    id: Sex.Male,
    name: 'Мужской',
  },
];
export const VacancyGenders = [
  {
    id: null,
    name: 'Не имеет значения',
  },
  {
    id: Sex.Female,
    name: 'Женщина',
  },
  {
    id: Sex.Male,
    name: 'Мужчина',
  },
];
