import { TIcon } from 'interfaces/TIcon';

const ReviewIcon = ({ className }: TIcon) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`fill-current ${className}`}
  >
    <path d="M10.5 14H18V12H12.5L10.5 14ZM6 14H8.45L14.35 8.15C14.45 8.05 14.5 7.925 14.5 7.775C14.5 7.625 14.45 7.5 14.35 7.4L12.6 5.65C12.5 5.55 12.375 5.5 12.225 5.5C12.075 5.5 11.95 5.55 11.85 5.65L6 11.55V14ZM2 22V4C2 3.45 2.196 2.979 2.588 2.587C2.97933 2.19567 3.45 2 4 2H20C20.55 2 21.021 2.19567 21.413 2.587C21.8043 2.979 22 3.45 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.021 17.8043 20.55 18 20 18H6L2 22Z" />
  </svg>
);
export default ReviewIcon;
