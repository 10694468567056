import Across from 'assets/icons/Across';
import { useCancelPlanCasingRateMutation } from 'pages/facilityCasing/api/mutations/generated/CancelPlanCasingRate';
import { GetCasingDocument } from 'pages/facilityCasing/api/query/generated/GetCasing';

interface Props {
  handleCancel: () => void;
  isRateHave: boolean;
  casingRateId: string;
}

const CancelChangeRate = (props: Props) => {
  const { isRateHave, handleCancel, casingRateId } = props;

  const [cancelRate] = useCancelPlanCasingRateMutation({
    refetchQueries: [GetCasingDocument],
    variables: { input: { casingRateId } },
  });

  return (
    <div
      className="flex gap-2 text-smena_red cursor-pointer self-start"
      onClick={() => {
        if (isRateHave) {
          cancelRate();
          handleCancel();
        } else {
          handleCancel();
        }
      }}
    >
      <Across />
      Отменить изменение
    </div>
  );
};

export default CancelChangeRate;
