import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MainLayout from 'components/layout/main';
import { balanceColumns } from 'components/table/columns';
import TableHeader from 'components/table/header';
import BalanceRow from 'pages/balance/components/BalanceRow';
import SimpleSelect from 'ui/select';
import { undefStr } from 'interfaces/CustomTypes';
import { convertMoney } from 'utils/helpers/convertMoney';
import dayjs from 'dayjs';
import { useGetDirectorOrManagerOrSupervisorGroupsQuery } from 'pages/main/api/queries/generated/GetDirectorOrManagerOrSupervisorGroups';
import { useFacilityGroupLazyQuery } from 'pages/facilityGroup/api/queries/generated/FacilityGroup';
import { useGetFacilityGroupOperationListLazyQuery } from './api/queries/generated/GetFacilityGroupOperationList';
import { sortBy as lodashSortBy } from 'lodash';

const BalancePage = () => {
  const [value, setValue] = useState<undefStr>();
  const { data: clientGroups } = useGetDirectorOrManagerOrSupervisorGroupsQuery();
  const [loadFacility, { data: facilityGroupData }] = useFacilityGroupLazyQuery();
  const options = clientGroups?.getDirectorOrManagerOrSupervisorGroups;
  const facilityGroup = facilityGroupData?.facilityGroup;

  const onChange = useCallback(
    (e: string) => {
      setValue(e);
      loadFacility({
        variables: {
          id: e,
        },
      });
    },
    [loadFacility],
  );

  const [loadBalanceList, { data, loading, error }] = useGetFacilityGroupOperationListLazyQuery();
  const balanceList = data?.getFacilityGroupOperationList;
  const sortBalanceList = useMemo(() => lodashSortBy(balanceList, el => dayjs(el.dayAndTime).valueOf()), [balanceList]);

  useEffect(() => {
    if (options?.length) {
      setValue(options[0].id);
      loadFacility({
        variables: {
          id: options[0].id,
        },
      });
    }
  }, [loadFacility, options]);

  useEffect(() => {
    if (value) {
      loadBalanceList({
        variables: {
          facilityGroupId: value,
        },
      });
    }
  }, [loadBalanceList, value]);

  return (
    <MainLayout title={'Баланс'} breadCrumbs={[{ path: '/balance', name: 'Баланс' }]} loading={loading} error={error}>
      <div className={'grid gap-y-6'}>
        <div className={'shadow-smena rounded-lg bg-smena_white p-5'}>
          <div className={'flex justify-between'}>
            <span className={'Subtitle1 text-smena_text-secondary'}>Баланс</span>
            <SimpleSelect
              divClassName="w-[160px]"
              label=""
              onChange={onChange}
              value={value}
              placeholder="Все группы"
              options={options}
            />
          </div>
          <div className={'flex flex-col gap-y-2'}>
            <span className={'Subtitle2 text-smena_text-secondary'}>Баланс на текущий момент</span>
            <span className={'ChartBigNumber'}>{`${convertMoney(facilityGroup?.balance)} ₽`}</span>
          </div>
        </div>
        <div>
          <TableHeader columns={balanceColumns} />
          <div className="main-table">{sortBalanceList?.map(el => <BalanceRow key={el.id} el={el} />)}</div>
        </div>
      </div>
    </MainLayout>
  );
};

export default BalancePage;
