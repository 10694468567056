import React, { useMemo } from 'react';
import dayjs from 'dayjs';

import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { Link } from 'react-router-dom';
import { positionName } from 'utils/helpers/filters/filtersName';
import { useStore } from 'store';
import { auchanLunchDurationTypes, getLunchDuration, lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { timeDifference } from 'utils/helpers/timeDifference';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import MainSidebar from 'ui/sidebar';
import { useMeRole } from 'store/hooks/useMeRole';
import SimpleSelect from 'ui/select';
import ViewScheduleFormElement from '../viewElement';
import { useDeleteRequestShiftByClientMutation } from 'pages/facilityRequests/api/mutations/generated/DeleteRequestShiftByClient';
import { useMe } from 'utils/api/useMe';
import { usePositions } from 'utils/api/usePositions';
import { userFullName } from 'utils/helpers/userFullName';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { useEditShiftTitle } from './hooks';
import { useEditShiftForm } from './useEditShiftForm';
import { Form, Formik } from 'formik';
import { Role } from 'generated/graphql';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';

const EditShift: React.FC = () => {
  const { positions } = usePositions();
  const { request } = useCurrentRequest();
  const { setShowEditRequestShiftSidebar, showEditRequestShift, requestShift, requestSchedule } = useStore();
  const setRequestShift = useStore(state => state.setRequestShift);
  const me = useMe();
  const { initialValues, validate, onSubmit } = useEditShiftForm();
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeRequestAuthor = request?.authorId === me?.id;

  const [deleteRequestShiftByClient] = useDeleteRequestShiftByClientMutation();

  const { isAuchan } = useFacilityGroupById(request?.facility?.facilityGroup?.id);

  const title = useEditShiftTitle();

  const deleteRequestShiftByClientHandler = () => {
    if (requestShift) {
      deleteRequestShiftByClient({
        variables: {
          id: requestShift?.id,
        },
      })
        .then(() => {
          successToast('Смена удалена');
          setShowEditRequestShiftSidebar(false);
        })
        .catch(e => errorToast(errorHandler(e)));
    }
  };

  const lunchOptions = useMemo(() => (isAuchan ? auchanLunchDurationTypes : lunchDurationTypes), [isAuchan]);

  const shiftDeleted = requestShift?.isDeleted;

  const isUserCanEditShift = useMemo(() => {
    const regularTerm = dayjs().isBefore(dayjs(requestShift?.dateEnd)) && !shiftDeleted;
    if (isMeSupervisor) {
      return regularTerm && isMeRequestAuthor;
    }
    return regularTerm;
  }, [isMeSupervisor, isMeRequestAuthor, requestShift?.dateEnd, shiftDeleted]);

  const shiftTime = useMemo(() => {
    return dayjs(requestShift?.newDateFrom).isValid() && dayjs(requestShift?.newDateEnd).isValid()
      ? `${dayjs(requestShift?.newDateFrom).format(TIME_DATE)} – ${dayjs(requestShift?.newDateEnd).format(TIME_DATE)}`
      : false;
  }, [requestShift?.newDateFrom, requestShift?.newDateEnd]);

  return (
    <MainSidebar
      title={title}
      show={showEditRequestShift}
      setShow={setShowEditRequestShiftSidebar}
      onClose={() => {
        setRequestShift(null);
      }}
    >
      <div className="flex flex-col gap-y-6">
        <div className="Subtitle1 flex gap-x-1">
          <span>График смены:</span>
          <span className="text-smena_text-secondary">
            {dayjs(requestSchedule?.dateFrom).format(RU_DATE)} – {dayjs(requestSchedule?.dateTo).format(RU_DATE)}
          </span>
        </div>
        <div className="flex flex-col gap-y-1">
          <span className="H4">{positionName(positions, requestShift?.positionId)}</span>
          <div className="flex gap-x-2 Subtitle2 text-smena_text-secondary">
            <ViewScheduleFormElement
              data={dayjs(requestShift?.dateFrom).format(RU_DATE)}
              newData={false}
              isNew={requestShift?.isNew}
              isDeleted={shiftDeleted}
            />
            <span>·</span>
            <ViewScheduleFormElement
              data={`${dayjs(requestShift?.dateFrom).format(TIME_DATE)} – ${dayjs(requestShift?.dateEnd).format(
                TIME_DATE,
              )}`}
              newData={shiftTime}
              isNew={requestShift?.isNew}
              isDeleted={shiftDeleted}
            />
            <span>·</span>
            <ViewScheduleFormElement
              data={getLunchDuration(requestShift?.lunchDuration)}
              newData={requestShift?.newLunchDuration ? getLunchDuration(requestShift?.newLunchDuration) : false}
              isNew={requestShift?.isNew}
              isDeleted={shiftDeleted}
            />
          </div>
        </div>
        <div className="flex items-center gap-x-1">
          <span className="Subtitle1">Исполнитель:</span>
          {requestShift?.shift?.user ? (
            <Link className="text-primary" to={`/users/${requestShift?.shift?.user?.id}`}>
              {userFullName(requestShift?.shift?.user)}
            </Link>
          ) : (
            <span className={'text-smena_text-secondary'}>отсутствует</span>
          )}
        </div>
        {isUserCanEditShift ? (
          <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
            {({ values, errors, touched, setFieldValue }) => {
              return (
                <Form className={'grid gap-y-6'}>
                  <hr className={'bg-smena_gray-30'} />
                  <div className="grid grid-cols-2 gap-y-4 gap-x-6">
                    <TimePickerInput
                      label="Начало смены"
                      className="col-span-1"
                      value={values.editShiftStartTime}
                      onChange={timeValue => {
                        setFieldValue('editShiftStartTime', timeValue);
                        if (isAuchan) {
                          setFieldValue(
                            'lunchDuration',
                            lunchDurationHandle(timeDifference(timeValue, values.editShiftEndTime)),
                          );
                        }
                      }}
                      placeholder={dayjs().format(TIME_DATE)}
                      error={errors.editShiftStartTime && touched.editShiftStartTime ? errors.editShiftStartTime : ''}
                    />
                    <TimePickerInput
                      label="Конец смены"
                      className="col-span-1"
                      value={values.editShiftEndTime}
                      onChange={timeValue => {
                        setFieldValue('editShiftEndTime', timeValue);
                        if (isAuchan) {
                          setFieldValue(
                            'lunchDuration',
                            lunchDurationHandle(timeDifference(values.editShiftStartTime, timeValue)),
                          );
                        }
                      }}
                      error={errors.editShiftEndTime && touched.editShiftEndTime ? errors.editShiftEndTime : ''}
                    />
                    <SimpleSelect
                      divClassName="col-span-1"
                      label="Обед"
                      onChange={value => {
                        if (!isAuchan) {
                          setFieldValue('lunchDuration', Number(value));
                        }
                      }}
                      sort={false}
                      value={values.lunchDuration}
                      options={lunchOptions}
                      disabled={isAuchan}
                      error={errors.editShiftEndTime && touched.editShiftEndTime ? errors.editShiftEndTime : ''}
                    />
                  </div>
                  <div className="flex justify-end gap-x-6">
                    <button className="btn-stroke_reject" type="button" onClick={deleteRequestShiftByClientHandler}>
                      Удалить
                    </button>
                    <button className="btn-primary" type="submit">
                      Изменить смену
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : null}
        {shiftDeleted ? <div className="Subtitle1 text-smena_red">Смена отменена</div> : null}
      </div>
    </MainSidebar>
  );
};

export default EditShift;
