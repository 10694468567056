import { Bonus } from './form';
import { PromotionEntityFragment } from 'pages/facilityGroup/api/fragments/generated/PromotionEntity.fragment';
import { useDeletePromotionInFacilityGroupMutation } from 'pages/facilityGroup/api/mutations/generated/DeletePromotionInFacilityGroup';
import { errorToast, successToast } from '../../../../utils/helpers/notify';
import { errorHandler } from '../../../../utils/helpers/errorHandler';
import { usePromotionStore } from '../../useBonusProgramStore';
import Modal from 'ui/modal';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  promotion: Bonus | PromotionEntityFragment;
};

const RemoveBonusProgramPopup = ({ show, setShow, promotion }: Props) => {
  const [deletePromotion] = useDeletePromotionInFacilityGroupMutation();
  const removePromotions = usePromotionStore(state => state.removePromotions);
  const showHandler = () => {
    setShow(value => !value);
  };
  const deleteHandler = () => {
    deletePromotion({
      variables: {
        input: {
          promotionId: promotion.id,
        },
      },
    })
      .then(response => {
        const res = response.data?.deletePromotionInFacilityGroup;
        if (!res) return;
        removePromotions(promotion.id);
        successToast('Акция сброшена на параметры по умолчанию');
      })
      .catch(err => {
        errorToast(errorHandler(err));
      })
      .finally(showHandler);
  };
  return (
    <Modal type="delete" title="Удалить бонусную программу?" open={show} onCancel={showHandler} onOk={deleteHandler}>
      Вы уверены, что хотите удалить бонусную программу?
    </Modal>
  );
};

export default RemoveBonusProgramPopup;
