import dayjs from 'dayjs';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import ShiftCell from './components/ShiftCell/ShiftCell';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import CustomShiftCell from './components/CustomShiftCell';
import { isFutureDay } from 'pages/facilityReport/lib/helpers/isFutureOrToday';
import DeleteObj from 'assets/icons/DeleteObj';
import Tooltip from 'antd/lib/tooltip';

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
}

const Days = (props: Props) => {
  const { facilityId } = useParams();
  const { data } = props;
  const [params] = useSearchParams();

  const dateParam = params.get('date') || dayjs().format('YYYY-MM-DD');

  const { isCalendar, setIsCalendar, selectedRow } = useFacilityReportStore();

  const currentDay = dayjs().date();

  const daysArray = useMemo(() => Array.from({ length: dayjs(dateParam).daysInMonth() }, (_, i) => i + 1), [dateParam]);

  const getDayFromShift = (shiftDate: string) => {
    return dayjs(shiftDate).date();
  };

  useEffect(() => {
    if (dateParam) {
      setIsCalendar(false);
    }
  }, [dateParam, setIsCalendar]);

  const groupedData = data?.reduce(
    (acc, item) => {
      const professionId = item.workPost.position?.id;

      const key = `${professionId}-${item.user.id}`;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    },
    {} as Record<string, typeof data>,
  );

  const isUserUnassigned = (
    userItem: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'][number],
    day: number,
  ) => {
    if (!userItem.user.userExperience) return false;

    const currentFacilityExps = userItem.user.userExperience.filter(
      exp => exp.facilityId === facilityId && userItem.workPost.position?.id === exp.positionId,
    );

    const currentDate = dayjs(dateParam).date(day);

    const assignedExp = currentFacilityExps.find(item => item.assignedAt && !item.unassignAt);
    let isAssigned = currentFacilityExps.some(item => item.unassignAt === null);

    if (assignedExp && !dayjs(assignedExp.assignedAt).isSame(currentDate, 'month')) {
      isAssigned = false;
    }

    if (isAssigned) return false;

    const lastUnasignedExpCurrentMonth = currentFacilityExps
      .filter(item => item.unassignAt !== null && dayjs(item.unassignAt).isSame(currentDate, 'month'))
      .sort((a, b) => new Date(b.unassignAt).valueOf() - new Date(a.unassignAt).valueOf())[0];

    if (!lastUnasignedExpCurrentMonth || !dateParam) return false;

    const unassignDate = dayjs(lastUnasignedExpCurrentMonth.unassignAt);

    return (
      unassignDate.isSame(currentDate, 'date') &&
      unassignDate.isSame(currentDate, 'month') &&
      unassignDate.isSame(currentDate, 'year')
    );
  };

  const isAfterUnassignment = (
    userItem: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'][number],
    day: number,
  ) => {
    if (!userItem.user.userExperience) return false;

    const currentFacilityExps = userItem.user.userExperience.filter(
      exp => exp.facilityId === facilityId && userItem.workPost.position?.id === exp.positionId,
    );

    const currentDate = dayjs(dateParam).date(day);

    const assignedExp = currentFacilityExps.find(item => item.assignedAt && !item.unassignAt); // тянучка
    let isAssigned = currentFacilityExps.some(item => item.unassignAt === null);

    if (assignedExp && !dayjs(assignedExp.assignedAt).isSame(currentDate, 'month')) {
      // тянучка
      isAssigned = false; // тянучка
    } else if (assignedExp && dayjs(assignedExp.assignedAt).isSame(currentDate, 'month')) {
      // тянучка
      return dayjs(assignedExp.assignedAt).date() > day; // тянучка
    } // тянучка

    if (isAssigned) return false;

    const lastUnasignedExpCurrentMonth = currentFacilityExps
      .filter(item => item.unassignAt !== null && dayjs(item.unassignAt).isSame(currentDate, 'month'))
      .sort((a, b) => new Date(b.unassignAt).valueOf() - new Date(a.unassignAt).valueOf())[0];

    if (!lastUnasignedExpCurrentMonth || !dateParam) return false;

    const unassignDate = dayjs(lastUnasignedExpCurrentMonth.unassignAt);

    return Boolean(
      currentDate.isAfter(unassignDate, 'day') &&
        currentDate.month() === unassignDate.month() &&
        currentDate.year() === unassignDate.year(),
    );
  };

  return (
    <div className="h-full">
      <div className="sticky top-0 z-10 bg-smena_gray-5">
        <div
          className="grid border-b-[1px] border-smena_gray-30"
          style={{ gridTemplateColumns: `repeat(${daysArray.length}, 70px)` }}
        >
          {daysArray.map(day => (
            <div
              key={day}
              className={clsx('pt-2 pb-2 text-center border-r-[1px] border-smena_gray-30 font-medium h-[36px] Table', {
                ['border-l-[2px] border-r-[2px] border-l-primary border-r-primary bg-primary-extra_light text-primary']:
                  currentDay === day &&
                  dayjs().month() === dayjs(dateParam).month() &&
                  dayjs().year() === dayjs(dateParam).year(),
              })}
            >
              {day}
            </div>
          ))}
        </div>
      </div>

      <div className="overflow-x-auto">
        <div className="w-fit">
          {groupedData &&
            Object.entries(groupedData).map(([, users], index) =>
              users.map((userItem, userIndex) => {
                return (
                  <div
                    className="grid border-b-[1px] Body2"
                    style={{ gridTemplateColumns: `repeat(${daysArray.length}, 70px)` }}
                    key={index + userIndex}
                  >
                    {daysArray.map(day => {
                      const shiftForDay = userItem.shifts?.find(
                        (shift: any) => getDayFromShift(shift.dateFrom) === day,
                      );
                      const isUnassigned = isUserUnassigned(userItem, day);
                      const isAfterUnassign = isAfterUnassignment(userItem, day);

                      if (isUnassigned) {
                        return (
                          <Tooltip title="Исполнитель откреплен" key={`${index}-${day}`}>
                            <div
                              className={clsx(
                                'pt-2 pb-2 text-center border-r-[1px] font-medium h-[36px] flex justify-center',
                                {
                                  ['border-l-[2px] border-r-[2px] border-primary']:
                                    currentDay === day &&
                                    dayjs().month() === dayjs(dateParam).month() &&
                                    dayjs().year() === dayjs(dateParam).year(),
                                },
                              )}
                            >
                              <DeleteObj />
                            </div>
                          </Tooltip>
                        );
                      }

                      if (isAfterUnassign) {
                        return (
                          <div
                            key={`${index}-${day}`}
                            className={clsx(
                              'pt-2 pb-2 text-center border-r-[1px] font-medium h-[36px] flex justify-center items-center text-smena_gray-30',
                              {
                                ['border-l-[2px] border-r-[2px] border-primary']:
                                  currentDay === day &&
                                  dayjs().month() === dayjs(dateParam).month() &&
                                  dayjs().year() === dayjs(dateParam).year(),
                              },
                            )}
                          >
                            ✕
                          </div>
                        );
                      }

                      if (isCalendar && !shiftForDay && index === selectedRow && isFutureDay(day, dateParam)) {
                        return (
                          <CustomShiftCell
                            groupIndex={index}
                            userIndex={userIndex}
                            dayIndex={day}
                            key={`${index}-${day}`}
                          />
                        );
                      }

                      return (
                        <ShiftCell
                          key={`${index}-${day}`}
                          shift={shiftForDay}
                          supervisor={userItem}
                          dayIndex={day}
                          rowIndex={userIndex}
                          groupIndex={index}
                          isDisabled={false}
                        />
                      );
                    })}
                  </div>
                );
              }),
            )}

          <div
            className="grid border-b-[1px] Body2"
            style={{ gridTemplateColumns: `repeat(${daysArray.length}, 70px)` }}
          >
            {daysArray.map(day => {
              const shiftCount = data?.reduce((count, supervisor) => {
                const shiftsForDay = supervisor.shifts?.filter((shift: any) => getDayFromShift(shift.dateFrom) === day);
                return count + Number(shiftsForDay?.length);
              }, 0);

              return (
                <div
                  key={day}
                  onClick={() => {}}
                  className={clsx('pt-2 pb-2 text-center border-r-[1px] font-medium h-[36px]', {
                    ['border-l-[2px] border-r-[2px] border-l-primary border-r-primary']:
                      currentDay === day &&
                      dayjs().month() === dayjs(dateParam).month() &&
                      dayjs().year() === dayjs(dateParam).year(),
                  })}
                >
                  {Number(shiftCount) > 0 ? shiftCount : 0}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Days;
