import React, { useState } from 'react';
import SimpleInput from 'ui/input';
import SimpleSelect from 'ui/select';
import { Spoiler } from 'ui/spoiler';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import dayjs from 'dayjs';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import SimpleCheckbox from 'ui/checkbox';
import EditBonusProgramPopup from './editPopup';
import { Form, Formik } from 'formik';
import usePromotionForm, { FacilityBonus } from './form';
import Status from 'pages/facilityGroup/components/promotionBlock/status';
import { repeatConditionOptions, rewardTypeOptions } from 'pages/facilityGroup/helpers/const';
import DefaultPromotionPopup from './defaultPromotionPopup';

type Props = { el: FacilityBonus };

const Promotion = ({ el }: Props) => {
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [defaultPromotionPopup, setDefaultPromotionPopup] = useState(false);
  const { initialValues, validate, onSubmit } = usePromotionForm({
    el,
    setShowDefault: setDefaultPromotionPopup,
    setShowEdit: setShowEditPopup,
  });
  const isExist = !!el['__typename'];
  const onRemovePromotion = () => {
    setDefaultPromotionPopup(true);
  };
  return (
    <Spoiler
      header={
        <div className={'flex items-center gap-x-2 bg-smena_gray-2 px-6 py-3 rounded-lg'}>
          <span className={'Subtitle2'}>{el.name || 'Без названия'}</span>
          <Status status={el.status} />
        </div>
      }
      className={'col-span-full'}
      arrowColor={'text-primary'}
      arrowButtonCss={'px-4'}
      defaultShow={false}
    >
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, setFieldValue, errors, handleChange, validateForm, touched, handleBlur, setFieldTouched }) => {
          const onPublishBonus = () => {
            validateForm(values).then(res => {
              if (Object.entries(res).length) {
                for (const key in res) {
                  setFieldTouched(key);
                }
                return;
              }
              setShowEditPopup(true);
            });
          };
          return (
            <Form id="editPromitionForm" className={'flex flex-col gap-y-6'}>
              <span className={'Subtitle2 text-smena_text-secondary'}>Общая информация</span>
              <SimpleInput
                label={'Название'}
                placeholder={'Введите название'}
                name={'name'}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && errors.name ? errors.name : undefined}
              />
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Требования</span>
              <div className={'grid grid-cols-1 gap-x-6'}>
                <SimpleInput
                  divClassName={'col-span-1'}
                  label={'Кол-во смен'}
                  placeholder={'Введите кол-во смен'}
                  name={'requirementShiftCount'}
                  type={'number'}
                  value={values.requirementShiftCount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.requirementShiftCount && errors.requirementShiftCount
                      ? errors.requirementShiftCount
                      : undefined
                  }
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Вознаграждение</span>
              <div className={'grid grid-cols-2 gap-x-6'}>
                <SimpleSelect
                  divClassName={'col-span-1'}
                  label={'Тип вознаграждения'}
                  options={rewardTypeOptions}
                  value={values.rewardType}
                  placeholder={'Выберите тип награждения'}
                  onBlur={() => {
                    setFieldTouched('rewardType');
                  }}
                  onChange={value => {
                    setFieldValue('rewardType', value);
                  }}
                  error={touched.rewardType && errors.rewardType ? errors.rewardType : undefined}
                />
                <SimpleInput
                  divClassName={'col-span-1'}
                  label={'Вознаграждение'}
                  placeholder={'1000'}
                  type={'number'}
                  name={'rewardSum'}
                  value={values.rewardSum}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.rewardSum && errors.rewardSum ? errors.rewardSum : undefined}
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Условия аннулирования</span>
              <p className={'Body1 text-smena_text-secondary col-span-full w-8/12'}>
                Вознаграждения, указанные в данной программе, будут аннулированы при превышении допустимого количества
                плохих смен.
              </p>
              <div className={'grid grid-cols-2'}>
                <SimpleInput
                  divClassName={'col-span-1'}
                  label={'Кол-во плохих смен'}
                  placeholder={'10'}
                  type={'number'}
                  name={'badShiftCount'}
                  value={values.badShiftCount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.badShiftCount && errors.badShiftCount ? errors.badShiftCount : undefined}
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              <span className={'Subtitle2 text-smena_text-secondary'}>Сроки проведения</span>
              <div className={'grid grid-cols-3 gap-6'}>
                <DatePickerInput
                  divClassName={'col-span-1'}
                  label={'Дата начала'}
                  placeholder={dayjs().format(RU_DATE)}
                  value={values.dateFrom}
                  onChange={value => {
                    setFieldValue('dateFrom', value);
                  }}
                  disabled
                  onBlur={() => {
                    setFieldTouched('dateFrom');
                  }}
                  minDate={dayjs()}
                  error={touched.dateFrom && errors.dateFrom ? errors.dateFrom : undefined}
                />
                <TimePickerInput
                  className={'col-span-1'}
                  label={'Время начала'}
                  placeholder={dayjs().format(TIME_DATE)}
                  value={values.timeFrom}
                  onChange={value => {
                    setFieldValue('timeFrom', value).then(() => setFieldTouched('timeFrom'));
                  }}
                  disabled
                  onBlur={() => {
                    setFieldTouched('timeFrom');
                  }}
                  error={touched.timeFrom && errors.timeFrom ? errors.timeFrom : undefined}
                />
                <div className={'col-span-1'}></div>
                <DatePickerInput
                  divClassName={'col-span-1'}
                  label={'Дата завершения'}
                  disabled
                  placeholder={dayjs().format(RU_DATE)}
                  value={values.dateTo}
                  onChange={value => {
                    setFieldValue('dateTo', value);
                  }}
                />
                <TimePickerInput
                  className={'col-span-1'}
                  label={'Время завершения'}
                  disabled
                  placeholder={dayjs().format(RU_DATE)}
                  value={values.timeTo}
                  onChange={value => {
                    setFieldValue('timeTo', value);
                  }}
                />
                <SimpleCheckbox
                  divClassName={'pt-7'}
                  name={'endless'}
                  disabled
                  label={'Бессрочно'}
                  onChange={handleChange}
                  checked={values.endless}
                />
                <SimpleSelect
                  divClassName={'col-span-1'}
                  label={'Повтор'}
                  disabled
                  options={repeatConditionOptions}
                  placeholder={'Выберите повтор'}
                  onChange={value => {
                    setFieldValue('repeatCondition', value);
                  }}
                  onBlur={() => {
                    setFieldTouched('repeatCondition');
                  }}
                  value={values.repeatCondition}
                  helperText={'Повтор сбросит бонус'}
                  error={touched.repeatCondition && errors.repeatCondition ? errors.repeatCondition : undefined}
                />
              </div>
              <hr className={'bg-smena_gray-30'} />
              <div className={'flex justify-between'}>
                <button className={'btn-stroke_reject'} type={'button'} onClick={onRemovePromotion}>
                  Параметры по умолчанию
                </button>
                <button className={'btn-secondary'} type={'button'} onClick={onPublishBonus}>
                  {isExist ? 'Изменить бонус. программу' : 'Опубликовать бонус. программу'}
                </button>
              </div>
              <EditBonusProgramPopup show={showEditPopup} setShow={setShowEditPopup} promotion={el} />
              <DefaultPromotionPopup show={defaultPromotionPopup} setShow={setDefaultPromotionPopup} promotion={el} />
            </Form>
          );
        }}
      </Formik>
    </Spoiler>
  );
};

export default Promotion;
