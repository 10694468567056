import clsx from 'clsx';

const ColoredCircle = ({ color }: { color: string }) => {
  return (
    <div className="relative inline-block">
      <div className={clsx('w-4 h-4 rounded-full relative opacity-25', color)}></div>
      <div
        className={clsx(
          'w-2 h-2 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20',
          color
        )}
      ></div>
    </div>
  );
};

export default ColoredCircle;
