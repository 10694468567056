import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ScrollSync } from 'react-scroll-sync';
import dayjs from 'dayjs';
import clsx from 'clsx';
import Skeleton from 'antd/es/skeleton';

import MainLayout from 'components/layout/main';
import SimpleSelect from 'ui/select';
import RequestCalendarPicker from 'pages/request/components/calendar';

import { ISO_DATE } from 'utils/helpers/constVariables';
import useFacilityHeaderMenu from 'utils/hooks/headerMenu/useFacilityHeaderMenu';
import useFacilityBreadcrumbs from 'utils/hooks/breadcrumbs/useFacilityBreadcrumbs';

import { useDaysCount } from 'utils/helpers/differenceBetweenDays';
import { useUsersAtScheduleRequest } from './hooks';
import Legend from 'assets/icons/Legend';
import ChevronDown from 'assets/icons/chevron-down';
import { useFacilityCasingsLazyQuery } from 'pages/facilityCasings/api/queries/generated/FacilityCasings';
import ScheduleHeader from './components/scheduleHeader';
import ScheduleTable from './components/table';
import { useFacility } from 'utils/api/useFacility';
import { useGetRequestByFacilityId } from '../../utils/api/useGetRequestByFacilityId';
import { useFacilityTitle } from '../facilityVacancies/hooks/pageProps';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import { useStore } from 'store';
import FacilityScheduleLegend from './components/legend/FacilityScheduleLegend';
import TelegramIcon from '../../assets/icons/TelegramIcon';
import { useSendFreeShiftsByRequestIdsMutation } from './api/mutations/generated/SendFreeShiftsByRequestIds';
import { errorToast, successToast } from '../../utils/helpers/notify';
import { errorHandler } from '../../utils/helpers/errorHandler';
import ScheduleInfo from './components/sidebar/schedule';
import ShiftSidebar from './components/sidebar/shift';
import Modal from 'ui/modal';

const FacilitySchedule = () => {
  const [params, setParams] = useSearchParams();
  const setFacilityWorkposts = useStore(state => state.setFacilityWorkposts);
  const [date, setDate] = useState<DayjsOrNull | undefined>(dayjs().startOf('month'));

  const [showLegendPopup, setShowLegendPopup] = useState(false);
  const [showMakeFreeShiftsPopup, setShowMakeFreeShiftsPopup] = useState(false);
  const { id } = useFacility();
  const dateParam = params.get('date');
  const positionId = useStore(state => state.positionId);
  const workerId = useStore(state => state.workerId);
  const setPositionId = useStore(state => state.setPositionId);
  const setWorkerId = useStore(state => state.setWorkerId);
  const facilityScheduleIds = useStore(state => state.facilityScheduleIds);
  const [sendFreeShiftsByRequestIds] = useSendFreeShiftsByRequestIdsMutation();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    if (!id) return;
    sendFreeShiftsByRequestIds({
      variables: {
        input: {
          requestIds: facilityScheduleIds,
          facilityId: id,
        },
      },
    })
      .then(() => {
        successToast('Неназначенные смены успешно отправлены');
      })
      .catch(err => errorToast(errorHandler(err)))
      .finally(() => setShowMakeFreeShiftsPopup(false));
  };

  const [loadData] = useFacilityCasingsLazyQuery();
  const title = useFacilityTitle();
  const handleWorkerIdChange = (value: string) => {
    setPositionId(undefined);
    setWorkerId(value);
  };
  const handlePositionIdChange = (value: string) => {
    setPositionId(value);
  };
  const showLegendHandler = () => {
    setShowLegendPopup(value => !value);
  };
  const days = useDaysCount({
    dateFrom: date?.startOf('month').format(ISO_DATE),
    dateTo: date?.endOf('month').format(ISO_DATE),
  });
  const { loading, error } = useGetRequestByFacilityId();
  const { usersOptions, positionOptions } = useUsersAtScheduleRequest();
  const headerMenu = useFacilityHeaderMenu();
  const breadCrumbs = useFacilityBreadcrumbs();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (dateParam) {
      setDate(dayjs(dateParam));
    }
  }, [dateParam]);

  useEffect(() => {
    if (id) {
      loadData({
        variables: {
          facilityId: id,
        },
        onCompleted: data => {
          if (data) {
            setFacilityWorkposts(data.facilityCasings);
          }
        },
      });
    }
  }, [id, loadData, setFacilityWorkposts]);

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs} error={error}>
      <div className="grid grid-cols-12 gap-6">
        <ScheduleHeader />
        <div className="xl:col-span-9 col-span-full p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-6">
          <div className={'flex flex-col gap-y-6'}>
            <div className="flex justify-between items-center relative">
              <h3 className="Subtitle1 text-smena_text-secondary">Графики</h3>
              <button
                className="Button1 text-primary flex items-center gap-x-2"
                onClick={showLegendHandler}
                ref={buttonRef}
              >
                <Legend />
                <span>Обозначения</span>
                <span className={clsx('inline-block transition-all', showLegendPopup && 'rotate-180 ')}>
                  <ChevronDown />
                </span>
              </button>
              <FacilityScheduleLegend
                showLegendPopup={showLegendPopup}
                setShowLegendPopup={setShowLegendPopup}
                triggerRef={buttonRef}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-x-6">
                <SimpleSelect
                  divClassName="w-[200px]"
                  label={'Исполнители'}
                  onChange={handleWorkerIdChange}
                  value={workerId}
                  options={usersOptions}
                  allOption="Все исполнители"
                  placeholder={'Выберите исполнителя'}
                />
                <SimpleSelect
                  divClassName="w-[200px]"
                  label={'Профессии'}
                  onChange={handlePositionIdChange}
                  value={positionId}
                  options={positionOptions}
                  allOption="Все профессии"
                  placeholder={'Выберите профессию'}
                />
              </div>
              <RequestCalendarPicker date={date} params={params} setParams={setParams} />
            </div>
          </div>
          <ScrollSync>{loading ? <Skeleton active /> : <ScheduleTable days={days} />}</ScrollSync>
          <button
            type={'button'}
            className={'link self-start Button1 flex items-center gap-x-2'}
            onClick={() => {
              setShowMakeFreeShiftsPopup(true);
            }}
          >
            <TelegramIcon />
            Отправить неназначенные смены исполнителям
          </button>

          <Modal
            title="Сделать смены свободными?"
            open={showMakeFreeShiftsPopup}
            onCancel={() => setShowMakeFreeShiftsPopup(false)}
            okText="Сделать свободными"
            okButtonProps={{ form: 'makeShiftsFree', htmlType: 'submit' }}
          >
            <form id="makeShiftsFree" onSubmit={onSubmit}>
              Вы уверены, что хотите все неназначенные смены сделать свободными?
            </form>
          </Modal>
        </div>
      </div>
      <ScheduleInfo />
      <ShiftSidebar />
    </MainLayout>
  );
};

export default FacilitySchedule;
