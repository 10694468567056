import { facilityName } from 'utils/helpers/filters/filtersName';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useMemo } from 'react';
import { UserExperienceFragment } from '../../user/api/fragments/generated/UserExperience.fragment';
import { useCurrentUser } from 'utils/api/useUser';

export const useUserExperience = () => {
  const { user } = useCurrentUser();
  const { facilities } = useSelectFacilities();
  return useMemo(
    () =>
      user?.userExperience
        ? [...user.userExperience].sort((a, b) => {
            if (a && b) {
              if (facilityName(facilities, a.facilityId) < facilityName(facilities, b.facilityId)) {
                return -1;
              }
              if (facilityName(facilities, a.facilityId) > facilityName(facilities, b.facilityId)) {
                return 1;
              }
            }
            return 0;
          })
        : [],
    [facilities, user?.userExperience],
  );
};

export const useUserExperienceFirstElement = (experience: UserExperienceFragment) => {
  const { user } = useCurrentUser();
  const sameFacilities = useMemo(
    () => user?.userExperience?.filter(experienceEl => experienceEl.facilityId === experience.facilityId),
    [experience.facilityId, user?.userExperience],
  );
  const sameFacilitiesIndex = useMemo(
    () =>
      sameFacilities?.findIndex(
        experienceEl =>
          experienceEl.facilityId === experience.facilityId && experienceEl.positionId === experience.positionId,
      ),
    [experience.facilityId, experience.positionId, sameFacilities],
  );

  return {
    sameFacilities,
    sameFacilitiesIndex,
  };
};

export const useAddPaddingAndBorder = (experience: UserExperienceFragment) => {
  const { sameFacilities, sameFacilitiesIndex } = useUserExperienceFirstElement(experience);
  return useMemo(
    () =>
      !!sameFacilities?.length &&
      sameFacilitiesIndex &&
      sameFacilities[sameFacilities.length - 1] === sameFacilities[sameFacilitiesIndex],
    [sameFacilities, sameFacilitiesIndex],
  );
};
