interface Props {
  row?: any;
}

const RowStats: React.FC<Props> = ({ row }) => {
  const shifts = row?.shifts || [];
  const shiftsCount = shifts.length;

  return (
    <div className="flex border-b-[1px] Body2">
      <div className="w-[90px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] h-[36px]">{row ? shiftsCount : null}</div>
      <div className="w-[80px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] h-[36px]">{row?.analytics?.workTimeFact}</div>
      <div className="w-[155px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] h-[36px]">{row?.analytics?.toPaymentCount}</div>
      <div className="w-[80px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] h-[36px]">{row?.analytics?.workTimeFact}</div>
      <div className="w-[80px] pt-2 pb-2 pl-4 pr-4 h-[36px]">{row?.analytics?.workTimePlan}</div>
    </div>
  );
};

export default RowStats;
