import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Role, WorkPostStatus } from 'generated/graphql';
import uniqueArray from 'utils/helpers/uniqueArray';
import { morph } from 'utils/helpers/morph';
import { userRole } from 'pages/main/lib/helpers/userRole';

interface Props {
  user?: {
    id: string;
    role: Role;
    Facility_FOREMAN?:
      | {
          id: string;
          name: string;
        }[]
      | null;
    workposts?:
      | {
          status: string;
          positionId: string;
          facilityId: string;
          facility?: {
            id: string;
            name: string;
          } | null;
        }[]
      | null;
  } | null;
}

const FacilityCell = ({ user }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const isUserWorker = userRole([Role.Worker], user);
  const isUserForeman = userRole([Role.Foreman], user);
  const showAllHandle = () => {
    setShowAll(value => !value);
  };

  const userFacilities = useMemo(() => {
    if (isUserWorker) {
      return uniqueArray({
        array: user?.workposts?.filter(el => el.status === WorkPostStatus.Active),
        field: 'facilityId',
      }).map(el => el.facility);
    } else if (isUserForeman) {
      return user?.Facility_FOREMAN;
    }
  }, [isUserForeman, isUserWorker, user?.Facility_FOREMAN, user?.workposts]);

  return (
    <div className={'flex flex-col gap-y-1'}>
      {userFacilities?.map((facility, index) => {
        const condition = !showAll ? index < 2 : true;
        if (condition) {
          return (
            <React.Fragment key={facility?.id}>
              <div key={facility?.id} className={'max-w-fit'}>
                <Link to={`/facilities/${facility?.id}`} className="text-primary link" title={facility?.name}>
                  {facility?.name}
                </Link>
              </div>
              {index === 1 && userFacilities.length > 2
                ? !showAll && (
                    <button className={'Table-small text-left text-primary'} type={'button'} onClick={showAllHandle}>
                      {`+${userFacilities.length - 2} ${morph(userFacilities.length - 2, [
                        'объект',
                        'объекта',
                        'объектов',
                      ])}`}
                    </button>
                  )
                : showAll &&
                  userFacilities.length - 1 === index && (
                    <button className={'Table-small text-left text-primary'} type={'button'} onClick={showAllHandle}>
                      Свернуть
                    </button>
                  )}
            </React.Fragment>
          );
        }
      })}
    </div>
  );
};

export default FacilityCell;
