import React from 'react';
import { TIcon } from 'interfaces/TIcon';
import clsx from 'clsx';

const BalanceIcon = ({ className }: TIcon) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 13.75C15.5 13.5511 15.579 13.3603 15.7197 13.2197C15.8603 13.079 16.0511 13 16.25 13H18.25C18.4489 13 18.6397 13.079 18.7803 13.2197C18.921 13.3603 19 13.5511 19 13.75C19 13.9489 18.921 14.1397 18.7803 14.2803C18.6397 14.421 18.4489 14.5 18.25 14.5H16.25C16.0511 14.5 15.8603 14.421 15.7197 14.2803C15.579 14.1397 15.5 13.9489 15.5 13.75ZM3 5H3.014C3.07549 4.44999 3.33754 3.94194 3.75004 3.57297C4.16255 3.20401 4.69656 3.00002 5.25 3H16.75C17.0455 3 17.3381 3.0582 17.611 3.17127C17.884 3.28434 18.1321 3.45008 18.341 3.65901C18.5499 3.86794 18.7157 4.11598 18.8287 4.38896C18.9418 4.66194 19 4.95453 19 5.25V6.087C19.7118 6.25601 20.3458 6.66019 20.7995 7.23417C21.2532 7.80815 21.5 8.51838 21.5 9.25V17.75C21.5 18.612 21.1576 19.4386 20.5481 20.0481C19.9386 20.6576 19.112 21 18.25 21H6.25C5.38805 21 4.5614 20.6576 3.9519 20.0481C3.34241 19.4386 3 18.612 3 17.75V5ZM18.25 7.5H4.5V17.75C4.5 18.716 5.284 19.5 6.25 19.5H18.25C18.7141 19.5 19.1592 19.3156 19.4874 18.9874C19.8156 18.6592 20 18.2141 20 17.75V9.25C20 8.78587 19.8156 8.34075 19.4874 8.01256C19.1592 7.68437 18.7141 7.5 18.25 7.5ZM17.5 6V5.25C17.5 5.05109 17.421 4.86032 17.2803 4.71967C17.1397 4.57902 16.9489 4.5 16.75 4.5H5.25C5.05109 4.5 4.86032 4.57902 4.71967 4.71967C4.57902 4.86032 4.5 5.05109 4.5 5.25C4.5 5.44891 4.57902 5.63968 4.71967 5.78033C4.86032 5.92098 5.05109 6 5.25 6H17.5Z"
        className={clsx('fill-current', className)}
        // fill="#393B55"
      />
    </svg>
  );
};

export default BalanceIcon;
