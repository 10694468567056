import { useCallback } from 'react';
import { useStore } from 'store';
import { errorToast, successToast } from 'utils/helpers/notify';
import { userQueryConfigFn } from 'utils/graphqlConfigs/userQueryConfig';
import { errorHandler } from 'utils/helpers/errorHandler';
import { ISO_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';
import { useUnassignedWorkerFromPositionMutation } from 'pages/user/api/mutations/generated/UnassignedWorkerFromPosition';
import Modal from 'ui/modal';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useParams } from 'react-router';
import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';

const StopCooperationPopup: React.FC = () => {
  const { facilityId } = useParams();
  const { stopCooperationDate, stopCooperationComment } = useStore();
  const {
    setShowStopCooperationPopup,
    stopCooperationAllPositions,
    setShowStopCooperationAtAllFacilitiesPopup,
    stopCooperationPopup,
    user,
  } = useFacilityReportStore();
  const onCloseShowStopCooperationPopup = useCallback(
    () => setShowStopCooperationPopup(false),
    [setShowStopCooperationPopup],
  );

  const userConfig = userQueryConfigFn(user?.id);
  //wait workpost id
  const [unassignedWorkerFromPosition] = useUnassignedWorkerFromPositionMutation({
    awaitRefetchQueries: true,
    refetchQueries: [userConfig, GetSupervisorWorkSheetDocument],
  });
  const onClickShowStopCooperationPopup = useCallback(() => {
    if (stopCooperationAllPositions) {
      setShowStopCooperationAtAllFacilitiesPopup(true);
      setShowStopCooperationPopup(false);
    } else {
      if (user) {
        unassignedWorkerFromPosition({
          variables: {
            input: {
              userId: user?.id,
              positionId: user.positionId,
              facilityId: facilityId || '',
              unassignAt: dayjs(stopCooperationDate).format(ISO_DATE),
              unassignComment: stopCooperationComment,
            },
          },
        })
          .then(() => {
            successToast('Сотрудник успешно откреплен');
          })
          .catch(error => errorToast(errorHandler(error)));
      }
      setShowStopCooperationPopup(false);
    }
  }, [
    stopCooperationAllPositions,
    setShowStopCooperationAtAllFacilitiesPopup,
    setShowStopCooperationPopup,
    user,
    unassignedWorkerFromPosition,
    facilityId,
    stopCooperationDate,
    stopCooperationComment,
  ]);
  return (
    <Modal
      title="Изменить статус?"
      open={stopCooperationPopup}
      onCancel={onCloseShowStopCooperationPopup}
      onOk={onClickShowStopCooperationPopup}
    >
      Исполнитель будет откреплен от {stopCooperationAllPositions ? 'всех профессий' : 'этой профессии'} на этом
      объекте.
    </Modal>
  );
};

export default StopCooperationPopup;
