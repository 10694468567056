import React, { useEffect, useMemo } from 'react';
import { useGetUsersWhoRespondedToTheRequestScheduleLazyQuery } from 'pages/facilityRequestsSchedule/api/queries/generated/GetUsersWhoRespondedToTheRequestSchedule';
import { useStore } from 'store';
import WantedShift from './wantedShift';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useAcceptRequestShiftsSuggestionsMutation } from '../../../api/mutations/generated/AcceptRequestShiftsSuggestions';
import dayjs from 'dayjs';
import { useDeclineRequestShiftsSuggestionsMutation } from '../../../api/mutations/generated/DeclineRequestShiftsSuggestions';

const FreeSchedule = () => {
  const freeShiftUsers = useStore(state => state.freeShiftUsers);
  const setFreeShiftUsers = useStore(state => state.setFreeShiftUsers);
  const requestSchedule = useStore(state => state.requestSchedule);
  const setShowGraphicSidebar = useStore(state => state.setShowGraphicSidebar);
  const [loadQuery, { data: getUsersWhoResponded }] = useGetUsersWhoRespondedToTheRequestScheduleLazyQuery();
  const [acceptRequestShiftsSuggestions] = useAcceptRequestShiftsSuggestionsMutation();
  const [declineRequestShiftsSuggestions] = useDeclineRequestShiftsSuggestionsMutation();
  const shifts = useMemo(() => {
    const arr = getUsersWhoResponded?.getUsersWhoRespondedToTheRequestSchedule.zayavkaShift;
    if (!arr) return [];
    return [...arr].sort((a, b) => {
      return dayjs(a.dateFrom).valueOf() - dayjs(b.dateFrom).valueOf();
    });
  }, [getUsersWhoResponded?.getUsersWhoRespondedToTheRequestSchedule.zayavkaShift]);
  useEffect(() => {
    if (!requestSchedule) return;
    loadQuery({
      variables: {
        input: {
          requestScheduleId: requestSchedule.id,
        },
      },
      fetchPolicy: 'cache-and-network',
    });
  }, [loadQuery, requestSchedule]);
  const approveUser = () => {
    if (!requestSchedule?.id || !freeShiftUsers.length) return;
    acceptRequestShiftsSuggestions({
      variables: {
        input: {
          requestScheduleId: requestSchedule.id,
          acceptedSuggestionIds: freeShiftUsers,
        },
      },
    })
      .then(() => {
        successToast('Пользователь подтвержден');
      })
      .catch(err => {
        errorToast(errorHandler(err));
      })
      .finally(() => {
        setShowGraphicSidebar(false);
        setFreeShiftUsers([]);
      });
  };
  const declineShift = () => {
    if (!requestSchedule || !freeShiftUsers.length) return;
    declineRequestShiftsSuggestions({
      variables: {
        input: {
          requestScheduleId: requestSchedule.id,
          declinedSuggestionIds: freeShiftUsers,
        },
      },
    })
      .then(() => {
        successToast('Пользователь отклонен');
        setFreeShiftUsers([]);
      })
      .catch(err => {
        errorToast(errorHandler(err));
      })
      .finally(() => {
        setShowGraphicSidebar(false);
        setFreeShiftUsers([]);
      });
  };

  return (
    <div className={'flex flex-col gap-y-2'}>
      <div className={'flex flex-col gap-y-1'}>
        {shifts?.map(shift => <WantedShift shift={shift} key={shift.id} />)}
      </div>
      <button className={'btn-primary self-end'} onClick={approveUser} disabled={!freeShiftUsers.length}>
        Подтвердить исполнителей
      </button>
      <button className={'btn-reject self-end'} onClick={declineShift} disabled={!freeShiftUsers.length}>
        Отклонить
      </button>
    </div>
  );
};

export default FreeSchedule;
