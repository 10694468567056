import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Calendar, dayjsLocalizer, SlotInfo } from 'react-big-calendar';
import dayjs from 'dayjs';

import { useStore } from 'store';

import { shiftsClassName } from 'utils/helpers/shiftsClassName';
import { LegendShifts } from './legend';
import { ICustomEvent } from 'interfaces/IEvent';

import { ISO_DATE } from 'utils/helpers/constVariables';
import useEvents from '../lib/hooks/useEvents';
import CustomToolbar from 'components/calendar/customToolbar';
import CustomEvent from 'components/calendar/customEvent';
import CustomDateCell from 'components/calendar/customDateCell/trackingCalendar';

const localizer = dayjsLocalizer(dayjs);

const ShiftsCalendar = () => {
  const [params, setParams] = useSearchParams();
  const { setSelectedShifts, setShowSidebar, showSidebar } = useStore();
  const events = useEvents();

  const mainEventPropGetter = (event: ICustomEvent): React.HTMLAttributes<HTMLDivElement> => {
    const { resource } = event;
    return shiftsClassName(resource);
  };

  const onSelectSlot = (slotInfo: SlotInfo) => {
    const shifts = events?.filter(event => dayjs(event.start).startOf('day').valueOf() === Number(slotInfo.start));
    if (shifts.length) {
      setShowSidebar(true);
      setSelectedShifts(shifts);
    }
  };

  const onNavigateHandler = (newDate: Date) => {
    const dateFrom = dayjs(newDate).startOf('month');
    const dateTo = dayjs(newDate).endOf('month');

    params.set('dateFrom', dateFrom.format(ISO_DATE));
    params.set('dateTo', dateTo.format(ISO_DATE));
    setParams(params);
  };

  const onShowMoreHandler = useCallback(
    (shifts: ICustomEvent[]) => {
      if (showSidebar) {
        setShowSidebar(false);
        setTimeout(() => {
          if (shifts.length) {
            setSelectedShifts(shifts);
            setShowSidebar(true);
          }
        }, 300);
      } else {
        if (shifts.length) {
          setSelectedShifts(shifts);
          setShowSidebar(true);
        }
      }
    },
    [setShowSidebar, setSelectedShifts, showSidebar],
  );

  return (
    <div className="shifts__wrapper flex flex-wrap">
      <div className="shifts__calendar">
        <Calendar
          localizer={localizer}
          formats={{
            weekdayFormat: 'dd',
            dateFormat: 'D',
          }}
          length={10}
          events={events}
          startAccessor="start"
          endAccessor="end"
          defaultView="month"
          views={['month']}
          messages={{
            showMore: target => `...еще ${target}`,
          }}
          selectable
          onSelectSlot={onSelectSlot}
          onNavigate={onNavigateHandler}
          eventPropGetter={mainEventPropGetter}
          components={{
            event: CustomEvent,
            toolbar: CustomToolbar,
            dateCellWrapper: CustomDateCell,
            month: {
              dateHeader: function dateHeaderFn({ label }) {
                return <div onClick={undefined}>{label}</div>;
              },
            },
          }}
          onShowMore={onShowMoreHandler}
          doShowMoreDrillDown={false}
        />
      </div>
      <LegendShifts />
    </div>
  );
};

export default ShiftsCalendar;
