import { DayjsOrNull, undefStr } from 'interfaces/CustomTypes';
import { useCreateShiftInModerationMutation } from 'pages/moderationReport/api/mutations/generated/CreateShiftInModeration';
import { getDateMinutes } from 'pages/moderationReport/components/sidebar/dailyShifts/create/helpers';
import { errorHandler } from 'utils/helpers/errorHandler';
import { isInt } from 'utils/helpers/isInt';
import { successToast, errorToast } from 'utils/helpers/notify';
import { timeDifference } from 'utils/helpers/timeDifference';
import dayjs from 'dayjs';
import { useStore } from 'store';
import { useParams } from 'react-router';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { useSearchParams } from 'react-router-dom';
import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { UnitType } from 'generated/graphql';

interface Props {
  startTime: DayjsOrNull;
  endTime: DayjsOrNull;
  shiftDuration: DayjsOrNull;
  lunchDuration: number;
  hourRate: string;
  toPayment?: boolean;
  toPaymentBonus?: boolean;
  value: string;
  reason: undefStr;
  comment: undefStr;
  showComment: boolean;
  paymentArrestedSum?: number;
  paymentArrestedCause?: string;
  paymentArrestedComment?: string;
  paymentSalarySum?: number;
  paymentSalaryCause?: string;
  paymentSalaryComment?: string;
}

export const useCreateForm = () => {
  const { facilityId } = useParams();
  const [params] = useSearchParams();
  const dateParam = params.get('date');
  const { user, selectedDay } = useFacilityReportStore();
  const [createShift] = useCreateShiftInModerationMutation({ refetchQueries: [GetSupervisorWorkSheetDocument] });
  const setShowSidebar = useStore(state => state.setShowSidebar);
  const hour = UnitType.Hour;

  const initialValues: Props = {
    startTime: null,
    endTime: null,
    shiftDuration: null,
    lunchDuration: 0,
    hourRate: '0',
    toPayment: true,
    toPaymentBonus: true,
    value: '0',
    reason: undefined,
    comment: undefined,
    showComment: true,
    paymentArrestedSum: undefined,
    paymentArrestedCause: undefined,
    paymentArrestedComment: undefined,
    paymentSalarySum: undefined,
    paymentSalaryCause: undefined,
    paymentSalaryComment: undefined,
  };

  const validate = (values: Props) => {
    const errors: any = {};
    if (!values.startTime) {
      errors.startTime = 'Обязательное поле';
    }

    if (!values.endTime) {
      errors.endTime = 'Обязательное поле';
    }

    if (!+values.hourRate) {
      errors.hourRate = 'Обязательное поле';
    }

    if (hour) {
      if (!values.shiftDuration) {
        errors.shiftDuration = 'Обязательное поле';
      }
    } else {
      if (!values.value) {
        errors.value = 'Обязательное поле';
      }
    }
    if (!+values.hourRate) {
      errors.hourRate = 'Обязательное поле';
    }

    if (values.paymentArrestedSum || values.paymentArrestedCause || values.paymentArrestedComment) {
      if (!values.paymentArrestedSum) {
        errors.paymentArrestedSum = 'Обязательное поле';
      }
      if (!values.paymentArrestedCause) {
        errors.paymentArrestedCause = 'Обязательное поле';
      }
      if (!values.paymentArrestedComment) {
        errors.paymentArrestedComment = 'Обязательное поле';
      }
    }

    if (values.paymentSalarySum || values.paymentSalaryCause || values.paymentSalaryComment) {
      if (!values.paymentSalarySum) {
        errors.paymentSalarySum = 'Обязательное поле';
      }
      if (!values.paymentSalaryCause) {
        errors.paymentSalaryCause = 'Обязательное поле';
      }
      if (!values.paymentSalaryComment) {
        errors.paymentSalaryComment = 'Обязательное поле';
      }
    }

    return errors;
  };

  const getFormattedDateTime = (time: DayjsOrNull) => {
    if (!time) return null;

    const [year, month] = dateParam?.split('-') || [dayjs().year().toString(), (dayjs().month() + 1).toString()];
    const day = selectedDay ?? dayjs().date();

    return dayjs()
      .year(Number(year))
      .month(Number(month) - 1)
      .date(day)
      .hour(time.hour())
      .minute(time.minute())
      .second(0)
      .millisecond(0)
      .toISOString();
  };

  const onSubmit = (values: Props) => {
    const dateFrom = getFormattedDateTime(values.startTime);
    const shiftEndDate = getFormattedDateTime(values.endTime);

    createShift({
      variables: {
        moderation: {
          value: isInt(parseFloat(values.value)),
          hourRate: isInt(parseFloat(values.hourRate)),
          duration: getDateMinutes(values.shiftDuration),
          cause: values.reason,
          comment: values.comment,
          toPayment: values.toPayment,
          toPaymentBonus: values.toPaymentBonus,
          paymentArrestedSum: Number(values.paymentArrestedSum) || undefined,
          paymentArrestedCause: values.paymentArrestedCause,
          paymentArrestedComment: values.paymentArrestedComment,
          paymentSalarySum: Number(values.paymentSalarySum) || undefined,
          paymentSalaryCause: values.paymentSalaryCause,
          paymentSalaryComment: values.paymentSalaryComment,
        },
        shift: {
          userId: user?.user?.id,
          facilityId: facilityId || '',
          positionId: user?.workPost?.position?.id,
          dateFrom,
          shiftEndDate,
          duration: String(timeDifference(values.startTime, values.endTime)),
          lunchDuration: values.lunchDuration,
        },
      },
    })
      .then(e => {
        if (e.data?.createShiftInModeration) {
          successToast('Смена успешно создана');
          setShowSidebar(false);
        }
      })
      .catch(e => {
        errorToast(errorHandler(e));
      });
  };
  return { initialValues, onSubmit, validate };
};
