import React from 'react';
import { useFacilityGroupTitle } from './hooks/useFacilityGroupTitle';
import useFacilityGroupMenu from '../../utils/hooks/headerMenu/useFacilityGroupMenu';
import { useFacilityGroupBreadcrumbs } from './hooks/useFacilityGroupBreadcrumbs';
import MainLayout from 'components/layout/main';
import { useFacilityGroupExtended } from '../../utils/api/useFacilityGroup';
import UpdateGroup from './components/mainInfo';
import ResponsibleBlock from './components/responsibleBlock';
import { Role } from 'generated/graphql';
import { useMeRole } from '../../store/hooks/useMeRole';
import PromotionBlock from './components/promotionBlock';

const FacilityGroup = () => {
  const { loading, error, id } = useFacilityGroupExtended();
  const title = useFacilityGroupTitle();
  const headerMenu = useFacilityGroupMenu();
  const breadcrumbs = useFacilityGroupBreadcrumbs();
  const isMeAdminOrSupervisor = useMeRole([Role.Admin, Role.Supervisor]);

  return (
    <MainLayout
      title={title}
      headerMenu={headerMenu}
      breadCrumbs={breadcrumbs}
      loading={loading}
      error={error}
      className={'grid grid-cols-12 gap-6'}
    >
      <UpdateGroup />
      {id && isMeAdminOrSupervisor ? (
        <>
          <ResponsibleBlock />
          <PromotionBlock />
        </>
      ) : null}
    </MainLayout>
  );
};

export default FacilityGroup;
