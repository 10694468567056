import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Plus from 'assets/icons/plus';
import { useMeRole } from 'store/hooks/useMeRole';
import { useCustomSearchParams } from 'pages/users/lib/useParams';
import TableFilter from 'components/table/filters';
import SimpleSelect from 'ui/select';
import Filter from 'components/table/filters/filter';
import Search from 'ui/search';
import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast } from 'utils/helpers/notify';
import { useCreateStoryMutation } from '../api/mutations/generated/CreateStory';
import { Role, StoryStage } from 'generated/graphql';
import { storyStatuses } from '../utils/constants';
import { Table } from '@tanstack/react-table';
import ColumnSettings from 'components/table/columnSettings';
import { StoryFragment } from '../../story/api/fragments/generated/Strory.fragment';
import { getStoryStatus } from 'utils/helpers/get/getStoryStatus';

interface Props {
  table: Table<StoryFragment>;
}

const StoriesFilter = ({ table }: Props) => {
  const { params, setParams, useParamsCount, handleParams, resetFilter } = useCustomSearchParams();
  const navigate = useNavigate();
  const [createStory, { loading }] = useCreateStoryMutation();
  const stageParam = params.get('stage');
  const [status, setStatus] = useState<string | null>(stageParam);
  const [showFilter, setShowFilter] = useState(false);
  const paramsCount = useParamsCount();
  const btnRef = useRef<HTMLButtonElement>(null);
  const isMeAdmin = useMeRole([Role.Admin]);
  const createStoryHandle = useCallback(() => {
    createStory()
      .then(res => {
        navigate(`/stories/${res.data?.createStory.id}`);
      })
      .catch(error => errorToast(errorHandler(error)));
  }, [createStory, navigate]);

  return (
    <div className="filter-bar flex xs:flex-row flex-col gap-y-2 justify-between">
      <div className="flex flex-col gap-y-2">
        <div className={'flex relative gap-x-2'}>
          <Search />
          <button
            type="button"
            ref={btnRef}
            className="btn-stroke flex gap-x-1 items-center"
            onClick={() => {
              setShowFilter(value => !value);
            }}
          >
            Фильтр
            {Boolean(paramsCount) && (
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                {String(paramsCount)}
              </span>
            )}
          </button>
          <ColumnSettings<StoryFragment> table={table} />
          <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
            <SimpleSelect
              divClassName=""
              label="Статус"
              onChange={value => setStatus(value)}
              allOption={'Все статусы'}
              placeholder={'Не выбрано'}
              value={status}
              options={storyStatuses}
            />

            <div className="flex gap-x-5">
              <button
                className="btn-primary"
                onClick={() => {
                  handleParams('stage', status);
                  params.delete('page');
                  setParams(params);
                  setShowFilter(false);
                }}
              >
                <span>Применить</span>
              </button>
              <button
                className="btn-stroke xs:w-auto w-full"
                onClick={() => {
                  setStatus(null);
                  setParams();
                  setShowFilter(false);
                }}
              >
                Сбросить
              </button>
            </div>
          </TableFilter>
        </div>

        {!!paramsCount && (
          <div className="flex gap-2">
            <Filter
              show={!!stageParam}
              content={'Статус: ' + getStoryStatus(stageParam as StoryStage).name}
              clickHandler={() => {
                setStatus(null);
                resetFilter('stage');
              }}
            />
          </div>
        )}
      </div>
      {isMeAdmin && (
        <button
          type="button"
          disabled={loading}
          onClick={createStoryHandle}
          className="btn-primary_big self-start flex items-center gap-x-2"
        >
          {loading ? (
            'Загрузка'
          ) : (
            <>
              <Plus className="text-smena_white" />
              <span>Новая история</span>
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default StoriesFilter;
