import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainLayout from 'components/layout/main';
import clsx from 'clsx';
import { balanceOperationTypeOptions, RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';

import DatePickerInput from 'ui/pickers/DatePickerInput';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import SimpleSelect from 'ui/select';
import SimpleInput from 'ui/input';
import TextArea from 'ui/textArea';
import BalanceRow from './BalanceRow';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import { BalanceOperationType } from 'generated/graphql';
import useFacilityGroupMenu from 'utils/hooks/headerMenu/useFacilityGroupMenu';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { writeBalanceListCache } from './writeCahce';
import { convertMoney } from 'utils/helpers/convertMoney';
import { useGetFacilityGroupOperationListLazyQuery } from 'pages/balance/api/queries/generated/GetFacilityGroupOperationList';
import { useCreateBalanceOperationMutation } from './api/mutations/generated/CreateBalanceOperation';
import { useFacilityGroupTitle } from '../facilityGroup/hooks/useFacilityGroupTitle';
import { useFacilityGroupBreadcrumbs } from '../facilityGroup/hooks/useFacilityGroupBreadcrumbs';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';

const FacilityGroupBalance = () => {
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState<DayjsOrNull>(null);
  const [time, setTime] = useState<DayjsOrNull>(null);
  const [operation, setOperation] = useState<BalanceOperationType | null>(null);

  const router = useParams();
  const id = router.id;

  const title = useFacilityGroupTitle();
  const headerMenu = useFacilityGroupMenu();
  const breadcrumbs = useFacilityGroupBreadcrumbs();

  const [loadData, { data, loading, error }] = useGetFacilityGroupOperationListLazyQuery();

  const balanceList = data?.getFacilityGroupOperationList;

  const additionOperations = useMemo(() => {
    const balanceListAmount = balanceList
      ?.filter(el => el.operation === BalanceOperationType.Addition)
      .map(el => el.amount);
    return balanceListAmount?.length ? balanceListAmount.reduce((prev, curr) => prev + curr) : 0;
  }, [balanceList]);

  const subtractionOperations = useMemo(() => {
    const balanceListAmount = balanceList
      ?.filter(el => el.operation === BalanceOperationType.Subtraction)
      .map(el => el.amount);
    return balanceListAmount?.length ? balanceListAmount.reduce((prev, curr) => prev + curr) : 0;
  }, [balanceList]);

  const balance = useMemo(
    () => Number(additionOperations) - Number(subtractionOperations),
    [additionOperations, subtractionOperations],
  );

  const sortBalanceList = useMemo(() => sortBy(balanceList, el => dayjs(el.dayAndTime).valueOf()), [balanceList]);

  const lastTransaction = useMemo(() => {
    return dayjs(last(sortBalanceList)?.dayAndTime).format(RU_DATE);
  }, [sortBalanceList]);

  const [createBalanceOperation] = useCreateBalanceOperationMutation();

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!id) return;
      const dayAndTime = dayjs(date).set('hours', dayjs(time).get('hours')).set('minutes', dayjs(time).get('minutes'));
      createBalanceOperation({
        variables: {
          input: {
            facilityGroupId: id,
            amount: Number(amount),
            dayAndTime,
            description,
            operation: operation as BalanceOperationType,
          },
        },

        update: (store, { data }) => {
          writeBalanceListCache({ facilityGroupId: id, store, data: data?.createBalanceOperation });
        },
      })
        .then(res => {
          const data = res.data?.createBalanceOperation;
          if (data) {
            successToast('Транзакция создана');
          }
        })
        .catch(error => errorToast(errorHandler(error)));
    },
    [amount, createBalanceOperation, date, description, id, operation, time],
  );

  useEffect(() => {
    if (id) {
      loadData({
        variables: {
          facilityGroupId: id,
        },
      });
    }
  }, [id, loadData]);
  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadcrumbs} loading={loading} error={error}>
      <div className={'flex flex-col gap-y-3'}>
        <div className={'shadow-smena px-6 py-4 bg-smena_white rounded-lg inline-flex flex-col w-fit'}>
          <span className={clsx('ChartBigNumber', balance ? (balance >= 0 ? 'text-gray-800' : 'text-smena_red') : '')}>
            {`${convertMoney(balance)} ₽`}
          </span>
          <span className={'Subtitle1'}>последняя транзакция: {lastTransaction}</span>
        </div>
        <div className={'py-8 bg-smena_white w-fit rounded-lg shadow-smena'}>
          <div
            className={
              'grid grid-cols-[200px_100px_150px_180px_220px_115px] gap-x-4 pb-2 border-b border-smena_gray-30 px-6'
            }
          >
            <span className={'Subtitle2 text-smena_text-secondary'}>Дата</span>
            <span className={'Subtitle2 text-smena_text-secondary'}>Время</span>
            <span className={'Subtitle2 text-smena_text-secondary'}>Тип оплаты</span>
            <span className={'Subtitle2 text-smena_text-secondary'}>Сумма, ₽</span>
            <span className={'Subtitle2 text-smena_text-secondary'}>Основание</span>
            <span></span>
          </div>
          <div className={'flex flex-col gap-y-[10px] pt-2'}>
            <form className={'grid grid-cols-[200px_100px_150px_180px_220px_115px] gap-x-4 px-6'} onSubmit={onSubmit}>
              <DatePickerInput
                divClassName="Body2"
                popupClassName="fixed"
                value={date}
                onChange={date => {
                  setDate(date);
                }}
                maxDate={dayjs().endOf('day')}
              />

              <TimePickerInput
                label=""
                className="Body2"
                value={time}
                format={TIME_DATE}
                onChange={value => {
                  setTime(value);
                }}
              />
              <SimpleSelect
                divClassName="Body2"
                label=""
                onChange={value => {
                  setOperation(value as BalanceOperationType);
                }}
                value={operation}
                placeholder="Тип оплаты"
                options={balanceOperationTypeOptions}
              />
              <SimpleInput
                divClassName="Body2"
                label=""
                placeholder="0,00"
                onChange={({ target: { value } }) => {
                  setAmount(value);
                }}
                value={amount}
                name="name"
                type={'text'}
                float
              />
              <TextArea
                name={'description'}
                divClassName={'Body2'}
                value={description}
                placeholder={'Оставьте комментарий'}
                onChange={({ target: { value } }) => setDescription(value)}
              />
              <div className={'flex justify-center'}>
                <button className={'btn-secondary_small'} type={'submit'}>
                  Добавить
                </button>
              </div>
            </form>
            {sortBalanceList?.map(el => <BalanceRow key={el.id} el={el} />)}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default FacilityGroupBalance;
