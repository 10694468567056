import { ChartType } from 'generated/graphql';

export const getStatisticRu = (type: ChartType | string) => {
  const types = [
    { type: ChartType.FinishedShifts, typeRu: 'Завершенные смены' },
    { type: ChartType.GoodShifts, typeRu: 'Хорошие смены' },
    { type: ChartType.GoodFinishedShiftsRatio, typeRu: 'Процент хороших смен' },
  ];

  const res = types.find(el => el.type === type);
  if (!res) return '';
  return res.typeRu;
};
