import { FormikHandlers, FormikState } from 'formik';

import SimpleInput from 'ui/input';
import SimpleSelect from 'ui/select';
import { Role } from 'generated/graphql';
import { regFIO } from 'utils/helpers/constVariables';
import { useMeRole } from 'store/hooks/useMeRole';
import { useCountries } from 'utils/api/useCountries';
import { CreateUserFormProps } from '../../utils/form';
import { UpdateUserFormProps } from '../../../userEdit/utils/form';

type TValues = CreateUserFormProps | UpdateUserFormProps;

type Props = {
  values: TValues;
  setFieldValue: (name: string, value: string) => void;
  handleChange: FormikHandlers['handleChange'];
  touched: FormikState<TValues>['touched'];
  errors: FormikState<TValues>['errors'];
};

export const MainInfo = ({ touched, errors, handleChange, setFieldValue, values }: Props) => {
  const isMeClient = useMeRole([Role.ClientManager, Role.ClientDirector]);

  const { countries } = useCountries();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (regFIO.test(value) || value === '') {
      handleChange(e);
    }
  };

  return (
    <div className={'userPageBlock blockTemplate'}>
      <span className="Subtitle2 text-smena_text-secondary">Основная информация</span>
      <div className="flex gap-x-5">
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Фамилия"
          placeholder="Фамилия"
          maxLength={30}
          onChange={handleInputChange}
          value={values.lastname}
          type="text"
          name="lastname"
          autoComplete="lastname"
          showText={isMeClient}
          error={touched.lastname && errors.lastname ? errors.lastname : ''}
        />
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Имя"
          placeholder="Имя"
          maxLength={30}
          onChange={handleInputChange}
          value={values.firstname}
          type="text"
          name="firstname"
          autoComplete="firstname"
          showText={isMeClient}
          error={touched.firstname && errors.firstname ? errors.firstname : ''}
        />
        <SimpleInput
          divClassName="flex-[1_0]"
          label="Отчество"
          placeholder="Отчество"
          maxLength={30}
          onChange={handleInputChange}
          value={values.patronymic}
          type="text"
          name="patronymic"
          autoComplete="patronymic"
          showText={isMeClient}
        />
      </div>
      <div className={'flex gap-x-5'}>
        <SimpleSelect
          divClassName={'flex-[1_0]'}
          label={'Гражданство'}
          value={values.citizenshipId || 'Не выбрано'}
          onChange={value => {
            setFieldValue('citizenshipId', value);
          }}
          placeholder={'Гражданство'}
          options={countries}
          allOption={'Не выбрано'}
          error={touched.citizenshipId && errors.citizenshipId ? errors.citizenshipId : ''}
        />
        <div className={'flex-[1_0]'}></div>
        <div className={'flex-[1_0]'}></div>
      </div>
    </div>
  );
};
