import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import { OrderDirection } from 'generated/graphql';
import Down from 'assets/icons/sort/down';
import Up from 'assets/icons/sort/up';

const Header = () => {
  const { order, setOrder } = useFacilityReportStore();

  const handleSortClick = () => {
    setOrder(order === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc);
  };

  return (
    <div className="flex border-b-[1px] border-smena_gray-30 bg-smena_gray-5 Table-H1  uppercase sticky top-0 z-50">
      <div className="flex items-center gap-x-3 w-[200px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] border-smena_gray-30 font-medium w-[200px] h-[36px]">
        <span>Исполнитель</span>
        <button onClick={handleSortClick} className="cursor-pointer hover:text-primary">
          <span className="flex flex-col gap-1">
            <Up />
            <Down />
          </span>
        </button>
      </div>
      <div className="w-[150px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] border-smena_gray-30 font-medium w-[150px] h-[36px]">
        Профессия
      </div>
      <div className="w-[110px] pt-2 pb-2 pl-4 pr-4 font-medium w-[110px] border-smena_gray-30 h-[36px]">Действия</div>
    </div>
  );
};

export default Header;
