import clsx from 'clsx';
import { TIcon } from 'interfaces/TIcon';

const GarbageArrow = ({ className }: TIcon) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97557 15.6167C9.73981 15.6072 9.51682 15.507 9.35302 15.3372C9.18922 15.1673 9.09722 14.9409 9.09617 14.7049L9.09679 10.3653L7.74622 10.3684C6.95969 10.3696 6.56455 9.41982 7.12048 8.86389L9.36105 6.62333C9.52634 6.45803 9.75053 6.36517 9.98429 6.36517C10.2181 6.36517 10.4422 6.45803 10.6075 6.62333L12.8381 8.85392C13.3928 9.40861 13.0008 10.3566 12.2161 10.3584L10.855 10.3609L10.8562 14.7635C10.8572 14.878 10.835 14.9916 10.7909 15.0973C10.7467 15.203 10.6816 15.2986 10.5995 15.3784C10.5173 15.4582 10.4199 15.5206 10.3129 15.5616C10.206 15.6027 10.0919 15.6217 9.97744 15.6174L9.97557 15.6167ZM9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9Z"
      />
    </svg>
  );
};

export default GarbageArrow;
