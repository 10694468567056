import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useStore } from 'store';
import MainSidebar from 'ui/sidebar';
import CustomEvent from './customEvent';

const ShiftsSidebar = () => {
  const { showSidebar, setShowSidebar, selectedShifts } = useStore();

  const title = useMemo(
    () => `Смены
    ${selectedShifts ? dayjs(selectedShifts[0].start).format('DD MMMM') : ''}`,
    [selectedShifts]
  );

  return (
    <MainSidebar title={title} show={showSidebar} setShow={setShowSidebar}>
      {selectedShifts?.map(shift => (
        <CustomEvent key={shift.resource?.id} event={shift} />
      ))}
    </MainSidebar>
  );
};

export default ShiftsSidebar;
