import { useParams, useSearchParams } from 'react-router-dom';
import { convertSearch } from 'utils/helpers/searchString';
import {
  PaymentInfoEnum,
  Role,
  SortingWay,
  TaxPartnerInfo,
  UserContractStatus,
  UserFieldsForSorting,
  UserPersonalInfoStatus,
} from 'generated/graphql';
import { activeHandler } from 'utils/helpers/isActiveHandler';
import { useMemo } from 'react';
import { useUsersPaginatedQuery } from 'pages/users/api/queries/generated/UsersPaginated';

export const useUsersPaginated = (isCooperateWithGroup: boolean | null = null) => {
  const [params] = useSearchParams();
  const router = useParams();
  const facilityId = router.facilityId || params.get('facilityId');
  const facilityGroupId = params.get('facilityGroupId');
  const roleParam = params.get('role');
  const citizenshipParam = params.get('citizenship') ?? 'all';
  const documentStatusParam = params.get('documentStatus');
  const personalStatusParam = params.get('personal-status');
  const taxParam = params.get('tax');
  const paymentInfoParam = params.get('payment-info');
  const activeParam = params.get('active');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const {
    data: dataUsers,
    loading: loadingUsers,
    error,
  } = useUsersPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        isCooperateWithGroup,
        searchQuery: convertSearch(searchQuery),
        sortBy: sortBy as UserFieldsForSorting,
        sortWay: sortWay as SortingWay,
        facilityGroupId,
        facilityId,
        contractStatus: documentStatusParam as UserContractStatus,
        isActive: activeHandler(activeParam),
        role: roleParam as Role,
        ...(citizenshipParam === 'all'
          ? {}
          : { citizenshipId: citizenshipParam === 'unknown' ? null : citizenshipParam }),
        personalInfo: personalStatusParam as UserPersonalInfoStatus,
        taxPartnerInfo: taxParam as TaxPartnerInfo,
        paymentInfo: paymentInfoParam as PaymentInfoEnum,
        size,
      },
    },
  });

  const userPaginated = dataUsers?.usersPaginated;
  const users = useMemo(() => userPaginated?.users || [], [userPaginated?.users]);
  return { users, userPaginated, loadingUsers, error, page };
};
