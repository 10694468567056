import React, { useMemo } from 'react';
import { useStore } from 'store';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { ISO_DATE } from 'utils/helpers/constVariables';
import { DateCellWrapperProps } from 'react-big-calendar';

const CustomDateCell = ({ children, value }: DateCellWrapperProps) => {
  const { showSidebar, selectedSchedule } = useStore();
  const isActiveDay = useMemo(() => {
    if (showSidebar && selectedSchedule?.length) {
      return dayjs(selectedSchedule[0].start).format(ISO_DATE) === dayjs(value).format(ISO_DATE);
    }
    return false;
  }, [selectedSchedule, showSidebar, value]);
  return (
    <div className={clsx(children.props.className)}>
      <div className={clsx(isActiveDay ? 'border-[2px] rounded-[6px] border-primary' : '', 'h-full w-full')}></div>
    </div>
  );
};

export default CustomDateCell;
