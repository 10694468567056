import { FacilityBonus } from './form';
import { PromotionEntityFragment } from 'pages/facilityGroup/api/fragments/generated/PromotionEntity.fragment';
import Modal from 'ui/modal';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  promotion: FacilityBonus | PromotionEntityFragment;
};

const EditBonusProgramPopup = ({ show, setShow, promotion }: Props) => {
  const showHandler = () => {
    setShow(value => !value);
  };

  const isExist = !!promotion['__typename'];
  const title = isExist ? 'Изменить бонусную программу?' : 'Опубликовать бонусную программу?';
  const text = isExist
    ? 'Вы уверены, что хотите изменить бонусную программу'
    : 'Вы уверены, что хотите опубликовать бонусную программу?';
  const btnText = isExist ? 'Изменить' : 'Опубликовать';

  return (
    <Modal
      title={title}
      open={show}
      okText={btnText}
      okButtonProps={{ form: 'editPromitionForm', htmlType: 'submit' }}
      onCancel={showHandler}
    >
      {text}
    </Modal>
  );
};

export default EditBonusProgramPopup;
