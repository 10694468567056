import React from 'react';
import clsx from 'clsx';
import LegendRow from './Row';
import { useUserShifts } from '../../../userTracking/lib/hooks/useUserShifts';
import useRefusedShifts from 'utils/hooks/userCalendar/useRefusedShifts';
import useIneligibleShifts from 'utils/hooks/userCalendar/useIneligibleShifts';
import useProbationShifts from 'utils/hooks/userCalendar/useProbationShifts';
import useNotAcceptedByForemanShifts from 'utils/hooks/userCalendar/useNotAcceptedByForemanShifts';
import useNotAcceptedByWorkerShifts from 'utils/hooks/userCalendar/useNotAcceptedByWorkerShifts';
import { useMeRole } from 'store/hooks/useMeRole';
import useRestShits from 'utils/hooks/userCalendar/useRestShits';
import useAcceptedShifts from 'utils/hooks/userCalendar/useAcceptedShifts';
import { Role } from 'generated/graphql';

export const LegendSchedule = () => {
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const { userShifts } = useUserShifts();
  const refusedShifts = useRefusedShifts();
  const ineligibleShifts = useIneligibleShifts();
  const probationShifts = useProbationShifts();
  const acceptedShifts = useAcceptedShifts();
  const notAcceptedByForemanShifts = useNotAcceptedByForemanShifts();
  const restShifts = useRestShits();
  const notAcceptedByWorkerShifts = useNotAcceptedByWorkerShifts();

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex items-center">
        <span className="Table-small text-smena_text-secondary">Всего смен:</span>
        <span className="Caption-numbers text-smena_text ml-1">{userShifts.length}</span>
      </div>
      <div className="flex flex-col gap-y-4">
        <LegendRow text="Принятые смены" bgColor="bg-smena_green-light" array={acceptedShifts} />
        <LegendRow
          text="Ожидают подтверждения бригадира"
          bgColor="bg-smena_yellow-light"
          array={notAcceptedByForemanShifts}
        />
        <LegendRow
          text="Ожидают подтверждения исполнителя"
          bgColor="bg-smena_orange-light"
          array={notAcceptedByWorkerShifts}
        />
        <LegendRow text="Отклоненные" bgColor="bg-smena_gray-30" array={ineligibleShifts} />
        <LegendRow text="Отмененные" bgColor="bg-smena_red-light" array={refusedShifts} />
        <LegendRow text="Пробные смены" bgColor="bg-smena_purple-light" array={probationShifts} />
        <div className="flex items-center mt-7">
          <div className="flex relative mr-1 w-[25px] h-[14px]">
            <span
              style={{ width: '14px', height: '14px' }}
              className={`rounded-full ${
                isMeSupervisor ? 'nonsuper_schedule' : 'bg-smena_green-light'
              } absolute top-0 left-0 opacity-70`}
            ></span>
            <span
              className={clsx(
                'dot-legend',
                isMeSupervisor ? 'nonsuper_schedule' : 'bg-smena_green-light',
                'absolute top-0 left-0 opacity-70',
              )}
            ></span>
            <span className="dot-legend top-0 left-2 bg-smena_red-light absolute opacity-70"></span>
            <span className="dot-legend bg-smena_gray-30 top-2 left-1 absolute opacity-70"></span>
          </div>
          <span className="Caption-numbers text-smena_text mr-2 w-4">{restShifts.length}</span>
          <span className="Table-small text-smena_text-secondary">
            {isMeSupervisor ? 'Смен на недоступном или другом объекте' : 'Смен на другом объекте'}
          </span>
        </div>
      </div>
    </div>
  );
};
