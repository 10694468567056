import { useCallback } from 'react';
import { ChartType } from 'generated/graphql';
import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';
import { GetStatisticQuery } from 'pages/main/api/queries/generated/GetStatistic';

export const useStatisticArray = (data?: GetStatisticQuery | null) => {
  const metricByRole = useCallback(
    (type: ChartType) => {
      return sortBy(data?.getStatistic?.find(statistic => statistic.type === type)?.data, el =>
        dayjs(el.createdAt).valueOf()
      );
    },
    [data?.getStatistic]
  );
  return metricByRole(ChartType.GoodShifts).map((el, index) => {
    return {
      createdAt: dayjs(el.createdAt).valueOf(),
      [ChartType.GoodShifts]: metricByRole(ChartType.GoodShifts)[index].value,
      [ChartType.FinishedShifts]: metricByRole(ChartType.FinishedShifts)[index].value,
    };
  });
};
