import Across from 'assets/icons/Across';
import { useFacilityGroupFacilitiesStore } from '../../store';
import Check from 'assets/icons/check';
import OutsideClick from 'components/outsideComponent';

const StatusOptions = () => {
  const setNewStatus = useFacilityGroupFacilitiesStore(state => state.setNewStatus);
  const showStatusOptionsPopup = useFacilityGroupFacilitiesStore(state => state.showStatusOptionsPopup);
  const setShowStatusOptionsPopup = useFacilityGroupFacilitiesStore(state => state.setShowStatusOptionsPopup);
  const showChangeStatusPopup = useFacilityGroupFacilitiesStore(state => state.showChangeStatusPopup);
  const setShowChangeStatusPopup = useFacilityGroupFacilitiesStore(state => state.setShowChangeStatusPopup);

  const showStatusOptionsHandler = () => {
    setShowStatusOptionsPopup(!showStatusOptionsPopup);
  };
  const showChangeStatusHandler = () => {
    setShowChangeStatusPopup(!showChangeStatusPopup);
  };
  return (
    <OutsideClick
      show={showStatusOptionsPopup}
      setShow={setShowStatusOptionsPopup}
      className={
        'absolute top-full translate-y-2 left-0 flex flex-col gap-y-3 py-6 bg-smena_white rounded-lg shadow-smena z-50 transition-all w-[315px] h-auto'
      }
    >
      <h3 className="H4 px-6">Изменить статус?</h3>
      <button onClick={showStatusOptionsHandler} className={'absolute top-6 right-6'} type={'button'}>
        <Across />
      </button>
      <div className="flex flex-col items-center gap-x-5">
        <button
          className="Body1 hover:bg-smena_bb-background w-full py-2 flex items-center gap-x-2 text-left px-6"
          onClick={() => {
            setNewStatus(true);
            showStatusOptionsHandler();
            showChangeStatusHandler();
          }}
          type={'button'}
        >
          <Check className="text-smena_green-dark" />
          <span>Активный объект</span>
        </button>
        <button
          className="Body1 hover:bg-smena_bb-background w-full py-2 flex items-center gap-x-2 text-left px-6"
          onClick={() => {
            setNewStatus(false);
            showStatusOptionsHandler();
            showChangeStatusHandler();
          }}
          type={'button'}
        >
          <Across className="text-smena_red" />
          <span>Неактивный объект</span>
        </button>
      </div>
    </OutsideClick>
  );
};

export default StatusOptions;
