import { useMemo } from 'react';
import dayjs from 'dayjs';
import { clsx } from 'clsx';

import { useStore } from 'store';
import { userNameFacilitySchedule } from './hooks';
import { useRequestShift } from 'pages/request/lib/hooks/useRequestShift';
import { FacilityRequestSchedulesFragment } from 'pages/facilityRequests/api/fragments/generated/FacilityRequestSchedules.fragment';

interface IRequestProfessionDay {
  day: dayjs.Dayjs;
  requestSchedule?: FacilityRequestSchedulesFragment | null;
}

const RequestProfessionDay = ({ day, requestSchedule }: IRequestProfessionDay) => {
  const setUserIntersect = useStore(state => state.setUserIntersect);
  const setRequestShift = useStore(state => state.setRequestShift);
  const setShowShiftSidebar = useStore(state => state.setShowShiftSidebar);
  const requestShift = useMemo(
    () => requestSchedule?.zayavkaShift?.find(shift => dayjs(shift.dateFrom).isSame(day, 'day')),
    [day, requestSchedule?.zayavkaShift],
  );
  const { className, content } = useRequestShift({ requestShift, day, requestScheduleId: requestSchedule?.id });
  const userName = userNameFacilitySchedule(requestShift?.shift?.user);
  const usersSuggestions = requestShift?.requestShiftSuggestions.length;
  function shiftUser() {
    if (!requestShift) return '';
    if (userName) return userName;
    if (usersSuggestions === 1) return userNameFacilitySchedule(requestShift?.requestShiftSuggestions[0].user);
    if (usersSuggestions) return `${usersSuggestions} исполн.`;
    if (requestShift?.isFree) return 'Свободная';
    return 'Назначить';
  }

  return (
    <button
      className={clsx('flex flex-col justify-center items-center rounded Tag py-0.5', className)}
      type="button"
      onClick={() => {
        if (requestShift) {
          setRequestShift(requestShift);
          setShowShiftSidebar(true);
          setUserIntersect(undefined);
        }
      }}
    >
      <span
        className={clsx(
          usersSuggestions && usersSuggestions > 1 ? 'text-smena_red' : '',
          !usersSuggestions ? (!requestShift?.shiftId ? (requestShift?.isFree ? 'opacity-50' : '') : '') : '',
        )}
      >
        {shiftUser()}
      </span>
      <span>{content}</span>
    </button>
  );
};

export default RequestProfessionDay;
