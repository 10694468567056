import { Link } from 'react-router-dom';

import { mapColumns } from 'utils/helpers/mapColumns';
import { citiesColumns } from 'components/table/columns';
import { CityFragment } from '../../city/api/fragments/generated/City.fragment';

interface Props {
  city: CityFragment;
}

const CityRow = ({ city }: Props) => {
  return (
    <div
      className="table__row grid odd:bg-smena_white even:bg-smena_bb-background_base hover:bg-smena_bb-background"
      style={{
        gridTemplateColumns: mapColumns(citiesColumns),
      }}
    >
      <div className={citiesColumns[0].className}>
        <Link to={`/cities/${city.id}`} className={'link'}>
          {city.name}
        </Link>
      </div>
      <div className={citiesColumns[1].className}>{city.timeZone}</div>
    </div>
  );
};

export default CityRow;
