import { useParams } from 'react-router-dom';
import { usePositionLazyQuery } from '../../../pages/position/api/queries/generated/Position';
import { useEffect } from 'react';

export const usePosition = () => {
  const router = useParams();
  const id = router.positionId;

  const [loadData, { data: dataPosition, loading, error }] = usePositionLazyQuery();

  useEffect(() => {
    if (id) {
      loadData({
        variables: { id },
      });
    }
  }, [loadData, id]);

  const position = dataPosition?.getPosition;
  return { position, loading, error, id };
};

export const usePositionById = (id?: string | null) => {
  const [loadData, { data: dataPosition, loading, error }] = usePositionLazyQuery();

  useEffect(() => {
    if (id) {
      loadData({
        variables: { id },
      });
    }
  }, [loadData, id]);

  const position = dataPosition?.getPosition;
  return { position, loading, error };
};
