import React from 'react';
import MainLayout from '../../components/layout/main';
import PushesFilter from './components/PushesFilter';
import { useSearchParams } from 'react-router-dom';
import { NotifyLettersListSorting, NotifyLetterStatus, SortingWay } from 'generated/graphql';
import { useGetNotifyLettersPaginatedQuery } from './api/queries/generated/GetNotifyLettersPaginated';
import CustomPagination from '../../ui/pagination';
import NewTable from '../../components/table/newTable';
import { usePushesTable } from '../../components/table/columns/pushes/pushes';
import { NotifyLetterPaginatedFragment } from './api/fragments/generated/NotifyLetterPaginated.fragment';

const PushesPage = () => {
  const [params] = useSearchParams();
  const searchQuery = params.get('search');
  const sortWay = params.get('sortWay');
  const sortBy = params.get('sortBy');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;
  const statusParam = params.get('status');
  const { data, loading, error } = useGetNotifyLettersPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        page,
        searchQuery,
        sortWay: sortWay as SortingWay,
        sortBy: sortBy as NotifyLettersListSorting,
        status: statusParam as NotifyLetterStatus,
        size,
      },
    },
  });
  const pushesPaginated = data?.getNotifyLettersPaginated;
  const pushes = pushesPaginated?.notifyLetters || [];
  const table = usePushesTable(pushes);

  return (
    <MainLayout title={'Пуш-уведомления'} breadCrumbs={[{ path: '/pushes', name: 'Пуш-уведомления' }]} error={error}>
      <div className={'flex flex-col shadow-smena bg-smena_white rounded'}>
        <PushesFilter table={table} />
        <NewTable<NotifyLetterPaginatedFragment>
          table={table}
          length={pushes.length}
          loading={loading}
          headerMenu={true}
        />
        {pushesPaginated && <CustomPagination total={pushesPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default PushesPage;
