import { ShiftStage } from 'generated/graphql';

export const getShiftBgColorFuture = (shift: any) => {
  const { stage, authorId, userId } = shift;

  if (stage === ShiftStage.Accepted || stage === ShiftStage.Working) {
    return 'bg-smena_green-extra_light';
  }

  if (stage === ShiftStage.Refused) {
    return 'bg-smena_red-extra_light_tabel';
  }

  if (stage === ShiftStage.Ineligible) {
    return 'bg-smena_gray-30';
  }

  if (stage === ShiftStage.Wasted) {
    return 'bg-smena_yellow-extra_light';
  }

  if (stage === ShiftStage.New) {
    if (authorId !== userId) {
      return 'bg-smena_orange-extra_light';
    } else {
      return 'bg-smena_yellow-extra_light';
    }
  }
};
