import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import MainLayout from 'components/layout/main';
import useFacilityHeaderMenu from 'utils/hooks/headerMenu/useFacilityHeaderMenu';
import useFacilityBreadcrumbs from 'utils/hooks/breadcrumbs/useFacilityBreadcrumbs';
import CustomPagination from 'ui/pagination';
import { useRequestPaginatedQuery } from './api/queries/generated/RequestPaginated';
import { useFacility } from 'utils/api/useFacility';
import { SortingWay, ZayavkaFieldsSorting } from '../../generated/graphql';
import { useFacilityTitle } from '../facilityVacancies/hooks/pageProps';
import { useFacilityRequestsTable } from '../../components/table/columns/requests/facilityRequests';
import NewTable from '../../components/table/newTable';
import RequestsFacilityFilter from './components/RequestsFacilityFilter';
import { PaginatedRequestFragment } from './api/fragments/generated/PaginatedRequest.fragment';

const FacilityRequestsPage = () => {
  const { id } = useFacility();
  const [params] = useSearchParams();

  const status = params.get('status');
  const dateFrom = params.get('dateFrom');
  const dateTo = params.get('dateTo');
  const searchQuery = params.get('search');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const { data, loading, error } = useRequestPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        status,
        page,
        searchQuery: searchQuery && Number(searchQuery) ? searchQuery : '',
        sortBy: sortBy as ZayavkaFieldsSorting,
        sortWay: sortWay as SortingWay,
        dateFrom,
        dateTo,
        facilityId: id,
        size,
      },
    },
  });

  const requestsPaginated = data?.zayavkiPaginated;
  const requests = useMemo(() => requestsPaginated?.zayavky || [], [requestsPaginated?.zayavky]);
  const table = useFacilityRequestsTable(requests);
  const title = useFacilityTitle();
  const headerMenu = useFacilityHeaderMenu();
  const breadCrumbs = useFacilityBreadcrumbs();

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadCrumbs} error={error}>
      <div className={'grid gap-y-5 shadow-smena rounded bg-smena_white'}>
        <RequestsFacilityFilter table={table} />
        <NewTable<PaginatedRequestFragment>
          table={table}
          length={requests.length}
          loading={loading}
          headerMenu={true}
        />
        {requestsPaginated && <CustomPagination currentPage={page} total={requestsPaginated.pages} />}
      </div>
    </MainLayout>
  );
};

export default FacilityRequestsPage;
