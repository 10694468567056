import { useVacancyLazyQuery } from '../../../pages/vacancy/api/queries/generated/Vacancy';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useVacancy = () => {
  const router = useParams();
  const vacancyId = router.vacancyId ?? '';
  const [loadQuery, { data, loading, error }] = useVacancyLazyQuery({
    variables: { vacancyId },
  });
  useEffect(() => {
    if (vacancyId) {
      loadQuery();
    }
  }, [loadQuery, vacancyId]);
  if (vacancyId) {
    const vacancy = data?.vacancy;
    return { vacancy, loading, error, vacancyId };
  }
  return { vacancyId };
};
