import { useState } from 'react';
import PhoneInput from 'ui/phoneMask';
import { useSubmitForm } from './hooks';

export const InviteUserForm = () => {
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
    if (phone.length === 0) {
      setError('');
    }
  };

  const { onSubmit, loading } = useSubmitForm({ phone, setError });

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={submitForm}>
      <div className="flex flex-col gap-y-4">
        <h4 className="Body1 text-smena_text-secondary">
          Введите номер телефона пользователя, которого хотите пригласить.
        </h4>

        <div className="flex justify-between">
          <div className="w-[300px]">
            <PhoneInput
              name="phone"
              onChange={handlePhone}
              value={phone}
              placeholder="+7 (921) 345 45 45"
              error={phone.length > 4 && error}
            />
          </div>

          <div>
            <button className="btn-primary_big" disabled={loading} type="submit">
              Отправить
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
