import React from 'react';
import { YMapsApi, ZoomControl } from 'react-yandex-maps';

type Props = {
  ymaps: YMapsApi | null;
};

const ZoomComponent: React.FC<Props> = ({ ymaps }) => {
  if (!ymaps) return null;
  const ZoomLayout = ymaps.templateLayoutFactory.createClass(
    `
      <button class='zoom-out w-[40px] h-[40px] bg-white rounded-b-md flex items-center justify-center shadow-smena text-smena_text'>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        class='fill-current'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z'
        />
      </svg>
    </button>`,

    {
      onClick: function () {
        const map = this.getData().control.getMap();
        map.setZoom(map.getZoom() - 1, { checkZoomRange: true });
      },
      build: function () {
        ZoomLayout.superclass.build.call(this);
        this.getData().control.events.add('click', this.onClick.bind(this), this);
      },

      clear: function () {
        this.getData().control.events.remove('click', this.onClick.bind(this), this);
        ZoomLayout.superclass.clear.call(this);
      },
    },
  );

  return <ZoomControl options={{ layout: ZoomLayout, position: { right: 16, top: 290 } }} />;
};
export default ZoomComponent;
