import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { useChangeShiftModerationMutation } from 'pages/facilityRequests/api/mutations/generated/ChangeShiftModeration';
import { useStore } from 'store';
import { errorHandler } from 'utils/helpers/errorHandler';
import { successToast, errorToast } from 'utils/helpers/notify';

interface Props {
  toPayment: boolean;
}

export const useUpdateShiftPayment = () => {
  const { shift, setShift } = useStore() as any;
  const [changeShift, { loading }] = useChangeShiftModerationMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
    awaitRefetchQueries: true,
  });

  const initialValues: Props = {
    toPayment: shift?.moderation?.toPayment,
  };

  const validate = () => {
    const errors: any = {};
    return errors;
  };

  const onSubmit = (values: Props) => {
    changeShift({
      variables: {
        moderation: {
          shiftId: shift.id,
          hourRate: shift.moderation?.hourRate || 0,
          value: shift.moderation?.value || 0,
          toPayment: values.toPayment,
        },
      },
    })
      .then(res => {
        if (res.data?.changeShiftModeration) {
          setShift({
            ...shift,
            moderation: {
              ...shift.moderation,
              toPayment: values.toPayment,
            },
          });
          successToast('Все изменения сохранены');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  return { initialValues, onSubmit, validate, loading };
};
