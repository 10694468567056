import { Modal as AntdModal } from 'antd/es';
import type { ModalProps as AntdModalProps } from 'antd';

type ModalType = 'default' | 'delete';

interface ModalProps extends AntdModalProps {
  type?: ModalType;
}

const Modal: React.FC<ModalProps> = ({
  children,
  type = 'default',
  okText = type === 'default' ? 'Подтвердить' : 'Удалить',
  cancelText = 'Назад',
  okButtonProps,
  ...props
}) => {
  const isDefault = type === 'default';

  return (
    <AntdModal
      okText={okText}
      cancelText={cancelText}
      okButtonProps={{ danger: !isDefault, ...okButtonProps }}
      {...props}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
