import { useParams } from 'react-router-dom';
import { ZayavkaStage } from 'generated/graphql';
import { useGetRequestLazyQuery } from 'pages/facilityUpdateRequest/api/queries/generated/GetRequest';
import { useZayavkaChangesSubSubscription } from 'pages/request/api/subscription/generated/RequestChangesSub';
import { useEffect } from 'react';

export const useCurrentRequest = () => {
  const router = useParams();
  const id = router.requestId;

  const [loadData, { data, loading, error }] = useGetRequestLazyQuery();
  const { data: requestSub } = useZayavkaChangesSubSubscription({
    variables: { id: id || '' },
  });

  useEffect(() => {
    if (!id) return;
    loadData({
      variables: {
        id,
      },
    });
  }, [id, loadData]);

  const request = requestSub?.zayavkaChangesSub || data?.getZayavka;
  const isFinishedRequest = request?.status === ZayavkaStage.Finished;
  return { request, loading, error, id, isFinishedRequest };
};
