import { UserPersonalInfoStatus } from 'generated/graphql';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import { WarningExclamation } from 'assets/icons/WarningExclamation';

export const getPersonalInfoIcon = (personalInfoStatus?: UserPersonalInfoStatus | null) => {
  switch (personalInfoStatus) {
    case UserPersonalInfoStatus.PersonalDataVerified:
      return <Check className={'text-smena_green'} />;
    case UserPersonalInfoStatus.PersonalDataNotSent:
      return <Across className={'text-smena_red'} />;
    case UserPersonalInfoStatus.PersonalDataNotVerified:
      return <WarningExclamation className={'text-smena_red'} />;
  }
};
