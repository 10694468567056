import SimpleSelect from 'ui/select';
import { useMemo } from 'react';
import { useStore } from 'store';

interface IPositionSelect {
  selectWorkpostHandler: (value: string) => void;
  positionsFromCasings?: any[];
}

const PositionSelect = ({ selectWorkpostHandler, positionsFromCasings }: IPositionSelect) => {
  const workpostId = useStore(state => state.workpostId);
  const workpostIdError = useStore(state => state.workpostIdError);
  const options = useMemo(
    () =>
      positionsFromCasings
        ? positionsFromCasings?.length === 1
          ? [{ id: positionsFromCasings[0].id, name: positionsFromCasings[0].name }]
          : positionsFromCasings.map(el => ({ id: el.id, name: el.name }))
        : null,
    [positionsFromCasings],
  );

  return (
    <SimpleSelect
      divClassName="w-[180px]"
      label="Должность"
      onChange={selectWorkpostHandler}
      value={workpostId}
      placeholder="Выберите должность"
      options={options}
      error={workpostIdError}
    />
  );
};

export default PositionSelect;
