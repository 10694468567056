import type { FormikHandlers, FormikState } from 'formik';
import SimpleInput from 'ui/input';
import TextArea from 'ui/textArea';

type Props = {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  touched: FormikState<any>['touched'];
  errors: FormikState<any>['errors'];
};

const MainInfo = (props: Props) => {
  const { values, handleChange, touched, errors } = props;

  const handleInputAndAreaChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    handleChange(e);
  };

  return (
    <>
      <span className="Subtitle2 text-smena_text-secondary">Основная информация</span>
      <SimpleInput
        label="Название"
        placeholder="Название"
        maxLength={30}
        onChange={handleInputAndAreaChange}
        value={values.name}
        name="name"
        type="text"
        error={touched.name && errors.name ? errors.name : ''}
      />

      <TextArea
        style={{
          height: '118px',
        }}
        label="Описание"
        name="description"
        maxLength={150}
        onChange={handleInputAndAreaChange}
        value={values.description}
        helperText="Можно оставить пустым. Не более 150 символов"
        placeholder="Введите описание"
      />
    </>
  );
};

export default MainInfo;
