import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from 'store';
import { ChartType, StatisticPeriod } from 'generated/graphql';
import { ITooltipStatus } from 'interfaces/ITooltipStatus';
import { useGetStatisticResponse, useIsSendRequest } from '../hooks';
import { useGetStatisticLazyQuery } from 'pages/main/api/queries/generated/GetStatistic';

import SimpleSelect from 'ui/select';
import { chartTimes } from 'utils/helpers/lists/chartTimes';
import Skeleton from 'antd/es/skeleton';
import { tickFormatterHandlerFn } from 'utils/helpers/charts/tickFormatter';
import { intervalChart } from 'utils/helpers/charts/intervalChart';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { labelFormatterHandler } from 'utils/helpers/charts/getLabelFormaterCharts';
import { chartClick, chartHover } from 'utils/helpers/charts/chartHandlers';

const FinishedAndGoodShiftsRatio = () => {
  const { trendsFacilityId, trendsGroupId } = useStore();
  const [time, setTime] = useState(StatisticPeriod.Day);
  const [tooltipStatus, setTooltipStatus] = useState<ITooltipStatus>({
    barId: undefined,
    status: 'hover',
  });
  const isSendRequest = useIsSendRequest();
  const [loadData, { data, loading }] = useGetStatisticLazyQuery();

  const res = useGetStatisticResponse(data);

  const handleTimeChange = useCallback((value: string) => {
    setTime(value as StatisticPeriod);
  }, []);

  const tickFormatterHandler = useCallback(
    (value: any) => {
      if (value && value !== 'auto') {
        return tickFormatterHandlerFn(value, time);
      }
      return '0';
    },
    [time],
  );

  const interval = useMemo(() => intervalChart(time), [time]);

  useEffect(() => {
    if (!isSendRequest) return;
    loadData({
      variables: {
        input: {
          facilityId: trendsFacilityId,
          facilityGroupId: trendsGroupId,
          period: time,
          types: [ChartType.GoodFinishedShiftsRatio],
        },
      },
    });
  }, [isSendRequest, loadData, time, trendsFacilityId, trendsGroupId]);
  return (
    <div className={'row-span-1 grid gap-y-[5px]'}>
      <div className="grid grid-cols-[auto,_auto] justify-between items-center">
        <span className="Subtitle2 text-smena_text-secondary">Процент хороших смен</span>
        <SimpleSelect
          divClassName="chart-filter"
          label=""
          onChange={handleTimeChange}
          value={time}
          sort={false}
          options={chartTimes}
        />
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="h-[240px]">
          <div className={'flex gap-x-2 items-center'}>
            <span className={'w-[10px] h-[10px] bg-smena_teal rounded-full'}></span>
            <span className={'Caption text-smena_text-secondary'}>Процент хороших смен</span>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={res}
              onClick={state => {
                if (state !== null) {
                  if (tooltipStatus.barId === state.activeTooltipIndex) {
                    setTooltipStatus(chartHover(state));
                  } else {
                    setTooltipStatus(chartClick(state));
                  }
                }
              }}
              margin={{ right: 25, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="5" />
              <XAxis
                dataKey={res ? 'createdAt' : undefined}
                interval={interval}
                tickFormatter={tickFormatterHandler}
                height={30}
                angle={-25}
                tickMargin={10}
              />
              <YAxis domain={[0, 100]} />
              <Tooltip
                cursor={false}
                trigger={tooltipStatus.status}
                labelFormatter={(label: string) => labelFormatterHandler({ label, time })}
                formatter={(value: string) => [`${value}%`, 'Процент хороших смен:']}
                separator={':'}
              />
              <Line type="monotone" dataKey={'value'} stroke="#2CD7B8" activeDot={{ r: 5 }} strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default FinishedAndGoodShiftsRatio;
