import clsx from 'clsx';

import { Role, ZayavkaChangesStatus, ZayavkaStage, ZayavkaToCancelStatus } from 'generated/graphql';
import { getRequestStatusRu } from 'utils/helpers/get/getRequestStatusRu';
import { getRequestClassName } from 'utils/helpers/get/getRequestStyle';
import StatusIcon from './helper/icon';
import { useMeRole } from 'store/hooks/useMeRole';
import React, { useCallback } from 'react';

interface IRequestStatus {
  request?: {
    authorId: string;
    status: ZayavkaStage;
    zayavkaChanges?: { authorId: string; stage: ZayavkaChangesStatus }[] | null;
    toCancel: ZayavkaToCancelStatus;
  };
}

const RequestStatus = React.memo(({ request }: IRequestStatus) => {
  const isMeSupervisor = useMeRole([Role.Supervisor]);

  const requestStatus = useCallback(
    (status?: ZayavkaStage) => {
      if (isMeSupervisor) {
        return getRequestStatusRu(status)?.supervisorStatus;
      }
      return getRequestStatusRu(status)?.clientStatus;
    },
    [isMeSupervisor],
  );

  return (
    <div className="flex gap-x-1">
      <span
        className={clsx(
          'h-6 flex items-center justify-center rounded-full px-2 Table-small',
          getRequestClassName(request?.status),
        )}
      >
        {requestStatus(request?.status)}
      </span>
      <StatusIcon request={request} />
    </div>
  );
});
export default RequestStatus;
