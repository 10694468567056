import LegendRow from './Row';
import useEvents from '../../lib/hooks/useEvents';
import useGoodShifts from '../../lib/hooks/useGoodShifts';
import useBadShifts from '../../lib/hooks/useBadShifts';
import useWithoutMarks from '../../lib/hooks/useWithoutMarks';
import useWorkingShifts from '../../lib/hooks/useWorkingShifts';
import React from 'react';
import useProbationShifts from 'pages/userTracking/lib/hooks/useProbationShifts';

export const LegendShifts = () => {
  const events = useEvents();
  const goodShifts = useGoodShifts(events);
  const badShifts = useBadShifts(events);
  const withoutMarks = useWithoutMarks(events);
  const workingShifts = useWorkingShifts(events);
  const probationShifts = useProbationShifts(events);
  return (
    <div className="mt-11 ml-3 flex flex-col gap-y-5">
      <div className="flex items-center gap-x-1">
        <span className="Table-small text-smena_text-secondary">Всего смен:</span>
        <span className="Caption-numbers text-smena_text">{events.length}</span>
      </div>

      <div className="flex flex-col gap-y-4">
        <LegendRow text="«Хорошие» смены" bgColor="bg-smena_green-light" array={goodShifts} />
        <LegendRow text="«Плохие» смены" bgColor="bg-smena_red-light" array={badShifts} />
        <LegendRow text="Пробные смены" bgColor="bg-smena_purple-light" array={probationShifts} />
        <LegendRow text="Смена без одной или больше отметок" bgColor="bg-smena_orange-light" array={withoutMarks} />
        <LegendRow text="В работе" bgColor="bg-primary-secondary" array={workingShifts} />
      </div>
    </div>
  );
};
