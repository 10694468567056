import { PushFormProps, validatePushForm } from 'pages/push/utils/hooks/usePushForm';
import React, { useState } from 'react';
import PushPopupPublic from '../popup';
import PushPopupWithdraw from '../popup/withdraw';
import { FormikHelpers } from 'formik';
import { usePush } from 'utils/api/usePush';

interface Props {
  values: PushFormProps;
  validateForm: FormikHelpers<PushFormProps>['validateForm'];
}

const BlockButton = ({ values, validateForm }: Props) => {
  const { push } = usePush();
  const { isPushIsNotNew, isPushRemoved, isPushPublished } = usePush();
  const [showPopup, setShowPopup] = useState(false);
  const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);

  const showPopupHandle = () => {
    setShowPopup(value => !value);
  };
  const showWithdrawPopupHandle = () => {
    setShowWithdrawPopup(value => !value);
  };
  const onPublishHandle = () => {
    validatePushForm({ values, validateForm, handler: showPopupHandle });
  };
  const showWithdrawButtonFn = () => {
    if (isPushIsNotNew && !isPushRemoved) {
      if (isPushPublished) {
        return push?.publicCondition?.interval !== null;
      }
      return true;
    }
    return false;
  };
  const showWithdrawButton = showWithdrawButtonFn();
  // isPushPublished && push?.publicCondition?.interval !== null
  return (
    <>
      <div className={'flex justify-end items-center gap-x-6'}>
        {showWithdrawButton ? (
          <button className={'self-end btn-secondary'} type={'button'} onClick={showWithdrawPopupHandle}>
            Снять с публикации
          </button>
        ) : null}
        {!isPushIsNotNew ? (
          <button className={'self-end btn-primary'} type={'button'} onClick={onPublishHandle}>
            Опубликовать
          </button>
        ) : null}
      </div>
      <PushPopupWithdraw showPopup={showWithdrawPopup} setShowPopup={setShowWithdrawPopup} values={values} />
      <PushPopupPublic showPopup={showPopup} setShowPopup={setShowPopup} />
    </>
  );
};

export default BlockButton;
