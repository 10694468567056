export const lunchDurationHandle = (duration: number) => {
  const hours = duration / 60;
  if (duration % 60 !== 0) {
    const hours = (duration - (duration % 60)) / 60;
    const minutes = duration % 60;
    if (hours >= 1 && minutes > 0 && hours < 8) {
      return 30;
    }
    if (hours >= 8 && minutes > 0 && hours < 13) {
      return 60;
    }
    if (hours >= 13 && minutes > 0) {
      return 90;
    }
    return 0;
  }
  if (hours > 1 && hours < 9) {
    return 30;
  }
  if (hours > 8 && hours < 14) {
    return 60;
  }
  if (hours > 13) {
    return 90;
  }
  return 0;
};
