import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';

import { useStore } from 'store';
import { scheduleClassName } from 'utils/helpers/scheduleClassName';
import SchedulePopup from './popup';
import { ICustomEvent } from 'interfaces/IEvent';
import { ISO_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import CustomScheduleButtons from './customScheduleButtons';
import EditShiftButtons from './editShiftButtons';
import CustomToolbar from 'components/calendar/customToolbar';
import CustomEvent from 'components/calendar/customEvent';
import useScheduleMainEvents from 'utils/hooks/userCalendar/useScheduleMainEvents';
import { shiftExist } from 'utils/helpers/scheduleFns';
import { useCurrentUser } from 'utils/api/useUser';
import { useMe } from 'utils/api/useMe';
import { setTime } from 'utils/helpers/scheduleTime';
import { nanoid } from 'nanoid';
import { errorToast } from 'utils/helpers/notify';
import CustomDateCell from 'components/calendar/customDateCell/scheduleCalendar';
import EditScheduleButtons from './editScheduleButtons';
import { LegendSchedule } from './legend';

const localizer = dayjsLocalizer(dayjs);

const CalendarMain = () => {
  const [dateFrom, setDateFrom] = useState(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState(dayjs().endOf('month'));
  const [popup, setPopup] = useState(false);
  const [params, setParams] = useSearchParams();
  const typeSchedule = useStore(state => state.typeSchedule);
  const customScheduleEvents = useStore(state => state.customScheduleEvents);
  const facilityId = useStore(state => state.facilityId);
  const workpostId = useStore(state => state.workpostId);
  const setShowSidebar = useStore(state => state.setShowSidebar);
  const setSelectedSchedule = useStore(state => state.setSelectedSchedule);
  const hideCanceledShifts = useStore(state => state.hideCanceledShifts);
  const shift = useStore(state => state.shift);
  const schedule = useStore(state => state.schedule);
  const setCustomScheduleEvents = useStore(state => state.setCustomScheduleEvents);
  const scheduleEvent = useStore(state => state.scheduleEvent);
  const lunchDuration = useStore(state => state.lunchDuration);
  const addCustomScheduleEvents = useStore(state => state.addCustomScheduleEvents);
  const { userId } = useCurrentUser();
  const me = useMe();
  const { mainEvents, mainEventsWithoutCanceled } = useScheduleMainEvents();
  const customMainEvents = useMemo(() => [...mainEvents, ...customScheduleEvents], [mainEvents, customScheduleEvents]);
  const customMainEventsWithoutCanceled = useMemo(
    () => [...mainEventsWithoutCanceled, ...customScheduleEvents],
    [mainEventsWithoutCanceled, customScheduleEvents],
  );

  const showEvents = useMemo(() => {
    if (typeSchedule === 1) {
      if (hideCanceledShifts) {
        return mainEventsWithoutCanceled;
      }
      return mainEvents;
    }
    if (hideCanceledShifts) {
      return customMainEventsWithoutCanceled;
    }
    return customMainEvents;
  }, [
    typeSchedule,
    mainEvents,
    mainEventsWithoutCanceled,
    customMainEvents,
    customMainEventsWithoutCanceled,
    hideCanceledShifts,
  ]);

  const onNavigateHandler = useCallback(
    (newDate: Date) => {
      const dateFrom = dayjs(newDate).startOf('month');
      const dateTo = dayjs(newDate).endOf('month');

      setDateFrom(dateFrom);
      setDateTo(dateTo);

      params.set('dateFrom', dateFrom.format(ISO_DATE));
      params.set('dateTo', dateTo.format(ISO_DATE));
      setParams(params);
    },
    [params, setParams],
  );

  const onShowMoreHandler = useCallback(
    (shifts: ICustomEvent[]) => {
      if (shifts.length) {
        setSelectedSchedule(shifts);
        setShowSidebar(true);
      }
    },
    [setShowSidebar, setSelectedSchedule],
  );

  const mainEventPropGetter = ({ resource }: ICustomEvent): HTMLAttributes<HTMLDivElement> => {
    return scheduleClassName({
      facilityId,
      workpostId,
      resource,
    });
  };

  const onSelectEventHandler = useCallback(
    (obj: ICustomEvent) => {
      const clickOnCustomScheduleEvent = () => {
        if (shiftExist(obj, customScheduleEvents).length) {
          setCustomScheduleEvents(
            customScheduleEvents.filter(event => dayjs(event.start).valueOf() !== dayjs(obj.start).valueOf()),
          );
        }
      };

      if (typeSchedule === 2) {
        return clickOnCustomScheduleEvent();
      }
      const shifts = mainEvents.filter(
        event => dayjs(event.start).startOf('day').valueOf() === dayjs(obj.start).startOf('day').valueOf(),
      );

      if (shifts.length) {
        setSelectedSchedule(shifts);
        setShowSidebar(true);
      }
    },
    [customScheduleEvents, mainEvents, setCustomScheduleEvents, setSelectedSchedule, setShowSidebar, typeSchedule],
  );

  const onSelectSlot = useCallback(
    ({ start, end }: { start: Date; end: Date }) => {
      if (!userId) return;
      const startCellAndCurrentTime = dayjs(start)
        .set('hours', dayjs().get('hours'))
        .set('minutes', dayjs().get('minutes'));
      const sameDate = startCellAndCurrentTime.isSame(dayjs(scheduleEvent?.start), 'date');
      const term = sameDate
        ? dayjs(scheduleEvent?.start).isBefore(dayjs())
        : startCellAndCurrentTime.isBefore(dayjs(scheduleEvent?.start));
      if (typeSchedule === 2) {
        if (
          !dayjs(start).isSame(dayjs(end).subtract(1, 'day')) ||
          dayjs(start).isBefore(dayjs(dateFrom)) ||
          dayjs(start).isAfter(dayjs(dateTo)) ||
          term
        ) {
          return null;
        }
        if (scheduleEvent) {
          const {
            start: startEvent,
            end: endEvent,
            resource: { facility },
          } = scheduleEvent;

          const FullYearDate = new Date().setFullYear(start.getFullYear(), start.getMonth(), start.getDate());

          const startDate = new Date(FullYearDate);
          const endDate = new Date(FullYearDate);

          setTime(startDate, startEvent);
          setTime(endDate, endEvent);

          const dateEndNextDay = dayjs(endDate)
            .set('date', end.getDate())
            .set('month', end.getMonth())
            .set('year', end.getFullYear());

          const currentDayShift =
            startEvent.getHours() * 60 + startEvent.getMinutes() < endEvent.getHours() * 60 + endEvent.getMinutes();

          const newEvent: ICustomEvent = {
            ...scheduleEvent,
            title: `${dayjs(startEvent).format(TIME_DATE)} - ${dayjs(endEvent).format(TIME_DATE)}`,
            start: startDate,
            end: endDate,
            resource: {
              id: nanoid(),
              userId,
              authorId: me?.id || '',
              dateFrom: dayjs(startDate),
              dateEnd: currentDayShift ? dayjs(endDate) : dateEndNextDay,
              stage: 'CUSTOM',
              positionId: workpostId || '',
              facility: {
                id: facility?.id || '',
              },
              lunchDuration,
              isProbation: false,
            },
          };

          if (shiftExist(newEvent, customScheduleEvents).length || shiftExist(newEvent, mainEvents).length) {
            return errorToast('Нельзя ставить несколько смен на один день');
          }
          addCustomScheduleEvents(newEvent);
        } else {
          return errorToast('Сначала примените шаблон');
        }
      } else {
        const shifts = mainEvents.filter(
          event => dayjs(event.start).startOf('day').valueOf() === dayjs(start).startOf('day').valueOf(),
        );

        if (shifts.length) {
          setSelectedSchedule(shifts);
          setShowSidebar(true);
        }
      }
    },
    [
      addCustomScheduleEvents,
      customScheduleEvents,
      dateFrom,
      dateTo,
      lunchDuration,
      mainEvents,
      me?.id,
      scheduleEvent,
      setSelectedSchedule,
      setShowSidebar,
      typeSchedule,
      userId,
      workpostId,
    ],
  );

  useEffect(() => {
    setSelectedSchedule(undefined);
  }, [setSelectedSchedule]);

  return (
    <>
      <div className="schedule">
        <div className="schedule__wrapper flex flex-wrap">
          <div className="schedule__calendar">
            <Calendar
              selectable
              localizer={localizer}
              formats={{
                weekdayFormat: 'dd',
                dateFormat: 'D',
              }}
              events={showEvents}
              components={{
                event: CustomEvent,
                toolbar: CustomToolbar,
                dateCellWrapper: CustomDateCell,
                month: {
                  dateHeader: function dateHeaderFn({ label }) {
                    return <div onClick={undefined}>{label}</div>;
                  },
                },
              }}
              onShowMore={onShowMoreHandler}
              startAccessor="start"
              onSelectEvent={onSelectEventHandler}
              onSelectSlot={onSelectSlot}
              endAccessor="end"
              defaultView="month"
              views={['month']}
              onNavigate={onNavigateHandler}
              eventPropGetter={mainEventPropGetter}
              messages={{
                showMore: target => `...еще ${target}`,
              }}
              doShowMoreDrillDown={false}
            />
          </div>
          <div className="mt-11 ml-3 flex flex-col justify-between">
            <LegendSchedule />
            {!!customScheduleEvents.length && <CustomScheduleButtons setPopup={setPopup} />}
            {shift?.id && shift.action === 'remove' && <EditShiftButtons />}
            {schedule?.id && schedule.action === 'remove' && <EditScheduleButtons />}
          </div>
        </div>
      </div>
      <SchedulePopup setPopup={setPopup} popup={popup} />
    </>
  );
};

export default CalendarMain;
