import { ApolloCache, Reference, StoreObject } from '@apollo/client';

interface Props {
  userId: string;
  facilityId: string;
}

export const removeSupervisorCache = (cache: ApolloCache<any>, { userId, facilityId }: Props) => {
  cache.modify({
    id: `RatingUser:${userId}`,
    fields: {
      Facility_SUPERVISOR(prev = [], { readField }) {
        return prev.filter((el: Reference | StoreObject | undefined) => readField('id', el) !== facilityId);
      },
    },
  });
  cache.modify({
    id: `Facility:${facilityId}`,
    fields: {
      supervisors(prev = [], { readField }) {
        return prev.filter((el: Reference | StoreObject | undefined) => readField('id', el) !== userId);
      },
    },
  });
};
