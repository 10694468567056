import { GetSupervisorWorkSheetQuery } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import RowStats from './components/RowStats';

interface Props {
  data?: GetSupervisorWorkSheetQuery['getSupervisorWorkSheet']['data'];
}

const Statistics: React.FC<Props> = ({ data }) => {
  return (
    <div className="border-l-[1px] border-l-smena_gray-60 h-full">
      <div className="flex border-b-[1px] border-smena_gray-30 bg-smena_gray-5 Table-H1 uppercase sticky top-0 z-50">
        <div className="w-[90px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] border-smena_gray-30 font-medium h-[36px]">
          Смены
        </div>
        <div className="w-[80px] pt-2 pb-2 pl-4 pr-4 border-r-[1px]  border-smena_gray-30 font-medium h-[36px]">
          Часы
        </div>
        <div className="w-[155px] pt-2 pb-2 pl-4 pr-4 border-r-[1px] border-smena_gray-30 font-medium h-[36px]">
          Смен к оплате
        </div>
        <div className="w-[80px] pt-2 pb-2 pl-4 pr-4 border-r-[1px]  border-smena_gray-30 font-medium h-[36px]">
          Факт
        </div>
        <div className="w-[80px] pt-2 pb-2 pl-4 pr-4  h-[36px] font-medium">План</div>
      </div>

      <div>
        {data?.map((row, index) => <RowStats key={index} row={row} />)}
        <RowStats />
      </div>
    </div>
  );
};

export default Statistics;
