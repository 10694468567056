export const convertPhoneNumberToNormal = (phoneNumber?: string) => {
  if (!phoneNumber) return '';
  return `+7 (${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(
    6,
    8,
  )}-${phoneNumber.substring(8, 10)}`;
};

export const convertPhoneToString = (phoneNumber?: string) => {
  return phoneNumber ? phoneNumber.replace(/[^0-9]/g, '').substring(1) : '';
};
