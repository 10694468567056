import { Carousel } from 'antd';
import Across from 'assets/icons/Across';
import { ArrowLeft } from 'assets/icons/ArrowLeft';
import { ArrowRight } from 'assets/icons/ArrowRight';
import { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { useParams } from 'react-router';
import Modal from 'ui/modal';
import { getImagePath } from 'utils/helpers/get/getImagePath';
import 'react-image-crop/dist/ReactCrop.css';
import { useUploadCropImageMutation } from 'graphql/mutations/generated/UploadCropImage';

interface Props {
  images: any[];
  setInitialSlideIndex: (index: number) => void;
  initialSlideIndex: number;
  handleCoverPhotoId?: (photoId: string | null) => void;
  coverPhoto?: any;
  setSliderVisible: any;
  isCreate: boolean;
  coverPhotoInCreating: any;
}

const Slider = (props: Props) => {
  const { vacancyId } = useParams();
  const [modalCropVisible, setModalCropVisible] = useState(false);
  const [crop, setCrop] = useState<any>();
  const imageRef = useRef<any>();

  const [uploadCropImage] = useUploadCropImageMutation();

  const {
    images,
    handleCoverPhotoId,
    setInitialSlideIndex,
    initialSlideIndex,
    coverPhoto,
    isCreate,
    coverPhotoInCreating,
    setSliderVisible,
  } = props;
  const handleUpload = async () => {
    const originalWidth = imageRef.current.naturalWidth;
    const width = imageRef.current.width;
    const height = imageRef.current.height;

    const calculatedSizeMultiplier = originalWidth / width;

    let cropWidth = crop.width - 1;
    if (crop.width + crop.x > width) cropWidth = width - crop.x;

    let cropHeight = crop.height - 1;
    if (crop.height + crop.y > height) cropHeight = height - crop.y;

    const cropX = Math.ceil(crop.x * calculatedSizeMultiplier);
    const cropY = Math.ceil(crop.y * calculatedSizeMultiplier);

    const cropData = {
      height: Math.ceil(cropHeight * calculatedSizeMultiplier),
      width: Math.ceil(cropWidth * calculatedSizeMultiplier),
      left: cropX,
      top: cropY,
    };

    if (vacancyId && !isCreate) {
      await uploadCropImage({
        variables: {
          fileId: images[initialSlideIndex]?.file?.id,
          cropData,
        },
      }).then(
        data =>
          data.data?.uploadCropImage.id && handleCoverPhotoId && handleCoverPhotoId(data.data?.uploadCropImage.id),
      );
    } else if (isCreate) {
      await uploadCropImage({
        variables: {
          fileId: images[initialSlideIndex]?.file?.id,
          cropData,
        },
      }).then(data => {
        coverPhotoInCreating.current = data.data?.uploadCropImage;
        // Костыль, разобраться и убрать
        setSliderVisible(true);
        setSliderVisible(false);
      });
    }
  };

  return (
    <div className="flex justify-center items-center select-none fixed top-0 left-0 right-0 bottom-0 bg-black/70 z-[100]">
      <div style={{ borderRadius: '10px', overflow: 'hidden', width: '100%' }}>
        <Carousel
          arrows
          initialSlide={initialSlideIndex}
          dots={false}
          afterChange={currentSlide => setInitialSlideIndex(currentSlide)}
          prevArrow={
            <div>
              <ArrowLeft />
            </div>
          }
          nextArrow={
            <div>
              <ArrowRight />
            </div>
          }
        >
          {images.map((img, index) => (
            <div key={index}>
              <img
                style={{ maxWidth: '70%', maxHeight: '90vh', margin: 'auto auto' }}
                onClick={e => e.stopPropagation()}
                src={getImagePath(img.file?.path)}
              />
            </div>
          ))}
        </Carousel>

        <Modal
          title="Редактировать фото"
          open={modalCropVisible}
          onCancel={() => setModalCropVisible(false)}
          onOk={() => {
            handleUpload();
            setModalCropVisible(false);
          }}
          maskClosable={false}
        >
          <ReactCrop crop={crop} onChange={c => setCrop(c)} aspect={16 / 9}>
            <img ref={imageRef} src={getImagePath(images[initialSlideIndex]?.file?.path)} />
          </ReactCrop>
        </Modal>
      </div>
      <div
        onClick={() => setSliderVisible(false)}
        style={{ backgroundColor: `rgba(0, 0, 0, 0.1)`, borderRadius: '50%' }}
        className="absolute right-[50px] top-[50px] cursor-pointer bg-white p-2"
      >
        <Across className="fill-white" />
      </div>

      {(vacancyId || isCreate) && images[initialSlideIndex]?.id && !images[initialSlideIndex].facility && (
        <button
          type="button"
          onClick={() => setModalCropVisible(true)}
          className="absolute bottom-10 text-white bg-white/10 rounded-full pt-3 pb-3 pl-5 pr-5"
        >
          Сделать обложкой вакансии
        </button>
      )}

      {vacancyId && images[initialSlideIndex]?.file?.id === coverPhoto?.id && initialSlideIndex === 0 && (
        <button
          type="button"
          onClick={() => {
            handleCoverPhotoId && handleCoverPhotoId(null);
            setSliderVisible(false);
          }}
          className="absolute bottom-10 text-smena_red bg-red-500/10 rounded-full pt-3 pb-3 pl-5 pr-5"
        >
          Удалить обложку
        </button>
      )}

      {isCreate && coverPhotoInCreating && initialSlideIndex === 0 && (
        <button
          type="button"
          onClick={() => {
            coverPhotoInCreating.current = null;
            setSliderVisible(false);
          }}
          className="absolute bottom-10 text-smena_red bg-red-500/10 rounded-full pt-3 pb-3 pl-5 pr-5"
        >
          Удалить обложку
        </button>
      )}
    </div>
  );
};

export default Slider;
