import React, { useState } from 'react';
import { mapColumns } from 'utils/helpers/mapColumns';
import { promotionColumns } from 'components/table/columns';
import { PromotionEntityFragment } from '../../facilityGroup/api/fragments/generated/PromotionEntity.fragment';
import dayjs from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';
import Status from '../../facilityGroup/components/promotionBlock/status';
import { clsx } from 'clsx';
import ChevronRight from 'assets/icons/ChevronRight';
import { Link } from 'react-router-dom';

interface Props {
  promotion?: PromotionEntityFragment;
  index: number;
}

const Promotion = ({ promotion, index }: Props) => {
  const [show, setShow] = useState(false);
  return (
    <div className={index % 2 ? 'even-row' : ''}>
      <div
        className={'grid px-4'}
        style={{
          gridTemplateColumns: mapColumns(promotionColumns),
        }}
        onClick={() => setShow(value => !value)}
      >
        <div className={promotionColumns[0].className}>{promotion?.name}</div>
        <div className={promotionColumns[1].className}>
          <Status status={promotion?.status} />
        </div>
        <div className={promotionColumns[2].className}>{dayjs(promotion?.dateTimeStart).format(RU_DATE)}</div>
        <div className={promotionColumns[3].className}>
          {promotion?.dateTimeEnd ? dayjs(promotion?.dateTimeEnd).format(RU_DATE) : 'Бессрочно'}
        </div>
        <div className={promotionColumns[4].className}>{promotion?.childPromotions?.length}</div>
        <div className={promotionColumns[5].className}>
          <ChevronRight className={clsx(show ? '-rotate-90' : 'rotate-90', 'inline-flex text-smena_text')} />
        </div>
      </div>
      {show ? (
        <div className={'py-4 px-4 col-span-full bg-white'}>
          <div className={'grid border-b border-smena_gray-5 px-4 grid-cols-1'}>
            <div className={'col-span-full table__header'}>Изменено в объекте</div>
          </div>
          <div className={'grid grid-cols-1'}>
            {promotion?.childPromotions?.map((promotion, index) => (
              <div key={promotion.facility?.id} className={`px-12 py-4 col-span-full ${index % 2 ? 'even-row' : ''}`}>
                <Link to={`/facilities/${promotion.facility?.id}`} className={'link Table-small'}>
                  {promotion.facility?.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Promotion;
