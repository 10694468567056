import dayjs, { Dayjs } from 'dayjs';
import DatePickerIcon from 'assets/icons/DatePickerIcon';
import { RangePickerProps as BaseRangePickerProps } from 'antd/lib/date-picker/generatePicker';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import { ErrorNotification } from '../errorNotification';
import { RU_DATE } from 'utils/helpers/constVariables';
import DatePicker, { DatePickerProps } from 'antd/es/date-picker';
import { IMask } from 'react-imask';

interface Props {
  divClassName?: string;
  pickerClassName?: string;
  style?: React.CSSProperties;
  popupClassName?: string;
  label?: string;
  format?: string;
  value?: Dayjs | null;
  onChange: (date: Dayjs | null, dateString: string) => void;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  disabled?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  placeholder?: string;
  open?: boolean;
  showText?: boolean;
  asterisk?: boolean;
  error?: any;
  placement?: DatePickerProps['placement'];
}

const DatePickerInput = React.memo(
  ({
    divClassName,
    style,
    pickerClassName,
    popupClassName,
    label,
    value,
    format = RU_DATE,
    onChange,
    onBlur,
    disabled,
    maxDate,
    minDate,
    error,
    placeholder = dayjs().format(RU_DATE),
    picker,
    asterisk = false,
    open,
    showText,
    placement,
  }: Props) => {
    const masked = IMask.createMask({
      mask: '00.00.0000',
    });
    const disabledDate: BaseRangePickerProps<Dayjs>['disabledDate'] = useCallback(
      (current: Dayjs) => {
        if (maxDate && minDate) {
          return current && (current > maxDate || current < minDate);
        }
        if (maxDate) {
          return current && current > maxDate;
        }
        if (minDate) {
          return current && current < minDate;
        }
        return false;
      },
      [maxDate, minDate],
    );

    const defaultValue = value || undefined;

    const minuteStep = 5;

    return (
      <>
        {showText ? (
          <div className={clsx('flex flex-col gap-y-0.5', divClassName)}>
            {Boolean(label) && <div className="Subtitle2 mb-0.5">{label}</div>}
            <span className="Body2 text-smena_text-secondary">
              {value ? dayjs(value).format(RU_DATE) : 'Отсутствует'}
            </span>
          </div>
        ) : (
          <div className={clsx('flex flex-col gap-y-0.5', divClassName)}>
            {Boolean(label) && (
              <label className="label-primary">
                {label} {asterisk ? <span className={clsx(error ? 'text-smena_red' : '')}>*</span> : null}
              </label>
            )}
            <DatePicker
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              format={format}
              suffixIcon={<DatePickerIcon className="opacity-100" />}
              defaultValue={defaultValue}
              disabled={disabled}
              minuteStep={minuteStep}
              inputRender={props => (
                <input
                  onKeyUp={e => {
                    const input = e.target as HTMLInputElement;
                    masked.resolve(input.value);
                    input.value = masked.value;
                  }}
                  {...props}
                />
              )}
              changeOnBlur
              className={clsx(
                'custom__picker',
                error ? '!border-smena_red' : 'border-smena_gray-40',
                pickerClassName || 'w-full',
              )}
              style={style}
              disabledDate={disabledDate}
              placeholder={placeholder}
              popupClassName={popupClassName}
              picker={picker}
              open={open}
              placement={placement}
            />
            {error && <ErrorNotification text={error} />}
          </div>
        )}
      </>
    );
  },
);

export default DatePickerInput;
