import { RequestProgressFragment } from 'pages/facilityUpdateRequest/api/fragmetns/generated/RequestProgress.fragment';
import { RequestFragment } from 'pages/facilityRequests/api/fragments/generated/Request.fragment';
import { RequestPlanFragment } from 'pages/facilityRequests/api/fragments/generated/RequestPlan.fragment';
import { useMemo } from 'react';

import { usePositions } from 'utils/api/usePositions';

export const useRequestPositions = (request: RequestProgressFragment | RequestFragment) => {
  const { positions } = usePositions();
  return useMemo(
    () =>
      request.plans
        .map((requestPlan: RequestPlanFragment, index: number) => {
          if (index < 3) {
            return positions?.find(position => position.id === requestPlan.position.id)?.name;
          }
          return null;
        })
        .filter(Boolean),
    [positions, request.plans],
  );
};
