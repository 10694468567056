import { StoryStage } from 'generated/graphql';
import { storyStatuses } from 'pages/stories/utils/constants';

export const getStoryStatus = (status?: StoryStage | null) => {
  const response = storyStatuses.find(element => element.id === status);
  if (!response)
    return {
      name: 'Статус не найден',
      className: 'bg-smena_white border border-smena_red text-smena_red',
    };
  return response;
};
