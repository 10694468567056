import { useCallback, useMemo } from 'react';
import { useFacility } from 'utils/api/useFacility';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import {
  useSetWaitingMedicalOnFacilityMutation
} from '../../../facilities/api/mutations/generated/SetWaitingMedicalOnFacility';

export const useFacilityMedicalInvoice = () => {
  const { facility, id } = useFacility();
  const { isMedInvoiceAllowed, numberDaysWaitMedical } = facility || {};

  const [setWaitingMedicalOnFacility] = useSetWaitingMedicalOnFacilityMutation();

  const promotionSwitchOffHandler = useCallback(() => {
    if (!id) return;
    setWaitingMedicalOnFacility({
      variables: {
        facilityId: id,
        isMedInvoiceAllowed: false,
        numberDaysWaitMedical: 0,
      },
    })
      .then(() => successToast('Акция выключена'))
      .catch(error => errorToast(errorHandler(error)));
  }, [id, setWaitingMedicalOnFacility]);

  const initialValues = useMemo(
    () => ({
      isMedInvoiceAllowed: isMedInvoiceAllowed || false,
      numberDaysWaitMedical: numberDaysWaitMedical || '',
    }),
    [isMedInvoiceAllowed, numberDaysWaitMedical]
  );

  const validate = (values: typeof initialValues) => {
    const error: any = {};
    if (!values.numberDaysWaitMedical) {
      error.numberDaysWaitMedical = 'Обязательное поле';
    }
    return error;
  };

  const onSubmit = (values: typeof initialValues) => {
    if (!id) return;
    setWaitingMedicalOnFacility({
      variables: {
        facilityId: id,
        isMedInvoiceAllowed: values.isMedInvoiceAllowed,
        numberDaysWaitMedical: Number(values.numberDaysWaitMedical),
      },
    })
      .then(() => successToast('Данные сохранены'))
      .catch(error => errorToast(errorHandler(error)));
  };

  return { initialValues, validate, onSubmit, promotionSwitchOffHandler };
};
