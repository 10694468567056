import { useGetScheduleTypesQuery } from 'pages/vacancy/api/queries/generated/GetScheduleTypes';

const useGetScheduleTypes = () => {
  const { data, loading, error } = useGetScheduleTypesQuery();
  const scheduleTypes = data?.getScheduleTypes || [];
  const twoXTwo = scheduleTypes.find(el => el.name === '2/2')?.id;
  return { scheduleTypes, loading, error, twoXTwo };
};

export default useGetScheduleTypes;
