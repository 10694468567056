const GeorgiaIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9559_3141)">
        <path
          d="M17.7776 2.77783H11.3887V8.61117H19.9998V5.00005C19.9998 4.41068 19.7657 3.84545 19.3489 3.42871C18.9322 3.01196 18.3669 2.77783 17.7776 2.77783Z"
          fill="#EEEEEE"
        />
        <path
          d="M11.3889 2.77783H8.61111V8.61117H0V11.3889H8.61111V17.2223H11.3889V11.3889H20V8.61117H11.3889V2.77783Z"
          fill="#E8112D"
        />
        <path
          d="M16.0641 5.32508C16.0819 4.97828 16.1229 4.63307 16.1869 4.29175C15.8603 4.33219 15.5301 4.33219 15.2035 4.29175C15.2683 4.63397 15.3093 4.97841 15.3263 5.32508C14.9795 5.30727 14.6343 5.26625 14.293 5.2023C14.3334 5.52883 14.3334 5.85911 14.293 6.18564C14.6343 6.12169 14.9795 6.08067 15.3263 6.06286C15.3085 6.40966 15.2675 6.75487 15.2035 7.09619C15.5301 7.05575 15.8603 7.05575 16.1869 7.09619C16.1229 6.75487 16.0819 6.40966 16.0641 6.06286C16.4109 6.08067 16.7561 6.12169 17.0974 6.18564C17.057 5.85911 17.057 5.52883 17.0974 5.2023C16.7548 5.26786 16.4104 5.30879 16.0641 5.32508Z"
          fill="#E8112D"
        />
        <path
          d="M8.61111 2.77783H2.22222C1.63285 2.77783 1.06762 3.01196 0.650874 3.42871C0.234126 3.84545 0 4.41068 0 5.00005L0 8.61117H8.61111V2.77783Z"
          fill="#EEEEEE"
        />
        <path
          d="M4.67541 5.32508C4.69322 4.97828 4.73424 4.63307 4.79819 4.29175C4.47166 4.33219 4.14138 4.33219 3.81485 4.29175C3.87967 4.63397 3.92059 4.97841 3.93763 5.32508C3.59083 5.30727 3.24561 5.26625 2.9043 5.2023C2.94474 5.52883 2.94474 5.85911 2.9043 6.18564C3.24561 6.12169 3.59083 6.08067 3.93763 6.06286C3.91982 6.40966 3.8788 6.75487 3.81485 7.09619C4.14138 7.05575 4.47166 7.05575 4.79819 7.09619C4.73424 6.75487 4.69322 6.40966 4.67541 6.06286C5.02221 6.08067 5.36742 6.12169 5.70874 6.18564C5.6683 5.85911 5.6683 5.52883 5.70874 5.2023C5.36615 5.26786 5.0217 5.30879 4.67541 5.32508Z"
          fill="#E8112D"
        />
        <path
          d="M19.9998 15V11.3889H11.3887V17.2223H17.7776C18.3669 17.2223 18.9322 16.9881 19.3489 16.5714C19.7657 16.1546 19.9998 15.5894 19.9998 15Z"
          fill="#EEEEEE"
        />
        <path
          d="M16.0641 14.6749C16.0811 15.0212 16.122 15.3657 16.1869 15.7083C15.8603 15.6678 15.5301 15.6678 15.2035 15.7083C15.2683 15.366 15.3093 15.0216 15.3263 14.6749C14.9795 14.6927 14.6343 14.7337 14.293 14.7977C14.3334 14.4712 14.3334 14.1409 14.293 13.8144C14.6352 13.8792 14.9796 13.9201 15.3263 13.9371C15.3085 13.5903 15.2675 13.2451 15.2035 12.9038C15.5301 12.9443 15.8603 12.9443 16.1869 12.9038C16.1229 13.2451 16.0819 13.5903 16.0641 13.9371C16.4109 13.9193 16.7561 13.8783 17.0974 13.8144C17.057 14.1409 17.057 14.4712 17.0974 14.7977C16.7564 14.732 16.411 14.6909 16.0641 14.6749Z"
          fill="#E8112D"
        />
        <path
          d="M8.61111 11.3889H0V15C0 15.5894 0.234126 16.1546 0.650874 16.5714C1.06762 16.9881 1.63285 17.2223 2.22222 17.2223H8.61111V11.3889Z"
          fill="#EEEEEE"
        />
        <path
          d="M4.67541 14.6749C4.69244 15.0212 4.73337 15.3657 4.79819 15.7083C4.47166 15.6678 4.14138 15.6678 3.81485 15.7083C3.87967 15.366 3.92059 15.0216 3.93763 14.6749C3.59083 14.6927 3.24561 14.7337 2.9043 14.7977C2.94474 14.4712 2.94474 14.1409 2.9043 13.8144C3.24652 13.8792 3.59096 13.9201 3.93763 13.9371C3.91982 13.5903 3.8788 13.2451 3.81485 12.9038C4.14138 12.9443 4.47166 12.9443 4.79819 12.9038C4.73424 13.2451 4.69322 13.5903 4.67541 13.9371C5.02221 13.9193 5.36742 13.8783 5.70874 13.8144C5.6683 14.1409 5.6683 14.4712 5.70874 14.7977C5.3677 14.732 5.02236 14.6909 4.67541 14.6749Z"
          fill="#E8112D"
        />
      </g>
      <defs>
        <clipPath id="clip0_9559_3141">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GeorgiaIcon;
