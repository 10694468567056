import Plus from 'assets/icons/plus';
import Minus from 'assets/icons/iconMinus';
import { useState } from 'react';
import SimpleInput from 'ui/input';
import SimpleSelect from 'ui/select';
import TextArea from 'ui/textArea';
import { tabelHoldReasons } from 'utils/helpers/lists/reasons';

interface Props {
  values: any;
  setFieldValue: any;
  handleChange: any;
  errors: any;
  touched: any;
}

const Hold = (props: Props) => {
  const { values, setFieldValue, handleChange, errors, touched } = props;

  const [show, setShow] = useState(false);

  return (
    <div className="gap-y-3 border-b border-smena_gray-30">
      <p className={'Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30'}>Удержание</p>
      <button
        className={'Button2 text-primary flex items-center gap-x-[10px] px-4 py-2'}
        type="button"
        onClick={() => setShow(prevState => !prevState)}
      >
        {show ? <Minus className={'text-primary'} /> : <Plus className={'text-primary'} />}
        <span className="Button1">Добавить удержание</span>
      </button>

      {show && (
        <div className="px-4">
          <div className={'grid grid-cols-2'}>
            <div className="col-span-1 flex flex-col gap-y-2">
              <span className="Subtitle2">Сумма</span>
              <span className="Table-small text-smena_text-secondary">{`${0} ₽`}</span>
            </div>
            <SimpleInput
              divClassName="col-span-1"
              label="Ред. сумму"
              onChange={handleChange}
              regText
              value={values.paymentArrestedSum}
              type="text"
              name="paymentArrestedSum"
              error={touched.paymentArrestedSum && errors.paymentArrestedSum ? errors.paymentArrestedSum : ''}
            />
          </div>

          <div className="flex flex-col gap-y-3 pb-2">
            <SimpleSelect
              divClassName="relative"
              label="Причина удержания"
              onChange={value => {
                setFieldValue('paymentArrestedCause', value);
              }}
              value={values.paymentArrestedCause}
              placeholder="Все причины"
              options={tabelHoldReasons}
              popupClassName={'fixed'}
              error={touched.paymentArrestedCause && errors.paymentArrestedCause ? errors.paymentArrestedCause : ''}
            />
            <TextArea
              style={{
                height: '100px',
              }}
              placeholder={'Комментарий'}
              label={'Оставьте комментарий'}
              onChange={handleChange}
              value={values.paymentArrestedComment}
              name={'paymentArrestedComment'}
              error={
                touched.paymentArrestedComment && errors.paymentArrestedComment ? errors.paymentArrestedComment : ''
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Hold;
