import React, { useCallback, useEffect } from 'react';
import { useStore } from 'store';

export const RowCheckbox: React.FC<{
  id: string;
}> = ({ id }) => {
  const { editFacilityScheduleIds, facilityScheduleIds } = useStore();

  const [checkbox, setCheckbox] = React.useState(false);

  const checkboxHandler = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setCheckbox(checked);
      editFacilityScheduleIds(id);
    },
    [editFacilityScheduleIds, id]
  );

  useEffect(() => {
    setCheckbox(facilityScheduleIds.includes(id));
  }, [facilityScheduleIds, id]);

  return (
    <input type="checkbox" name="reportId" className="checkbox-primary" checked={checkbox} onChange={checkboxHandler} />
  );
};
