import { useMemo } from 'react';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useCities } from 'utils/api/useCities';
import { getFacility, getFacilityByGroupId } from 'utils/helpers/get/getFacility';
import uniqueArray from 'utils/helpers/uniqueArray';
import { getCity } from 'utils/helpers/get/getCity';

interface Props {
  facilityIds: string[];
  facilityGroupIds: string[];
}

export const useFilteredCities = ({ facilityGroupIds, facilityIds }: Props) => {
  const { facilities } = useSelectFacilities();
  const { cities } = useCities();
  return useMemo(() => {
    const facilityObjects = facilityIds.map(facilityId => getFacility({ facilityId, facilities })).filter(Boolean);
    const facilityGroupObjects = facilityIds.length
      ? []
      : facilityGroupIds.map(facilityGroupId => getFacilityByGroupId({ facilityGroupId, facilities })).flat();
    const allFacilities = [...facilityObjects, ...facilityGroupObjects];
    const uniqFacilities = allFacilities.length
      ? uniqueArray({ array: [...facilityObjects, ...facilityGroupObjects], field: 'id' })
      : [];

    if (!uniqFacilities.length) return cities;
    return uniqueArray({
      array: uniqFacilities.map(el => getCity({ cities, cityId: el.cityId })).filter(Boolean),
      field: 'id',
    });
  }, [cities, facilities, facilityGroupIds, facilityIds]);
};
