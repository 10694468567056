import useMenuElement from 'utils/hooks/useMenuElements';
import MenuElementMobile from '../element/MobileElement';
import MobileSubMenu from '../submenu/MobileSubMenu';

const MobileMenuElements = () => {
  const menuElements = useMenuElement();
  return (
    <>
      {menuElements.map(item => {
        if (item.menus?.length) {
          return <MobileSubMenu key={item.id} item={item} />;
        }
        return <MenuElementMobile key={item.id} item={item} />;
      })}
    </>
  );
};

export default MobileMenuElements;
