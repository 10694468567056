import clsx from 'clsx';

interface ILegendRow {
  text: string;
  bgColor: string;
  array?: any[];
}

const LegendRow = ({ text, bgColor, array }: ILegendRow) => {
  return (
    <div className="flex items-center gap-x-2">
      <span className={clsx('dot-legend', bgColor)}></span>
      <span className="Caption-numbers text-smena_text w-4">{array?.length || 0}</span>
      <span className="Table-small text-smena_text-secondary">{text}</span>
    </div>
  );
};

export default LegendRow;
