import Dates from './components/Dates';
import MainInfo from './components/MainInfo';
import { FormikTouched, FormikErrors } from 'formik';
import Rewards from './components/Rewards';
import { InitialValues } from 'pages/referralProgramCreate/utils/hooks';

interface Props {
  values: InitialValues;
  handleChange: any;
  touched: FormikTouched<any>;
  errors: FormikErrors<InitialValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<InitialValues>>;
}

const Editor = ({ values, handleChange, touched, errors, setFieldValue }: Props) => {
  return (
    <div className="blockTemplate">
      <MainInfo values={values} handleChange={handleChange} touched={touched} errors={errors} />
      <hr />

      <Rewards values={values} handleChange={handleChange} touched={touched} errors={errors} />
      <hr />

      <Dates
        values={values}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        touched={touched}
        errors={errors}
      />
    </div>
  );
};

export default Editor;
