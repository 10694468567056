import { useMemo } from 'react';
import { PayoutType, UnitType } from 'generated/graphql';
import { MapVacancyFragment } from '../api/fragments/generated/MapVacancy.fragment';

export const useCost = (vacancy?: MapVacancyFragment) => {
  return useMemo(() => {
    if (vacancy?.isPublishCostOfShift) {
      if (vacancy?.isRangeCost) {
        return `${vacancy?.shiftCostFrom} - ${vacancy?.shiftCostUpTo} ₽/смена`;
      }
      return `${vacancy?.averageCost} ₽/смена`;
    }
    if (vacancy?.payout === PayoutType.Hourly) {
      return `${vacancy?.hourRate} ₽/час`;
    }
    return `${vacancy?.hourRate} ₽/${vacancy?.unit === UnitType.Piece ? 'шт.' : '%'}`;
  }, [
    vacancy?.averageCost,
    vacancy?.hourRate,
    vacancy?.isPublishCostOfShift,
    vacancy?.isRangeCost,
    vacancy?.payout,
    vacancy?.shiftCostFrom,
    vacancy?.shiftCostUpTo,
    vacancy?.unit,
  ]);
};
