import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import { Slide, ToastContainer } from 'react-toastify';
import AdminLogin from './components/AdminLogin';
import CodeLogin from './components/CodeLogin';
import Login from './components/Login';
import Logo from 'ui/logo';
import { FastLogin } from './components/fastLogin';

const LoginPage = () => {
  const [loginPage, setLoginPage] = useState('login');
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap"
          rel="stylesheet"
        />
        <title>Авторизация</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Helmet>
      <div className="min-h-screen bg-smena_bb-background_base flex items-center">
        <div className="mx-auto md:w-full md:max-w-xs flex flex-col gap-y-5">
          <div className={'flex justify-between items-center'}>
            <Logo />
            <FastLogin />
          </div>
          {loginPage === 'login' && <Login setLoginPage={setLoginPage} />}
          {loginPage === 'password' && <AdminLogin />}
          {loginPage === 'sms' && <CodeLogin />}
        </div>
        {/*<ToastContainer*/}
        {/*  stacked*/}
        {/*  transition={Slide}*/}
        {/*  position="top-center"*/}
        {/*  autoClose={5000}*/}
        {/*  newestOnTop={false}*/}
        {/*  closeOnClick={false}*/}
        {/*  rtl={false}*/}
        {/*  draggable*/}
        {/*  pauseOnHover*/}
        {/*/>*/}
      </div>
    </>
  );
};

export default LoginPage;
