import { ApolloCache } from '@apollo/client';
import { CityFragment, CityFragmentDoc } from '../fragments/generated/City.fragment';

interface Props {
  cache: ApolloCache<any>;
  data?: CityFragment;
  update?: boolean;
}

export const createCityCache = ({ cache, data }: Props) => {
  cache.modify({
    fields: {
      cities(existingCities = []) {
        const newPosition = cache.writeFragment({
          data,
          fragment: CityFragmentDoc,
        });
        return [...existingCities, newPosition];
      },
    },
  });
};
