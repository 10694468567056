import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import MainLayout from 'components/layout/main';
import { activeHandler } from 'utils/helpers/isActiveHandler';
import useFacilityGroupMenu from 'utils/hooks/headerMenu/useFacilityGroupMenu';
import CustomPagination from 'ui/pagination';
import { useFacilitiesPaginatedQuery } from 'pages/facilities/api/queries/generated/FacilitiesPaginated';
import { useFacilityGroupTitle } from '../facilityGroup/hooks/useFacilityGroupTitle';
import { useFacilityGroupBreadcrumbs } from '../facilityGroup/hooks/useFacilityGroupBreadcrumbs';
import { FacilityFieldsSorting, SortingWay } from 'generated/graphql';
import { useFacilityGroupFacilitiesTable } from 'components/table/columns/facilityGroupFacilitites';
import NewTable from 'components/table/newTable';
import { FacilityPaginatedFragment } from 'pages/facilities/api/fragments/generated/FacilityPaginated.fragment';
import GroupFacilitiesFilter from './components/GroupFacilitiesFilter';

const FacilitiesGroupPage = () => {
  const [params] = useSearchParams();
  const router = useParams();
  const id = router.id;
  const title = useFacilityGroupTitle();
  const headerMenu = useFacilityGroupMenu();
  const breadcrumbs = useFacilityGroupBreadcrumbs();

  const searchParam = params.get('search');
  const activeParam = params.get('active');
  const cityParam = params.get('cityId');
  const namixObjectIdParam = params.get('namixObjectId');
  const sortBy = params.get('sortBy');
  const sortWay = params.get('sortWay');
  const pageParam = params.get('page');
  const page = pageParam ? +pageParam : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;

  const {
    data: dataFacilities,
    loading: loadingFacilities,
    error,
  } = useFacilitiesPaginatedQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        groupId: id,
        page,
        cityId: cityParam,
        isActive: activeHandler(activeParam),
        searchQuery: searchParam,
        sortBy: sortBy as FacilityFieldsSorting,
        sortWay: sortWay as SortingWay,
        naimixObjectId: namixObjectIdParam,
        size,
      },
    },
  });

  const facilitiesPaginated = dataFacilities?.facilitiesPaginated;
  const facilities = useMemo(() => facilitiesPaginated?.facilities || [], [facilitiesPaginated?.facilities]);
  const table = useFacilityGroupFacilitiesTable(facilities);

  return (
    <MainLayout title={title} headerMenu={headerMenu} breadCrumbs={breadcrumbs} error={error}>
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <GroupFacilitiesFilter table={table} />
        <NewTable<FacilityPaginatedFragment>
          table={table}
          length={facilities.length}
          loading={loadingFacilities}
          headerMenu={true}
        />
        {facilitiesPaginated && <CustomPagination currentPage={page} total={facilitiesPaginated.pages} />}
      </div>
    </MainLayout>
  );
};

export default FacilitiesGroupPage;
