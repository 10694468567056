import React, { useMemo, useState } from 'react';
import { ColumnDef, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { PaginationModerationReportFragment } from 'pages/moderationReports/api/fragments/generated/PaginationModerationReport.fragment';
import Checkbox from './checkbox';
import { groupName } from 'utils/helpers/filters/filtersName';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { shiftPeriodStatus } from 'utils/helpers/get/getShiftPeriodStatus';
import { getModerationStatusRu } from 'utils/helpers/lists/statusType';
import Actions from './actions';

const useModerationTable = (data: PaginationModerationReportFragment[]) => {
  const { facilitiesGroups } = useSelectFacilityGroups();

  const columns: ColumnDef<PaginationModerationReportFragment>[] = useMemo(() => {
    return [
      {
        id: 'checkbox',
        header: ({ table }) => (
          <>
            <Checkbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
                original: undefined,
              }}
            />
          </>
        ),
        size: 52,
        minSize: 52,
        cell: ({ row }) => (
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
              original: row.original,
            }}
          />
        ),
      },
      {
        id: 'dateStart',
        header: 'Период',
        minSize: 140,
        cell: ({ row: { original } }) => (
          <Link to={`/moderation/${original.id}`} className="Table-small text-primary hover:underline">
            {`${dayjs(original.dateStart).format('DD.MM.YY')}-${dayjs(original.dateEnd).format('DD.MM.YY')}`}
          </Link>
        ),
      },
      {
        id: 'group',
        header: 'Группа',
        minSize: 160,
        cell: ({ row: { original } }) => groupName(facilitiesGroups, original.facility?.facilityGroupId),
      },
      {
        id: 'facility',
        header: 'Объект',
        minSize: 190,
        cell: ({ row: { original } }) => original.facility?.name,
      },
      {
        id: 'employers',
        header: 'Исполнители',
        minSize: 145,
        cell: ({ row: { original } }) => original.workersCount,
      },
      {
        id: 'updatedAt',
        header: 'Изменен',
        minSize: 145,
        cell: ({ row: { original } }) => dayjs(original.updatedAt).format('DD.MM.YY'),
      },
      {
        id: 'status',
        header: 'Статус',
        minSize: 145,
        cell: ({ row: { original } }) => (
          <span className={`moderation__status ${shiftPeriodStatus(original.status)}`}>
            {getModerationStatusRu(original.status)}
          </span>
        ),
      },
      {
        id: 'statusDate',
        header: 'Дата статуса',
        minSize: 170,
        cell: ({ row: { original } }) =>
          original.statusDate
            ? dayjs(original.statusDate).format('DD.MM.YY')
            : dayjs(original.createdAt).format('DD.MM.YY'),
      },
      {
        id: 'actions',
        header: 'Действия',
        minSize: 70,
        cell: ({ row: { original } }) => <Actions original={original} />,
      },
    ];
  }, [facilitiesGroups]);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );
  const enableRowSelection = (row: Row<PaginationModerationReportFragment>) => {
    const notModeratedMoreThanO = Number(row.original?.noModerated) > 0;
    const workersCountEqualO = Number(row.original?.workersCount) === 0;
    const shiftCountEqualO = Number(row.original?.shiftCount) === 0;

    const disableCheckbox = notModeratedMoreThanO || workersCountEqualO || shiftCountEqualO;

    return !disableCheckbox;
  };
  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    meta: {
      dateStart: true,
      updatedAt: true,
      statusDate: true,
    },
    enableRowSelection,
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};

export default useModerationTable;
