import React from 'react';

import RateComponent from 'ui/rate';
import ProgressComponent from 'ui/progress';
import { useProgressValues } from './hooks';
import { progressColor } from './helpers';
import { useCurrentUser } from 'utils/api/useUser';

const RateBlock = () => {
  const { user } = useCurrentUser();
  const userAutoRating = user?.userAutoRating;

  const { rating, documents, performance, penalization, diligence, discipline } = useProgressValues(
    user?.userAutoRating
  );

  return (
    <div className="userPageBlock blockTemplate">
      <h2 className="blockTitleTemplate">Рейтинг</h2>
      <div className="rate-block">
        <div className="flex flex-col gap-y-3">
          <div className="flex flex-col gap-y-1">
            <div className="Subtitle2 flex items-center gap-x-1">
              <span>Автоматическая оценка</span>
            </div>
            <div className="flex gap-x-2 w-[140px]">
              <span className="Subtitle2">{userAutoRating?.rating}</span>
              <ProgressComponent percent={rating} strokeColor={progressColor(rating)} />
            </div>
          </div>
          <div className="rateBlockAuto gap-x-2 gap-y-1">
            <span className="Body2 text-smena_text-secondary">Документы</span>
            <ProgressComponent percent={documents} strokeColor={progressColor(documents)} />
            <span className="Body2 text-smena_text-secondary">Дисциплина</span>
            <ProgressComponent percent={discipline} strokeColor={progressColor(discipline)} />
            <span className="Body2 text-smena_text-secondary">Работоспособность</span>
            <ProgressComponent percent={performance} strokeColor={progressColor(performance)} />
            <span className="Body2 text-smena_text-secondary">Исполнительность</span>
            <ProgressComponent percent={diligence} strokeColor={progressColor(diligence)} />
            <span className="Body2 text-smena_text-secondary">Штрафуемость</span>
            <ProgressComponent percent={penalization} strokeColor={progressColor(penalization)} />
          </div>
        </div>
        <div className="flex flex-col gap-y-5 md:gap-y-10">
          <span className="Subtitle2">Ручная оценка</span>
          <div className="rateBlockAuto gap-x-2 gap-y-1">
            <span className="Body2 text-smena_text-secondary">Скорость</span>
            <RateComponent defaultValue={user?.speed} disabled />
            <span className="Body2 text-smena_text-secondary">Ответственность</span>
            <RateComponent defaultValue={user?.responsiveness} disabled />
            <span className="Body2 text-smena_text-secondary">Характер</span>
            <RateComponent defaultValue={user?.character} disabled />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateBlock;
