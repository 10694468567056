import { useMemo } from 'react';
import { useFacility } from '../../api/useFacility';
import { useParams } from 'react-router-dom';
import { useFacilityGroup } from '../../api/useFacilityGroup';

const useFacilityBreadcrumbs = () => {
  const router = useParams();
  const { facility } = useFacility();
  const facilityGroupId = router.id;
  const { facilityGroup } = useFacilityGroup();
  const facilityBreadcrumb = useMemo(
    () =>
      facility?.id
        ? { path: '/facilities/' + facility.id, name: `${facility.name}` }
        : { path: '/facilities/create', name: 'Новый объект' },
    [facility?.id, facility?.name],
  );
  return useMemo(() => {
    if (facilityGroupId) {
      return [
        { path: '/groups', name: 'Группы' },
        { path: `/groups/${facilityGroupId}`, name: `${facilityGroup?.name}` },
        facilityBreadcrumb,
      ];
    }
    return [{ path: '/facilities', name: 'Объекты' }, facilityBreadcrumb];
  }, [facilityGroup?.name, facilityBreadcrumb, facilityGroupId]);
};

export default useFacilityBreadcrumbs;
