import { useMemo } from 'react';
import { WorkPostStatus } from 'generated/graphql';
import { chain } from 'lodash';
import { WorkpostFragment } from 'pages/user/api/fragments/generated/Workpost.fragment';

const useFacilityCasingsAtCalendarHeader = (
  facilityId: string,
  facilityCasingsPositions?: any[],
  workpostsPositions?: WorkpostFragment[] | null
) => {
  return useMemo(() => {
    if (facilityId) {
      return chain(workpostsPositions)
        .filter(workpost => workpost.status === WorkPostStatus.Active)
        .map(workpost => {
          return facilityCasingsPositions?.filter(
            facilityCasing =>
              facilityCasing.id === workpost.position?.id && facilityCasing.facilityId === workpost.facilityId
          );
        })
        .flatten()
        .compact()
        .value();
    }
    return [];
  }, [workpostsPositions, facilityCasingsPositions, facilityId]);
};

export default useFacilityCasingsAtCalendarHeader;
