import { ShiftStage } from 'generated/graphql';
import { setEndDateSchedule } from 'utils/helpers/setEndDateSchedule';
import dayjs from 'dayjs';
import { ICustomEvent } from 'interfaces/IEvent';
import { useMemo } from 'react';
import { TIME_DATE } from 'utils/helpers/constVariables';
import { useUserShifts } from '../../../pages/userTracking/lib/hooks/useUserShifts';

const useScheduleMainEvents = () => {
  const { userShifts } = useUserShifts();
  return useMemo(() => {
    const mainEvents: ICustomEvent[] = [];
    const mainEventsWithoutCanceled: ICustomEvent[] = [];
    if (userShifts) {
      userShifts.forEach(shift => {
        const {
          id,
          authorId,
          userId,
          dateFrom,
          dateEnd,
          facility,
          positionId,
          lunchDuration,
          comment,
          stage,
          rejectedById,
          isProbation,
          scheduleId,
        } = shift;

        const event = {
          title: `${dayjs(shift.dateFrom).format(TIME_DATE)}-${
            shift.dateEnd ? dayjs(shift.dateEnd).format(TIME_DATE) : ' –:–'
          }`,
          start: dayjs(shift.dateFrom).toDate(),
          end: setEndDateSchedule(dayjs(shift.dateFrom).toDate(), dayjs(shift.dateEnd).toDate()),
          resource: {
            id,
            scheduleId,
            authorId,
            userId,
            dateFrom,
            dateEnd,
            facility,
            positionId,
            lunchDuration,
            comment,
            stage,
            rejectedById: rejectedById || '',
            isProbation,
            schedule: {
              id: shift.scheduleId || '',
              scheduleTypeId: shift.schedule?.scheduleTypeId,
              dateFrom: shift.schedule?.dateFrom,
              dateTo: shift.schedule?.dateTo,
              isCustom: shift.schedule?.isCustom,
            },
            request: {
              id: shift.zayavkaId,
              number: shift.zayavka?.number,
            },
          },
        };
        if (shift.stage !== ShiftStage.Refused && shift.stage !== ShiftStage.Ineligible) {
          mainEventsWithoutCanceled.push(event);
        }
        mainEvents.push(event);
      });
    }
    return {
      mainEvents,
      mainEventsWithoutCanceled,
    };
  }, [userShifts]);
};

export default useScheduleMainEvents;
