import { useMemo } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { RU_DATE } from 'utils/helpers/constVariables';
import { useStore } from 'store';
import { useMeRole } from 'store/hooks/useMeRole';
import { useRequestShift } from 'pages/request/lib/hooks/useRequestShift';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { useMe } from 'utils/api/useMe';
import { Role } from 'generated/graphql';
import { FacilityRequestSchedulesFragment } from 'pages/facilityRequests/api/fragments/generated/FacilityRequestSchedules.fragment';

interface IRequestProfessionDay {
  day: dayjs.Dayjs;
  requestSchedule: FacilityRequestSchedulesFragment;
}

const RequestProfessionDay = ({ day, requestSchedule }: IRequestProfessionDay) => {
  const me = useMe();
  const { request } = useCurrentRequest();
  const isMeRequestAuthor = me?.id === request?.authorId;
  const setShowEditRequestShiftSidebar = useStore(state => state.setShowEditRequestShiftSidebar);
  const setShowCreateRequestShiftSidebar = useStore(state => state.setShowCreateRequestShiftSidebar);
  const setRequestShift = useStore(state => state.setRequestShift);
  const setRequestDayAtRow = useStore(state => state.setRequestDayAtRow);
  const setRequestSchedule = useStore(state => state.setRequestSchedule);

  const dayRequestShift = useMemo(
    () => requestSchedule.zayavkaShift?.find(shift => dayjs(shift.dateFrom).format(RU_DATE) === day.format(RU_DATE)),
    [day, requestSchedule.zayavkaShift],
  );

  const { requestShift, content, className } = useRequestShift({
    requestShift: dayRequestShift,
    day,
  });

  const isMeSupervisor = useMeRole([Role.Supervisor]);

  const onClick = () => {
    setRequestSchedule(requestSchedule);
    setRequestDayAtRow({ day, positionId: requestSchedule.positionId, requestScheduleId: requestSchedule.id });
    if (requestShift) {
      setRequestShift(requestShift);
      setShowEditRequestShiftSidebar(true);
    } else {
      if ((isMeSupervisor && isMeRequestAuthor) || !isMeSupervisor) {
        setShowCreateRequestShiftSidebar(true);
      }
    }
  };

  return (
    <button className={clsx('text-center rounded Tag', className)} type="button" onClick={onClick}>
      {content}
    </button>
  );
};

export default RequestProfessionDay;
