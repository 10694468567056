import { FacilitiesFragment } from 'pages/main/api/fragments/generated/Facilities.fragment';
import { FacilityGroupsFragment } from 'pages/main/api/fragments/generated/FacilityGroups.fragment';
import { getFacilityGroup } from '../get/getFacilityGroup';
import uniqueArray from '../uniqueArray';

interface Props {
  facilities: FacilitiesFragment[];
  facilitiesGroups: FacilityGroupsFragment[];
  cityId: string;
}

export const filterFacilityGroups = ({ facilities, facilitiesGroups, cityId }: Props) => {
  if (!cityId) return facilitiesGroups;
  return uniqueArray({
    array: facilities
      .filter(facility => (cityId ? facility.cityId === cityId : true))
      .map(facility => getFacilityGroup({ facilityGroupId: facility.facilityGroupId, facilitiesGroups })),
    field: 'id',
  });
};
