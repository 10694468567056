import React from 'react';
import MainLayout from 'components/layout/main';
import { useStocksHeaderMenu } from '../leads/utils/hooks/headerMenu';
import PromotionRow from './components/PromotionRow';
import { useFacilityGroups } from '../../utils/api/useFacilityGroups';

const PromotionsPage = () => {
  const headerMenu = useStocksHeaderMenu();
  // const [params] = useSearchParams();

  // const searchQuery = params.get('search');

  const { facilitiesGroups, loading, error } = useFacilityGroups();
  return (
    <MainLayout
      title="Акции"
      breadCrumbs={[{ path: '/offers/twentyOne', name: 'Акции' }]}
      headerMenu={headerMenu}
      error={error}
      loading={loading}
    >
      <div className={'grid gap-y-6'}>
        {facilitiesGroups?.map(group => <PromotionRow key={group.id} group={group} />)}
      </div>
    </MainLayout>
  );
};

export default PromotionsPage;
