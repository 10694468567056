import React from 'react';
import Skeleton from 'antd/es/skeleton';

const FacilityGroupsSkeleton = () => {
  const groupsItems = [...Array(20)];
  return (
    <div className="grid grid-cols-12 gap-6">
      {groupsItems.map((_, index) => (
        <div
          key={++index}
          className="col-span-3 p-5 flex justify-between items-center shadow-smena rounded-lg bg-smena_white"
        >
          <div className={'flex flex-col gap-y-2'}>
            <Skeleton.Button
              active
              size={'small'}
              style={{
                width: '100px',
                height: '20px',
              }}
            />
            <Skeleton.Button
              active
              size={'small'}
              style={{
                width: '70px',
                height: '20px',
              }}
            />
          </div>
          <Skeleton.Avatar
            active
            style={{
              width: '74px',
              height: '74px',
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default FacilityGroupsSkeleton;
