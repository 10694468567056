import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast, successToast } from 'utils/helpers/notify';
import { latitudeReg, longitudeReg, regFacilityName } from 'utils/helpers/constVariables';
import { useCreateFacilityMutation } from 'pages/facilities/api/mutations/generated/CreateFacility';
import { useUpdateFacilityMutation } from 'pages/facilities/api/mutations/generated/UpdateFacility';
import { useFacility } from 'utils/api/useFacility';
import { createFacilityCache } from '../../api/cache/createFacilityCache';
import { useMapStore } from './useMapStore';

const useFacilityPageForm = () => {
  const { facility, id } = useFacility();
  const router = useParams();
  const location = useLocation();
  const groupId = router.id;
  const navigate = useNavigate();
  const { setLatitude, setLongitude, latitude, longitude } = useMapStore();

  const [updateFacility, { loading: updateLoading }] = useUpdateFacilityMutation();

  const [createFacility, { loading: createLoading }] = useCreateFacilityMutation({
    update: (cache, { data }) => createFacilityCache(cache, { data, groupId }),
  });

  const loading = useMemo(() => updateLoading || createLoading, [createLoading, updateLoading]);

  const initialValues = {
    group: groupId ?? (facility?.facilityGroupId || undefined),
    name: facility?.name,
    externalId: facility?.externalId,
    metro: facility?.metro,
    metroStationIds: facility?.metroStationsIds,
    radius: facility?.radius || 500,
    manager: { id: '', name: '' },
    is_active: facility?.is_active || false,
    is_bonus_active: facility?.is_bonus_active || false,
    showSalaryBeforeModeration: facility?.showSalaryBeforeModeration || false,
    is_finance_active: facility?.is_finance_active || false,
    cityId: facility?.cityId,
    cityFiasId: facility?.city?.externalId,
    addressString: facility?.addressString,
    legalEntityId: facility?.legalEntity?.id,
    naimixObjectId: facility?.naimixObjectId,
    naimixObjectName: facility?.naimixObjectName,
    naimixScenarioNumber: facility?.naimixScenarioNumber ?? null,
    checkInMeasurement: facility?.checkInMeasurement ?? 15,
    checkOutMeasurement: facility?.checkOutMeasurement ?? 15,
  };
  const validate = (values: typeof initialValues) => {
    let errors: any = {};
    if (!values.name?.trim()) errors.name = 'Обязательное поле';
    if (!values.cityId) errors.cityId = 'Обязательное поле';
    if (!values.group) errors.group = 'Вы не указали группу ';
    if (values.name && !regFacilityName.test(values.name))
      errors.name = 'Поле может содержать только буквы русского или латинского алфавитов, цифры, дефис, пробел';
    if (!values.addressString) errors.addressString = 'Обязательное поле';
    if (!latitude) errors.latitude = 'Обязательное поле';
    if (latitude && !latitudeReg.test(latitude)) errors.latitude = 'Вы неправильно ввели широту';
    if (!longitude) errors.longitude = 'Обязательное поле';
    if (longitude && !longitudeReg.test(longitude)) errors.longitude = 'Вы неправильно ввели долготу';
    if (!values.radius) errors.radius = 'Обязательное поле';
    if (!values.radius) errors.radius = 'Обязательное поле';
    if (!values.radius) errors.radius = 'Обязательное поле';
    if (!values.checkInMeasurement) errors.checkInMeasurement = 'Обязательное поле';
    if (!values.checkOutMeasurement) errors.checkOutMeasurement = 'Обязательное поле';

    return errors;
  };

  const onSubmit = (values: typeof initialValues) => {
    const commonFacilityValues = {
      facilityGroupId: values.group,
      name: values.name!.trim(),
      externalId: values.externalId,
      metro: values.metro,
      metroStationsIds: values.metroStationIds,
      addressString: values.addressString,
      latitude: latitude ? latitude?.toString().trim() : '',
      longitude: longitude ? longitude?.toString().trim() : '',
      radius: Number(values.radius),
      is_active: values.is_active,
      is_bonus_active: values.is_bonus_active,
      showSalaryBeforeModeration: values.showSalaryBeforeModeration,
      is_finance_active: values.is_finance_active,
      cityId: values.cityId!,
      cityFiasId: values.cityFiasId,
      legalEntityId: values.legalEntityId,
      naimixObjectId: values.naimixObjectId,
      naimixObjectName: values.naimixObjectName,
      naimixScenarioNumber:
        values.naimixScenarioNumber && values.naimixScenarioNumber.trim() !== '' ? values.naimixScenarioNumber : null,
      checkInMeasurement: values.checkInMeasurement,
      checkOutMeasurement: values.checkOutMeasurement,
    };
    if (id) {
      updateFacility({
        variables: {
          input: {
            ...commonFacilityValues,
            id,
          },
        },
      })
        .then(response => {
          if (response.data?.updateFacility) {
            successToast('Объект изменен');
          }
        })
        .catch(e => {
          errorToast(errorHandler(e));
        });
    } else {
      createFacility({
        variables: {
          input: commonFacilityValues,
        },
      })
        .then(response => {
          if (response.data?.createFacility) {
            const pathnameArray = location.pathname.split('/');
            const link = pathnameArray.slice(0, pathnameArray.length - 1).join('/');
            successToast('Объект создан');
            navigate(`${link}/${response.data?.createFacility.id}`);
          }
        })
        .catch(e => errorToast(errorHandler(e)));
    }
  };

  useEffect(() => {
    if (facility?.latitude) setLatitude(facility.latitude);
    if (facility?.longitude) setLongitude(facility?.longitude);
  }, [facility?.latitude, facility?.longitude]);

  return { initialValues, validate, onSubmit, loading };
};

export default useFacilityPageForm;
