import { TIcon } from 'interfaces/TIcon';

export const WarningExclamationSmall = ({ className }: TIcon) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8 6.0002C10.8 8.65116 8.65096 10.8002 6 10.8002C3.34903 10.8002 1.2 8.65116 1.2 6.0002C1.2 3.34923 3.34903 1.2002 6 1.2002C8.65096 1.2002 10.8 3.34923 10.8 6.0002ZM6.6 8.4002C6.6 8.73157 6.33137 9.0002 6 9.0002C5.66863 9.0002 5.4 8.73157 5.4 8.4002C5.4 8.06882 5.66863 7.8002 6 7.8002C6.33137 7.8002 6.6 8.06882 6.6 8.4002ZM6 3.0002C5.66863 3.0002 5.4 3.26882 5.4 3.6002V6.0002C5.4 6.33157 5.66863 6.6002 6 6.6002C6.33137 6.6002 6.6 6.33157 6.6 6.0002V3.6002C6.6 3.26882 6.33137 3.0002 6 3.0002Z"
      />
    </svg>
  );
};
