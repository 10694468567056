import React from 'react';
import { ErrorNotification } from '../errorNotification';
import clsx from 'clsx';

type FormInput = {
  divClassName?: string;
  label?: string;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  defaultChecked?: boolean;
  checked: boolean;
  name: string;
  error?: string;
  disabled?: boolean;
};

const SimpleCheckbox = ({
  divClassName,
  label,
  required,
  onChange,
  checked,
  defaultChecked,
  name,
  error,
  disabled,
}: FormInput) => {
  return (
    <div className={divClassName}>
      <div className={clsx('flex gap-y-1 gap-x-3 items-center')}>
        <input
          type="checkbox"
          required={required}
          name={name}
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={onChange}
          id={name}
          disabled={disabled}
          className={`${error ? 'border-error' : ''} checkbox-primary`}
        />
        {Boolean(label) && (
          <label htmlFor={name} className={`label-checkbox ${disabled ? 'cursor-not-allowed' : ''}`}>
            {label}
          </label>
        )}

        {error && <ErrorNotification text={error} />}
      </div>
    </div>
  );
};

export default SimpleCheckbox;
