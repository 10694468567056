import React from 'react';
import RequestsIcon from 'assets/icons/RequestsIcon';
import FacilityIcon from 'assets/icons/menu/facilityIcon';
import GroupsIcon from 'assets/icons/menu/groupsIcon';
import Home from 'assets/icons/menu/home';
import PaymentsIcon from 'assets/icons/menu/payments';
import ProfessionIcon from 'assets/icons/menu/professionIcon';
import ReferralIcon from 'assets/icons/menu/refferalIcon';

import TimesheetIcon from 'assets/icons/menu/timesheetIcon';
import UsersIcon from 'assets/icons/menu/users';
import VacancyIcon from 'assets/icons/menu/vacancyIcon';
import BalanceIcon from 'assets/icons/Balance';
import QuestionMarkIconBig from 'assets/icons/menu/QuestionMarkIconBig';
import EnvelopIcon from 'assets/icons/EnvelopIcon';
import CitiesIcon from 'assets/icons/menu/citiesIcon';
import ContractIcon from 'assets/icons/ContractIcon';

// import FeedbackIcon from 'assets/icons/menu/feedbackIcon';

export interface MenuItemProps {
  id: number;
  name: string;
  url: string;
  icon: JSX.Element;
  className?: string;
  menus?: { name: string; url: string; className?: string }[];
}

export const clientManagerMenuItemList: MenuItemProps[] = [
  {
    id: 1,
    name: 'Статистика',
    url: '/',
    icon: <Home className="" />,
    className: 'mb-6',
  },
  {
    id: 3,
    name: 'Объекты',
    url: '/facilities',
    icon: <FacilityIcon className="" />,
  },
  {
    id: 4,
    name: 'Заявки',
    url: '/requests',
    icon: <RequestsIcon className="" />,
  },
  {
    id: 2,
    name: 'Исполнители',
    url: '/users',
    className: 'mb-6',
    icon: <UsersIcon className="" />,
  },
  {
    id: 5,
    name: 'Отчёты',
    url: '/timesheet',
    icon: <TimesheetIcon className="" />,
  },
];
export const clientDirectorMenuItemList: MenuItemProps[] = [
  ...clientManagerMenuItemList,
  {
    id: 6,
    name: 'Баланс',
    url: '/balance',
    icon: <BalanceIcon className="" />,
  },
];

export const superMenuItemList: MenuItemProps[] = [
  {
    id: 1,
    name: 'Статистика',
    url: '/',
    className: 'mb-6',
    icon: <Home className="" />,
  },
  {
    id: 2,
    name: 'Пользователи',
    url: '/users',
    icon: <UsersIcon className="" />,
  },
  {
    id: 5,
    name: 'Объекты',
    url: '/facilities',
    icon: <FacilityIcon className="" />,
  },
  {
    id: 3,
    name: 'Заявки',
    url: '/requests',
    icon: <RequestsIcon className="" />,
  },
  {
    id: 4,
    name: 'Модерация смен',
    url: '/moderation',
    className: 'mb-6',
    icon: <PaymentsIcon className="" />,
  },
  {
    id: 6,
    name: 'Отчёты',
    url: '/timesheet',
    icon: <TimesheetIcon className="" />,
  },
];

export const menuItemList: MenuItemProps[] = [
  {
    id: 1,
    name: 'Статистика',
    url: '/',
    className: 'mb-6',
    icon: <Home className="" />,
  },
  {
    id: 2,
    name: 'Пользователи',
    url: '/users',
    icon: <UsersIcon className="" />,
  },
  {
    id: 13,
    name: 'Документы',
    url: '/contracts',
    icon: <ContractIcon />,
  },
  {
    id: 3,
    name: 'Заявки',
    url: '/requests',
    icon: <RequestsIcon className="" />,
  },
  {
    id: 4,
    name: 'Модерация смен',
    url: '/moderation',
    className: 'mb-6',
    icon: <PaymentsIcon className="" />,
  },
  {
    id: 5,
    name: 'Группы',
    url: '/groups',
    icon: <GroupsIcon className="" />,
  },
  {
    id: 6,
    name: 'Объекты',
    url: '/facilities',
    icon: <FacilityIcon className="" />,
  },
  {
    id: 7,
    name: 'Профессии',
    url: '/positions',
    icon: <ProfessionIcon className="" />,
  },
  {
    id: 8,
    name: 'Справочник городов',
    url: '/cities',
    icon: <CitiesIcon className="" />,
  },
  {
    id: 9,
    name: 'Вакансии',
    url: '/vacancies',
    className: 'mb-6',
    icon: <VacancyIcon className="" />,
  },

  {
    id: 10,
    name: 'Отчёты',
    url: '/timesheet',
    icon: <TimesheetIcon className="" />,
  },
  {
    id: 11,
    name: 'Акции',
    url: '/offers',
    icon: <ReferralIcon className="" />,
  },
  {
    id: 12,
    name: 'Рассылки',
    url: '/mailing',
    menus: [
      { name: 'Истории', url: '/stories' },
      { name: 'Пуш-уведомления', url: '/pushes' },
    ],
    icon: <EnvelopIcon />,
  },
];

export const bottomElements: MenuItemProps[] = [
  // {
  //   id: 98,
  //   url: '',
  //   name: 'Обратная связь',
  //   icon: <FeedbackIcon />,
  // },
  {
    id: 99,
    name: 'База знаний',
    url: 'https://knowledge.smena.app',
    icon: <QuestionMarkIconBig className="" />,
  },
];
