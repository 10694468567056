import { useState } from 'react';
// import { useStore } from 'store';
// import { errorHandler } from 'utils/helpers/errorHandler';
// import { errorToast } from 'utils/helpers/notify';
import { ManualAccrualItemFragment } from '../api/fragments/generated/ManualAccrualItem.fragment';

// import { useWriteManualAccrualItemMutation } from '../api/mutations/generated/WriteManualAccuralItem';

interface CustomNameInterface {
  item?: {
    __typename?: 'ManualAccrualItem';
  } & ManualAccrualItemFragment;
  userId: string | undefined;
  setUserSum: (sum: number) => void;
}

const CustomName = ({ item }: CustomNameInterface) => {
  // const { manualAccrual, disabledUsersIds } = useStore();
  const [value, setValue] = useState<any>(item?.totalAmount);
  // const [writeManualAccrualItem, { loading }] = useWriteManualAccrualItemMutation();
  // const send = (customName: string | undefined | null, totalAmount: number | undefined) => {
  //   writeManualAccrualItem({
  //     variables: {
  //       input: {
  //         accrualId: manualAccrual ? manualAccrual?.id : '',
  //         activeMonth: 0,
  //         hardWorker: 0,
  //         rateAmount: 0,
  //         superWorker: 0,
  //         totalAmount,
  //         userId: userId || '',
  //         customName,
  //       },
  //     },
  //   })
  //     .then(e => {
  //       const userSum = e.data?.writeManualAccuralItem.userSum;
  //       if (userSum) {
  //         setUserSum(userSum);
  //       }
  //     })
  //     .catch(e => errorToast(errorHandler(e)));
  // };

  // const canEdit = disabledUsersIds.includes(String(userId));

  return (
    <div className="grid grid-cols-3 px-2">
      <span className="col-span-2">{item?.customName}</span>
      <input
        type="number"
        min="0"
        step={0.01}
        className="py-0 inline-block focus:ring-1 focus:ring-primary sm:text-sm border-none bg-transparent font-roboto"
        // style={canEdit ? { cursor: 'not-allowed' } : {}}
        value={value || 0}
        // disabled={canEdit || loading}
        onChange={e => {
          setValue(Number(e.target.value));
        }}
        // onBlur={() => {
        //   send(item?.customName, value);
        // }}
      />
    </div>
  );
};

export default CustomName;
