import { useMemo } from 'react';
import { useGetSupervisorsFacilitiesQuery } from 'pages/userSchedule/api/queries/generated/GetSupervisorsFacilities';
import useWorkpostFacilities from './useWorkpostFacilities';
import { WorkPost } from 'generated/graphql';

const useSupervisorWorkposts = (): WorkPost[] | undefined => {
  const { data } = useGetSupervisorsFacilitiesQuery();
  const workpostsFacilities = useWorkpostFacilities();
  const facilities = data?.getSupervisorsFacilities;
  return useMemo(
    () =>
      facilities?.map(facility => workpostsFacilities.filter(workpost => workpost.facilityId === facility.id)).flat(),
    [facilities, workpostsFacilities],
  );
};

export default useSupervisorWorkposts;
