import { Tooltip } from 'antd';
import { useGetUserNaimixContractLazyQuery } from '../api/queries/generated/GetUserNaimixContract';
import { DownloadIcon } from 'assets/icons/download';

interface DownloadButtonProps {
  contractId: string;
}

const DownloadContract: React.FC<DownloadButtonProps> = ({ contractId }) => {
  const [getUserNaimixContract] = useGetUserNaimixContractLazyQuery();

  const handleDownload = async () => {
    try {
      const response = await getUserNaimixContract({ variables: { input: { userContractId: contractId } } });

      if (response.data?.getUserNaimixContract?.fileContent) {
        const fileContent = response.data.getUserNaimixContract.fileContent;

        const blob = new Blob(
          [
            new Uint8Array(
              atob(fileContent)
                .split('')
                .map(c => c.charCodeAt(0)),
            ),
          ],
          { type: 'application/pdf' },
        );

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'document.pdf';
        link.click();
      } else {
        console.error('Нет содержимого для скачивания');
      }
    } catch (err) {
      console.error('Ошибка при скачивании файла', err);
    }
  };

  return (
    <Tooltip title="Скачать документ">
      <button onClick={handleDownload}>
        <DownloadIcon />
      </button>
    </Tooltip>
  );
};

export default DownloadContract;
