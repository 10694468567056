import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useStore } from 'store';

export const useEditShiftTitle = () => {
  const { requestShift } = useStore();
  return useMemo(() => {
    if (requestShift) {
      const { newDateEnd, newDateFrom, newLunchDuration, isDeleted } = requestShift;
      if (newDateEnd || newDateFrom || newLunchDuration || isDeleted) {
        return 'Изменения в смене';
      }
      if (dayjs().diff(dayjs(requestShift.dateEnd)) > 0) {
        return 'Смена';
      }
      if (requestShift.isNew) {
        return 'Новая смена';
      }
      return 'Редактировать смену';
    }
    return '';
  }, [requestShift]);
};
