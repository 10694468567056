import { ApolloCache } from '@apollo/client';

export const uploadPhotoInFacilityGalleryCache = (cache: ApolloCache<any>, { image, facilityId }: any) => {
  cache.modify({
    id: `Facility:${facilityId}`,
    fields: {
      facilityGallery(prev = []) {
        if (!prev) return [image];
        return [...prev, image];
      },
    },
  });
};
