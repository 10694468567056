import { useCallback, useState } from 'react';
import { Role, ZayavkaStage } from 'generated/graphql';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Edit from 'assets/icons/edit';
import GarbageArrowLess from 'assets/icons/GarbageArrowLess';
import { cancelRequestByManagerCache, cancelRequestCache } from '../../../../pages/requests/api/updateCache';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useMeRole } from 'store/hooks/useMeRole';
import { useCancelRequestMutation } from 'pages/facilityRequests/api/mutations/generated/CancelRequest';
import { useCancelRequestByManagerMutation } from 'pages/facilityRequests/api/mutations/generated/CancelRequestByManager';
import Tooltip from 'antd/es/tooltip';
import { PaginatedRequestFragment } from 'pages/facilityRequests/api/fragments/generated/PaginatedRequest.fragment';
import Modal from 'ui/modal';

interface Props {
  original: PaginatedRequestFragment;
}

const ActionColumn = ({ original }: Props) => {
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [cancelRequest, { loading: loadingCancelRequest }] = useCancelRequestMutation();
  const [cancelRequestByManager, { loading: loadingCancelRequestByManager }] = useCancelRequestByManagerMutation();

  const cancelRequestByManagerHandler = useCallback(
    (request: PaginatedRequestFragment) => {
      cancelRequestByManager({
        variables: {
          id: request.id,
        },
        update: cache => {
          cancelRequestByManagerCache(cache, request);
        },
      })
        .then(() => {
          successToast('Заявка заявка предложена к отклонению');
        })
        .catch(error => errorToast(errorHandler(error)))
        .finally(() => {
          setShowCancelPopup(false);
        });
    },
    [cancelRequestByManager],
  );

  const cancelRequestHandler = useCallback(
    (request: PaginatedRequestFragment) => {
      cancelRequest({
        variables: {
          id: request.id,
        },
        update: cache => {
          cancelRequestCache(cache, request);
        },
      })
        .then(() => {
          successToast('Заявка отклонена');
        })
        .catch(error => errorToast(errorHandler(error)))
        .finally(() => setShowCancelPopup(false));
    },
    [cancelRequest],
  );

  const isMeManager = useMeRole([Role.ClientManager]);
  const isRequestFinished = original.status === ZayavkaStage.Finished;
  return (
    <>
      <div className="flex items-center justify-start gap-x-4">
        <Tooltip title={'Изменить заявку'}>
          <button
            type="button"
            disabled={isRequestFinished || original.status === ZayavkaStage.Canceled}
            className={clsx(isRequestFinished || original.status === ZayavkaStage.Canceled ? 'cursor-not-allowed' : '')}
          >
            <Link
              to={`edit/${original.id}`}
              className={clsx(
                'block',
                isRequestFinished || original.status === ZayavkaStage.Canceled
                  ? 'pointer-events-none text-smena_text-disabled cursor-not-allowed'
                  : 'text-smena_text transform hover:scale-110 ',
              )}
            >
              <Edit />
            </Link>
          </button>
        </Tooltip>
        <Tooltip title={'Отменить заявку'}>
          <button
            disabled={isRequestFinished || original.status === ZayavkaStage.Canceled}
            className={clsx(isRequestFinished ? 'cursor-not-allowed' : '')}
            onClick={() => {
              setShowCancelPopup(true);
            }}
          >
            <GarbageArrowLess
              className={clsx(
                isRequestFinished || original.status === ZayavkaStage.Canceled
                  ? 'text-smena_text-disabled cursor-not-allowed'
                  : '',
              )}
            />
          </button>
        </Tooltip>
      </div>
      <Modal
        type="delete"
        title="Отменить заявку?"
        open={showCancelPopup}
        onCancel={() => setShowCancelPopup(false)}
        okButtonProps={{ disabled: loadingCancelRequest || loadingCancelRequestByManager }}
        onOk={() => {
          if (isMeManager) {
            cancelRequestByManagerHandler(original);
          } else {
            cancelRequestHandler(original);
          }
        }}
      >
        Вы уверены, что хотите отменить заявку?
      </Modal>
    </>
  );
};

export default ActionColumn;
