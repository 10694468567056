import { TIcon } from '../../interfaces/TIcon';
import clsx from 'clsx';

const LegendIcon = ({ className }: TIcon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99882 4.23242C4.80637 3.89909 4.32524 3.89909 4.13279 4.23242L2.40074 7.23242C2.20829 7.56576 2.44885 7.98242 2.83375 7.98242H6.29786C6.68276 7.98242 6.92332 7.56576 6.73087 7.23242L4.99882 4.23242ZM10.376 4.98242C9.82369 4.98242 9.37598 5.43014 9.37598 5.98242C9.37598 6.53471 9.82369 6.98242 10.376 6.98242L20.376 6.98242C20.9283 6.98242 21.376 6.53471 21.376 5.98242C21.376 5.43014 20.9283 4.98242 20.376 4.98242L10.376 4.98242ZM10.376 16.9824C9.82369 16.9824 9.37598 17.4301 9.37598 17.9824C9.37598 18.5347 9.82369 18.9824 10.376 18.9824H20.376C20.9283 18.9824 21.376 18.5347 21.376 17.9824C21.376 17.4301 20.9283 16.9824 20.376 16.9824H10.376ZM9.37598 11.9824C9.37598 11.4301 9.82369 10.9824 10.376 10.9824H20.376C20.9283 10.9824 21.376 11.4301 21.376 11.9824C21.376 12.5347 20.9283 12.9824 20.376 12.9824H10.376C9.82369 12.9824 9.37598 12.5347 9.37598 11.9824ZM3.06543 10.0176C2.78929 10.0176 2.56543 10.2414 2.56543 10.5176V13.5176C2.56543 13.7937 2.78929 14.0176 3.06543 14.0176H6.06543C6.34157 14.0176 6.56543 13.7937 6.56543 13.5176V10.5176C6.56543 10.2414 6.34157 10.0176 6.06543 10.0176H3.06543ZM4.56543 20.2324C5.80807 20.2324 6.81543 19.2251 6.81543 17.9824C6.81543 16.7398 5.80807 15.7324 4.56543 15.7324C3.32279 15.7324 2.31543 16.7398 2.31543 17.9824C2.31543 19.2251 3.32279 20.2324 4.56543 20.2324Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LegendIcon;
