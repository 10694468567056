import { useCallback, useState } from 'react';
import dayjs from 'dayjs';

import { useStore } from 'store';

import { auchanLunchDurationTypes, lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import { timeDifference } from 'utils/helpers/timeDifference';

import { DayjsOrNull } from 'interfaces/CustomTypes';
import SimpleSelect from 'ui/select';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import { useFacilityQuery } from 'pages/facility/api/queries/generated/Facility';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';

const NewCustomSchedule = () => {
  const { setIsCalendar } = useFacilityReportStore();
  const setCreateScheduleMenu = useStore(state => state.setCreateScheduleMenu);
  const [timeStart, setTimeStart] = useState<DayjsOrNull>(dayjs());
  const [timeEnd, setTimeEnd] = useState<DayjsOrNull>(dayjs());

  const lunchDuration = useStore(state => state.lunchDuration);
  const setLunchDuration = useStore(state => state.setLunchDuration);
  const facilityId = useStore(state => state.facilityId);

  const setStartScheduleTime = useStore(state => state.setStartScheduleTime);
  const setEndScheduleTime = useStore(state => state.setEndScheduleTime);

  const { data } = useFacilityQuery({
    variables: {
      id: facilityId,
    },
  });
  const { isAuchan } = useFacilityGroupById(data?.facility.facilityGroupId);

  const onChangeTimeStart = useCallback(
    (timeValue: DayjsOrNull) => {
      setTimeStart(timeValue);
      setStartScheduleTime(timeValue);
      if (isAuchan) {
        setLunchDuration(lunchDurationHandle(timeDifference(timeValue, timeEnd)));
      }
    },
    [isAuchan, setLunchDuration, setStartScheduleTime, timeEnd],
  );

  const onChangeTimeEnd = useCallback(
    (timeValue: DayjsOrNull) => {
      setTimeEnd(timeValue);
      setEndScheduleTime(timeValue);
      if (isAuchan) {
        setLunchDuration(lunchDurationHandle(timeDifference(timeStart, timeValue)));
      }
    },
    [setEndScheduleTime, isAuchan, setLunchDuration, timeStart],
  );

  const buttonClickHandler = () => {
    setIsCalendar(true);
    setCreateScheduleMenu(false);
  };

  return (
    <div className="grid grid-cols-4 gap-x-5 gap-y-4">
      <TimePickerInput
        label="Начало смены"
        className="col-span-2"
        popupClassName="fixed"
        value={timeStart}
        onChange={onChangeTimeStart}
      />
      <TimePickerInput
        label="Конец смены"
        className="col-span-2"
        popupClassName="fixed"
        value={timeEnd}
        onChange={onChangeTimeEnd}
      />
      <SimpleSelect
        divClassName="col-span-2"
        label="Обед"
        sort={false}
        onChange={value => {
          setLunchDuration(Number(value));
        }}
        value={lunchDuration}
        options={isAuchan ? auchanLunchDurationTypes : lunchDurationTypes}
      />

      <div className="col-span-full flex flex-col items-end">
        <button onClick={buttonClickHandler} type="button" className="btn-primary_small">
          Перейти к графику
        </button>
      </div>
    </div>
  );
};

export default NewCustomSchedule;
