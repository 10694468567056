import { TIcon } from '../../interfaces/TIcon';
import clsx from 'clsx';

const Magnifier = ({ className }: TIcon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('fill-current', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1967 5.1967C6.60322 3.79018 8.51088 3 10.5 3C12.4891 3 14.3968 3.79018 15.8033 5.1967C17.2098 6.60322 18 8.51088 18 10.5C18 12.1528 17.4545 13.7493 16.4631 15.0489L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L15.0489 16.4631C13.7493 17.4545 12.1528 18 10.5 18C8.51088 18 6.60322 17.2098 5.1967 15.8033C3.79018 14.3968 3 12.4891 3 10.5C3 8.51088 3.79018 6.60322 5.1967 5.1967ZM10.5 5C9.04131 5 7.64236 5.57946 6.61091 6.61091C5.57946 7.64236 5 9.04131 5 10.5C5 11.9587 5.57946 13.3576 6.61091 14.3891C7.64236 15.4205 9.04131 16 10.5 16C11.9587 16 13.3576 15.4205 14.3891 14.3891C15.4205 13.3576 16 11.9587 16 10.5C16 9.04131 15.4205 7.64236 14.3891 6.61091C13.3576 5.57946 11.9587 5 10.5 5Z"
      />
    </svg>
  );
};

export default Magnifier;
