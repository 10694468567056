import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import MainSidebar from 'ui/sidebar';
import dayjs, { Dayjs } from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import { lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import SimpleSelect from 'ui/select';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { shiftTimeDifference } from 'pages/userSchedule/helpers';
import { errorToast } from 'utils/helpers/notify';
// import { groupName } from 'utils/filters/filtersName';
// import { lunchDurationHandle } from 'utils/lunchDuration';
// import { timeDifference } from 'utils/timeDifference';
// import { auchanLunchDurationTypes, lunchDurationTypes } from 'utils/lists/lunchDurationTypes';
// import { positionName } from 'utils/filters/filtersName';
// import { errorHandler } from 'utils/errorHandler';

const EditScheduleSidebar = () => {
  const {
    showEditScheduleSidebar,
    setShowEditScheduleSidebar,
    schedule,
    setSchedule,
    setLunchDuration,
    lunchDuration,
    // positions,
  } = useStore();

  const [dateStart, setDateStart] = useState<Dayjs | null>(dayjs());
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs());
  const [selectedTime, setSelectedTime] = useState<DayjsOrNull>(dayjs());
  const [selectedEndTime, setSelectedEndTime] = useState<DayjsOrNull>(dayjs().add(30, 'm'));
  const [selectedType, setSelectedType] = useState<undefined | string>();
  const { scheduleTypes, twoXTwo } = useGetScheduleTypes();

  // const facilityGroupName = groupName(groups, data?.facility.facilityGroupId);

  // const [editScheduleMutation] = useEditScheduleMutation();

  const onChangeTimeStart = useCallback((timeValue: DayjsOrNull) => {
    if (timeValue) {
      setSelectedTime(dayjs(timeValue).set('milliseconds', 0));
      // if (facilityGroupName === 'Ашан') {
      //   setLunchDuration(lunchDurationHandle(timeDifference(timeValue, selectedEndTime)));
      // }
    }
  }, []);

  const onChangeTimeEnd = useCallback((timeValue: DayjsOrNull) => {
    if (timeValue) {
      setSelectedEndTime(timeValue.set('milliseconds', 0));
      // setEndScheduleTime(timeValue);
      // if (facilityGroupName === 'Ашан') {
      //   setLunchDuration(lunchDurationHandle(timeDifference(selectedTime, timeValue)));
      // }
    }
  }, []);

  const exitHandler = useCallback(() => {
    setShowEditScheduleSidebar(false);
    setSchedule({ action: '', id: '' });
  }, [setSchedule, setShowEditScheduleSidebar]);

  const onChangeSchedule = useCallback((value: string) => {
    setSelectedType(value);
  }, []);

  const buttonClickHandler = useCallback(() => {
    if (shiftTimeDifference(selectedTime, selectedEndTime)) {
      return errorToast('Смена не может быть меньше 1 часа');
    }
    if (schedule) {
      // editScheduleMutation({
      //   variables: {
      //     input: {
      //       id: schedule.id,
      //       lunchDuration,
      //       dateStart: selectedTime ? String(selectedTime.valueOf()) : '',
      //       dateEnd: selectedEndTime ? String(selectedEndTime.valueOf()) : '',
      //     },
      //   },
      // })
      //   .then(() => successToast('Смена успешно изменена'))
      //   .catch(error => {
      //     errorToast(errorHandler(error));
      //   });
      // .finally(() => {
      //   setShowEditScheduleSidebar(false);
      // });
    }
  }, [schedule, selectedEndTime, selectedTime]);

  useEffect(() => {
    if (schedule) {
      setSelectedTime(dayjs(schedule.dateFrom));
      setSelectedEndTime(dayjs(schedule.dateTo));
      setDateStart(dayjs(schedule.dateFrom));
      setDateEnd(dayjs(schedule.dateTo));
    }
  }, [schedule, setLunchDuration]);
  useEffect(() => {
    if (twoXTwo) setSelectedType(twoXTwo);
  }, [twoXTwo]);
  return (
    <MainSidebar title="Редактировать график" show={showEditScheduleSidebar} setShow={setShowEditScheduleSidebar}>
      <div className="flex flex-col gap-y-10">
        <div className="flex flex-col gap-y-1">
          {/*<div className="H4">{positionName(positions, shift?.positionId)}</div>*/}
          <div className="flex gap-x-2">
            <div>
              {dayjs(schedule?.dateFrom).format(RU_DATE)} – {dayjs(schedule?.dateTo).format(RU_DATE)}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-x-5 gap-y-4">
          <SimpleSelect
            divClassName="col-span-2"
            label="График"
            onChange={onChangeSchedule}
            value={selectedType}
            placeholder="Выберите график"
            options={scheduleTypes}
          />
          <TimePickerInput
            label="Начало смены"
            className="col-span-2"
            popupClassName="fixed"
            value={selectedTime}
            onChange={onChangeTimeStart}
          />
          <TimePickerInput
            label="Конец смены"
            className="col-span-2"
            popupClassName="fixed"
            value={selectedEndTime}
            onChange={onChangeTimeEnd}
          />
          <DatePickerInput
            // check
            label="Дата начала"
            divClassName="col-span-2"
            popupClassName="fixed"
            value={dateStart}
            onChange={dateStart => {
              setDateStart(dateStart);
              if (dayjs(dateStart).valueOf() > dayjs(dateEnd).valueOf()) {
                setDateEnd(dateStart);
              }
            }}
            minDate={dayjs().subtract(1, 'day')}
            error={!dateStart ? 'Обязательное поле' : ''}
          />
          <DatePickerInput
            label="Дата конца"
            divClassName="col-span-2"
            popupClassName="fixed"
            value={dateEnd}
            onChange={dateEnd => setDateEnd(dateEnd)}
            minDate={dateStart || undefined}
            error={!dateEnd ? 'Обязательное поле' : ''}
          />
          <SimpleSelect
            divClassName="col-span-2"
            label="Обед"
            sort={false}
            onChange={value => {
              // if (facilityGroupName !== 'Ашан') {
              setLunchDuration(Number(value));
              // }
            }}
            value={lunchDuration}
            options={lunchDurationTypes}
            // options={facilityGroupName === 'Ашан' ? auchanLunchDurationTypes : lunchDurationTypes}
            // disabled={facilityGroupName === 'Ашан'}
          />

          <div className="col-span-full flex justify-end gap-x-5">
            <button onClick={exitHandler} type="button" className="btn-stroke_reject_small">
              <span>Отменить</span>
            </button>
            <button onClick={buttonClickHandler} type="button" className="btn-primary_small">
              <span>Изменить график</span>
            </button>
          </div>
        </div>
      </div>
    </MainSidebar>
  );
};

export default EditScheduleSidebar;
