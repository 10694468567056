import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import MainSidebar from 'ui/sidebar';
import dayjs from 'dayjs';
import { RU_DATE } from 'utils/helpers/constVariables';
import { useSearchParams } from 'react-router-dom';
import ShiftTitle from './components/shiftTitle';
import Hold from './components/Hold';
import Surcharge from './components/Surcharge';
import ShiftPlan from './components/shiftPlan';
import ShiftFact from './components/shiftFact';
import ShiftBonus from './components/shiftBonus';
import UpdateShiftPayment from './components/UpdateShiftPayment';
import { useFacility } from 'utils/api/useFacility';

const EditModerationShift = () => {
  const { facility } = useFacility();

  const { setShowEditModerationShiftSidebar, showEditModerationShiftSidebar, selectedDay, user } =
    useFacilityReportStore();

  const [params] = useSearchParams();
  const dateParam = params.get('date');

  if (!selectedDay || !dateParam) {
    return null;
  }

  const modifiedDate = dayjs(dateParam).date(selectedDay);

  const title = modifiedDate.format(RU_DATE);

  return (
    <MainSidebar
      title={title}
      show={showEditModerationShiftSidebar}
      setShow={setShowEditModerationShiftSidebar}
      className={'!w-[450px] !px-0'}
      titleClassName={'px-5'}
    >
      <div className="mb-5 flex flex-col gap-x-1 px-5">
        <div className="flex gap-x-1 H4">
          <span>{user?.user?.lastname}</span>
          <span>{user?.user?.firstname}</span>
        </div>

        <div className="flex gap-x-1 Subtitle2">
          <span>{facility?.facilityGroup?.name} ·</span>
          <span>{facility?.name}</span>
          <span className="truncate">· {user?.workPost?.position?.name}</span>
        </div>
      </div>

      <ShiftTitle />

      <div className="gap-y-3 bg-smena_white">
        <p className={'Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30'}>
          Информация о смене
        </p>

        <ShiftPlan />
        <ShiftFact />
      </div>

      <UpdateShiftPayment />

      <ShiftBonus />
      <Hold />
      <Surcharge />
    </MainSidebar>
  );
};

export default EditModerationShift;
