import { useMemo } from 'react';
import { useFacilityGroup } from 'utils/api/useFacilityGroup';

export const useFacilityGroupTitle = () => {
  const { facilityGroup } = useFacilityGroup();
  return useMemo(() => {
    if (facilityGroup?.name) {
      return `Группа ${facilityGroup.name}`;
    }
    return 'Создать группу';
  }, [facilityGroup?.name]);
};
