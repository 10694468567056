import { useEffect, useRef } from 'react';

interface Props {
  children: React.ReactNode;
  show: boolean;
  setShow: (value: boolean) => void;
  className?: string;
  style?: React.CSSProperties;
  triggerRef?: React.RefObject<HTMLElement>;
}

/**
 * Hook that alerts clicks outside the passed ref
 */
const useOutsideClick = (
  ref: React.RefObject<HTMLDivElement>,
  show: boolean,
  setShow: (value: boolean) => void,
  triggerRef?: React.RefObject<HTMLElement>,
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: { target: any; path?: any }) => {
      const clsx = event.target.className;
      const antComponent =
        clsx && typeof clsx.includes !== 'undefined' && (clsx.includes('ant') || clsx.includes('rc'));
      const isCurrentRef = ref.current && ref.current.contains(event.target);
      const isTriggerRef = triggerRef && triggerRef.current && triggerRef.current.contains(event.target);
      if (!isCurrentRef && !isTriggerRef && !antComponent && show) {
        setShow(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, triggerRef, setShow, show]);
};

/**
 * Component that alerts if you click outside it
 */
export default function OutsideClick({ children, show, setShow, className, style, triggerRef }: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick(wrapperRef, show, setShow, triggerRef);

  return (
    <div ref={wrapperRef} className={className} style={style}>
      {children}
    </div>
  );
}
