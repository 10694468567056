import { useCallback, useMemo } from 'react';
import { Role, ShiftStage } from 'generated/graphql';
import { useStore } from 'store';
import { useMeRole } from 'store/hooks/useMeRole';
import { UserShiftsFragment } from 'pages/user/api/fragments/generated/UserShifts.fragment';
import { useUserShifts } from '../../../pages/userTracking/lib/hooks/useUserShifts';
import useSupervisorShifts from '../../../pages/userTracking/lib/hooks/useSupervisorShifts';

const useRestShits = () => {
  const { userShifts } = useUserShifts();
  const supervisorShifts = useSupervisorShifts();
  const { hideCanceledShifts, facilityId, workpostId } = useStore();

  const isMeSupervisor = useMeRole([Role.Supervisor]);

  const filterCanceledShifts = useCallback(
    (shift?: UserShiftsFragment[]) =>
      shift?.filter(shift => ![ShiftStage.Refused, ShiftStage.Ineligible].includes(shift.stage)),
    [],
  );

  return useMemo(() => {
    const roleShifts = isMeSupervisor ? supervisorShifts : userShifts;
    const shifts = (hideCanceledShifts ? filterCanceledShifts(roleShifts) : roleShifts) || [];
    return shifts.filter(shift => {
      const isPositionFits = workpostId ? shift.positionId !== workpostId : false;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId !== facilityId : false;
      return isFacilityFits ? isFacilityFits && isPositionFits : isPositionFits;
    });
  }, [facilityId, filterCanceledShifts, hideCanceledShifts, isMeSupervisor, supervisorShifts, userShifts, workpostId]);
};
export default useRestShits;
