import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import { convertNumber } from 'utils/helpers/convertNumber';
import { getPushRepeatRu } from 'pages/pushes/utils/helpers/getPushRepeatRu';
import { NotifyLetterPaginatedFragment } from 'pages/pushes/api/fragments/generated/NotifyLetterPaginated.fragment';
import More from './more';
import { getPushStatus } from 'utils/helpers/get/getPushStatus';

export const usePushesTable = (data: NotifyLetterPaginatedFragment[]) => {
  const columns: ColumnDef<NotifyLetterPaginatedFragment>[] = useMemo(() => {
    return [
      {
        id: 'publication',
        header: 'Публикация',
        minSize: 400,
        cell: ({ row: { original } }) => (
          <Link className={'link'} to={`/pushes/${original.id}`}>
            {original.name}
          </Link>
        ),
      },
      {
        id: 'status',
        header: 'Статус',
        size: 200,
        cell: ({ row: { original } }) => {
          const { statusRu, className } = getPushStatus(original.status);
          return (
            <div className={clsx(className, 'rounded-full inline-flex px-2 py-[2px] Table-small')}>{statusRu}</div>
          );
        },
      },
      {
        id: 'receivers',
        header: 'Получатели',
        minSize: 200,
        cell: ({ row: { original } }) => (
          <div className={clsx('Body2')}>
            {original.publicCondition?.recipientsCount ? convertNumber(original.publicCondition.recipientsCount) : '-'}
          </div>
        ),
      },
      {
        id: 'publishFrom',
        header: 'Дата и время отправки',
        minSize: 250,
        cell: ({ row: { original } }) => (
          <>
            <div className={'flex gap-x-1 Body2'}>
              <span className={'text-smena_text-secondary'}>Начало:</span>
              <span className={'text-smena_text-primary'}>
                {original.publicCondition?.publishFrom
                  ? dayjs(original.publicCondition.publishFrom).format(`${RU_DATE} ${TIME_DATE}`)
                  : null}
              </span>
            </div>
            <div className={'flex gap-x-1 Body2'}>
              <span className={'text-smena_text-secondary'}>Конец:</span>
              <span className={'text-smena_text-primary'}>
                {original.publicCondition?.indefinitely
                  ? 'Бессрочно'
                  : original.publicCondition?.publishTo
                  ? dayjs(original.publicCondition.publishTo).format(`${RU_DATE} ${TIME_DATE}`)
                  : null}
              </span>
            </div>
          </>
        ),
      },
      {
        id: 'interval',
        header: 'Повтор',
        minSize: 150,
        cell: ({ row: { original } }) => (
          <div className={clsx('Body2')}>{getPushRepeatRu(original.publicCondition?.interval)}</div>
        ),
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 65,
        cell: ({ row: { original } }) => <More push={original} />,
      },
    ];
  }, []);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    meta: {
      // inviteFriendDate: true,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
  });
};
