import { ShiftTime } from 'generated/graphql';
import MoonIcon from 'assets/icons/MoonIcon';
import SunIcon from 'assets/icons/SunIcon';

export const getPeriodBlock = (period?: ShiftTime) => {
  if (period === ShiftTime.Night) {
    return (
      <>
        <MoonIcon />
        <span>Ночная смена</span>
      </>
    );
  }
  if (period === ShiftTime.Day) {
    return (
      <>
        <SunIcon />
        <span>Дневная смена</span>
      </>
    );
  }
  return null;
};
