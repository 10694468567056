import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { LeadStatus } from 'generated/graphql';
import { mapColumns } from 'utils/helpers/mapColumns';
import { twentyOneLeadColumns } from 'components/table/columns';
import { TwentyOneLeadsFragment } from '../api/fragments/generated/TwentyOneLeads.fragment';
import { userFullName } from 'utils/helpers/userFullName';
import { RU_DATE } from 'utils/helpers/constVariables';
import { leadStatus } from '../../leads/utils/hooks/leadStatus';
import CheckCircleFill12 from 'assets/icons/CheckCircleFill12';

interface Props {
  lead?: TwentyOneLeadsFragment;
}

const TwentyOneLead = ({ lead }: Props) => {
  const isStatusAccepted = useMemo(() => {
    return lead?.status === LeadStatus.Accepted;
  }, [lead?.status]);

  const isStockComplete = useMemo(() => {
    if (!lead?.inviteFriendProgress) return;
    return lead.inviteFriendProgress.currentShifts >= lead.inviteFriendProgress.targetShifts;
  }, [lead?.inviteFriendProgress]);

  return (
    <div
      className={'grid gap-x-[10px] px-4'}
      style={{
        gridTemplateColumns: mapColumns(twentyOneLeadColumns),
      }}
    >
      <div className={twentyOneLeadColumns[0].className}>
        <Link to={`/users/${lead?.user?.id}`} className={clsx('link')}>
          {userFullName(lead?.user)}
        </Link>
      </div>
      <div className={twentyOneLeadColumns[1].className}>
        <div className={clsx(isStockComplete ? 'text-smena_green-dark' : '', 'Table-small flex')}>
          <span className={clsx(isStatusAccepted ? 'text-smena_green-dark' : '')}>
            {/*{isStockComplete ? lead.inviteFriendProgress.targetShifts : lead?.inviteFriendProgress.currentShifts}*/}
            {lead?.inviteFriendProgress.currentShifts}
          </span>
          <span>/{lead?.inviteFriendProgress.targetShifts}</span>
          {isStockComplete && <CheckCircleFill12 className={'inline-flex self-center ml-1 text-smena_green-dark'} />}
        </div>
      </div>
      <div className={twentyOneLeadColumns[2].className}>{leadStatus(lead?.status)}</div>
      <div className={twentyOneLeadColumns[3].className}>
        {lead?.inviteFriendDate ? dayjs(lead.inviteFriendDate).format(RU_DATE) : null}
      </div>
    </div>
  );
};

export default TwentyOneLead;
