import { useState } from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import { errorHandler } from 'utils/helpers/errorHandler';
import { errorToast } from 'utils/helpers/notify';
import TimesheetLoader from 'pages/timesheets/ui/Loader';
import { ISO_DATE, RU_DATE } from 'utils/helpers/constVariables';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import { useCreateDailyReportMutation } from '../api/mutations/generated/CreateDailyRepor';
import { DayjsOrNull } from 'interfaces/CustomTypes';

interface Props {
  date: DayjsOrNull;
}
const DailyReport = () => {
  const [resLink, setResLink] = useState('');
  const [createDailyReport, { loading }] = useCreateDailyReportMutation();
  const initialValues: Props = {
    date: null,
  };
  return (
    <div className="xs:col-span-5 col-span-full xs:col-start-1 h-fit p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
      <h2 className="Subtitle1 text-smena_text-secondary">Ежедневный отчет об отметках</h2>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={values => {
          const errors: any = {};
          if (values.date === null) {
            errors.date = 'Выберите дату';
          }
          return errors;
        }}
        onSubmit={values => {
          if (!values.date) return;
          createDailyReport({
            variables: {
              input: {
                date: values.date.format(ISO_DATE),
              },
            },
          })
            .then(response => {
              if (response.data?.createDailyReport) {
                const filePath = response.data.createDailyReport.filePath;
                const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;
                setResLink(url);
              }
            })
            .catch(e => {
              errorToast(errorHandler(e));
            });
        }}
      >
        {({ handleSubmit, errors, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="flex xs:flex-row flex-col xs:items-end gap-x-5 gap-y-3 flex-wrap">
              <DatePickerInput
                divClassName="w-[190px]"
                label="Дата"
                value={values.date}
                placeholder={dayjs().format(RU_DATE)}
                onChange={selectedDate => {
                  setFieldValue('date', selectedDate);
                  setResLink('');
                }}
                maxDate={dayjs()}
                error={errors.date}
              />
              <div className="">
                <button type="submit" disabled={loading || Boolean(resLink)} className="btn-secondary">
                  Сформировать
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
      <TimesheetLoader loading={loading} resLink={resLink} />
    </div>
  );
};

export default DailyReport;
