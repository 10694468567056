import { useStore } from 'store';
import MainSidebar from 'ui/sidebar';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';
import ChevronRight from 'assets/icons/ChevronRight';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useCreateForm } from './hooks';
import SimpleInput from 'ui/input';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import SimpleSelect from 'ui/select';
import Hold from './components/Hold';
import Surcharge from './components/Surcharge';
import { timeDifference } from 'utils/helpers/timeDifference';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import ShiftApproveBlock from './components/shiftApproveBlock';
import { lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';

const NewModerationShift = () => {
  const [params] = useSearchParams();
  const dateParam = params.get('date');

  const [show, setShow] = useState(true);

  const { showSidebar, setShowSidebar } = useStore();
  const { selectedDay, user } = useFacilityReportStore();

  const { initialValues, onSubmit, validate } = useCreateForm();

  if (!selectedDay || !dateParam) {
    return null;
  }
  const modifiedDate = dayjs(dateParam).date(selectedDay);

  const title = modifiedDate.format(RU_DATE);

  return (
    <MainSidebar
      title={title}
      show={showSidebar}
      setShow={setShowSidebar}
      className={'!w-[450px] !px-0'}
      titleClassName={'px-5'}
    >
      <div className="mb-5 flex flex-col gap-x-1 px-5">
        <div className="flex gap-x-1 H4">
          <span>{user?.user?.lastname}</span>
          <span>{user?.user?.firstname}</span>
        </div>

        <div className="flex gap-x-1 Subtitle2">
          <span>·</span>
          <span className="truncate">{user?.workPost?.position?.name}</span>
          <span>·</span>
        </div>
      </div>
      <div
        className="flex justify-between cursor-pointer border-b border-smena_gray-30 py-5 px-5"
        onClick={() => {
          setShow(!show);
        }}
      >
        <p className="Button1">Добавить новую смену</p>
        <div>
          <span className={clsx('inline-flex transform transition-transform', show ? 'rotate-180' : '')}>
            <ChevronRight className="text-smena_text transform rotate-90" />
          </span>
        </div>
      </div>

      {show && (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
          {({ values, setFieldValue, touched, errors, handleChange }) => {
            return (
              <Form className="flex flex-col justify-center items-center gap-y-4 bg-smena_bb-background_base w-full p-4">
                <div className="rounded-lg bg-smena_white shadow-smena flex flex-col">
                  <div className={'grid grid-cols-3 gap-4 border-b border-b-smena_gray-30 p-4'}>
                    <TimePickerInput
                      label="Начало смены"
                      className="col-span-1"
                      popupClassName="fixed"
                      value={values.startTime}
                      onChange={startTime => {
                        setFieldValue('startTime', startTime);
                        setFieldValue('lunchDuration', lunchDurationHandle(timeDifference(startTime, values.endTime)));
                      }}
                      error={touched.startTime && errors.startTime ? errors.startTime : ''}
                    />
                    <TimePickerInput
                      label="Конец смены"
                      className="col-span-1"
                      popupClassName="fixed"
                      value={values.endTime}
                      onChange={endTime => {
                        setFieldValue('endTime', endTime);
                        setFieldValue('lunchDuration', lunchDurationHandle(timeDifference(values.startTime, endTime)));
                      }}
                      error={touched.endTime && errors.endTime ? errors.endTime : ''}
                    />
                    <SimpleSelect
                      divClassName="col-span-1"
                      label="Обед"
                      sort={false}
                      value={values.lunchDuration}
                      onChange={value => {
                        setFieldValue('lunchDuration', Number(value));
                      }}
                      options={lunchDurationTypes}
                    />
                  </div>
                  <div className={'grid grid-cols-2 gap-4 border-b border-b-smena_gray-30 p-4'}>
                    <TimePickerInput
                      label="Факт., ч."
                      className="col-span-1"
                      value={values.shiftDuration}
                      onChange={shiftDuration => {
                        setFieldValue('shiftDuration', shiftDuration);
                      }}
                      placeholder={values.startTime?.format(TIME_DATE)}
                      error={touched.shiftDuration && errors.shiftDuration ? errors.shiftDuration : ''}
                    />

                    <SimpleInput
                      divClassName="col-span-1"
                      value={values.hourRate}
                      label="Ставка, ₽/час"
                      onChange={handleChange}
                      float
                      type="text"
                      name="hourRate"
                      error={touched.hourRate && errors.hourRate ? errors.hourRate : ''}
                    />
                  </div>
                  <ShiftApproveBlock
                    setFieldValue={setFieldValue}
                    toPayment={values.toPayment}
                    comment={values.comment}
                    reason={values.reason}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                  <div className="gap-y-3 bg-smena_white">
                    <p className={'Caption text-smena_text-secondary px-4 py-2 border-b border-smena_gray-30'}>
                      Бонусы
                    </p>
                    <div className="grid grid-cols-2 gap-y-1 p-4 border-b border-smena_gray-30">
                      <label
                        className={clsx(
                          'rounded-l-lg radio-block',
                          values.toPaymentBonus ? 'bg-smena_green-extra_light border-smena_green-mark' : '',
                        )}
                      >
                        <input
                          type="radio"
                          className="radio radio_approve"
                          id="toPaymentBonus"
                          value=""
                          checked={values.toPaymentBonus}
                          onChange={() => {
                            setFieldValue('toPaymentBonus', true);
                          }}
                        />
                        К оплате
                      </label>
                      <label
                        className={clsx(
                          'radio-block rounded-r-lg',
                          !values.toPaymentBonus ? 'bg-smena_red-extra_light border-smena_red shift__radio-bad' : '',
                        )}
                      >
                        <input
                          type="radio"
                          id="nonpay"
                          className="radio radio_reject"
                          value=""
                          checked={!values.toPaymentBonus}
                          onChange={() => {
                            setFieldValue('toPaymentBonus', false);
                          }}
                        />
                        Без оплаты
                      </label>
                    </div>
                  </div>
                  <Hold
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                  <Surcharge
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />

                  <div className="rounded-lg bg-smena_white shadow-smena flex flex-col">
                    <button type="submit" className="btn-primary_small self-end m-4">
                      Добавить
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </MainSidebar>
  );
};

export default NewModerationShift;
