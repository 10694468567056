import { useMemo } from 'react';
import {
  clientDirectorMenuItemList,
  clientManagerMenuItemList,
  menuItemList,
  superMenuItemList
} from 'components/layout/menu/list';
import { useMeRole } from 'store/hooks/useMeRole';
import { Role } from 'generated/graphql';

const useMenuElement = () => {
  const isMeSupervisor = useMeRole([Role.Supervisor]);
  const isMeClientManager = useMeRole([Role.ClientManager]);
  const isMeClientDirector = useMeRole([Role.ClientDirector]);
  return useMemo(() => {
    if (isMeSupervisor) {
      return superMenuItemList;
    }
    if (isMeClientManager) {
      return clientManagerMenuItemList;
    }
    if (isMeClientDirector) {
      return clientDirectorMenuItemList;
    }
    return menuItemList;
  }, [isMeClientDirector, isMeClientManager, isMeSupervisor]);
};

export default useMenuElement;
