export const MedicalCase = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.3335 9.99992V11.3333C7.3335 11.5221 7.3975 11.6806 7.5255 11.8086C7.6535 11.9366 7.81172 12.0004 8.00016 11.9999C8.18861 11.9995 8.34705 11.9355 8.4755 11.8079C8.60394 11.6804 8.66772 11.5221 8.66683 11.3333V9.99992H10.0002C10.1891 9.99992 10.3475 9.93592 10.4755 9.80792C10.6035 9.67992 10.6673 9.5217 10.6668 9.33325C10.6664 9.14481 10.6024 8.98659 10.4748 8.85859C10.3473 8.73059 10.1891 8.66659 10.0002 8.66659H8.66683V7.33325C8.66683 7.14436 8.60283 6.98614 8.47483 6.85859C8.34683 6.73103 8.18861 6.66703 8.00016 6.66659C7.81172 6.66614 7.6535 6.73014 7.5255 6.85859C7.3975 6.98703 7.3335 7.14525 7.3335 7.33325V8.66659H6.00016C5.81127 8.66659 5.65305 8.73059 5.5255 8.85859C5.39794 8.98659 5.33394 9.14481 5.3335 9.33325C5.33305 9.5217 5.39705 9.68014 5.5255 9.80859C5.65394 9.93703 5.81216 10.0008 6.00016 9.99992H7.3335ZM2.66683 14.6666C2.30016 14.6666 1.98639 14.5361 1.7255 14.2753C1.46461 14.0144 1.33394 13.7004 1.3335 13.3333V5.33325C1.3335 4.96659 1.46416 4.65281 1.7255 4.39192C1.98683 4.13103 2.30061 4.00036 2.66683 3.99992H5.3335V2.66659C5.3335 2.29992 5.46416 1.98614 5.7255 1.72525C5.98683 1.46436 6.30061 1.3337 6.66683 1.33325H9.3335C9.70016 1.33325 10.0142 1.46392 10.2755 1.72525C10.5368 1.98659 10.6673 2.30036 10.6668 2.66659V3.99992H13.3335C13.7002 3.99992 14.0142 4.13059 14.2755 4.39192C14.5368 4.65325 14.6673 4.96703 14.6668 5.33325V13.3333C14.6668 13.6999 14.5364 14.0139 14.2755 14.2753C14.0146 14.5366 13.7006 14.667 13.3335 14.6666H2.66683ZM2.66683 13.3333H13.3335V5.33325H2.66683V13.3333ZM6.66683 3.99992H9.3335V2.66659H6.66683V3.99992Z"
        fill="#FF555F"
      />
    </svg>
  );
};
