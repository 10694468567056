import dayjs from 'dayjs';
import clsx from 'clsx';
import { TIME_DATE } from 'utils/helpers/constVariables';
import { getDistance } from 'utils/helpers/get/getDistance';
import { MarkType } from 'generated/graphql';
import { useMarks, useShiftConditions } from 'pages/moderationReport/lib/hooks';
import ShiftMarks from '../shiftMarks';
import { useStore } from 'store';

const ShiftFact = () => {
  const { shift } = useStore() as any;
  const { unitSymbol, factValue } = useShiftConditions(shift);
  const { moderation, marks = [] } = shift || {};
  const { marksType: checkins = [], marksColor: markColorCheckin } = useMarks({ marks, type: MarkType.Checkin });
  const { marksType: checkouts = [], marksColor: markColorCheckout } = useMarks({ marks, type: MarkType.Checkout });

  if (!shift) return null;

  return (
    <div className="grid grid-cols-2 gap-4 border-b border-b-smena_gray-5 p-4">
      {moderation ? (
        <div className="col-span-full flex gap-x-1 Subtitle2">
          <span className="text-smena_text">Факт:</span>
          <span className="text-smena_text-secondary">
            {factValue || 0}
            {unitSymbol}
          </span>
        </div>
      ) : null}

      <div className="col-span-1">
        <div className="flex gap-x-4">
          <div className="flex flex-col">
            <span className="Subtitle2 text-smena_text">Чекин</span>
            <span className={clsx('Table-small', markColorCheckin)}>
              {checkins?.length ? dayjs(checkins[0].time).format(TIME_DATE) : '—:—'}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="Subtitle2 text-smena_text">Расстояние</span>
            <span className={clsx('Table-small', markColorCheckin)}>
              {checkins?.length ? getDistance(Number(checkins[0].distance)) : '—'}
            </span>
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <div className="flex gap-x-4">
          <div className="flex flex-col">
            <span className="Subtitle2 text-smena_text">Чекаут</span>
            <span className={clsx('Table-small', markColorCheckout)}>
              {checkouts?.length ? dayjs(checkouts[checkouts.length - 1].time).format(TIME_DATE) : '—:—'}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="Subtitle2 text-smena_text">Расстояние</span>
            <span className={clsx('Table-small', markColorCheckout)}>
              {checkouts?.length ? getDistance(Number(checkouts[checkouts.length - 1].distance)) : '—'}
            </span>
          </div>
        </div>
      </div>

      <ShiftMarks />
    </div>
  );
};

export default ShiftFact;
