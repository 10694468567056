import Skeleton from 'antd/es/skeleton';
import React from 'react';

const MetricsLoader = () => {
  return (
    <div className={'grid gap-10 grid-cols-2'}>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );
};

export default MetricsLoader;
