import React, { useCallback } from 'react';
import Up from 'assets/icons/sort/up';
import Down from 'assets/icons/sort/down';
import { IColumns } from 'interfaces/IColumns';
import { SortingWay } from 'generated/graphql';
import clsx from 'clsx';

interface Props {
  column: IColumns;
  sortWay?: string;
  setSortWay: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const TwentyOneLeadColumn = ({ column, sortWay, setSortWay }: Props) => {
  const desc = sortWay === SortingWay.Desc;
  const asc = sortWay === SortingWay.Asc;

  const color = useCallback(
    (icon: string) => {
      if (desc) {
        if (icon === 'up') {
          return 'text-smena_text-disabled';
        }
        return 'text-smena_text-helper';
      }
      if (asc) {
        if (icon === 'up') {
          return 'text-smena_text-helper';
        }
        return 'text-smena_text-disabled';
      }
      return 'text-smena_text-disabled';
    },
    [asc, desc],
  );
  const onClickHandler = () => {
    if (column.sorted) {
      if (sortWay === undefined) {
        setSortWay(SortingWay.Desc);
      }
      if (desc) {
        setSortWay(SortingWay.Asc);
      }
      if (asc) {
        setSortWay(undefined);
      }
    }
  };
  return (
    <div
      className={clsx(column.header_className, column.sorted ? 'cursor-pointer' : '', 'flex gap-x-2')}
      id={column.name}
      onClick={onClickHandler}
    >
      {typeof column.Header === 'string' ? <span className="pointer-events-none">{column.Header}</span> : column.Header}
      {column.sorted && (
        <span className="pointer-events-none flex flex-col gap-y-1">
          <Up className={color('up')} />
          <Down className={color('down')} />
        </span>
      )}
    </div>
  );
};

export default TwentyOneLeadColumn;
