import React from 'react';
import { Form, Formik } from 'formik';

import MainLayout from 'components/layout/main';
import SimpleInput from 'ui/input';
import SimpleCheckbox from 'ui/checkbox';
import { erpReg } from 'utils/helpers/constVariables';
import { usePositionPage } from './hooks';

const UpdatePosition = () => {
  const { title, initialValues, validate, onSubmit, loading, breadcrumbs, id, error, loadingPosition } =
    usePositionPage();

  return (
    <MainLayout title={title} breadCrumbs={breadcrumbs} error={error} loading={loadingPosition}>
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, touched, errors, handleChange, handleBlur }) => {
          return (
            <Form className="flex flex-col max-w-[570px]">
              <div className="px-6 pb-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5">
                <span className="Subtitle1 text-smena_text-secondary pt-5">Основная информация</span>
                <div className="flex gap-5 flex-wrap">
                  <SimpleInput
                    divClassName="w-[250px]"
                    label="Название профессии"
                    placeholder="Курьер"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    type="text"
                    name="name"
                    error={touched.name && errors.name ? errors.name : ''}
                  />
                  <SimpleInput
                    divClassName="w-[250px]"
                    label="ERP ID"
                    placeholder="Введите ERP ID"
                    onChange={e => {
                      const value = e.target.value;
                      if (erpReg.test(value) || value === '') {
                        handleChange(e);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.externalId}
                    type="text"
                    name="externalId"
                  />
                </div>
              </div>
              <div className="px-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5 mt-[10px] pb-[23px]">
                <span className="Subtitle1 text-smena_text-secondary pt-5">Самозанятость</span>
                <div className="">
                  <SimpleCheckbox
                    divClassName="flex items-center"
                    label="Доступна самозанятость?"
                    onChange={handleChange}
                    checked={values.isSelfEmployed}
                    name="isSelfEmployed"
                  />
                </div>
              </div>
              <div className="flex mt-5 self-end">
                <button type="submit" disabled={loading} className="btn-primary_big">
                  <span>{loading ? 'Загрузка' : id ? 'Сохранить' : 'Создать'}</span>
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdatePosition;
