import Skeleton from 'antd/es/skeleton';
import { mapColumns } from '../../utils/helpers/mapColumns';
import { IColumns } from '../../interfaces/IColumns';
import clsx from 'clsx';

interface SkeletonLayoutInterface {
  columns: IColumns[];
  rows?: number;
  headerMenu?: boolean;
}

const SkeletonLayout = ({ columns, rows = 10, headerMenu }: SkeletonLayoutInterface) => {
  const rowsArray: number[] = [];
  for (let i = 0; i < rows; i++) {
    rowsArray.push(i);
  }

  return (
    <div className={clsx(headerMenu ? 'max-h-[495px]' : 'max-h-[550px]')}>
      {rowsArray.map(el => (
        <div
          key={el}
          className="table__row grid"
          style={{
            gridTemplateColumns: mapColumns(columns),
          }}
        >
          {columns?.map(column => (
            <div key={column.name} className={column.className} style={column.style}>
              <Skeleton.Button active size={'small'} block />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SkeletonLayout;
