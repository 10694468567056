import React, { useCallback } from 'react';
import { useStore } from 'store';
import SimpleSelect from 'ui/select';
import SimpleCheckbox from 'ui/checkbox';

export const FilterSystem = () => {
  const { system, setSystem, cluster, setCluster, profession, setProfession } = useStore();
  const changeSystem = useCallback((value: string) => setSystem(value), [setSystem]);
  const changeProfession = useCallback((value: string) => setProfession(value), [setProfession]);
  const changeCluster = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setCluster(e.target.checked),
    [setCluster]
  );

  const positions = [
    'Грузчик',
    'Кассир',
    'Пекарь',
    'Продавец-Кассир',
    'Сборщик заказов',
    'Уборщик',
    'Работник торгового зала',
    'Работник склада',
    'Комплектовщик',
    'Работник кассы самообслуживания',
    'Промоутер',
  ];

  return (
    <div className="absolute z-50 top-0 left-0 m-5 p-3 flex gap-x-5 bg-smena_white rounded-lg">
      <SimpleSelect
        divClassName=""
        label="Группа"
        onChange={changeSystem}
        allOption={'Все системы'}
        value={system}
        options={[
          { id: 'smena', name: 'Smena' },
          { id: 'erp', name: 'ERP' },
        ]}
      />
      <SimpleCheckbox
        divClassName="flex items-end justify-center mb-2"
        label="Кластер"
        onChange={changeCluster}
        checked={cluster}
        name={'hiddenCanceledShifts'}
      />
      <SimpleSelect
        divClassName=""
        label="Группа"
        onChange={changeProfession}
        allOption={'Все профессии'}
        value={profession}
        options={positions.map(el => ({ id: el, value: el }))}
      />
    </div>
  );
};
