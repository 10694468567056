import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeShiftStageMutationVariables = Types.Exact<{
  input: Types.ChangeShiftStageInput;
}>;


export type ChangeShiftStageMutation = { __typename?: 'Mutation', changeShiftStage: { __typename?: 'Shift', id: string } };


export const ChangeShiftStageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeShiftStage"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangeShiftStageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeShiftStage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type ChangeShiftStageMutationFn = Apollo.MutationFunction<ChangeShiftStageMutation, ChangeShiftStageMutationVariables>;

/**
 * __useChangeShiftStageMutation__
 *
 * To run a mutation, you first call `useChangeShiftStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeShiftStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeShiftStageMutation, { data, loading, error }] = useChangeShiftStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeShiftStageMutation(baseOptions?: Apollo.MutationHookOptions<ChangeShiftStageMutation, ChangeShiftStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeShiftStageMutation, ChangeShiftStageMutationVariables>(ChangeShiftStageDocument, options);
      }
export type ChangeShiftStageMutationHookResult = ReturnType<typeof useChangeShiftStageMutation>;
export type ChangeShiftStageMutationResult = Apollo.MutationResult<ChangeShiftStageMutation>;
export type ChangeShiftStageMutationOptions = Apollo.BaseMutationOptions<ChangeShiftStageMutation, ChangeShiftStageMutationVariables>;