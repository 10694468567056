import { useMemo } from 'react';

import { useStore } from 'store';
import PaymentCheckbox from './checkbox';
import PaymentsHeader from './header';
import Payment from './payment';
import uniqueArray from 'utils/helpers/uniqueArray';

const PaymentsTable = () => {
  const manualAccrual = useStore(state => state.manualAccrual);
  // const {  setPaymentCheckboxes, setManualAccrualUserIds, setDisabledUsersIds } = useStore();

  const workpostUsers = useMemo(
    () =>
      uniqueArray({ array: manualAccrual?.facility?.workPosts, field: 'userId' }).sort((a, b) => {
        if (a.user?.firstname < b.user?.firstname) return -1;
        if (a.user?.firstname > b.user?.firstname) return 1;
        return 0;
      }),
    [manualAccrual?.facility?.workPosts],
  );

  const disabledUsersArray = useMemo(
    () => uniqueArray({ array: manualAccrual?.manualUserAccruals, field: 'userId' }),
    [manualAccrual?.manualUserAccruals],
  );

  const disabledUsersIds = useMemo(() => disabledUsersArray.map(ids => ids.userId), [disabledUsersArray]);

  // useEffect(() => {
  //   setDisabledUsersIds(disabledUsersIds);
  //   setPaymentCheckboxes(workpostUsers.length - disabledUsersArray.length);
  //   const userIds = workpostUsers.map(x => x.userId);
  //   setManualAccrualUserIds(userIds);
  //   if (disabledUsersArray.length) {
  //     const remove = disabledUsersIds.map(disabledId => userIds.filter(userId => userId === disabledId)).flat();
  //     setManualAccrualUserIds(userIds.filter(id => !remove.includes(id)));
  //   }
  // }, [
  //   disabledUsersArray.length,
  //   disabledUsersIds,
  //   setDisabledUsersIds,
  //   setManualAccrualUserIds,
  //   setPaymentCheckboxes,
  //   workpostUsers,
  // ]);

  return (
    <div className="bg-smena_white border-b border-smena_gray-30 rounded-t-lg">
      <PaymentsHeader pays={workpostUsers.length - disabledUsersArray.length} />
      {workpostUsers.map(workpost => (
        <div
          key={workpost.userId}
          className={`grid payments-row ${disabledUsersIds.find(id => workpost.userId === id) ? 'opacity-50' : ''}`}
          style={{ gridTemplateColumns: '50px 1fr' }}
        >
          <PaymentCheckbox userId={workpost.userId} />
          <Payment user={workpost.user} />
        </div>
      ))}
    </div>
  );
};

export default PaymentsTable;
