import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { isTest, RU_DATE } from 'utils/helpers/constVariables';
import { useCallback, useState } from 'react';
import { Role, ZayavkaStage } from 'generated/graphql';
import { percentColor, progressPercentObj } from 'utils/helpers/progressPercentHelper';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import GarbageArrowLess from 'assets/icons/GarbageArrowLess';
import Edit from 'assets/icons/edit';
import Check from 'assets/icons/check';
import { useMeRole } from 'store/hooks/useMeRole';
import { useRequestStatus } from '../buttons/hooks';
import RequestStatus from '../status';
import DeclineButton from '../buttons/decline';
import SendButton from '../buttons/send';
import ProgressComponent from 'ui/progress';
import { useCancelRequestMutation } from '../../../facilityRequests/api/mutations/generated/CancelRequest';
import { useCancelRequestByManagerMutation } from 'pages/facilityRequests/api/mutations/generated/CancelRequestByManager';
import { useCurrentRequest } from 'utils/api/useCurrentRequest';
import { useShowHelperButtons } from './hooks';
import { cancelRequestByManagerCache, cancelRequestCache } from '../../../requests/api/updateCache';
import Tooltip from 'antd/es/tooltip';
import Modal from 'ui/modal';

const RequestInformation = () => {
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const { request, id } = useCurrentRequest();

  const { shiftsProgress, hoursProgress } = progressPercentObj(request);
  const shiftsStroke = percentColor(shiftsProgress);
  const hoursStroke = percentColor(hoursProgress);

  const isMeManager = useMeRole([Role.ClientManager]);

  const [cancelRequest, { loading: loadingCancelRequest }] = useCancelRequestMutation({
    update: cache => {
      cancelRequestCache(cache, request);
    },
  });

  const [cancelRequestByManager, { loading: loadingCancelRequestByManager }] = useCancelRequestByManagerMutation({
    update: cache => {
      cancelRequestByManagerCache(cache, request);
    },
  });
  const canceledStatus = useRequestStatus({ status: [ZayavkaStage.Canceled] });
  const showHelperButtons = useShowHelperButtons();
  const cancelRequestByManagerHandler = useCallback(() => {
    if (!id) return;
    cancelRequestByManager({
      variables: {
        id,
      },
    })
      .then(() => {
        successToast('Заявка заявка предложена к отклонению');
        setShowCancelPopup(false);
      })
      .catch(error => errorToast(errorHandler(error)));
  }, [cancelRequestByManager, id]);

  const cancelRequestHandler = useCallback(() => {
    if (request?.id) {
      cancelRequest({
        variables: {
          id: request.id,
        },
      })
        .then(() => {
          successToast('Заявка отклонена');
          setShowCancelPopup(false);
        })
        .catch(error => errorToast(errorHandler(error)));
    }
  }, [cancelRequest, request?.id]);

  const isMeSupervisor = useMeRole([Role.Supervisor]);

  return (
    <div className="grid grid-cols-[1fr,_minmax(auto,_auto)] gap-y-4">
      <div className="flex flex-wrap gap-x-6 gap-y-2">
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Статус</span>
          <RequestStatus request={request} />
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Номер заявки</span>
          <span className="Body2">{request?.number}</span>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Объект</span>
          <Link className="text-primary Body2 hover:underline" to={`/facilities/${request?.facilityId}`}>
            {request?.facility?.name}
          </Link>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Период</span>
          <span className="Body2">
            {dayjs(request?.dateFrom).format(RU_DATE)}
            {' - '}
            {dayjs(request?.dateTo).format(RU_DATE)}
          </span>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Сумма смен</span>
          <div className="Body2 flex items-center gap-x-1">
            <ProgressComponent type="circle" percent={shiftsProgress} width={16} strokeColor={shiftsStroke} />
            <div className="flex">
              <span>{request?.shiftsScheduleCount}</span>
              <span>/</span>
              <span>{request?.shiftsPlanCount}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <span className="Subtitle2">Сумма часов</span>
          <div className="Body2 flex items-center gap-x-1">
            <ProgressComponent type="circle" percent={hoursProgress} width={16} strokeColor={hoursStroke} />
            <div className="flex">
              <span>{request?.hoursScheduleCount}</span>
              <span>/</span>
              <span>{request?.hoursPlanCount}</span>
            </div>
          </div>
        </div>
        {isTest && (
          <>
            <div className="flex flex-col gap-y-2">
              <span className="Subtitle2">Автор заявки</span>
              <div className="Body2 flex items-center gap-x-1">{request?.authorId}</div>
            </div>
            <div className="flex flex-col gap-y-2">
              <span className="Subtitle2">Статус заявки</span>
              <div className="Body2 flex items-center gap-x-1">{request?.status}</div>
            </div>
            <div className="flex flex-col gap-y-2">
              <span className="Subtitle2">Изменения</span>
              <div className="Body2 flex items-center gap-1 ">
                {request?.zayavkaChanges?.map(el => (
                  <div className="flex gap-x-2" key={el.id}>
                    <div className="flex flex-col">
                      <span>Статус (stage)</span>
                      <span>{el.stage}</span>
                    </div>
                    <div className="flex flex-col">
                      <span>Автор</span>
                      <span>{el.authorId}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex gap-2">
        {showHelperButtons && request?.status !== ZayavkaStage.Canceled && (
          <div className={'flex items-start justify-end gap-x-2'}>
            <Tooltip title={'Отменить заявку'}>
              <button
                className={'rounded-lg p-1 bg-smena_bb-background'}
                disabled={loadingCancelRequest || loadingCancelRequestByManager}
                onClick={() => {
                  setShowCancelPopup(true);
                }}
              >
                <GarbageArrowLess className={'text-primary'} />
              </button>
            </Tooltip>

            {!canceledStatus && (
              <Tooltip title={'Редактировать заявку'}>
                <div className={'flex items-center'}>
                  <Link
                    to={`/requests/edit/${request?.id}`}
                    className={'rounded-lg p-1 bg-smena_bb-background inline-flex'}
                  >
                    <Edit className={'text-primary'} />
                  </Link>
                </div>
              </Tooltip>
            )}
          </div>
        )}

        <div className={'flex flex-wrap gap-2'}>
          <DeclineButton />
          <SendButton />
        </div>

        {request?.status === ZayavkaStage.New && !isMeSupervisor ? (
          <div className="flex gap-x-1 h-full items-center">
            <span className="text-smena_green-dark">
              <Check />
            </span>
            <span className="Body1">Заявка отправлена</span>
          </div>
        ) : null}
      </div>

      <Modal
        type="delete"
        title="Отменить заявку?"
        open={showCancelPopup}
        onCancel={() => setShowCancelPopup(false)}
        okText="Отменить"
        onOk={() => {
          if (isMeManager) {
            cancelRequestByManagerHandler();
          } else {
            cancelRequestHandler();
          }
        }}
        okButtonProps={{ disabled: loadingCancelRequest || loadingCancelRequestByManager }}
      >
        Вы уверены, что хотите отменить заявку?
      </Modal>
    </div>
  );
};
export default RequestInformation;
