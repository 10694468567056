import Search from 'ui/search';
import { useCustomSearchParams } from '../../users/lib/useParams';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DayOrNullArray } from 'interfaces/CustomTypes';
import { useSelectFacilities } from 'utils/api/useSelectFacilities';
import { useSelectFacilityGroups } from 'utils/api/useSelectFacilityGroups';
import { filterFacilities } from 'utils/helpers/filters/filterFacilities';
import dayjs from 'dayjs';
import TableFilter from 'components/table/filters';
import RangePickerInput from 'ui/pickers/RangePickerInput';
import SimpleSelect from 'ui/select';
import { ISO_DATE, RU_DATE } from 'utils/helpers/constVariables';
import Filter from 'components/table/filters/filter';
import { rolesForStoryAndPush } from 'utils/helpers/lists/roleArray';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import { useFacilityById } from 'utils/api/useFacility';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';

const TwentyOneFilter = () => {
  const { params, setParams, handleParams, resetFilter } = useCustomSearchParams();
  const facilityGroupIdParam = params.get('facilityGroupId');
  const facilityIdParam = params.get('facilityId');
  const roleParams = params.get('role');
  const dateFrom = params.get('dateFrom');
  const dateTo = params.get('dateTo');
  const [facilityGroupId, setFacilityGroupId] = useState(facilityGroupIdParam);
  const [facilityId, setFacilityId] = useState(facilityIdParam);
  const [role, setRole] = useState(roleParams);
  const [value, setValue] = useState<DayOrNullArray>(null);
  const [showFilter, setShowFilter] = useState(false);
  // const [resLink, setResLink] = useState('');

  const btnRef = useRef<HTMLButtonElement>(null);

  let paramsCount = 0;

  const paramCounter = (name: string) => {
    if (params.has(name)) {
      return paramsCount++;
    }
  };
  paramCounter('facilityGroupId');
  paramCounter('facilityId');
  paramCounter('role');
  if (params.has('dateFrom') || params.has('dateTo')) {
    paramsCount++;
  }

  const { facility } = useFacilityById(facilityIdParam);
  const { facilities } = useSelectFacilities();
  const { facilityGroupName } = useFacilityGroupById(facilityGroupIdParam);
  const { facilitiesGroups } = useSelectFacilityGroups();
  const filteredFacilities = useMemo(
    () => filterFacilities(facilityGroupId, facilities),
    [facilityGroupId, facilities],
  );

  useEffect(() => {
    if (params.has('dateFrom') && params.has('dateTo')) {
      setValue([dayjs(dateFrom), dayjs(dateTo)]);
      setParams(params);
    } else {
      setValue(null);
    }
  }, [dateFrom, dateTo, params, setParams, setValue]);

  return (
    <div className="filter-bar justify-between flex xs:flex-row flex-col gap-y-2 shadow-smena">
      <div className="flex flex-col gap-y-2 w-full">
        <div className={`flex justify-between`}>
          <div className={'flex relative gap-x-2'}>
            <button
              type="button"
              ref={btnRef}
              className="btn-stroke flex gap-x-1 items-center"
              onClick={() => {
                setShowFilter(value => !value);
              }}
            >
              Фильтр
              {Boolean(paramsCount) && (
                <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px]">
                  {paramsCount}
                </span>
              )}
            </button>
            <Search />
            <TableFilter show={showFilter} setShow={setShowFilter} triggerRef={btnRef}>
              <SimpleSelect
                label="Роль реферера"
                onChange={value => {
                  setRole(value);
                }}
                value={role}
                placeholder={'Не выбрано'}
                allOption={'Все'}
                options={rolesForStoryAndPush.map(el => ({ id: el.value, name: el.name }))}
              />
              <SimpleSelect
                divClassName=""
                popupClassName={'max-w-[300px]'}
                label="Группа"
                onChange={value => {
                  setFacilityGroupId(value);
                  setFacilityId(null);
                }}
                value={facilityGroupId}
                placeholder={'Не выбрано'}
                allOption={'Все'}
                options={facilitiesGroups}
              />
              <SimpleSelect
                divClassName=""
                label="Объект"
                onChange={value => {
                  setFacilityId(value);
                }}
                value={facilityId}
                placeholder="Не выбрано"
                allOption={'Все'}
                options={filteredFacilities}
              />
              <RangePickerInput
                label="Даты достижения цели по друзьям"
                value={value && [value[0], value[1]]}
                onChange={newValue => {
                  setValue(newValue);
                }}
              />
              <div className="flex gap-x-5">
                <button
                  className="btn-primary"
                  onClick={() => {
                    handleParams('facilityGroupId', facilityGroupId);
                    handleParams('facilityId', facilityId);
                    handleParams('role', role);
                    if (value) {
                      params.set('dateFrom', String(dayjs(value[0]).format(ISO_DATE)));
                      params.set('dateTo', String(dayjs(value[1]).format(ISO_DATE)));
                    } else {
                      if (params.has('dateFrom') && params.has('dateTo')) {
                        params.delete('dateFrom');
                        params.delete('dateTo');
                      }
                    }
                    setParams(params);
                    setShowFilter(false);
                  }}
                >
                  Применить
                </button>
                <button
                  className="btn-stroke xs:w-auto w-full"
                  onClick={() => {
                    setValue(null);
                    setFacilityGroupId(null);
                    setFacilityId(null);
                    setRole(null);
                    setParams();
                    setShowFilter(false);
                  }}
                >
                  Сбросить
                </button>
              </div>
            </TableFilter>
          </div>
          {/*<div className={'flex flex-col gap-2'}>*/}
          {/*  <button type="button" className={'btn-primary'}>*/}
          {/*    Сформировать отчет*/}
          {/*  </button>*/}
          {/*  <TimesheetLoader load={false} resLink={'resLink'} />*/}
          {/*</div>*/}
        </div>
        {Boolean(paramsCount) && (
          <div className="flex">
            <Filter
              show={!!(dateFrom || dateTo)}
              content={dayjs(dateFrom).format(RU_DATE) + ' - ' + dayjs(dateTo).format(RU_DATE)}
              clickHandler={() => {
                setValue(null);
                params.delete('dateFrom');
                params.delete('dateTo');
                params.delete('page');
                setParams(params);
              }}
            />
            <Filter
              show={!!facilityGroupIdParam}
              content={`Группа ${facilityGroupName}`}
              clickHandler={() => {
                setFacilityGroupId(null);
                resetFilter('facilityGroupId');
              }}
            />
            <Filter
              show={!!facilityIdParam}
              content={facility?.name}
              clickHandler={() => {
                setFacilityId(null);
                resetFilter('facilityId');
              }}
            />
            <Filter
              show={!!roleParams}
              content={getRoleRu(roleParams)}
              clickHandler={() => {
                setRole(null);
                resetFilter('role');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TwentyOneFilter;
