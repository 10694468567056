import { PayoutType } from 'generated/graphql';

export const payoutTypes = [
  {
    id: PayoutType.Hourly,
    name: 'Почасовая',
  },
  {
    id: PayoutType.Piecework,
    name: 'Сдельная ',
  },
];
