import React from 'react';
import SimpleInput from 'ui/input';
import SimpleSelect from 'ui/select';
import { VacancyGenders } from 'utils/helpers/lists/gendersList';
import TextArea from 'ui/textArea';
import { FormikHandlers, FormikState } from 'formik';
import { useCountries } from '../../../utils/api/useCountries';
import MultiSelect from '../../../ui/multiSelect';

interface Props {
  values: any;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (name: string, value: any) => void;
  errors: FormikState<any>['errors'];
  touched: FormikState<any>['touched'];
}

const DescriptionForRecruiter = ({ values, handleChange, setFieldValue, errors, touched }: Props) => {
  const { countries } = useCountries();
  return (
    <>
      <span className="Subtitle1 text-smena_text-secondary">Доп описание и требования вакансии (для рекрутеров)</span>
      <div className={'flex gap-4'}>
        <div className={'flex-[1_0] flex gap-x-4'}>
          <SimpleInput
            divClassName="flex-[1_0]"
            label="Возраст"
            placeholder={'От (если надо)'}
            onChange={handleChange}
            value={values.minAge}
            maxLength={2}
            regText
            type="text"
            name="minAge"
            error={touched.minAge && errors.minAge ? errors.minAge : ''}
          />
          <SimpleInput
            divClassName="flex-[1_0] pt-6"
            label=""
            placeholder={'До (если надо)'}
            onChange={handleChange}
            value={values.maxAge}
            maxLength={2}
            regText
            type="text"
            name="maxAge"
            error={touched.maxAge && errors.maxAge ? errors.maxAge : ''}
          />
        </div>
        <SimpleSelect
          divClassName="flex-[1_0]"
          label="Пол"
          onChange={value => {
            setFieldValue('sex', value);
          }}
          placeholder={'Выберите пол'}
          value={values.sex}
          options={VacancyGenders}
        />
        <MultiSelect
          divClassName="flex-[1_0]"
          value={values.citizenshipsIds}
          selectClassName={'w-full'}
          label={'Гражданство'}
          name={'citizenshipsIds'}
          placeholder={'Не выбрано'}
          options={countries.map(el => ({ label: el.name, value: el.id }))}
          onChange={value => {
            setFieldValue('citizenshipsIds', value);
          }}
        />
      </div>
      <TextArea
        style={{
          height: '118px',
        }}
        label={'Транспорт'}
        name={'transport'}
        maxLength={1000}
        onChange={handleChange}
        value={values.transport}
        helperText={'Не более 1000 символов'}
        placeholder={'Введите nранспорт'}
      />
      <TextArea
        style={{
          height: '118px',
        }}
        label={'Примечание к документам'}
        name={'documentsNotes'}
        maxLength={1000}
        onChange={handleChange}
        value={values.documentsNotes}
        helperText={'Не более 1000 символов'}
        placeholder={'Введите примечание к документам'}
      />
      <TextArea
        style={{
          height: '118px',
        }}
        label={'Примечание'}
        name={'notes'}
        onChange={handleChange}
        value={values.notes}
        maxLength={1000}
        helperText={'Не более 1000 символов'}
        placeholder={'Введите примечание'}
      />
    </>
  );
};

export default DescriptionForRecruiter;
