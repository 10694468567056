import React, { useCallback, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';

import { ISO_DATE } from 'utils/helpers/constVariables';
import Notification from './Notification';
import OutsideClick from 'components/outsideComponent';
import Across from 'assets/icons/Across';
import Plug from './Plug';
import { useReadAllNotificationMutation } from 'graphql/mutations/generated/ReadAllNotification';
import { useGetNotificationsLazyQuery } from 'graphql/queries/generated/GetNotifications';
import { selectNewNotifications, selectOldNotifications, useNotificationsStore } from '../store/notificationsStore';
import LoadingSpin from '../../loading/LoadingSpin';

interface INotificationSidebar {
  showNotificationSidebar: boolean;
  setShowNotificationSidebar: (showNotificationSidebar: boolean) => void;
}

const NotificationSidebar: React.FC<INotificationSidebar> = React.memo(
  ({ showNotificationSidebar, setShowNotificationSidebar }) => {
    const [loadNotificationByAddingPage, { loading: loadingNotifications }] = useGetNotificationsLazyQuery();
    const [notificationSidebar, setNotificationsScroll] = useState(0);
    const { setNotifications, isRead, newCount, readCount, setAllReadStatus, setIsRead } = useNotificationsStore();

    const [readAllNotification, { loading }] = useReadAllNotificationMutation();

    const notifications = useNotificationsStore(isRead ? selectNewNotifications : selectOldNotifications);

    const observer = useRef<any>();
    const lastBookElement = useCallback(
      (node: any) => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(
          entries => {
            const offset = notifications.length;
            if (entries[0].isIntersecting) {
              loadNotificationByAddingPage({
                variables: {
                  input: {
                    isRead,
                    offset,
                    size: 10,
                  },
                },
              }).then(res => {
                const data = res.data;
                if (data) {
                  setNotifications(data.getNotifications.notifications);
                }
              });
            }
          },
          {
            threshold: 0.9,
          },
        );
        if (node) observer.current.observe(node);
      },
      [notifications.length, isRead, loadNotificationByAddingPage, setNotifications],
    );

    const readAllNotificationsHandler = useCallback(() => {
      readAllNotification().then(res => {
        if (res.data?.readAllNotification) {
          setAllReadStatus();
        }
      });
    }, [readAllNotification, setAllReadStatus]);

    const onNewButtonClick = useCallback(() => {
      setIsRead(false);
    }, [setIsRead]);

    const onReadButtonClick = useCallback(() => {
      setIsRead(true);
    }, [setIsRead]);

    const groupByNotifications = useMemo(
      () => groupBy(notifications, el => dayjs(el.createdAt).format(ISO_DATE)),
      [notifications],
    );
    const headerRef = useRef<HTMLDivElement>(null);
    const sidebar = headerRef.current?.parentElement;
    const sidebarHeight = sidebar?.offsetHeight ? Number(sidebar?.offsetHeight) : 0;
    const headerHeight = headerRef.current?.offsetHeight ? Number(headerRef.current?.offsetHeight) : 0;
    const contentHeight = sidebarHeight - headerHeight - 20;

    return (
      <OutsideClick
        show={showNotificationSidebar}
        setShow={setShowNotificationSidebar}
        className={clsx(
          'fixed top-0 flex flex-col gap-y-6 h-full transition-all duration-500 ease-in-out pt-6 px-5 z-40 bg-smena_white w-[430px] shadow-smena',
          showNotificationSidebar ? 'right-0' : '-right-full invisible',
        )}
      >
        <div
          className={clsx(
            'bg-smena_white flex flex-col gap-y-6 ',
            notificationSidebar ? 'border-b border-smena_gray-30' : '',
          )}
          ref={headerRef}
        >
          <div>
            <span
              className="w-auto h-auto cursor-pointer flex justify-end relative right-0"
              onClick={() => {
                setShowNotificationSidebar(false);
              }}
            >
              <Across className="text-smena_text" />
            </span>
            <h3>Уведомления</h3>
          </div>
          <div className={'flex w-full'}>
            <button
              className={clsx(
                'Button1 text-primary rounded-l-md border transition-all flex flex-1 justify-center items-center gap-x-1 py-1',
                !isRead ? 'bg-smena_bb-background border-primary' : 'border-smena_bb-border',
              )}
              type={'button'}
              onClick={onNewButtonClick}
            >
              <span>Новые</span>
              <span
                className={clsx(
                  newCount ? 'bg-smena_red text-smena_white' : 'bg-primary bg-opacity-20 text-primary',
                  'Button3 rounded-full  px-[7.5px]',
                )}
              >
                {newCount}
              </span>
            </button>
            <button
              className={clsx(
                'Button1 text-primary rounded-r-md border transition-all flex flex-1 justify-center items-center gap-x-1 py-1',
                isRead ? 'bg-smena_bb-background border-primary' : 'border-smena_bb-border',
              )}
              type={'button'}
              onClick={onReadButtonClick}
            >
              <span>Прочитанные</span>
              <span className="Button3 text-primary rounded-full bg-primary bg-opacity-20 px-[7.5px] text-center">
                {readCount}
              </span>
            </button>
          </div>
        </div>
        <div
          className={'overflow-x-auto flex flex-col gap-y-6'}
          style={{ height: contentHeight }}
          onScroll={e => {
            const target = e.target as HTMLDivElement;
            setNotificationsScroll(target.scrollTop);
          }}
        >
          {!isRead && Boolean(notifications?.length) && (
            <div className="flex justify-end">
              <button className="Button3 text-primary" onClick={readAllNotificationsHandler} disabled={loading}>
                Прочитать все
              </button>
            </div>
          )}
          {notifications?.length ? (
            notifications.map((notification, index) => {
              if (notifications.length === index + 1) {
                return (
                  <React.Fragment key={notification.id}>
                    <Notification
                      notificationRef={lastBookElement}
                      notification={notification}
                      groupNotifications={groupByNotifications[dayjs(notification.createdAt).format(ISO_DATE)]}
                    />
                    {loadingNotifications && (
                      <div className={'w-full h-5 flex'}>
                        <LoadingSpin textColor={'text-primary'} />
                      </div>
                    )}
                  </React.Fragment>
                );
              }
              return (
                <Notification
                  key={notification.id}
                  notification={notification}
                  groupNotifications={groupByNotifications[dayjs(notification.createdAt).format(ISO_DATE)]}
                />
              );
            })
          ) : (
            <Plug />
          )}
        </div>
      </OutsideClick>
    );
  },
);

export default NotificationSidebar;
