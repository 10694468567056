export const moderationShiftReasons = [
  {
    id: 'Техническая проблема приложения',
    name: 'Техническая проблема приложения',
  },
  {
    id: 'Техническая проблема пользователя',
    name: 'Техническая проблема пользователя',
  },
  {
    id: 'Проблема доступа в интернет',
    name: 'Проблема доступа в интернет',
  },
  {
    id: 'Ошибка исполнителя',
    name: 'Ошибка исполнителя',
  },
  {
    id: 'Ошибка супервайзера/бригадира',
    name: 'Ошибка супервайзера/бригадира',
  },
  {
    id: 'Не прошёл пробный период',
    name: 'Не прошёл пробный период',
  },
];

export const moderationPenaltyShiftReasons = [
  {
    id: 'Ошибка исполнителя',
    name: 'Ошибка исполнителя',
  },
  {
    id: 'Ошибка назначения смены',
    name: 'Ошибка назначения смены',
  },
  {
    id: 'Уважительная причина отмены',
    name: 'Уважительная причина отмены',
  },
  {
    id: 'Техническая проблема пользователя',
    name: 'Техническая проблема пользователя',
  },
  {
    id: 'Проблема доступа в интернет',
    name: 'Проблема доступа в интернет',
  },
  {
    id: 'Техническая проблема приложения',
    name: 'Техническая проблема приложения',
  },
];

export const tabelHoldReasons = [
  {
    id: 'Удержание от заказчика',
    name: 'Удержание от заказчика',
  },
  {
    id: 'Невыход',
    name: 'Невыход',
  },
  {
    id: 'Опоздание',
    name: 'Опоздание',
  },
  {
    id: 'Удержание за спецодежду',
    name: 'Удержание за спецодежду',
  },
  {
    id: 'Порча имущества (ТСД и тд)',
    name: 'Порча имущества (ТСД и тд)',
  },
];

export const tabelSurchargeReasons = [
  {
    id: 'Надбавка за смену',
    name: 'Надбавка за смену',
  },
  {
    id: 'Надбавка за выходные/праздничные дни',
    name: 'Надбавка за выходные/праздничные дни',
  },
  {
    id: 'Компенсация ЛМК',
    name: 'Компенсация ЛМК',
  },
  {
    id: 'Компенсация еды',
    name: 'Компенсация еды',
  },
  {
    id: 'Компенсация проезда',
    name: 'Компенсация проезда',
  },
];
