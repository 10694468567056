const ProfessionIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.485 0C13.0084 0 13.4668 0.271117 13.7202 0.677029C15.7233 1.18461 17.2467 2.89867 17.5508 5.00179C18.4686 5.10477 19.182 5.88342 19.182 6.8287C19.182 7.77414 18.4684 8.55289 17.5504 8.65567C17.1858 11.1729 15.0744 13.1325 12.4847 13.1325C9.89888 13.1325 7.79001 11.1789 7.42062 8.66717H7.36331C6.34795 8.66717 5.52484 7.84406 5.52484 6.8287C5.52484 5.81334 6.34795 4.99023 7.36331 4.99023H7.42072C7.72879 2.89232 9.25038 1.18355 11.2499 0.67697C11.5033 0.271091 11.9617 0 12.485 0ZM13.9295 2.53258V2.41856C14.9397 2.88684 15.7072 3.83167 15.9497 4.99023H9.02017C9.26272 3.8316 10.0302 2.88673 11.0405 2.41848V2.53258C11.0405 3.30964 11.6872 3.93958 12.485 3.93958C13.2828 3.93958 13.9295 3.30964 13.9295 2.53258ZM12.7739 1.40699C12.7739 1.25158 12.6446 1.12559 12.485 1.12559C12.3255 1.12559 12.1961 1.25158 12.1961 1.40699V2.53258C12.1961 2.688 12.3255 2.81398 12.485 2.81398C12.6446 2.81398 12.7739 2.688 12.7739 2.53258V1.40699ZM9.02015 8.66717C9.36942 10.333 10.8039 11.5567 12.4847 11.5567C14.1654 11.5567 15.5999 10.333 15.9492 8.66717H9.02015ZM7.10067 6.8287C7.10067 6.68365 7.21826 6.56606 7.36331 6.56606H17.3436C17.4886 6.56606 17.6062 6.68365 17.6062 6.8287C17.6062 6.97375 17.4886 7.09134 17.3436 7.09134H7.36331C7.21826 7.09134 7.10067 6.97375 7.10067 6.8287ZM6.57583 21.9738C6.57583 18.7475 9.21526 16.1208 12.4852 16.1208C15.7551 16.1208 18.3946 18.7475 18.3946 21.9738C18.3946 22.1255 18.3888 22.2756 18.3774 22.4241H6.59302C6.58163 22.2756 6.57583 22.1255 6.57583 21.9738ZM12.4852 14.5449C8.35752 14.5449 5 17.8647 5 21.9738C5 22.3629 5.0302 22.7455 5.08855 23.1193C5.17033 23.6433 5.62336 23.9999 6.12151 23.9999H18.8489C19.347 23.9999 19.8 23.6433 19.8818 23.1193C19.9402 22.7455 19.9704 22.3629 19.9704 21.9738C19.9704 17.8647 16.6129 14.5449 12.4852 14.5449Z"
      />
    </svg>
  );
};

export default ProfessionIcon;
