import ArrowLeftShow from 'assets/icons/ArrowLeftShow';
import ArrowRightHide from 'assets/icons/ArrowRightHide';
import { useFacilityReportStore } from 'pages/facilityReport/useFacilityReportStore/useFacilityReportStore';

const ToggleStatistics: React.FC = () => {
  const { statisticsVisible, toggleStatisticsVisible } = useFacilityReportStore();

  return (
    <button className="text-primary Button1 flex items-center gap-2" onClick={toggleStatisticsVisible}>
      {statisticsVisible ? <ArrowRightHide /> : <ArrowLeftShow />}
      {statisticsVisible ? 'Скрыть итоги' : 'Показать итоги'}
    </button>
  );
};

export default ToggleStatistics;
