import { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import ReactCodeInput from 'react-code-input';
import { useNavigate } from 'react-router-dom';

import { setTokens } from 'app/config/auth';
import { useStore } from 'store';
import { errorToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { saveLoginData } from '../lib/utils';
import { useLoginByCodeMutation } from '../api/mutations/generated/LoginByCode';
import { useGetAuthOptionMutation } from '../api/mutations/generated/GetAuthOption';
import { userFullName } from 'utils/helpers/userFullName';
import { REDIRECT_PAGE } from 'utils/helpers/constVariables';
import { useMeShortlyLazyQuery } from 'pages/main/api/queries/generated/MeShortly';

const CodeLogin = () => {
  const { phoneNumber } = useStore();

  const [code, setCode] = useState('');
  const [seconds, setSeconds] = useState<number>(60);

  const navigate = useNavigate();
  const pageFrom = localStorage.getItem(REDIRECT_PAGE);

  const [loginTestMutation] = useLoginByCodeMutation();
  const [getAuthOption] = useGetAuthOptionMutation();
  const [load] = useMeShortlyLazyQuery();

  const timer = useRef<any>();

  useEffect(() => {
    if (seconds > 0) {
      timer.current = setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  }, [seconds]);

  const sendAgain = useCallback(() => {
    setSeconds(60);
    getAuthOption({
      variables: {
        phone: phoneNumber,
        device: 'WEB',
      },
    }).catch(e => errorToast(errorHandler(e)));
  }, [getAuthOption, phoneNumber]);

  return (
    <Formik
      initialValues={{ phoneNumber }}
      onSubmit={values => {
        loginTestMutation({
          variables: {
            input: {
              phone: values.phoneNumber,
              code,
            },
          },
        })
          .then(response => {
            clearTimeout(timer.current);
            const data = response.data?.loginByCode;
            if (data) {
              const {
                accessToken,
                refreshToken,
                user: { role },
              } = data;
              setTokens({
                accessToken,
                refreshToken,
              });
              load().then(meRes => {
                if (meRes.data) {
                  const user = meRes.data.meShortly;
                  localStorage.setItem('me', JSON.stringify(user));
                  saveLoginData({ name: userFullName(user), phone: values.phoneNumber, code, role });
                }
                if (pageFrom) {
                  navigate(pageFrom);
                  localStorage.removeItem(REDIRECT_PAGE);
                } else {
                  navigate('/');
                }
              });
            }
          })
          .catch(e => errorToast(errorHandler(e)));
      }}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className="bg-white shadow rounded-lg p-5">
            <div className="mb-3">
              <label className="label-primary mb-2">Введите код из СМС</label>
              <ReactCodeInput
                type="number"
                name="code"
                inputMode="numeric"
                fields={4}
                className="codeInput"
                value={code}
                onChange={value => {
                  setCode(value);
                  if (value.length === 4) {
                    handleSubmit();
                  }
                }}
              />
            </div>

            {/*<button type="submit" disabled={code.length < 4 || loading} className="btn-primary_big w-full">*/}
            {/*  {loading ? 'Загрузка' : 'Войти'}*/}
            {/*</button>*/}
            <div className="mt-3">
              {seconds !== 0 && (
                <span className="font-semibold text-sm text-smena_gray-50 pb-1">
                  Запросить новый код через {seconds > 0 && seconds}
                </span>
              )}
              {seconds === 0 && (
                <span
                  className="cursor-pointer text-primary-light font-semibold text-sm hover:text-primary"
                  onClick={sendAgain}
                >
                  Запросить новый код
                </span>
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CodeLogin;
