export type ErrorType = {
  message: string;
};

export const errorHandler = (error: ErrorType): string => {
  if (error.message === 'Failed to fetch') {
    return `Ошибка подключения! Проверьте доступ к сети интернет`;
  }

  return error.message;
};
