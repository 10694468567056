import { useStore } from 'store';
import { useCallback, useState } from 'react';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useRefuseShiftMutation } from 'pages/moderationReport/api/mutations/generated/RefuseShift';
import Modal from 'ui/modal';
import { GetSupervisorWorkSheetDocument } from 'pages/facilityReport/api/queries/generated/GetSupervisorWorkSheet';
import { Role, ShiftStage } from 'generated/graphql';
import { useChangeShiftStageMutation } from 'pages/facilityReport/api/mutations/generated/ChangeShiftStage';
import { useMe } from 'utils/api/useMe';

const EditShiftButtons = () => {
  const { setShift, shift } = useStore();
  const myRole = useMe()?.role;

  const isAdminNewShift = myRole === Role.Admin && shift?.authorId === shift?.userId && shift?.stage === ShiftStage.New;
  const isSupervisorShift = myRole === Role.Supervisor && shift?.authorId === shift?.userId;
  const isWorkerShift = myRole === Role.Worker && shift?.authorId === shift?.userId;
  const isCancelableShift = shift?.stage === ShiftStage.Accepted || shift?.stage === ShiftStage.Working;
  const isNewShift = shift?.stage === ShiftStage.New;

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [refuseShift, { loading: loadingRefuse }] = useRefuseShiftMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });

  const [cancelShiftMutation, { loading: loadingCancel }] = useChangeShiftStageMutation({
    refetchQueries: [GetSupervisorWorkSheetDocument],
  });

  const showRemovePopup = useCallback(() => {
    setShowRemoveModal(true);
  }, []);

  const showCancelPopup = useCallback(() => {
    setShowCancelModal(true);
  }, []);

  const removeShift = useCallback(() => {
    if (shift) {
      refuseShift({
        variables: {
          id: shift?.id,
        },
      })
        .then(() => successToast('Смена отменена'))
        .catch(err => errorToast(errorHandler(err)))
        .finally(() => {
          setShift(undefined);
          setShowRemoveModal(false);
        });
    }
  }, [refuseShift, setShift, shift]);

  const cancelShift = useCallback(() => {
    if (shift) {
      cancelShiftMutation({
        variables: {
          input: {
            id: shift?.id,
            stage: ShiftStage.Ineligible,
          },
        },
      })
        .then(() => successToast('Смена отклонена'))
        .catch(err => errorToast(errorHandler(err)))
        .finally(() => {
          setShift(undefined);
          setShowCancelModal(false);
        });
    }
  }, [cancelShiftMutation, setShift, shift]);

  const acceptedShift = useCallback(() => {
    if (shift) {
      cancelShiftMutation({
        variables: {
          input: {
            id: shift?.id,
            stage: ShiftStage.Accepted,
          },
        },
      })
        .then(() => successToast('Смена подтверждена'))
        .catch(err => errorToast(errorHandler(err)))
        .finally(() => {
          setShift(undefined);
          setShowCancelModal(false);
        });
    }
  }, [cancelShiftMutation, setShift, shift]);

  return (
    <div className="flex flex-col self-end">
      {(isAdminNewShift || isSupervisorShift || isWorkerShift) && (
        <button className="btn-primary mb-2" onClick={acceptedShift}>
          Подтвердить смену
        </button>
      )}

      {isCancelableShift && (
        <button className="btn-reject mb-2" onClick={showRemovePopup}>
          Отменить смену
        </button>
      )}

      {isNewShift && (
        <button className="btn-reject mb-2" onClick={showCancelPopup}>
          Отклонить смену
        </button>
      )}
      <Modal
        type="delete"
        title="Отменить смену"
        open={showRemoveModal}
        okButtonProps={{ disabled: loadingRefuse }}
        onCancel={() => setShowRemoveModal(false)}
        onOk={removeShift}
        okText="Отменить"
      >
        Вы уверены, что хотите отменить смену?
      </Modal>
      <Modal
        type="delete"
        title="Отклонить смену"
        open={showCancelModal}
        okButtonProps={{ disabled: loadingCancel }}
        onCancel={() => setShowCancelModal(false)}
        onOk={cancelShift}
        okText="Отклонить"
      >
        Вы уверены, что хотите отклонить смену?
      </Modal>
    </div>
  );
};

export default EditShiftButtons;
