import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import SelectArrow from 'assets/icons/SelectArrow';
import { useStore } from 'store';
import { positionName } from 'utils/helpers/filters/filtersName';
import { useParams } from 'react-router-dom';
import { RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';

import { WarningExclamation } from 'assets/icons/WarningExclamation';
import { UserIntersectFragment } from 'pages/user/api/fragments/generated/UserIntersect.fragment';
import { usePositions } from 'utils/api/usePositions';
import { DocumentStatus, ReviewType } from 'generated/graphql';
import { getUserFIO } from 'utils/helpers/userFullName';

interface IUserAtSidebar {
  user: UserIntersectFragment;
}

const UserAtSidebar = ({ user }: IUserAtSidebar) => {
  const { facilityWorkposts } = useStore();
  const [showConflicts, setShowConflicts] = useState(false);
  const { userIntersect, setUserIntersect, requestShift } = useStore();
  const { positions } = usePositions();

  const router = useParams();

  const facilityIdURL = router.facilityId;

  const isDocumentAccepted = useCallback(
    (type: ReviewType) => {
      return user.myDocumentsReviews.find(review => review.type === type)?.status === DocumentStatus.Accepted;
    },
    [user.myDocumentsReviews],
  );

  const warningText = useMemo(() => {
    const isNeedCheckMeedBook = facilityWorkposts?.find(
      el => el.positionId === requestShift?.positionId,
    )?.isMedBookRequired;
    const medBookEnd = user.requisite?.medicalAttestationEnd;
    const medInvoice = user.requisite?.medicalInvoiceIssueDate;
    if (!isNeedCheckMeedBook) {
      return;
    }
    if (dayjs(medInvoice).isValid()) {
      return;
    }
    if (!dayjs(medBookEnd).isValid() && !dayjs(medInvoice).isValid()) {
      return 'Мед. книжка отсутствует';
    }
    const isUserMedBookInvalid = dayjs(medBookEnd).isBefore(dayjs());
    if (isUserMedBookInvalid) {
      return 'Мед. книжка просрочена';
    }
    if (!isDocumentAccepted(ReviewType.Page2_3) && !isDocumentAccepted(ReviewType.Page28_29)) {
      return 'Мед. книжка не подтверждена';
    }
  }, [
    facilityWorkposts,
    isDocumentAccepted,
    requestShift?.positionId,
    user.requisite?.medicalAttestationEnd,
    user.requisite?.medicalInvoiceIssueDate,
  ]);

  const userIntersectOnThisObj = user.intersect?.filter(intersect => intersect.facilityId === facilityIdURL);
  const userIntersectNotOnThisObj = user.intersect?.filter(intersect => intersect.facilityId !== facilityIdURL);

  return (
    <>
      <div
        className={clsx(
          user.id === userIntersect?.id && '!bg-smena_bb-background',
          'graphicSearchUserTable p-4 cursor-pointer odd:bg-smena_white even:bg-smena_gray-2 gap-y-2',
        )}
        onClick={() => {
          if (!user.intersect?.length) {
            if (!warningText) {
              setUserIntersect(user);
            }
          } else {
            setShowConflicts(value => !value);
          }
        }}
      >
        <span className="Table-small">{getUserFIO(user)}</span>
        {user.intersect && user.intersect.length ? (
          <div className="Table-small text-right flex">
            <span>Занят (смен: {user.intersect?.length})</span>
            <span className={clsx('inline-flex transform transition-transform', showConflicts && 'rotate-180')}>
              <SelectArrow className="text-smena_text" />
            </span>
          </div>
        ) : (
          <span className="Table-small">Свободен</span>
        )}
        {Boolean(warningText) && (
          <div className={'flex gap-x-2 text-smena_red-dark Caption-small'}>
            <WarningExclamation />
            <span>{warningText}</span>
          </div>
        )}
      </div>
      {showConflicts && (
        <div className="px-4 pt-2 pb-1 flex flex-col gap-y-1">
          {userIntersectOnThisObj?.length ? (
            <>
              <span className="Table-small text-smena_text">На этом объекте</span>
              {userIntersectOnThisObj?.map(el => (
                <div key={el.id} className="flex gap-x-1 Body2 text-smena_text-secondary">
                  <span>{positionName(positions, el.positionId)}</span>
                  <span>·</span>
                  <span>{dayjs(el.dateFrom).format(RU_DATE)}</span>
                  <span>·</span>
                  <span>
                    {dayjs(el.dateFrom).format(TIME_DATE)} – {dayjs(el.dateEnd).format(TIME_DATE)}
                  </span>
                </div>
              ))}
            </>
          ) : null}
          {userIntersectNotOnThisObj?.length ? (
            <>
              <span className="Table-small text-smena_text">На другом объекте</span>
              {userIntersectNotOnThisObj?.map(el => (
                <div key={el.id} className="flex gap-1 Body2 text-smena_text-secondary">
                  <span>{positionName(positions, el.positionId)}</span>
                  <span>·</span>
                  <span>{dayjs(el.dateFrom).format(RU_DATE)}</span>
                  <span>·</span>
                  <span>
                    {dayjs(el.dateFrom).format(TIME_DATE)} – {dayjs(el.dateEnd).format(TIME_DATE)}
                  </span>
                </div>
              ))}
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default UserAtSidebar;
