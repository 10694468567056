import { Link } from 'react-router-dom';
import MainLayout from 'components/layout/main';
import { positionsColumns } from 'components/table/columns';
import PositionRow from 'pages/positions/components/PositionRow';
import Plus from 'assets/icons/plus';
import { usePositions } from 'utils/api/usePositions';
import Table from 'components/table';
import Search from 'ui/search';

const PositionPage = () => {
  const { positions, loading, error } = usePositions();

  return (
    <MainLayout
      title="Справочник профессий"
      breadCrumbs={[{ path: '/positions', name: 'Справочник профессий' }]}
      error={error}
    >
      <div className="flex-1 flex flex-col">
        <div className="shadow-smena rounded-lg bg-smena_white flex-grow flex flex-col">
          <div className="flex justify-between p-4 border-b border-smena_gray-30 flex-wrap gap-y-4">
            <Search />
            <Link to="/positions/create">
              <button type="button" className="btn-primary_big flex items-center gap-x-2">
                <Plus className="text-smena_white" />
                Новая профессия
              </button>
            </Link>
          </div>

          <div className="flex-grow overflow-auto">
            <Table data={positions} loadingData={loading} columns={positionsColumns}>
              {positions?.map(position => <PositionRow key={position.id} position={position} />)}
            </Table>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PositionPage;
