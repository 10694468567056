import { useMemo } from 'react';
import { useFacilityGroup } from '../../../utils/api/useFacilityGroup';

export const useFacilityGroupBreadcrumbs = () => {
  const { facilityGroup, id } = useFacilityGroup();
  return useMemo(
    () => [
      { path: '/groups', name: 'Группы' },
      id ? { path: `/groups/${id}`, name: `${facilityGroup?.name}` } : { path: '/groups/create', name: 'Новая группа' },
      // { path: `/groups/${id}/facilities`, name: `Объекты группы ${facilityGroup?.name}` },
    ],
    [facilityGroup?.name, id],
  );
};
