import PhotoItem from './PhotoItem';
import { useState } from 'react';
import Slider from './Slider';
import DownloadImage from './DownloadImage';
import { useParams } from 'react-router';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface FileEntity {
  __typename?: 'File';
  path: string;
}

interface FacilityGalleryEntity {
  file?: FileEntity | null;
}

interface Props {
  casingGallery: FacilityGalleryEntity[];
  facilityGallery: FacilityGalleryEntity[];
  createVacancyGallery?: any[];
  onDownload?: (image: File) => void;
  isDownload?: boolean;
  handleCoverPhotoId?: (photoId: string | null) => void;
  coverPhoto?: any;
  isDelete?: boolean;
  handleDelete?: (photoId: string) => void;
  photoIds?: string[];
  coverPhotoInCreating?: any;
}

const PhotoList = (props: Props) => {
  const { vacancyId } = useParams();
  const [sliderVisible, setSliderVisible] = useState(false);
  const [initialSlideIndex, setInitialSlideIndex] = useState(0);

  const {
    casingGallery,
    facilityGallery,
    onDownload,
    handleCoverPhotoId,
    coverPhoto,
    handleDelete,
    photoIds,
    createVacancyGallery,
    coverPhotoInCreating,
    isDelete = false,
    isDownload = false,
  } = props;

  const images = [...(coverPhoto ? [{ file: coverPhoto, isCover: true }] : []), ...casingGallery, ...facilityGallery];
  const groupedImages = [
    ...(coverPhoto
      ? [{ title: 'Обложка вакансии', images: [{ file: coverPhoto }] }]
      : [{ title: 'Обложка вакансии', images: [] }]),
    { title: 'Фото профессии', images: casingGallery },
    { title: 'Фото объекта', images: facilityGallery },
  ];

  createVacancyGallery?.forEach(item => {
    images.push({ file: coverPhotoInCreating.current }, ...item.casingGallery, ...item.facilityGallery);
  });

  const handleShowSlider = () => {
    setSliderVisible(true);
  };

  return (
    <>
      <span className="Subtitle1 text-smena_text-secondary">Фото</span>

      <div className="max-h-[450px] overflow-auto">
        {!createVacancyGallery?.length ? (
          <div className="mt-4" onClick={e => e.stopPropagation()}>
            <div className={clsx('flex gap-[5px] flex-wrap', vacancyId && 'flex-col')}>
              {isDownload && <DownloadImage onDownload={onDownload} />}
              {groupedImages.map((group, groupIndex) => (
                <div key={groupIndex} className="group">
                  {vacancyId && <h3 className="Subtitle2">{group.title}</h3>}
                  <div className="flex gap-[10px] flex-wrap mb-5">
                    {group.images.map(img => {
                      const imgIndex = images.indexOf(img);
                      return (
                        <PhotoItem
                          img={img}
                          index={imgIndex === -1 ? 0 : imgIndex}
                          isDelete={isDelete}
                          handleDelete={handleDelete}
                          setInitialSlideIndex={setInitialSlideIndex}
                          photoIds={photoIds}
                          handleShowSlider={handleShowSlider}
                          key={imgIndex}
                          isCover={groupIndex === 0}
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {coverPhotoInCreating?.current && (
          <div className="mb-5">
            <div className="Subtitle1 mb-1">Обложка вакансии</div>
            <PhotoItem
              img={{ file: coverPhotoInCreating?.current }}
              index={0}
              setInitialSlideIndex={setInitialSlideIndex}
              photoIds={photoIds}
              handleShowSlider={handleShowSlider}
              isCover={true}
            />
          </div>
        )}

        {createVacancyGallery?.length
          ? createVacancyGallery.map((item, index) =>
              item.casingGallery.length > 0 || item.facilityGallery.length > 0 ? (
                <div key={index} className="mb-5">
                  <div className="mb-3">
                    <Link to={`/facilities/${item.id}`} className="link cursor-pointer">
                      {item.name}
                    </Link>
                  </div>

                  {item.casingGallery.length > 0 && <div className="Subtitle1 mb-1">Фото профессии</div>}
                  <div className="flex gap-[10px] flex-wrap mb-3">
                    {item.casingGallery.map((img: any, index: number) => {
                      const imgIndex = images.indexOf(img);
                      return (
                        <PhotoItem
                          img={img}
                          index={imgIndex === -1 ? 0 : imgIndex}
                          setInitialSlideIndex={setInitialSlideIndex}
                          photoIds={photoIds}
                          handleShowSlider={handleShowSlider}
                          key={index}
                        />
                      );
                    })}
                  </div>

                  {item.facilityGallery.length > 0 && <div className="Subtitle1 mb-1">Фото объекта</div>}

                  <div className="flex gap-[10px] flex-wrap">
                    {item.facilityGallery.map((img: any, index: number) => {
                      const imgIndex = images.indexOf(img);
                      return (
                        <PhotoItem
                          img={img}
                          index={imgIndex === -1 ? 0 : imgIndex}
                          setInitialSlideIndex={setInitialSlideIndex}
                          photoIds={photoIds}
                          handleShowSlider={handleShowSlider}
                          key={index}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null,
            )
          : null}

        {sliderVisible && (
          <Slider
            images={images.length ? images : []}
            handleCoverPhotoId={handleCoverPhotoId}
            setInitialSlideIndex={setInitialSlideIndex}
            initialSlideIndex={initialSlideIndex}
            coverPhoto={coverPhoto}
            setSliderVisible={setSliderVisible}
            isCreate={!!createVacancyGallery?.length}
            coverPhotoInCreating={coverPhotoInCreating}
          />
        )}
      </div>
    </>
  );
};

export default PhotoList;
