import { DayjsOrNull } from 'interfaces/CustomTypes';
import { Dayjs } from 'dayjs';

export const getDateMinutes = (date: DayjsOrNull) => {
  if (date) {
    return date.get('hours') * 60 + date.get('minutes');
  }
  return 0;
};

export const currentDate = (time: DayjsOrNull, currentPeriodDay?: Dayjs) => {
  if (!time || !currentPeriodDay) return null;
  return time
    .set('year', currentPeriodDay.year())
    .set('month', currentPeriodDay.month())
    .set('date', currentPeriodDay.date());
};
