import { useMemo } from 'react';
import { useFacility } from '../../../utils/api/useFacility';

export const useFacilityTitle = () => {
  const { facility } = useFacility();
  return useMemo(() => {
    if (facility) {
      return `Объект ${facility.name}`;
    }
    return 'Объект';
  }, [facility]);
};
