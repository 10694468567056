import clsx from 'clsx';

import SelectArrow from 'assets/icons/SelectArrow';
import { moderationShiftReasons } from 'utils/helpers/lists/reasons';
import SimpleSelect from 'ui/select';
import TextArea from 'ui/textArea';
import { useGetModerationReport } from 'utils/api/useGetModerationReport';
import { ModerationStatus } from 'generated/graphql';
import { undefStr } from 'interfaces/CustomTypes';
import React, { useEffect } from 'react';
import { useModerationStore } from 'pages/moderationReport/useModerationStore';

interface Props {
  toPayment?: boolean;
  comment: undefStr | null;
  reason?: string;
  isShowComment?: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any) => void;
  touched: any;
  errors: any;
}

const ShiftApproveBlock = React.memo(
  ({ toPayment, comment, handleChange, setFieldValue, reason, errors, touched }: Props) => {
    const { moderationReport } = useGetModerationReport();
    const showApproveBlock = useModerationStore(state => state.showApproveBlock);
    const setShowApproveBlock = useModerationStore(state => state.setShowApproveBlock);
    const moderationStatus = moderationReport?.status;
    const sendStatus = moderationStatus === ModerationStatus.Sent;
    useEffect(() => {
      return () => {
        setShowApproveBlock(false);
      };
    }, [setShowApproveBlock]);
    return (
      <div className="grid grid-cols-2 gap-y-1 border-b border-b-smena_gray-30 p-4">
        <div className="col-span-2">
          <div className="mb-2 grid grid-cols-2 gap-2">
            <label
              className={clsx(
                'rounded-l-lg radio-block w-full',
                toPayment ? 'bg-smena_green-extra_light border-smena_green-mark' : '',
              )}
            >
              <input
                type="radio"
                name="status"
                className="radio radio_approve"
                id="toPay"
                value="pay"
                checked={toPayment === true}
                onChange={() => {
                  setShowApproveBlock(false);
                  setFieldValue('toPayment', true);
                }}
              />
              К оплате
            </label>
            <label
              className={clsx(
                'radio-block rounded-r-lg w-full',
                toPayment === false ? 'bg-smena_red-extra_light border-smena_red shift__radio-bad' : '',
              )}
            >
              <input
                type="radio"
                name="status"
                id="nonpay"
                className="radio radio_reject"
                value=""
                checked={toPayment === false}
                onChange={() => {
                  setFieldValue('toPayment', false);
                }}
              />
              Без оплаты
            </label>
          </div>
        </div>

        <div className="col-span-4">
          <div
            className="flex items-center gap-x-2 cursor-pointer mt-3"
            onClick={() => {
              setShowApproveBlock(!showApproveBlock);
            }}
          >
            <span className="Caption-numbers text-primary">Оставить комментарий</span>
            <span className={clsx('transform transition-transform', showApproveBlock && 'rotate-180')}>
              <SelectArrow className="text-primary" />
            </span>
          </div>

          {showApproveBlock && (
            <div className="flex flex-col gap-y-2">
              <SimpleSelect
                divClassName="relative"
                label="Причина редактирования"
                onChange={value => {
                  setFieldValue('reason', value);
                }}
                value={reason}
                disabled={sendStatus}
                placeholder="Все причины"
                options={moderationShiftReasons}
                error={touched.reason && errors.reason ? errors.reason : ''}
              />
              <TextArea
                label={'Комментарий (если нужно)'}
                onChange={handleChange}
                placeholder="Оставьте комментарий"
                value={comment ?? undefined}
                name={'comment'}
                disabled={sendStatus}
                error={touched.comment && errors.comment ? errors.comment : ''}
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default ShiftApproveBlock;
