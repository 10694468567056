import React, { useMemo } from 'react';
import OutsideClick from 'components/outsideComponent';
import clsx from 'clsx';
import { useFilterRequestWithSchedule } from '../../hooks';
import {
  useAcceptedShifts,
  useDesiredShifts,
  useEmptyShifts,
  useFreeShifts,
  useNotAcceptedByWorkerShifts,
  useProbationShifts,
  useRefusedShift,
} from '../../../request/components/legendPopup/hooks';
import LegendRow from 'pages/userSchedule/components/legend/Row';

interface Props {
  showLegendPopup: boolean;
  setShowLegendPopup: React.Dispatch<React.SetStateAction<boolean>>;
  triggerRef: React.MutableRefObject<HTMLButtonElement | null>;
}

const FacilityScheduleLegend: React.FC<Props> = React.memo(({ showLegendPopup, setShowLegendPopup, triggerRef }) => {
  const filterRequestWithSchedule = useFilterRequestWithSchedule();
  const shifts = useMemo(
    () => filterRequestWithSchedule.map(schedule => schedule.zayavkaShift).flat(),
    [filterRequestWithSchedule],
  );
  const emptyShifts = useEmptyShifts({ shifts });
  const freeShifts = useFreeShifts({ shifts });
  const desiredShifts = useDesiredShifts({ shifts });
  const acceptedShifts = useAcceptedShifts({ shifts });
  const notAcceptedByWorkerShifts = useNotAcceptedByWorkerShifts({ shifts });
  const refusedShift = useRefusedShift({ shifts });
  const probationShifts = useProbationShifts({ shifts });
  return (
    <OutsideClick
      show={showLegendPopup}
      setShow={setShowLegendPopup}
      className={clsx(
        'top-8 absolute right-0 flex-col gap-y-5 shadow-smena p-6 rounded-lg bg-smena_white z-30',
        showLegendPopup ? 'flex' : 'hidden',
      )}
      triggerRef={triggerRef}
    >
      <div className="flex flex-col gap-y-4">
        <LegendRow text="Смены без исполнителей" bgColor="bg-smena_bb-border" array={emptyShifts} />
        <LegendRow text="Свободные смены" bgColor="bg-primary-cyan" array={freeShifts} />
        <LegendRow text="Принятые смены" bgColor="bg-smena_green-mark" array={acceptedShifts} />
        <LegendRow
          text="Ожидают подтверждения исполнителя"
          bgColor="bg-smena_orange-light"
          array={notAcceptedByWorkerShifts}
        />
        <LegendRow text="Ожидают подтверждения супервайзера" bgColor="bg-smena_yellow-light" array={desiredShifts} />
        <LegendRow text="Отмененные" bgColor="bg-smena_red-light" array={refusedShift} />
        <LegendRow text="Пробные смены" bgColor="bg-smena_purple-light" array={probationShifts} />
      </div>
    </OutsideClick>
  );
});

export default FacilityScheduleLegend;
