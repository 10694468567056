import {
  ModerationReportsPaginatedDocument,
  ModerationReportsPaginatedQueryVariables
} from 'pages/moderationReports/api/queries/generated/ModerationReportsPaginated';

interface IReportsQueryConfig {
  query: typeof ModerationReportsPaginatedDocument;
  variables: ModerationReportsPaginatedQueryVariables;
}

export const reportsQueryConfig: () => IReportsQueryConfig = () => ({
  query: ModerationReportsPaginatedDocument,
  variables: {
    input: {},
  },
});
