import dayjs from 'dayjs';
import { NotifyLetterStatus, PushRoute, PushToUnassignedFilter, Role } from 'generated/graphql';
import { usePush } from 'utils/api/usePush';
import { getIdArr } from 'utils/helpers/get/getArrId';
import { useUpdateNotifyLetterMutation } from '../../api/mutations/generated/CreateNotifyLetter';
import dateHour from '../../../userSchedule/components/sidebar/newSchedule/helpers/dateHour';
import { FormikHelpers } from 'formik';
import { useStore } from 'store';
import { EAutoSave } from 'enums/EAutoSave';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useToPublishNotifyLetterMutation } from '../../api/mutations/generated/ToPublishNotifyLetter';

export interface PushFormProps {
  name: string;
  title: string;
  body: string;
  link?: string | null;
  status?: NotifyLetterStatus;
  userSettings: boolean;
  cities: string[];
  facilityGroups: string[];
  facilities: string[];
  roles: Role[];
  positions: string[];
  date: dayjs.Dayjs | null;
  dateEnd: dayjs.Dayjs | null;
  time: dayjs.Dayjs | null;
  timeEnd: dayjs.Dayjs | null;
  interval?: number | null;
  indefinitely: boolean;
  pushToUnassigned: PushToUnassignedFilter;
}

export const usePushForm = () => {
  const { push, id } = usePush();
  const { setAutoSaveState } = useStore();
  const [updateNotifyLetter, { loading }] = useUpdateNotifyLetterMutation();
  const [toPublishPush] = useToPublishNotifyLetterMutation();
  const initialValues: PushFormProps = {
    name: push?.name || '',
    title: push?.title || '',
    body: push?.body || '',
    link: push?.route,
    userSettings: !!push?.publicCondition?.useCondition,
    status: push?.status,
    cities: getIdArr(push?.publicCondition?.cities),
    facilityGroups: getIdArr(push?.publicCondition?.facilityGroups),
    facilities: getIdArr(push?.publicCondition?.facilities),
    roles: push?.publicCondition?.roles || [],
    positions: getIdArr(push?.publicCondition?.positions),
    date: push?.publicCondition?.publishFrom ? dayjs(push.publicCondition.publishFrom) : null,
    dateEnd: push?.publicCondition?.publishTo ? dayjs(push.publicCondition.publishTo) : null,
    time: push?.publicCondition?.publishFrom ? dayjs(push.publicCondition.publishFrom) : null,
    timeEnd: push?.publicCondition?.publishTo ? dayjs(push.publicCondition.publishTo) : null,
    interval: push?.publicCondition?.interval,
    indefinitely: !!push?.publicCondition?.indefinitely,
    pushToUnassigned: push?.publicCondition?.pushToUnassigned || PushToUnassignedFilter.WithoutUnassigned,
  };
  const validate = (values: PushFormProps) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = 'Обязательное поле';
    }
    if (!values.title) {
      errors.title = 'Обязательное поле';
    }
    if (!values.body) {
      errors.body = 'Обязательное поле';
    }
    if (!values.link) {
      errors.link = 'Обязательное поле';
    }
    if (!values.date) {
      errors.date = 'Обязательное поле';
    }
    if (!values.time) {
      errors.time = 'Обязательное поле';
    }
    return errors;
  };

  const toPublishHandle = () => {
    if (!id) return;
    toPublishPush({
      variables: {
        id,
      },
    })
      .then(() => successToast('Пуш опубликован'))
      .catch(error => errorToast(errorHandler(error)));
  };
  const onSave = (values: PushFormProps) => {
    if (!id) return;
    const publishFrom = dateHour(values.date, values.time);
    const publishTo = dateHour(values.dateEnd, values.timeEnd);
    setAutoSaveState(EAutoSave.LOADING);
    updateNotifyLetter({
      variables: {
        input: {
          id,
          name: values.name,
          title: values.title,
          body: values.body,
          useCondition: values.userSettings,
          status: values.status as NotifyLetterStatus,
          route: values.link as PushRoute,
          cities: values.cities,
          facilities: values.facilities,
          facilityGroups: values.facilityGroups,
          positions: values.positions,
          roles: values.roles,
          publishFrom,
          publicConditionId: push?.publicCondition?.id || '',
          interval: values.interval,
          publishTo,
          indefinitely: values.indefinitely,
          pushToUnassigned: values.pushToUnassigned,
        },
      },
    })
      .then(() => {
        setAutoSaveState(EAutoSave.SAVED);
      })
      .catch(() => setAutoSaveState(EAutoSave.ERROR));
  };
  const onSubmit = (values: PushFormProps) => {
    onSave(values);
  };

  return { initialValues, validate, onSubmit, loading, toPublishHandle, onSave };
};

interface ValidatePushFormProps {
  values: PushFormProps;
  validateForm: FormikHelpers<PushFormProps>['validateForm'];
  handler: () => void;
}

export const validatePushForm = ({ validateForm, values, handler }: ValidatePushFormProps) => {
  validateForm(values).then(validation => {
    if (Object.entries(validation).length) {
      return;
    }
    return handler();
  });
};
