import React from 'react';
import { morph } from 'utils/helpers/morph';
import { getImagePath } from 'utils/helpers/get/getImagePath';
import { useVacanciesMap } from '../../store';
import clsx from 'clsx';

const VacanciesList = () => {
  const facilityVacancies = useVacanciesMap(state => state.facilityVacancies);
  const changeStatus = useVacanciesMap(state => state.changeFacilityVacancyStatus);
  const setActiveFacilityVacancy = useVacanciesMap(state => state.setActiveFacilityVacancy);
  const setCenter = useVacanciesMap(state => state.setCenter);
  const setZoom = useVacanciesMap(state => state.setZoom);
  return (
    <div className={'flex flex-col overflow-auto'}>
      {facilityVacancies.map(el => (
        <div
          key={el.id}
          className={clsx(
            el.isHover ? 'bg-smena_bb-background_light' : '',
            'first:border-t border-b border-smena_gray-30 py-3 px-6 flex cursor-pointer gap-x-6 flex-[1_0]',
          )}
          onMouseEnter={() => {
            changeStatus(el.id);
          }}
          onMouseLeave={() => {
            changeStatus(el.id);
          }}
          onClick={() => {
            setActiveFacilityVacancy(el.id);
            const coords = [Number(el.latitude), Number(el.longitude)];
            setCenter(coords);
            setZoom(15);
          }}
        >
          <div className={'flex flex-col gap-y-2'}>
            <span className={'Subtitle1'}>{el.name}</span>
            <span className={'Body1 text-smena_text-secondary'}>{el.addressString || el.address?.address}</span>
            <span className={clsx('Body1', el.vacancies?.length ? 'text-primary' : 'text-smena_text-helper')}>
              {`${el.vacancies?.length} ${morph(el.vacancies?.length || 0, ['вакансия', 'вакансии', 'вакансий'])}`}
            </span>
          </div>
          <img
            src={getImagePath(el.facilityGroup?.logo?.path)}
            className={'w-[48px] h-[48px] object-cover rounded-full'}
            alt={'facility group image'}
          />
        </div>
      ))}
    </div>
  );
};

export default VacanciesList;
