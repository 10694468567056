import { useCallback } from 'react';
import { errorToast, successToast } from 'utils/helpers/notify';
import { DownloadIcon } from 'assets/icons/download';
import { clsx } from 'clsx';
import { ModerationStatus } from 'generated/graphql';
import SpinnerLoad from 'ui/loader';
import { toast } from 'react-toastify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useDownloadModerationReportMutation } from 'pages/moderationReports/api/mutations/generated/DownloadModerationReport';
import { PaginationModerationReportFragment } from 'pages/moderationReports/api/fragments/generated/PaginationModerationReport.fragment';
import Tooltip from 'antd/es/tooltip';

type Props = {
  original: PaginationModerationReportFragment;
};

const Actions = ({ original }: Props) => {
  const [downloadModerationReport, { loading }] = useDownloadModerationReportMutation();

  const downloadXLSHandler = useCallback(
    (id: string) => {
      toast.info('Создаем отчет...', {
        theme: 'colored',
        type: 'info',
        toastId: 'infoToast',
      });
      downloadModerationReport({
        variables: {
          id,
        },
      })
        .then(response => {
          if (response.data?.downloadModerationReport) {
            const filePath = response.data.downloadModerationReport.filePath;
            const url = `${process.env.REACT_APP_PUBLIC_DOMAIN}/${filePath}`;

            const link = document.createElement('a');

            link.href = url;
            link.download = filePath;

            document.body.appendChild(link);

            link.click();
            document.body.removeChild(link);

            toast.dismiss('infoToast');
            successToast('Отчет успешно скачан');
          }
        })
        .catch(e => {
          toast.dismiss('infoToast');
          setTimeout(() => {
            errorToast(errorHandler(e));
          }, 1000);
        });
    },
    [downloadModerationReport],
  );
  return (
    <div className="flex items-center">
      <Tooltip title="Скачать модерацию">
        <button
          className={clsx(original.status === ModerationStatus.InWork || loading ? 'opacity-50' : 'cursor-pointer')}
          disabled={original.status === ModerationStatus.InWork || loading}
          onClick={() => downloadXLSHandler(original.id)}
        >
          <DownloadIcon />
        </button>
      </Tooltip>

      {loading && <SpinnerLoad />}
    </div>
  );
};

export default Actions;
