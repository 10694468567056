import React from 'react';

import MainLayout from 'components/layout/main';
import CustomPagination from 'ui/pagination';
import { useUsersHeaderMenu } from 'utils/hooks/breadcrumbs/useUsersBreadcrumbs';
import NewTable from 'components/table/newTable';
import { useFiredUsersTable } from 'components/table/columns/firedUsers';
import { useUsersPaginated } from 'utils/api/useUsersPaginated';
import UsersFilters from '../users/components/ourUsers/UsersFilters';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';

const FiredUser = () => {
  const { userPaginated, users, loadingUsers, error, page } = useUsersPaginated(false);
  const headerMenu = useUsersHeaderMenu();
  const table = useFiredUsersTable(users);

  return (
    <MainLayout
      title="Пользователи"
      headerMenu={headerMenu}
      breadCrumbs={[{ path: '/users', name: 'Пользователи' }]}
      error={error}
    >
      <div className={'flex flex-col shadow-smena rounded bg-smena_white'}>
        <UsersFilters table={table} />
        <NewTable<UsersPaginatedFragment>
          table={table}
          length={users.length}
          loading={loadingUsers}
          headerMenu={true}
        />
        {userPaginated && <CustomPagination total={userPaginated.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default FiredUser;
