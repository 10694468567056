import SelectArrow from 'assets/icons/SelectArrow';
import OutsideClick from 'components/outsideComponent';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useModerationStore } from '../../pages/moderationReport/useModerationStore';

interface Props {
  disabled?: boolean;
  buttonText: string;
  options: string[];
  onButtonClick: () => void;
}

const SplitButtonSmena: React.FC<Props> = ({ disabled, buttonText, options, onButtonClick }) => {
  const selectedOption = useModerationStore(state => state.selectedOption);
  const setSelectedOption = useModerationStore(state => state.setSelectedOption);
  const [showOptions, setShowOptions] = useState(false);

  const btnRef = useRef<HTMLButtonElement>(null);

  const onShowOptions = useCallback(() => {
    setShowOptions(value => !value);
  }, []);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      const element = e.target as HTMLElement;
      const { value } = element.dataset;
      if (value) {
        setSelectedOption(options.indexOf(value));
        setShowOptions(false);
      }
    },
    [options, setSelectedOption],
  );

  useEffect(() => {
    return () => {
      setSelectedOption(0);
    };
  }, [setSelectedOption]);

  return (
    <div className={'inline-flex h-[32px] relative'}>
      <button disabled={disabled} className={'btn-split btn-split__left'} onClick={onButtonClick}>
        {buttonText}
      </button>
      <button disabled={disabled} ref={btnRef} className={'btn-split btn-split__right'} onClick={onShowOptions}>
        <SelectArrow className="text-smena_white" />
      </button>
      <OutsideClick
        show={showOptions}
        setShow={setShowOptions}
        triggerRef={btnRef}
        className={clsx('absolute top-full left-0 w-full z-30', showOptions ? 'visible' : 'invisible')}
      >
        <ul className={'rounded-lg bg-smena_white shadow-smena py-2'}>
          {options.map((el, index) => (
            <li
              key={el}
              className={clsx(
                index === selectedOption
                  ? 'bg-smena_bb-background hover:bg-smena_bb-border_light'
                  : 'hover:bg-smena_bb-background_light',
                'Body1 p-2 cursor-pointer',
              )}
              data-value={el}
              onClick={onClick}
            >
              {el}
            </li>
          ))}
        </ul>
      </OutsideClick>
    </div>
  );
};

export default SplitButtonSmena;
