import React from 'react';
import { Formik } from 'formik';

import { LeadStatus } from 'generated/graphql';

import MainLayout from 'components/layout/main';
import SimpleCheckbox from 'ui/checkbox';
import { userFullName } from 'utils/helpers/userFullName';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import SimpleSelect from 'ui/select';
import { leadStatusOptions } from 'utils/helpers/constVariables';
import { useLeadForm, useLeadTitle } from './hooks';
import { useLead } from '../../utils/api/useLead';

const UpdateLead = () => {
  const { lead, id, error } = useLead();
  const title = useLeadTitle();
  const { initialValues, onSubmit, loading } = useLeadForm();

  return (
    <MainLayout
      title={title}
      breadCrumbs={[
        { path: '/offers/leads', name: 'Акции' },
        { path: '/offers/leads' + id, name: 'Обработка лида' },
      ]}
      error={error}
    >
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, handleChange, setFieldValue, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="grid xs:grid-cols-12 grid-cols-3 gap-x-6">
              <div className="xs:col-span-7 col-span-3 gap-y-5 p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col">
                <span className="Subtitle1 text-smena_text-secondary">Информация</span>
                <div className="flex xs:flex-row flex-col gap-5">
                  <SimpleSelect
                    divClassName="w-[190px]"
                    label="Статус"
                    onChange={value => {
                      setFieldValue('status', value);
                    }}
                    disabled={!values.isProcessed}
                    value={values.status}
                    options={leadStatusOptions}
                  />
                  <DatePickerInput
                    divClassName="w-[250px]"
                    label="Дата обработки"
                    value={values.date}
                    placeholder="За весь период"
                    onChange={updateDate => {
                      setFieldValue('date', updateDate);
                    }}
                  />
                  <SimpleCheckbox
                    divClassName="flex items-end pb-2"
                    checked={!!values.isProcessed}
                    label="Обработан"
                    name="isProcessed"
                    onChange={e => {
                      handleChange(e);
                      if (values.isProcessed) {
                        setFieldValue('status', LeadStatus.Pending);
                      }
                    }}
                  />
                </div>
                <div className="flex items-center gap-x-1">
                  <span className="Subtitle2 text-smena_text">Кто привел:</span>
                  <span className="Body2 text-smena_text">{userFullName(lead?.user?.referrer)}</span>
                </div>
              </div>
              <div className="col-start-1 xs:col-span-7 col-span-3 justify-self-end pb-5">
                <button type="submit" disabled={loading} className="btn-primary_big mt-5">
                  <span>{loading ? 'Загрузка' : 'Сохранить'}</span>
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default UpdateLead;
