import { ModerationStatus } from 'generated/graphql';

export const shiftPeriodStatus = (status?: ModerationStatus) => {
  if (status === ModerationStatus.InWork) {
    return 'text-primary-dark bg-smena_bb-background';
  } else if (status === ModerationStatus.Sent) {
    return 'text-smena_green-dark bg-smena_green-extra_light';
  }
  return '';
};
