import { UserAutoRatingFragment } from 'pages/user/api/fragments/generated/UserAutoRating.fragment';
import { useCallback, useMemo } from 'react';

export const useProgressValues = (userRating?: UserAutoRatingFragment | null) => {
  const countRate = useCallback((rate: number) => {
    if (rate) {
      return rate * 33 + 1;
    }
    return 0;
  }, []);
  return useMemo(() => {
    if (userRating) {
      return {
        rating: userRating.rating * 20,
        documents: userRating.documents * 100,
        performance: countRate(userRating.performance),
        discipline: countRate(userRating.discipline),
        diligence: countRate(userRating.diligence),
        penalization: countRate(userRating.penalization),
      };
    }
    return {
      rating: 0,
      documents: 0,
      performance: 0,
      discipline: 0,
      diligence: 0,
      penalization: 0,
    };
  }, [countRate, userRating]);
};
