import MainLayout from 'components/layout/main';
import { useStocksHeaderMenu } from 'pages/leads/utils/hooks/headerMenu';
import ReferralProgramsFilters from './components/ReferralProgramsFilters';
import { useReferralProgramsTable } from 'components/table/columns/referralPrograms';
import NewTable from 'components/table/newTable';
import CustomPagination from 'ui/pagination';
import { useGetReferralProgramsPaginatedQuery } from './api/queries/generated/GetReferralProgramsPaginated';
import { useSearchParams } from 'react-router-dom';
import { ReferralProgramStatusEnum } from 'generated/graphql';

const ReferralProgramsPage = () => {
  const headerMenu = useStocksHeaderMenu();

  const [params] = useSearchParams();

  const searchParams = params.get('search');
  const pageParams = params.get('page');
  const page = pageParams ? +pageParams : 1;
  const sizeParam = params.get('size');
  const size = sizeParam ? +sizeParam : 25;
  const facilityGroupIdParam = params.get('facilityGroupId');
  const facilityIdParam = params.get('facilityId');
  const statusParams = params.get('status');

  const { data, loading } = useGetReferralProgramsPaginatedQuery({
    variables: {
      input: {
        page,
        size,
        searchQuery: searchParams,
        status: statusParams as ReferralProgramStatusEnum,
        facilityId: facilityIdParam,
        facilityGroupId: facilityGroupIdParam,
      },
    },
  });

  const referralProgramsPagination = data?.getReferralProgramsPaginated;
  const referralPrograms = referralProgramsPagination?.referralPrograms || [];
  const table = useReferralProgramsTable(referralPrograms);

  return (
    <MainLayout title="Акции" breadCrumbs={[{ path: '/offers/referrals', name: 'Акции' }]} headerMenu={headerMenu}>
      <div className="flex flex-col shadow-smena rounded bg-smena_white">
        <ReferralProgramsFilters />
        <NewTable table={table} length={referralPrograms.length} loading={loading} headerMenu={true} />
        {referralProgramsPagination && <CustomPagination total={referralProgramsPagination.pages} currentPage={page} />}
      </div>
    </MainLayout>
  );
};

export default ReferralProgramsPage;
