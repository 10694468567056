import dayjs from 'dayjs';
import clsx from 'clsx';

import RequestProfessionDay from './days';
import { FacilityRequestSchedulesFragment } from 'pages/facilityRequests/api/fragments/generated/FacilityRequestSchedules.fragment';

interface IRequestProfessionDates {
  requestSchedule?: FacilityRequestSchedulesFragment | null;
  days: dayjs.Dayjs[];
}
const RequestProfessionDates = ({ requestSchedule, days }: IRequestProfessionDates) => {
  return (
    <div
      className={clsx('gap-x-2 requestScheduleProfessionsRows border-b requestRow w-fit')}
      style={{
        gridTemplateColumns: `repeat(${days.length}, 80px)`,
      }}
    >
      {days.map(day => (
        <RequestProfessionDay key={day.valueOf()} day={day} requestSchedule={requestSchedule} />
      ))}
    </div>
  );
};

export default RequestProfessionDates;
