import { useState } from 'react';
import { MarkType } from 'generated/graphql';
import clsx from 'clsx';
import { TIME_DATE } from 'utils/helpers/constVariables';
import dayjs from 'dayjs';
import { getDistance } from 'utils/helpers/get/getDistance';
import SelectArrow from 'assets/icons/SelectArrow';
import { messageColorHelper } from 'pages/moderationReport/lib/helpers';
import { useShiftConditions } from 'pages/moderationReport/lib/hooks';
import { useStore } from 'store';

const ShiftMarks = () => {
  const { shift } = useStore() as any;
  const [showCheckins, setShowCheckins] = useState(false);
  const { isMarks } = useShiftConditions(shift);

  const checkins = shift?.marks?.filter((mark: any) => mark.type === MarkType.Checkin) || [];
  const checkouts = shift?.marks?.filter((mark: any) => mark.type === MarkType.Checkout) || [];

  const renderMark = (mark: any) => {
    const color = messageColorHelper(mark.statusMessage);
    return (
      <div className="flex gap-x-6" key={mark.time}>
        <div className="flex flex-col">
          <span className={clsx('Table-small', color, 'mr-1')}>{dayjs(mark.time).format(TIME_DATE)}</span>
        </div>
        <div className="flex flex-col">
          <span className={clsx('Table-small', color)}>{getDistance(Number(mark.distance))}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {isMarks && (
        <div className="col-span-full">
          <div className="flex items-center mb-2 cursor-pointer" onClick={() => setShowCheckins(value => !value)}>
            <span className="Caption-numbers text-primary">Раскрыть детали</span>
            <span className={clsx('transform transition-transform ml-1', showCheckins && 'rotate-180')}>
              <SelectArrow className="text-primary" />
            </span>
          </div>

          {showCheckins && (
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-y-2">{checkins.map(renderMark)}</div>
              <div className="flex flex-col gap-y-2">{checkouts.map(renderMark)}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShiftMarks;
