const Logo = () => {
  return (
    <svg width="150" height="28" viewBox="0 0 150 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.9251 24.3174C43.4599 24.3174 42.0275 24.1425 40.6279 23.7926C39.2283 23.4208 38.113 22.9616 37.282 22.4149L38.9877 18.7409C39.775 19.2439 40.7263 19.6594 41.8416 19.9874C42.9569 20.2936 44.0504 20.4467 45.1219 20.4467C47.2869 20.4467 48.3694 19.9109 48.3694 18.8393C48.3694 18.3363 48.0742 17.9755 47.4837 17.7568C46.8933 17.5381 45.9857 17.3523 44.7611 17.1992C43.3178 16.9805 42.1259 16.729 41.1856 16.4447C40.2452 16.1604 39.4251 15.6574 38.7253 14.9358C38.0474 14.2141 37.7084 13.1863 37.7084 11.8523C37.7084 10.737 38.0255 9.75287 38.6597 8.89999C39.3158 8.02524 40.2561 7.34731 41.4808 6.8662C42.7273 6.38509 44.1925 6.14453 45.8764 6.14453C47.1229 6.14453 48.3585 6.28668 49.5831 6.57097C50.8297 6.8334 51.8575 7.20516 52.6666 7.68628L50.9609 11.3274C49.4082 10.4527 47.7134 10.0153 45.8764 10.0153C44.783 10.0153 43.9629 10.1684 43.4162 10.4745C42.8694 10.7807 42.5961 11.1743 42.5961 11.6555C42.5961 12.2022 42.8913 12.5849 43.4818 12.8036C44.0722 13.0222 45.0126 13.23 46.3028 13.4268C47.7462 13.6674 48.9271 13.9298 49.8456 14.2141C50.7641 14.4765 51.5623 14.9686 52.2402 15.6902C52.9181 16.4119 53.2571 17.4179 53.2571 18.7081C53.2571 19.8016 52.9291 20.7747 52.273 21.6276C51.6169 22.4805 50.6547 23.1475 49.3863 23.6286C48.1398 24.0878 46.6527 24.3174 44.9251 24.3174Z"
        fill="#397DFF"
      />
      <path
        d="M79.0157 6.14453C81.2245 6.14453 82.974 6.80059 84.2642 8.11272C85.5763 9.40297 86.2324 11.3493 86.2324 13.9517V24.055H81.1151V14.7389C81.1151 13.3393 80.8199 12.3006 80.2294 11.6226C79.6609 10.9228 78.8408 10.5729 77.7692 10.5729C76.5664 10.5729 75.6151 10.9666 74.9153 11.7539C74.2155 12.5193 73.8656 13.6674 73.8656 15.1982V24.055H68.7483V14.7389C68.7483 11.9616 67.633 10.5729 65.4024 10.5729C64.2215 10.5729 63.2812 10.9666 62.5814 11.7539C61.8816 12.5193 61.5317 13.6674 61.5317 15.1982V24.055H56.4144V6.40695H61.302V8.44075C61.9581 7.69721 62.7563 7.12862 63.6967 6.73499C64.6589 6.34135 65.7086 6.14453 66.8458 6.14453C68.0923 6.14453 69.2185 6.39602 70.2245 6.899C71.2305 7.38011 72.0396 8.09085 72.6519 9.0312C73.3736 8.11272 74.2811 7.40198 75.3746 6.899C76.4899 6.39602 77.7036 6.14453 79.0157 6.14453Z"
        fill="#397DFF"
      />
      <path
        d="M108.299 15.2966C108.299 15.3622 108.266 15.8214 108.2 16.6743H94.8494C95.0899 17.7678 95.6585 18.6316 96.5551 19.2658C97.4517 19.9 98.5671 20.2171 99.901 20.2171C100.82 20.2171 101.629 20.0858 102.328 19.8234C103.05 19.5391 103.717 19.1018 104.329 18.5113L107.052 21.4636C105.39 23.3662 102.963 24.3174 99.7698 24.3174C97.7798 24.3174 96.0193 23.9347 94.4885 23.1693C92.9577 22.3821 91.7768 21.2996 90.9458 19.9218C90.1148 18.5441 89.6993 16.9805 89.6993 15.231C89.6993 13.5034 90.1039 11.9507 90.913 10.5729C91.744 9.17335 92.8702 8.09085 94.2917 7.32544C95.735 6.53817 97.3424 6.14453 99.1138 6.14453C100.841 6.14453 102.405 6.5163 103.805 7.25984C105.204 8.00337 106.298 9.07494 107.085 10.4745C107.894 11.8523 108.299 13.4596 108.299 15.2966ZM99.1466 10.0153C97.9875 10.0153 97.0144 10.3433 96.2271 10.9994C95.4398 11.6555 94.9587 12.5521 94.7838 13.6892H103.477C103.302 12.5739 102.821 11.6883 102.033 11.0322C101.246 10.3543 100.284 10.0153 99.1466 10.0153Z"
        fill="#397DFF"
      />
      <path
        d="M122.432 6.14453C124.619 6.14453 126.379 6.80059 127.713 8.11272C129.069 9.42484 129.747 11.3712 129.747 13.9517V24.055H124.63V14.7389C124.63 13.3393 124.324 12.3006 123.711 11.6226C123.099 10.9228 122.213 10.5729 121.054 10.5729C119.764 10.5729 118.736 10.9775 117.971 11.7867C117.206 12.5739 116.823 13.7548 116.823 15.3294V24.055H111.706V6.40695H116.593V8.47355C117.271 7.73001 118.113 7.16143 119.119 6.76779C120.125 6.35228 121.229 6.14453 122.432 6.14453Z"
        fill="#397DFF"
      />
      <path
        d="M141.504 6.14453C144.238 6.14453 146.337 6.80059 147.802 8.11272C149.267 9.40297 150 11.3602 150 13.9845V24.055H145.211V21.8572C144.249 23.4974 142.455 24.3174 139.831 24.3174C138.475 24.3174 137.294 24.0878 136.288 23.6286C135.304 23.1693 134.55 22.5351 134.025 21.726C133.5 20.9169 133.238 19.9984 133.238 18.9705C133.238 17.3304 133.85 16.0401 135.075 15.0998C136.321 14.1594 138.235 13.6892 140.815 13.6892H144.883C144.883 12.5739 144.544 11.7211 143.866 11.1306C143.188 10.5183 142.171 10.2121 140.815 10.2121C139.875 10.2121 138.945 10.3652 138.027 10.6714C137.13 10.9557 136.365 11.3493 135.731 11.8523L133.894 8.27673C134.856 7.5988 136.004 7.07395 137.338 6.70218C138.694 6.33042 140.083 6.14453 141.504 6.14453ZM141.11 20.8731C141.985 20.8731 142.762 20.6763 143.439 20.2827C144.117 19.8672 144.599 19.2658 144.883 18.4785V16.6743H141.373C139.273 16.6743 138.224 17.3632 138.224 18.7409C138.224 19.397 138.475 19.9218 138.978 20.3155C139.503 20.6872 140.214 20.8731 141.11 20.8731Z"
        fill="#397DFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8687 13.668C21.8687 9.13882 18.1971 5.46721 13.668 5.46721C9.13879 5.46721 5.46718 9.13882 5.46718 13.668C5.46718 18.1971 9.13879 21.8688 13.668 21.8688C18.1971 21.8688 21.8687 18.1971 21.8687 13.668ZM27.3359 13.668C27.3359 6.11938 21.2166 2.38681e-05 13.668 2.41981e-05C6.11935 2.4528e-05 -9.27405e-07 6.11938 -5.97445e-07 13.668C-2.67485e-07 21.2166 6.11935 27.3359 13.668 27.3359C21.2166 27.3359 27.3359 21.2166 27.3359 13.668Z"
        fill="#397DFF"
      />
    </svg>
  );
};

export default Logo;
