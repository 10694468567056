import { StoryStage } from 'generated/graphql';

export const storyStatuses = [
  {
    id: StoryStage.New,
    name: 'Черновик',
    className: 'bg-smena_white border border-smena_text-secondary text-smena_text-secondary',
  },
  {
    id: StoryStage.Await,
    name: 'Ожидает публикации',
    className: 'bg-smena_yellow-extra_light text-smena_orange-dark',
  },
  {
    id: StoryStage.Published,
    name: 'Опубликовано',
    className: 'bg-smena_green-extra_light text-smena_green-dark',
  },
  {
    id: StoryStage.Removed,
    name: 'Снято с публикации',
    className: 'bg-smena_white border border-smena_text-secondary text-smena_text-secondary',
  },
];
