import { useParams } from 'react-router-dom';
import { useFacilityLazyQuery } from 'pages/facility/api/queries/generated/Facility';
import { useEffect } from 'react';

export const useFacility = () => {
  const router = useParams();
  const id = router.facilityId;

  const [request, { data, loading, error, refetch }] = useFacilityLazyQuery();
  useEffect(() => {
    if (!id) return;
    request({
      variables: {
        id,
      },
    });
  }, [id, request]);
  const facility = data?.facility;
  return { facility, loading, error, id, refetchFacility: refetch };
};

export const useFacilityById = (id?: string | null) => {
  const [request, { data, loading, error }] = useFacilityLazyQuery();
  useEffect(() => {
    if (!id) return;
    request({
      variables: {
        id,
      },
    });
  }, [id, request]);
  const facility = data?.facility;
  return { facility, loading, error };
};
