import { useMemo } from 'react';
import { useMeRole } from '../../../store/hooks/useMeRole';
import { useFacility } from '../../api/useFacility';
import { getLink } from '../../helpers/get/getLink';
import { useParams } from 'react-router-dom';
import { Role } from 'generated/graphql';

const useFacilityHeaderMenu = () => {
  const { id } = useFacility();
  const router = useParams();
  const facilityGroupId = router.id;
  const isMeAdmin = useMeRole([Role.Admin]);
  const facilityLink = `/facilities/${id}`;
  const facilityGroupLink = `/groups/${facilityGroupId}${facilityLink}`;
  const mainPage = facilityGroupId ? getLink(facilityGroupLink) : getLink(facilityLink);
  const tabel = getLink(`${facilityLink}/tabel`);
  const users = facilityGroupId ? getLink(`${facilityGroupLink}/users`) : getLink(`${facilityLink}/users`);
  const firedUsers = facilityGroupId
    ? getLink(`${facilityGroupLink}/fired-users`)
    : getLink(`${facilityLink}/fired-users`);
  const requests = facilityGroupId ? getLink(`${facilityGroupLink}/requests`) : getLink(`${facilityLink}/requests`);
  const schedule = facilityGroupId ? getLink(`${facilityGroupLink}/schedule`) : getLink(`${facilityLink}/schedule`);
  const casings = facilityGroupId ? getLink(`${facilityGroupLink}/casings`) : getLink(`${facilityLink}/casings`);
  const vacancies = facilityGroupId ? getLink(`${facilityGroupLink}/vacancies`) : getLink(`${facilityLink}/vacancies`);
  return useMemo(() => {
    const array = [
      {
        name: 'Информация',
        link: mainPage,
        disabled: !id,
      },
      {
        name: 'Табель',
        link: tabel,
        disabled: !id,
      },
      {
        name: 'Сотрудничают',
        link: users,
        disabled: !id,
      },
      {
        name: 'Более не сотрудничают',
        link: firedUsers,
        disabled: !id,
      },
      {
        name: 'Заявки',
        link: requests,
        disabled: !id,
      },
      {
        name: 'Графики',
        link: schedule,
        disabled: !id,
      },
      {
        name: 'Профессии',
        link: casings,
        disabled: !id,
      },
    ];
    if (isMeAdmin) {
      array.push({
        name: 'Вакансии',
        link: vacancies,
        disabled: !id,
      });
    }
    return array;
  }, [casings, firedUsers, id, isMeAdmin, mainPage, requests, schedule, users, vacancies, tabel]);
};

export default useFacilityHeaderMenu;
