import React from 'react';

import { useStore } from 'store';

import MainLayout from 'components/layout/main';
import PaymentsTable from 'pages/payments/components';
import PaymentsFilter from 'pages/payments/components/filter';

import ManualUserAccruals from 'pages/payments/components/manualUserAccruals';

import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useSendManualAccrualsMutation } from './api/mutations/generated/SendManualAccurals';

const PaymentsPage = () => {
  const manualAccrual = useStore(state => state.manualAccrual);
  const manualAccrualUserIds = useStore(state => state.manualAccrualUserIds);
  const [sendManual, { loading }] = useSendManualAccrualsMutation();

  const submitHandler = () => {
    sendManual({
      variables: {
        input: {
          accrualId: manualAccrual ? manualAccrual.id : '',
          userIds: manualAccrualUserIds,
        },
      },
    })
      .then(e => {
        if (e.data?.sendManualAccurals) {
          successToast('Выплата успешно произведена');
        }
      })
      .catch(e => errorToast(errorHandler(e)));
  };

  return (
    <MainLayout title="Начисления" breadCrumbs={[{ path: '/payments', name: 'Начисления' }]}>
      <div className="mt-5 pb-10 flex flex-col gap-y-5">
        <PaymentsFilter />
        {manualAccrual && (
          <>
            <PaymentsTable />
            <div>
              <button
                className="btn-primary_big my-4 mb-8"
                style={manualAccrualUserIds.length === 0 ? { cursor: 'not-allowed' } : {}}
                disabled={manualAccrualUserIds.length === 0 || loading}
                onClick={submitHandler}
              >
                Выплатить
              </button>
            </div>

            {!!manualAccrual.manualUserAccruals?.length && <ManualUserAccruals />}
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default PaymentsPage;
