import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import MainSidebar from 'ui/sidebar';
import { useStore } from 'store';
import { isTest, RU_DATE, TIME_DATE } from 'utils/helpers/constVariables';
import { errorToast, successToast } from 'utils/helpers/notify';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useAssignRequestScheduleMutation } from 'pages/facilityRequests/api/mutations/generated/AssignRequestSchedule';
import { useDeleteWorkerFromRequestScheduleMutation } from 'pages/facilityRequests/api/mutations/generated/DeleteWorkerFromRequestSchedule';
import { getScheduleTypeName } from 'utils/api/useGetScheduleTypes/getScheduleTypeName';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { useGetRequestByFacilityId } from '../../../../../utils/api/useGetRequestByFacilityId';
import FreeSchedule from './freeSchedule';
import ScheduleInfo from './scheduleInfo';
import Modal from 'ui/modal';

const ScheduleSidebar = () => {
  const [showPopup, setShowPopup] = useState(false);
  const showFreeScheduleSidebar = useStore(state => state.showFreeScheduleSidebar);
  const setShowFreeScheduleSidebar = useStore(state => state.setShowFreeScheduleSidebar);
  const showGraphicSidebar = useStore(state => state.showGraphicSidebar);
  const setShowGraphicSidebar = useStore(state => state.setShowGraphicSidebar);
  const { sortedRequests } = useGetRequestByFacilityId();
  const { scheduleTypes } = useGetScheduleTypes();
  const { requestSchedule, userIntersect, facilityWorkposts } = useStore();

  const currentRequest = useMemo(
    () => sortedRequests.find(el => el.number === requestSchedule?.number),
    [requestSchedule?.number, sortedRequests],
  );

  const title = useMemo(
    () =>
      `График ${dayjs(requestSchedule?.dateFrom).format(RU_DATE)} - ${dayjs(requestSchedule?.dateTo).format(RU_DATE)}`,
    [requestSchedule?.dateFrom, requestSchedule?.dateTo],
  );
  const [assignRequestSchedule] = useAssignRequestScheduleMutation();
  const [deleteWorkerFromRequestSchedule, { loading: loadingDeleteWorker }] =
    useDeleteWorkerFromRequestScheduleMutation();

  const closePopupHandler = useCallback(() => {
    setShowPopup(false);
  }, []);

  const deleteWorkerFromRequestScheduleHandler = useCallback(() => {
    if (requestSchedule?.id) {
      deleteWorkerFromRequestSchedule({
        variables: {
          id: requestSchedule.id,
        },
      })
        .then(res => {
          if (res.data?.deleteWorkerFromZayavkaSchedule) {
            successToast('Все исполнители удалены из графика');
          }
        })
        .catch(error => {
          errorToast(errorHandler(error));
        })
        .finally(closePopupHandler);
    }
  }, [closePopupHandler, deleteWorkerFromRequestSchedule, requestSchedule?.id]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!requestSchedule) return errorToast('Выберите исполнителя');
    if (!userIntersect) return;
    const isNeedCheckMeedBook = facilityWorkposts?.find(
      el => el.positionId === requestSchedule.positionId,
    )?.isMedBookRequired;
    const medBookEnd = userIntersect.requisite?.medicalAttestationEnd;
    const isUserMedBookInvalid = dayjs(medBookEnd).isBefore(dayjs());
    if (isNeedCheckMeedBook) {
      if (!dayjs(medBookEnd).isValid()) {
        return errorToast('Мед. книжка отсутствует');
      }
      if (isUserMedBookInvalid) {
        return errorToast('Мед. книжка просрочена');
      }
    }

    return assignRequestSchedule({
      variables: {
        workerId: userIntersect.id,
        zayavkaScheduleId: requestSchedule.id,
      },
    })
      .then(res => {
        const data = res.data?.assignZayavkaSchedule;
        if (data) {
          successToast('Пользователь назначен на график');
          setShowGraphicSidebar(false);
        }
      })
      .catch(error => errorToast(errorHandler(error)));
  };

  return (
    <>
      <MainSidebar
        title={title}
        show={showGraphicSidebar}
        setShow={setShowGraphicSidebar}
        onClose={() => {
          setTimeout(() => {
            setShowFreeScheduleSidebar(false);
          }, 100);
        }}
      >
        <form className="flex flex-col gap-y-2" onSubmit={onSubmit}>
          {isTest && (
            <>
              <div className="flex gap-x-1 Subtitle1">
                <span>ID графика: </span>
                <span>{requestSchedule?.id}</span>
              </div>
            </>
          )}
          <div className="flex gap-x-1 Subtitle1">
            <Link to={`/requests/${requestSchedule?.zayavkaId}`} className="text-primary">
              {requestSchedule?.number}
            </Link>
            <span>·</span>
            <span>{dayjs(currentRequest?.dateFrom).format(RU_DATE)}</span>
            <span> – </span>
            <span>{dayjs(currentRequest?.dateTo).format(RU_DATE)}</span>
          </div>
          <div className="flex flex-col gap-y-6">
            <h4 className="H4">{requestSchedule?.position?.name}</h4>
            <div className="flex gap-x-1 Subtitle2">
              <span>
                {getScheduleTypeName(
                  scheduleTypes,
                  requestSchedule?.newScheduleTypeId || requestSchedule?.scheduleTypeId,
                )}
              </span>
              <span>·</span>
              <span>
                {dayjs(requestSchedule?.dateFrom).format(TIME_DATE)} –{' '}
                {dayjs(requestSchedule?.dateTo).format(TIME_DATE)}
              </span>
            </div>
            {showFreeScheduleSidebar ? <FreeSchedule /> : <ScheduleInfo setShowPopup={setShowPopup} />}
          </div>
        </form>
      </MainSidebar>

      <Modal
        type="delete"
        title="Снять со всего графика?"
        open={showPopup}
        onCancel={closePopupHandler}
        onOk={deleteWorkerFromRequestScheduleHandler}
        okButtonProps={{ disabled: loadingDeleteWorker }}
        okText="Снять"
      >
        Вы уверены, что хотите снять исполнителя со всего графика?
      </Modal>
    </>
  );
};

export default ScheduleSidebar;
