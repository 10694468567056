import { usePush } from 'utils/api/usePush';

export const useStoriesTitle = () => {
  const { push } = usePush();

  if (push?.name) {
    return push.name;
  }
  return 'Без названия';
};
