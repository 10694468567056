import { ReferralProgramStatusEnum } from 'generated/graphql';

export const getReferralProgramStatus = (status?: ReferralProgramStatusEnum | string | null): string => {
  if (!status) return '';

  const statuses: Record<ReferralProgramStatusEnum, string> = {
    [ReferralProgramStatusEnum.Active]: 'Активно',
    [ReferralProgramStatusEnum.Archive]: 'Архив',
    [ReferralProgramStatusEnum.Draft]: 'Черновик',
    [ReferralProgramStatusEnum.Planned]: 'Запланировано',
  };

  const enumStatus = Object.values(ReferralProgramStatusEnum).includes(status as ReferralProgramStatusEnum)
    ? (status as ReferralProgramStatusEnum)
    : null;

  return enumStatus ? statuses[enumStatus] : 'Неизвестный статус';
};
