import { Link } from 'react-router-dom';
import Across from 'assets/icons/Across';
import Check from 'assets/icons/check';

import { mapColumns } from 'utils/helpers/mapColumns';
import { positionsColumns } from 'components/table/columns';
import { PositionFragment } from '../../position/api/fragments/generated/Position.fragment';

interface Props {
  position: PositionFragment;
}
const PositionsRow = ({ position }: Props) => {
  return (
    <div
      className="table__row grid odd:bg-smena_white even:bg-smena_bb-background_base hover:bg-smena_bb-background"
      style={{
        gridTemplateColumns: mapColumns(positionsColumns),
      }}
    >
      <div className={positionsColumns[0].className}>
        <Link to={`/positions/${position.id}`} className={'link'}>
          {position.name}
        </Link>
      </div>

      <div className={positionsColumns[1].className}>
        {position.isSelfEmployed ? <Check className="text-smena_green-dark" /> : <Across className="text-smena_red" />}
      </div>
    </div>
  );
};

export default PositionsRow;
