import MainLayout from '../../components/layout/main';
import Editor from './components/Editor';
import PublicationSettings from './components/PublicationSettigns';
import { useReferralProgramHeaderMenu } from '../../utils/hooks/headerMenu/useReferralProgramHeaderMenu';
import { Form, Formik } from 'formik';
import useReferralProgramCreatePage from './utils/hooks';

const CreateReferralProgramPage = () => {
  const headerMenu = useReferralProgramHeaderMenu();
  const { onSubmit, validate, initialValues } = useReferralProgramCreatePage();

  return (
    <MainLayout
      title="Новая реферальная программа"
      breadCrumbs={[
        { path: '/offers/referrals', name: 'Акции' },
        { path: '/offers/referrals/create', name: 'Новая реферальная программа' },
      ]}
      headerMenu={headerMenu}
    >
      <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ values, touched, errors, handleChange, setFieldValue }) => {
          return (
            <Form>
              <PublicationSettings values={values} setFieldValue={setFieldValue} />
              <Editor
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
              />
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};

export default CreateReferralProgramPage;
