import React from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { mapColumns } from 'utils/helpers/mapColumns';
import { twentyOneColumns } from 'components/table/columns';
import Column from 'components/table/header/column';
import SkeletonLayout from 'components/table/Skeleton';
import { IColumns } from 'interfaces/IColumns';
import { useSearchParams } from 'react-router-dom';
import { OchkoReferrersSorting } from 'generated/graphql';

interface Props {
  data?: any[];
  loading: boolean;
  columns: IColumns[];
  defaultSortBy: OchkoReferrersSorting;
  children: React.ReactNode;
  headerMenu?: boolean;
}

const TwentyOneTable = ({ data, loading, columns, children, defaultSortBy }: Props) => {
  const [params] = useSearchParams();
  const searchParam = params.get('search');
  return (
    <ScrollSync>
      <div className="grid gap-y-3 rounded-lg relative overflow-y-auto">
        <ScrollSyncPane>
          <div
            className="grid z-20 sticky top-0 bg-smena_bb-background_base"
            style={{
              gridTemplateColumns: mapColumns(twentyOneColumns),
            }}
          >
            {twentyOneColumns.map(column => (
              <Column key={column.name} column={column} defaultSortBy={defaultSortBy} />
            ))}
          </div>
        </ScrollSyncPane>
        {loading ? (
          <SkeletonLayout columns={columns} />
        ) : data?.length ? (
          <ScrollSyncPane>
            <>{children}</>
          </ScrollSyncPane>
        ) : (
          <div className="w-full flex justify-center my-5">
            <h3 className="text-smena_text Body1">
              По вашему запросу <span className="font-bold">{searchParam}</span> ничего не нашлось
            </h3>
          </div>
        )}
      </div>
    </ScrollSync>
  );
};

export default TwentyOneTable;
