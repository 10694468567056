import React from 'react';
import Progress from 'antd/es/progress';

interface Props {
  percent: number;
  strokeColor: string;
  type?: 'circle' | 'line' | 'dashboard';
  width?: number;
}

const ProgressComponent: React.FC<Props> = React.memo(({ percent, strokeColor, type, width }) => {
  return (
    <Progress
      type={type}
      percent={percent}
      strokeColor={strokeColor}
      trailColor="#E0E0E0"
      size={width}
      showInfo={false}
    />
  );
});

export default ProgressComponent;
