import { useUserLazyQuery, useUserQuery } from 'pages/user/api/queries/generated/User';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useCurrentUser = () => {
  const router = useParams();
  const userId = router.userId;

  const [requestUser, { data, loading, error }] = useUserLazyQuery();

  const user = data?.user;
  const requisites = user?.requisite;

  useEffect(() => {
    if (!userId) return;
    requestUser({
      variables: {
        userId,
      },
    });
  }, [requestUser, userId]);

  return { user, loading, error, userId, requisites };
};

export const useUser = (userId: string) => {
  const { data, loading, error } = useUserQuery({
    variables: {
      userId,
    },
  });
  const user = data?.user;
  const requisites = user?.requisite;

  return { user, loading, error, requisites };
};
