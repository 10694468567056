import React from 'react';
import clsx from 'clsx';

interface Props {
  showBurgerMenu: boolean;
  setShowBurgerMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const BurgerButton = React.memo(({ showBurgerMenu, setShowBurgerMenu }: Props) => {
  return (
    <button
      className="w-5 h-5 md:hidden"
      onClick={() => {
        setShowBurgerMenu(value => !value);
      }}
    >
      <span
        className={clsx(
          "after:content-[''] after:block after:absolute after:h-0.5 after:rounded-lg after:w-full after:bg-primary after:ease-in-out after:duration-300",
          showBurgerMenu ? 'after:top-0 after:rotate-45' : 'after:-top-2',
          "before:content-[''] before:block before:absolute before:h-0.5 before:rounded-lg before:w-full before:bg-primary before:ease-in-out before:duration-300",
          showBurgerMenu ? 'before:top-0 before:-rotate-45' : 'before:top-2',
          'block relative w-full rounded-lg bg-primary ease-in-out duration-300',
          showBurgerMenu ? 'h-0' : 'h-0.5',
        )}
      ></span>
    </button>
  );
});

export default BurgerButton;
