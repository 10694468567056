import * as Types from '../../../../../generated/graphql';

import { DocumentNode } from 'graphql';
import { ShiftFactFragmentDoc } from '../../fragments/generated/ShiftFact.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateShiftInModerationMutationVariables = Types.Exact<{
  moderation: Types.CreateModerationInput;
  shift: Types.SingleShiftInput;
}>;


export type CreateShiftInModerationMutation = { __typename?: 'Mutation', createShiftInModeration: { __typename?: 'ShiftFact', id: string, updatedAt: any, comment?: string | null, dateFrom: any, dateEnd: any, cost: number, isProbation: boolean, hourRate?: number | null, lunchDuration?: number | null, newCommentsNum: number, userId: string, durationFact: number, durationPlan: number, unit: Types.UnitType, probationRate?: number | null, positionId: string, value?: number | null, payout: Types.PayoutType, stage: Types.ShiftStage, comments: Array<{ __typename?: 'CommentEntity', createdAt: any, isRead: boolean, id: string, message: string, author: { __typename?: 'User', id: string, firstname: string, lastname: string, patronymic?: string | null } }>, promotionResults?: Array<{ __typename?: 'PromotionResult', progress: number, promotion: { __typename?: 'PromotionEntity', name: string, rewardSum: number, rewardType: Types.PromotionRewardTypeEnum, requirementShiftCount: number } }> | null, moderation?: { __typename?: 'Moderation', id: string, cause: string, comment: string, cost: number, duration: number, hourRate: number, shiftId: string, toPayment: boolean, updatedAt: any, value?: number | null, probationRate?: number | null, toPaymentBonus: boolean } | null, marks: Array<{ __typename?: 'Mark', status: Types.MarkStatus, statusMessage: string, type: Types.MarkType, time: any, latitude?: string | null, longitude?: string | null, distance?: number | null }>, penalties?: Array<{ __typename?: 'ShiftPenalty', id: string, updatedAt: any, authorId?: string | null, cause?: string | null, comment?: string | null, cost: number, penaltyId: string, shiftId: string, status: Types.ShiftPenaltyStatus, userId: string }> | null } };


export const CreateShiftInModerationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateShiftInModeration"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"moderation"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateModerationInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"shift"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SingleShiftInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createShiftInModeration"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"moderation"},"value":{"kind":"Variable","name":{"kind":"Name","value":"moderation"}}},{"kind":"Argument","name":{"kind":"Name","value":"shift"},"value":{"kind":"Variable","name":{"kind":"Name","value":"shift"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShiftFact"}}]}}]}},...ShiftFactFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateShiftInModerationMutationFn = Apollo.MutationFunction<CreateShiftInModerationMutation, CreateShiftInModerationMutationVariables>;

/**
 * __useCreateShiftInModerationMutation__
 *
 * To run a mutation, you first call `useCreateShiftInModerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftInModerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftInModerationMutation, { data, loading, error }] = useCreateShiftInModerationMutation({
 *   variables: {
 *      moderation: // value for 'moderation'
 *      shift: // value for 'shift'
 *   },
 * });
 */
export function useCreateShiftInModerationMutation(baseOptions?: Apollo.MutationHookOptions<CreateShiftInModerationMutation, CreateShiftInModerationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShiftInModerationMutation, CreateShiftInModerationMutationVariables>(CreateShiftInModerationDocument, options);
      }
export type CreateShiftInModerationMutationHookResult = ReturnType<typeof useCreateShiftInModerationMutation>;
export type CreateShiftInModerationMutationResult = Apollo.MutationResult<CreateShiftInModerationMutation>;
export type CreateShiftInModerationMutationOptions = Apollo.BaseMutationOptions<CreateShiftInModerationMutation, CreateShiftInModerationMutationVariables>;