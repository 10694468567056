import React, { useEffect } from 'react';
import Promotion from './promotion';
import dayjs from 'dayjs';
import { usePromotionStore } from 'pages/facilityGroup/useBonusProgramStore';
import { useGetListPromotionsForFacilityLazyQuery } from 'pages/facility/api/queries/generated/GetListPromotionsForFacility';
import { useFacility } from '../../../../utils/api/useFacility';

const PromotionBlock = () => {
  const { id } = useFacility();
  const promotions = usePromotionStore(state => state.promotions);
  const setPromotions = usePromotionStore(state => state.setPromotions);
  const [loadPromotions] = useGetListPromotionsForFacilityLazyQuery();

  useEffect(() => {
    if (!id) return;
    loadPromotions({ variables: { input: { facilityId: id } } }).then(res => {
      const promotions = res.data?.getListPromotionsForFacility;
      if (promotions?.length) {
        return setPromotions(
          promotions.map(el => ({
            ...el,
            dateFrom: el.dateTimeStart ? dayjs(el.dateTimeStart) : null,
            timeFrom: el.dateTimeStart ? dayjs(el.dateTimeStart) : null,
            dateTo: el.dateTimeEnd ? dayjs(el.dateTimeEnd) : null,
            timeTo: el.dateTimeEnd ? dayjs(el.dateTimeEnd) : null,
            endless: false,
          })),
        );
      }
    });
  }, [id, loadPromotions, setPromotions]);

  useEffect(() => {
    return () => {
      setPromotions([]);
    };
  }, [setPromotions]);
  return (
    <div className="facilityPageBlock blockTemplate">
      <div className="grid grid-cols-6 gap-y-6">
        <span className="blockTitleTemplate">Управление бонусными программами</span>
        <p className={'Body1 text-smena_text-secondary col-span-full'}>
          Настройка бонусных программ здесь повлияет на бонусные программы всех объектов этой группы. Исключение:
          бонусы, которые были созданы или изменены непосредственно на объекте.
        </p>
        {promotions.map(el => (
          <Promotion key={el.id} el={el} />
        ))}
      </div>
    </div>
  );
};

export default PromotionBlock;
