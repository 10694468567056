import { UserContractStatus, UserContractType } from 'generated/graphql';
import { contractStatuses, contractTypes } from './consts';

export const getContractTypeRu = (type: UserContractType | null) => {
  const res = contractTypes.find(contractType => contractType.name === type)?.nameRu;
  if (!res) return '';
  return res;
};

export const getContractStatusRu = (status: UserContractStatus | null) => {
  return contractStatuses.find(contractStatus => contractStatus.name === status);
};
