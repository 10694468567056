import { useParams } from 'react-router-dom';
import { getLink } from '../../helpers/get/getLink';

const useFacilityGroupMenu = () => {
  const router = useParams();

  const id = router.id;
  const informPage = `/groups/${id}`;
  const groupFacilities = `/groups/${id}/facilities`;
  const groupCasings = `/groups/${id}/casings`;
  const groupVacancies = `/groups/${id}/vacancies`;
  const groupBalance = `/groups/${id}/balance`;
  return [
    { name: 'Информация', link: getLink(informPage) },
    {
      name: 'Объекты группы',
      link: getLink(groupFacilities),
      disabled: !id,
    },
    {
      name: 'Профессии',
      link: getLink(groupCasings),
      disabled: !id,
    },
    {
      name: 'Вакансии',
      link: getLink(groupVacancies),
      disabled: !id,
    },
    {
      name: 'Баланс',
      link: getLink(groupBalance),
      disabled: !id,
    },
  ];
};

export default useFacilityGroupMenu;
