import { ShiftStage } from 'generated/graphql';

export const statusBg = (stage: ShiftStage | string) => {
  if (stage === ShiftStage.Ineligible) {
    return {
      className: 'bg-smena_gray-5',
    };
  } else if (stage === ShiftStage.Refused) {
    return {
      className: 'bg-smena_red-extra_light',
    };
  } else if (stage === 'CUSTOM') {
    return {
      className: 'bg-primary-secondary',
    };
  } else if (
    [ShiftStage.Accepted, ShiftStage.Working, ShiftStage.Finished, ShiftStage.Wasted].includes(stage as ShiftStage)
  ) {
    return {
      className: 'bg-smena_green-extra_light',
    };
  }
  return {
    className: '',
  };
};
