import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { useStore } from 'store';

import SimpleSelect from 'ui/select';
import { errorHandler } from 'utils/helpers/errorHandler';
import { auchanLunchDurationTypes, lunchDurationTypes } from 'utils/helpers/lists/lunchDurationTypes';
import { lunchDurationHandle } from 'utils/helpers/lunchDuration';
import { timeDifference } from 'utils/helpers/timeDifference';
import { errorToast, successToast } from 'utils/helpers/notify';
import dateHour from './helpers/dateHour';
import DatePickerInput from 'ui/pickers/DatePickerInput';
import TimePickerInput from 'ui/pickers/TimePickerInput';
import { DayjsOrNull } from 'interfaces/CustomTypes';
import { dateEndFn } from 'utils/helpers/dateEndFn';
import { writeScheduleShiftsCache } from '../../../api/updateCache';
import { useChangeScheduleMutation } from 'pages/userSchedule/api/mutations/generated/ChangeSchedule';
import { useSearchParams } from 'react-router-dom';
import { useCurrentUser } from 'utils/api/useUser';
import useGetScheduleTypes from 'utils/api/useGetScheduleTypes';
import { shiftTimeDifference } from '../../../helpers';
import { useFacilityGroupById } from 'utils/api/useFacilityGroup';
import { useGetFacilityLazyQuery } from 'pages/userSchedule/api/queries/generated/GetFacility';

const NewSchedule = () => {
  const [params] = useSearchParams();
  const [dateStart, setDateStart] = useState<Dayjs | null>(dayjs());
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(dayjs());
  const [selectedTime, setSelectedTime] = useState<DayjsOrNull>(dayjs());
  const [selectedEndTime, setSelectedEndTime] = useState<DayjsOrNull>(dayjs().add(4, 'h'));
  const { scheduleTypes, twoXTwo } = useGetScheduleTypes();
  const [selectedType, setSelectedType] = useState<undefined | string>();
  const { userId } = useCurrentUser();
  const setCreateScheduleMenu = useStore(state => state.setCreateScheduleMenu);
  const facilityId = useStore(state => state.facilityId);
  const [loadFacility, { data }] = useGetFacilityLazyQuery();
  const { isAuchan } = useFacilityGroupById(data?.facility.facilityGroupId);

  const {
    lunchDuration,
    setLunchDuration,
    setFacilityIdError,
    workpostId,
    setWorkpostIdError,
    setStartScheduleTime,
    setEndScheduleTime,
  } = useStore();

  const dateFromParam = params.get('dateFrom');
  const dateToParam = params.get('dateTo');

  const dateFrom = dateFromParam ? dayjs(dateFromParam) : dayjs().startOf('month');
  const dateTo = dateToParam ? dayjs(dateToParam).endOf('day') : dayjs().endOf('month').endOf('day');

  const [changeScheduleMutation, { loading }] = useChangeScheduleMutation();

  const onChangeDateStart = useCallback(
    (selectedTime: DayjsOrNull) => {
      setSelectedTime(selectedTime);
      setStartScheduleTime(selectedTime);
      if (isAuchan) {
        setLunchDuration(lunchDurationHandle(timeDifference(selectedTime, selectedEndTime)));
      }
    },
    [isAuchan, selectedEndTime, setLunchDuration, setStartScheduleTime],
  );

  const onChangeDateEnd = useCallback(
    (selectedEndTime: DayjsOrNull) => {
      setSelectedEndTime(selectedEndTime);
      setEndScheduleTime(selectedEndTime);
      if (isAuchan) {
        setLunchDuration(lunchDurationHandle(timeDifference(selectedTime, selectedEndTime)));
      }
    },
    [setEndScheduleTime, isAuchan, setLunchDuration, selectedTime],
  );

  const dateEndVariable = useMemo(
    () => dateEndFn({ date: dateEnd, endTime: selectedEndTime, startTime: selectedTime }),
    [selectedEndTime, selectedTime, dateEnd],
  );

  const options = useMemo(() => (isAuchan ? auchanLunchDurationTypes : lunchDurationTypes), [isAuchan]);

  const onChangeSchedule = (value: string) => {
    setSelectedType(value);
  };

  const onChangeLunchDuration = useCallback(
    (value: string) => {
      if (!isAuchan) {
        setLunchDuration(Number(value));
      }
    },
    [isAuchan, setLunchDuration],
  );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (shiftTimeDifference(selectedTime, selectedEndTime)) {
      return errorToast('Смена не может быть меньше 1 часа');
    }
    if (!facilityId) {
      return setFacilityIdError('Обязательное поле');
    }
    if (!workpostId) {
      return setWorkpostIdError('Обязательное поле');
    }
    setWorkpostIdError('');
    setFacilityIdError('');

    if (selectedTime === null || selectedEndTime === null) {
      return errorToast('Выберите время');
    }

    if (!userId) return;

    const values = {
      type: Number(selectedType),
      typeId: String(selectedType),
      shiftEndDate: dateEndFn({
        date: dateStart,
        startTime: dateHour(dateStart, selectedTime),
        endTime: dateHour(dateStart, selectedEndTime),
      }),
      dateStart: dateHour(dateStart, selectedTime),
      dateEnd: dateEndVariable,
      facilityId,
      positionId: workpostId,
      userId,
      lunchDuration,
    };
    changeScheduleMutation({
      variables: {
        input: values,
      },
      update: (cache, { data }) => {
        writeScheduleShiftsCache({
          cache,
          userId,
          data: data?.changeSchedule,
          dateFrom,
          dateTo,
        });
      },
    })
      .then(() => {
        successToast('Вы успешно предложили график');
        setCreateScheduleMenu(false);
      })
      .catch(e => {
        errorToast(`Произошла ошибка: ${errorHandler(e)}`);
      });
  };

  useEffect(() => {
    if (twoXTwo) setSelectedType(twoXTwo);
  }, [twoXTwo]);

  useEffect(() => {
    if (!facilityId) return;
    loadFacility({
      variables: {
        id: facilityId,
      },
    });
  }, [facilityId, loadFacility]);

  return (
    <form onSubmit={onSubmit} className="grid grid-cols-4 gap-x-5 gap-y-4">
      <SimpleSelect
        divClassName="col-span-2"
        label="График"
        onChange={onChangeSchedule}
        value={selectedType}
        placeholder="Выберите график"
        options={scheduleTypes}
      />

      <SimpleSelect
        divClassName="col-span-2"
        label="Обед"
        sort={false}
        onChange={onChangeLunchDuration}
        value={lunchDuration}
        options={options}
        disabled={isAuchan}
      />

      <TimePickerInput
        label="Время начала смены"
        className="col-span-2"
        popupClassName="fixed"
        value={selectedTime}
        onChange={onChangeDateStart}
      />
      <TimePickerInput
        label="Время конца смены"
        className="col-span-2"
        popupClassName="fixed"
        value={selectedEndTime}
        onChange={onChangeDateEnd}
      />
      <DatePickerInput
        label="Дата начала"
        divClassName="col-span-2"
        popupClassName="fixed"
        value={dateStart}
        onChange={dateStart => {
          setDateStart(dateStart);
          if (dayjs(dateStart).valueOf() > dayjs(dateEnd).valueOf()) {
            setDateEnd(dateStart);
          }
        }}
        // minDate={dayjs().subtract(1, 'day')}
        error={!dateStart ? 'Обязательное поле' : ''}
      />
      <DatePickerInput
        label="Дата конца"
        divClassName="col-span-2"
        popupClassName="fixed"
        value={dateEnd}
        onChange={dateEnd => setDateEnd(dateEnd)}
        minDate={dateStart || undefined}
        error={!dateEnd ? 'Обязательное поле' : ''}
        placement={'bottomRight'}
      />
      <div className="col-span-full flex justify-end mt-7">
        <button type="submit" className="btn-primary_small" disabled={loading}>
          Предложить график
        </button>
      </div>
    </form>
  );
};

export default NewSchedule;
