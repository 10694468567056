import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import SimpleInput from 'ui/input';

import { useStore } from 'store';
import camera from 'assets/svg/Subtract.svg';
import { errorToast } from 'utils/helpers/notify';
import { useFacilityGroupExtended } from '../../../../utils/api/useFacilityGroup';
import { useFacilityGroupForm } from '../../hooks/useFacilityGroupForm';
import { getImagePath } from '../../../../utils/helpers/get/getImagePath';
// import { Role } from 'generated/graphql';
// import BonusProgramBlock from './components/bonusProgramBlock';

const UpdateGroup = () => {
  const [imgSrc, setImgSrc] = useState('');
  const { setFiles } = useStore();
  const { facilityGroup } = useFacilityGroupExtended();
  const { initialValues, validate, onSubmit, loading: onSubmitLoading } = useFacilityGroupForm(setImgSrc);

  let inputRef: HTMLInputElement | null;

  const onChange = ({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (validity.valid && files) {
      const file = files.item(0);
      if (file) {
        if (file.size > 1024 * 100) {
          return errorToast('Допустимый вес картинки - до 100 Кб');
        }
        setFiles([file]);
        setImgSrc(URL.createObjectURL(file));
      }
    }
  };

  const dragOverHandle = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dropHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setImgSrc(URL.createObjectURL(e.dataTransfer.files[0]));
    }
  };

  const onLoadHandler = useCallback(
    (e: React.ChangeEvent<HTMLImageElement>) => {
      const img = e.currentTarget;
      const height = img.naturalHeight;
      const width = img.naturalWidth;
      if (height > 500 || width > 500) {
        setFiles([]);
        setImgSrc('');
        errorToast('Допустимые размеры картинки - 500х500');
      }
    },
    [setFiles],
  );

  return (
    <Formik enableReinitialize initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ values, touched, errors, handleChange }) => {
        const imagePath = getImagePath(values.imageURL);
        return (
          <Form className="groupPageBlock blockTemplate">
            <span className="blockTitleTemplate">Основная информация</span>
            <div className="flex sm:flex-row flex-col justify-between gap-5">
              <div className="flex flex-col gap-y-5">
                <SimpleInput
                  divClassName="w-[220px]"
                  label="Название группы"
                  placeholder="Введите название группы"
                  onChange={handleChange}
                  value={values.groupName}
                  type="text"
                  name="groupName"
                  error={touched.groupName && errors.groupName ? errors.groupName : ''}
                />
              </div>

              <input
                type="file"
                onChange={onChange}
                name="fileUpload"
                id="fileUpload"
                ref={refParam => (inputRef = refParam)}
                className="hidden"
                accept="image/png, image/jpeg"
                value=""
              />
              <div className="flex flex-col items-center" style={{ width: '210px' }}>
                <button
                  className="uploadButton rounded-full border-primary
                      overflow-hidden flex justify-center items-center"
                  type="button"
                  onDragOver={dragOverHandle}
                  onDrop={dropHandler}
                  onClick={() => inputRef?.click()}
                >
                  {imgSrc || values.imageURL ? (
                    <img
                      src={imgSrc ? imgSrc : getImagePath(values.imageURL)}
                      alt=""
                      onLoad={onLoadHandler}
                      className="rounded-full object-cover w-[175px] h-[175px]"
                    />
                  ) : (
                    <div className="bg-smena_bb-background flex justify-center items-center rounded-full w-[175px] h-[175px]">
                      <img
                        src={values.imageURL ? imagePath : camera}
                        alt=""
                        style={{ width: '70px', height: '59px' }}
                      />
                    </div>
                  )}
                </button>
                <span className="Table text-smena_text px-4 pt-4 text-center">Нажмите, чтобы добавить логотип</span>
              </div>
            </div>
            <button type="submit" disabled={onSubmitLoading} className="btn-primary_big self-end">
              {facilityGroup?.id ? 'Сохранить' : 'Создать группу'}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UpdateGroup;
