import { useMemo } from 'react';
import { Role, ShiftStage } from 'generated/graphql';
import { useStore } from 'store';
import { useMeRole } from 'store/hooks/useMeRole';
import { useUserShifts } from '../../../pages/userTracking/lib/hooks/useUserShifts';
import useSupervisorShifts from '../../../pages/userTracking/lib/hooks/useSupervisorShifts';

const useRefusedShift = () => {
  const { userShifts } = useUserShifts();
  const { facilityId, workpostId, hideCanceledShifts } = useStore();
  const supervisorShifts = useSupervisorShifts();
  const isMeSupervisor = useMeRole([Role.Supervisor]);

  return useMemo(() => {
    const shifts = (hideCanceledShifts ? [] : isMeSupervisor ? supervisorShifts : userShifts) || [];
    return shifts.filter(shift => {
      const isStageFits = shift.stage === ShiftStage.Refused;
      const isPositionFits = workpostId ? shift.positionId === workpostId : true;
      const shiftFacilityId = shift.facility?.id;
      const isFacilityFits = facilityId ? shiftFacilityId === facilityId : true;
      return isStageFits && isPositionFits && isFacilityFits;
    });
  }, [facilityId, hideCanceledShifts, isMeSupervisor, supervisorShifts, userShifts, workpostId]);
};

export default useRefusedShift;
