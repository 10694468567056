import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { getRoleRu } from 'utils/helpers/get/getRoleRu';
import Check from 'assets/icons/check';
import Across from 'assets/icons/Across';
import React, { useCallback, useMemo, useState } from 'react';
import { errorToast, successToast } from 'utils/helpers/notify';
import FacilityCell from 'pages/users/components/ourUsers/facilityCell';
import { Role } from 'generated/graphql';
import { convertPhoneNumberToNormal } from 'utils/helpers/convertPhone';
import CopyIcon from 'assets/icons/CopyIcon';
import GarbageArrowLess from 'assets/icons/GarbageArrowLess';
import Garbage from 'assets/icons/GarbageArrow';
import Key from 'assets/icons/key';
import { errorHandler } from 'utils/helpers/errorHandler';
import { useFakeCodeMutation } from 'pages/users/api/mutations/generated/FakeCode';
import { useMeRole } from 'store/hooks/useMeRole';
import { updateUserPaginationRowCache } from '../../../pages/users/api/updateCache';
import { useChangeUserStatusMutation } from 'pages/users/api/mutations/generated/ChangeUserStatus';
import { userRole } from 'pages/main/lib/helpers/userRole';
import { UsersPaginatedFragment } from 'pages/users/api/fragments/generated/UsersPaginated.fragment';
import Tooltip from 'antd/es/tooltip';

export const useFiredUsersTable = (data: UsersPaginatedFragment[]) => {
  const isMeAdmin = useMeRole([Role.Admin]);
  const [fakeCodeMutation, { loading }] = useFakeCodeMutation();
  const fakeCode = useCallback(
    (user: UsersPaginatedFragment) => {
      fakeCodeMutation({
        variables: {
          phone: user.phone,
        },
      })
        .then(res => successToast(`Код доступа ${res.data?.fakeCode}`))
        .catch(e => errorHandler(e));
    },
    [fakeCodeMutation],
  );
  const [mutateUserStatus, { loading: mutateUserStatusLoading }] = useChangeUserStatusMutation();
  const toggleUserStatus = useCallback(
    (user: UsersPaginatedFragment) => {
      mutateUserStatus({
        variables: {
          input: {
            userId: user.id,
            isActive: !user.isActive,
          },
        },
        update: (cache, { data }) => {
          updateUserPaginationRowCache({ cache, data, user });
        },
      })
        .then(() => successToast('Статус активности изменен!'))
        .catch(err => errorToast(errorHandler(err)));
    },
    [mutateUserStatus],
  );
  const columns: ColumnDef<UsersPaginatedFragment>[] = useMemo(() => {
    return [
      {
        id: 'lastname',
        header: 'Фамилия',
        size: 150,
        minSize: 150,
        cell: ({ row: { original } }) => {
          return (
            <Link to={`/users/${original.id}`} className="link">
              {original.lastname ? original.lastname : 'Нет информации'}
            </Link>
          );
        },
      },
      {
        id: 'firstname',
        header: 'Имя',
        size: 150,
        minSize: 150,
        accessorKey: 'firstname',
      },
      {
        id: 'patronymic',
        header: 'Отчество',
        size: 150,
        minSize: 150,
        accessorKey: 'patronymic',
      },
      {
        id: 'role',
        header: 'Роль',
        size: 135,
        minSize: 135,
        cell: ({ row: { original } }) => getRoleRu(original.role),
      },
      {
        id: 'phone',
        header: 'Телефон',
        minSize: 185,
        cell: ({ row: { original } }) => {
          return (
            <div className={'flex items-center gap-x-2 group/number min-h-[24px]'}>
              <span>{convertPhoneNumberToNormal(original.phone)}</span>
              <button
                className={'group-hover/number:block hidden'}
                onClick={() => {
                  navigator.clipboard.writeText(original.phone).then(() => {
                    successToast('Номер скопирован', 1000);
                  });
                }}
              >
                <CopyIcon />
              </button>
            </div>
          );
        },
      },
      {
        id: 'facility',
        header: 'Объект',
        size: 180,
        minSize: 180,
        cell: ({ row: { original } }) => {
          return <FacilityCell user={original} showAcross={false} />;
        },
      },
      {
        id: 'currentVersion',
        header: 'Версия',
        cell: ({ row: { original } }) => original.currentVersion,
      },
      {
        id: 'isActive',
        header: 'Статус',
        size: 96,
        minSize: 96,
        cell: ({ row: { original } }) => {
          return original.isActive ? (
            <Check className="text-smena_green-dark" />
          ) : (
            <Across className="text-smena_red" />
          );
        },
      },
      {
        id: 'actions',
        header: 'Действия',
        size: 135,
        minSize: 135,
        cell: ({ row: { original } }) => {
          const isUserAdmin = userRole([Role.Admin], original);
          return (
            <div className="flex gap-x-3">
              <Tooltip title={'Изменить статус активности'}>
                <button disabled={mutateUserStatusLoading} onClick={() => toggleUserStatus(original)}>
                  {original.isActive ? <GarbageArrowLess /> : <Garbage />}
                </button>
              </Tooltip>
              {isMeAdmin ? (
                isUserAdmin ? (
                  <button disabled className="cursor-not-allowed">
                    <Key className="text-smena_text-disabled" />
                  </button>
                ) : (
                  <Tooltip title={'Получить SMS-код'}>
                    <button disabled={loading} onClick={() => fakeCode(original)}>
                      <Key className="text-smena_text" />
                    </button>
                  </Tooltip>
                )
              ) : (
                <button disabled className="cursor-not-allowed">
                  <Key className="text-smena_text-disabled" />
                </button>
              )}
            </div>
          );
        },
      },
    ];
  }, [fakeCode, isMeAdmin, loading, mutateUserStatusLoading, toggleUserStatus]);
  const [columnOrder, setColumnOrder] = useState(
    columns.map(column => column.id as string), //must start out with populated columnOrder so we can splice
  );

  return useReactTable({
    data,
    state: {
      columnOrder,
    },
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    columns,
    meta: {
      lastname: true,
      requisiteUpdatedAt: true,
      version: true,
      currentVersion: true,
    },
  });
};
