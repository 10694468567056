import React from 'react';
import EmptyBoxIcon from 'assets/icons/EmptyBoxIcon';

const Plug = () => {
  return (
    <div className={'flex flex-col justify-center items-center h-full gap-y-6'}>
      <EmptyBoxIcon />
      <div className={'flex items-center flex-col gap-y-2'}>
        <span className={'Subtitle2'}>У вас нет новых уведомлений</span>
        <span className={'Body2 text-smena_text-secondary text-center'}>
          Чтобы посмотреть старые уведомления, перейдите на вкладку “Прочитанные”
        </span>
      </div>
    </div>
  );
};

export default Plug;
