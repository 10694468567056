import React, { useCallback, useState } from 'react';
import Pagination, { PaginationProps } from 'antd/es/pagination';
import ChevronDown from 'assets/icons/chevron-down';
import { useSearchParams } from 'react-router-dom';
import SimpleInput from '../input';
import SimpleSelect from 'ui/select';

interface Props {
  currentPage: number;
  total: number;
  handleChangePage?: () => void;
}

const CustomPagination: React.FC<Props> = React.memo(({ currentPage, total, handleChangePage }) => {
  const [params, setParams] = useSearchParams();
  const [page, setPage] = useState<undefined | string>(currentPage.toString());
  const size = params.get('size') || 25;
  const onChange = useCallback(
    (page: number) => {
      params.set('page', String(page));
      setParams(params);
      if (handleChangePage) {
        handleChangePage();
      }
    },
    [handleChangePage, params, setParams],
  );
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const page = e.target.value;
    setPage(page);
  };
  const onSelectChange = useCallback(
    (value: string) => {
      params.set('size', value);
      setParams(params);
    },
    [params, setParams],
  );
  const onPageChange = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    params.set('page', String(page));
    setParams(params);
    if (handleChangePage) {
      handleChangePage();
    }
  };
  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <button className={'rotate-90 inline-block p-3'}>
          <ChevronDown />
        </button>
      );
    }
    if (type === 'next') {
      return (
        <button className={'-rotate-90 inline-block p-3'}>
          <ChevronDown />
        </button>
      );
    }
    return originalElement;
  };

  return (
    <div className={'flex justify-between p-4'}>
      <div className={'flex gap-x-4'}>
        <form className={'flex items-center gap-x-2'} onSubmit={onPageChange}>
          <span className={'Body2'}>Страница: </span>
          <SimpleInput
            divClassName={'w-[65px]'}
            type={'number'}
            onChange={onInputChange}
            value={page}
            name={'page'}
            min={1}
            max={total}
          />
        </form>
        <Pagination
          pageSize={1}
          current={currentPage}
          total={total}
          onChange={onChange}
          itemRender={itemRender}
          showSizeChanger={false}
        />
      </div>
      <div className={'flex items-center gap-x-2'}>
        <span className={'Body2'}>Показываем:</span>
        <SimpleSelect
          onChange={onSelectChange}
          placeholder={'10'}
          options={[
            { id: 10, name: '10' },
            { id: 25, name: '25' },
            { id: 50, name: '50' },
            { id: 100, name: '100' },
          ]}
          sort={false}
          value={size}
        />
      </div>
    </div>
  );
});

export default CustomPagination;
