import React from 'react';

const EmptyBoxIcon = () => {
  return (
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0903 4.68299L14.1053 4.64484L14.1139 4.60476C14.3388 3.55509 14.8776 2.69814 15.7457 2.01875C16.6201 1.3344 17.5751 1 18.625 1H46.375C47.4249 1 48.3799 1.3344 49.2543 2.01875C50.1224 2.69814 50.6612 3.55509 50.8861 4.60476L50.8947 4.64484L50.9097 4.68299L64 37.9698V59.125C64 60.4951 63.5299 61.6379 62.5839 62.5839C61.6379 63.5299 60.4951 64 59.125 64H5.875C4.5049 64 3.36205 63.5299 2.41605 62.5839C1.47006 61.6379 1 60.4951 1 59.125V37.9698L14.0903 4.68299ZM59.125 59.625H59.625V59.125V43.125V42.625H59.125H48.5H48.2336L48.085 42.8462L42.8586 50.625H22.1414L16.915 42.8462L16.7664 42.625H16.5H5.875H5.375V43.125V59.125V59.625H5.875H59.125ZM44.6535 41.0453L44.6919 40.9928L44.7156 40.9322C45.0553 40.0641 45.5718 39.4374 46.2589 39.0215C46.952 38.602 47.8557 38.375 49 38.375H58.125H58.8518L58.592 37.6962L46.342 5.69624L46.219 5.375H45.875H19.125H18.781L18.658 5.69624L6.40805 37.6962L6.14821 38.375H6.875H16C17.1443 38.375 18.048 38.602 18.7411 39.0215C19.4282 39.4374 19.9447 40.0641 20.2844 40.9322L20.3081 40.9928L20.3465 41.0453L24.0965 46.1703L24.2463 46.375H24.5H40.5H40.7537L40.9035 46.1703L44.6535 41.0453Z"
        fill="#EBEFFF"
        stroke="#397DFF"
      />
    </svg>
  );
};

export default EmptyBoxIcon;
